<section>
    <div class="title" data-aos="fade-up" data-aos-duration="3000"></div>
    <div>
        <div >
          <ul class="list-group list-group-horizontal-md" >
            <li class="list-group-item" *ngIf="Placementcategory" (click)="filtercategory(0)">All</li>
            <li class="list-group-item" *ngFor="let dt of Placementcategory" value="{{dt.categoryId}}">          
             <span (click)="filtercategory(dt.categoryId)">{{dt.categoryname}}</span>
            </li>
          </ul>
        </div>
      </div>
    <div class="placement">
        <div class="slide_img_content">
            <ng-image-slider #nav
              [images]="imageObject"
              [imagePopup]="false"
              [infinite]="false"
              [autoSlide]="1"
              [imageSize]="{width: '35%', height: 200}"
              slideImage="1"
              (imageClick)="imageClickHandler($event)"></ng-image-slider>
          </div>
    </div>
    <div class="d-flex justify-content-end">
        <button class="btn view_sm_btn mt-4" routerLink="/placements/{{fromslug}}">View All</button>
    </div>
</section>
