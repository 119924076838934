import { Component } from '@angular/core';

@Component({
  selector: 'app-students-grievance-redressal',
  templateUrl: './students-grievance-redressal.component.html',
  styleUrls: ['./students-grievance-redressal.component.css']
})
export class StudentsGrievanceRedressalComponent {

}
