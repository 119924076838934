import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, of, switchMap, tap } from 'rxjs';
import { ApicallService } from 'src/app/services/apicall.service';
import { HomeSeoService } from 'src/app/services/homeseo.service';
import { ProjectSeoService } from 'src/app/services/projectseo.service';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import * as AOS from 'aos';
import { CommonService } from '../service/common.service';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent {
  project_id = environment.projectid;   
  alldata:any=[];
  pgdata:any=[];
  AssignCategory:any=[];
  contentData:any=[];
  allcontentdata:any=[];
  constructor(   private route: ActivatedRoute,
    private seoService: HomeSeoService,
    private projectService: ProjectSeoService,
    private apiService: ApicallService,    
    private _service: CommonService,
    private spinner:NgxSpinnerService,
    private _ApicallService: ApicallService,){}

  ngOnInit(): void {
    AOS.init();
    this.getseo();
  }

  getseo() {
    let tbody = {
      slug:'gallery',
    };
    this._ApicallService.getGetseo(tbody).subscribe((data: any) => {
      this.getgallerydata();
      this.alldata = data?.data;
      this.pgdata = data?.data?.pagedata;
      this.projectService.sendMessageblog(data?.data?.Blog);
      this.projectService.sendMessagenews(data?.data?.News)
      this.projectService.setFacultysubject(data?.data?.Faculty);
      this.projectService.setPlacementsubject(data?.data?.Placement);
      this.projectService.setprogramssubject(data?.data?.Programs);//
      this.projectService.sendMessageseo(data?.data?.Testimonial);
      this.projectService.sendMessageFaqs(data?.data?.FAQs);
      this.projectService.setmeta(data?.data);
      this.projectService.sendMessagebread(data?.data.breadcrumb)
    });
  }

  getgallerydata(){
    this.spinner.show();
    let obj = {
      "Project_Id": this.project_id,
      "Type": "Photo Gallery",
      "pageurl":"home"
    }
    this._service.getContentbytype(obj).subscribe(
      res => {  
        this.spinner.hide();
        if(res.data){
          this.AssignCategory=JSON.parse(res.data[0]?.AssignCategory);
          this.allcontentdata=JSON.parse(res.data[0]?.contentData);
          this.contentData=JSON.parse(res.data[0]?.contentData);
          console.log('this.category',this.AssignCategory);
          console.log('this.caontentdata',this.contentData);
        }
        
      })
  }

  getimage(catid:number){
    let data=this.allcontentdata.filter((dt:any)=>{
      return dt.category==catid
    }).map((obj:any)=>{
      return obj;
    })
    this.contentData=data;
  }
}
