
    <div class="programers_content">
        <div class="title">
            Programmes
        </div>
        <div class="img_txt_list boysImg_mobileView">
            <div class="row">
                <div class="col-md-7">
                    <div class="listDiv mt-4">
                        <div class="">
                            <img srcset="../../../assets/img/PlayGroup.png" loading="lazy" class="listImg1 responsive" align="center"
                                alt="">
                        </div>
                        <div class="listText">
                            <div class="programTitle">PlayGroup</div>
                            <div class="infoContent">
                                <div class="text1">
                                    <div class="sub_text">Admission Age Group</div>
                                    <div class="sub_text">1.5 - 2.5 Years</div>
                                </div>
                                <div class="text2">
                                    <div class="sub_text">Duration</div>
                                    <div class="sub_text">3 Hours/Day</div>
                                </div>
                            </div>
                            <div class="subPara">
                                <div class="box">
                                    <input type="checkbox" id="expanded1">
                                    <p>Children in this age group as identified as Toddlers. Children are born curious. They begin to explore the environment around them as soon as they are born. Kidzee’s playgroup age appropriate curriculum plays an important role in helping children continue their exploration.</p>
                                    <label for="expanded1" class="readMoreBtn" role="button" routerLink="playgroup">Read More</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="listDiv mt-4">
                        <div class="">
                            <img srcset="../../../assets/img/Nursery.png" loading="lazy" alt="" class="listImg1 responsive"
                                align="center">
                        </div>
                        <div class="listText">
                            <div class="programTitle">Nursery</div>
                            <div class="infoContent">
                                <div class="text1">
                                    <div class="sub_text">Admission Age Group</div>
                                    <div class="sub_text">2.5 - 3.5 Years</div>
                                </div>
                                <div class="text2">
                                    <div class="sub_text">Duration</div>
                                    <div class="sub_text">3 Hours/Day</div>
                                </div>
                            </div>
                            <div class="subPara">
                                <div class="box">
                                    <input type="checkbox" id="expanded2">
                                    <p>The child is now identified as a Pre-schooler. (Mandatory level 1 of Foundational Stage as per NEP)
                                        To set a firm foundation in the early years of preschool, quality education is vital for the overall development of a child. Our nursery education has a right array of activities that helps a child move towards achieving their early learning goals.</p>

                                    <label for="expanded2" class="readMoreBtn" role="button" routerLink="nursery">Read More</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="listDiv mt-4">
                        <div class="">
                            <img srcset="../../../assets/img/Kindergarten.png" loading="lazy" alt="" class="listImg1 responsive"
                                align="center">
                        </div>
                        <div class="listText">
                            <div class="programTitle">Kindergarten (Junior K.G / Lower K.G)</div>
                            <div class="infoContent">
                                <div class="text1">
                                    <div class="sub_text">Age Group</div>
                                    <div class="sub_text">4 - 5 Years</div>
                                </div>
                                <div class="text2">
                                    <div class="sub_text">Duration</div>
                                    <div class="sub_text">4 Hours/Day</div>
                                </div>
                            </div>
                            <div class="programTitle mt-4">Kindergarten (Senior K.G / Upper K.G)</div>
                            <div class="infoContent">
                                <div class="text1">
                                    <div class="sub_text">Age Group</div>
                                    <div class="sub_text">5 - 6 Years</div>
                                </div>
                                <div class="text2">
                                    <div class="sub_text">Duration</div>
                                    <div class="sub_text">4 Hours/Day</div>
                                </div>
                            </div>
                            <div class="subPara">
                                <div class="box">
                                    <input type="checkbox" id="expanded3">
                                    <p>This is level 2 and 3 of preschool -Foundational Stage 1 - Mandatory as per NEP). This is in progression to the Kidzee Nursery Curriculum.</p>
                                    <p>At Kidzee, the Kindergarten (KG) curriculum ensures a smooth transition to formal schooling. Age-appropriate activities in various areas like language, numbers, general knowledge, music, and art are conducted in a planned manner throughout the program. Proprietary programs for Phonics and English Readiness makes us the one of the best preschools in India. A Child's development is measured thrice in a year using a well-designed assessment.</p>
                                    
                                    <label for="expanded3" class="readMoreBtn" role="button" routerLink="kindergarten">Read More</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="listDiv mt-4">
                        <div class="">
                            <img srcset="../../../assets/img/Teacher.png" loading="lazy" alt="" class="listImg1 responsive"
                                align="center">
                        </div>
                        <div class="listText">
                            <div class="programTitle">Teacher Training Programme</div>
                            <div class="subPara">
                                <div class="box">
                                    <input type="checkbox" id="expanded4">
                                    <p>Zee Learn Preschool Teacher Training Programme is a Zee Learn Ltd initiative. Kidzee (Preschool vertical of Zee Learn Ltd) has set unrivalled standards in the CDE (Child Development & Education) space as a leader in ECCE (Early Childhood Care & Education). Kidzee is one of the leading preschool chains in India.
                                        With the onset of NEP, which regulates preschool education in India; it is mandatory that all preschool teachers are trained in Early Child Education to be eligible to teach at the Foundational stage.</p>
                                    <label for="expanded4" class="readMoreBtn" role="button" routerLink="teacher-training-programme">Read More</label>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="col-md-5">
                    <div class="imgDiv mt-4">
                        <div class="">
                            <img srcset="../../../assets/img/boy.png" loading="lazy" alt="PlayGroup,Nursery,Kindergarten,Teacher Training Programme" class="boyImg responsive" align="center">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <svg class="boysImg_mobileView" id="svg2" xmlns="http://www.w3.org/2000/svg" width="1440" height="1340"
            viewBox="0 0 1440 1340" fill="none">
            <path
                d="M0 180.001C0 180.001 90 90.573 262 67.0763C434 43.5796 624.535 117.817 780 112.075C932.711 106.436 1021.85 31.3142 1174.5 21.0815C1336 10.2552 1440 84.2811 1440 84.2811V1288.6C1440 1288.6 1314.5 1239.26 1174.5 1229.07C969 1214.1 864.789 1310.59 715.5 1310.59C569 1310.59 419.5 1229.07 292 1229.07C84.8618 1229.07 0 1320 0 1320V180.001Z"
                fill="#DFCFFF" />
        </svg>
        <div class="mobileWaves">
            <div class="bgWaves">
                <div class="mobResponciveDiv">
                    <div class="row mt-5">
                        <div class="col-4 mt-5">
                            <div class="">
                                <img srcset="../../../assets/img/PlayGroup.png" loading="lazy" class="listImg1 responsive" align="center"
                                    alt="">

                            </div>
                        </div>
                        <div class="col-8 mt-5">
                            <div class="listText">
                                <div class="programTitle">PlayGroup</div>
                                <div class="infoContent">
                                    <div class="text1">
                                        <div class="sub_text">Age Group</div>
                                        <div class="sub_text">1.5 - 2.5 Years</div>
                                    </div>
                                    <div class="text2">
                                        <div class="sub_text">Duration</div>
                                        <div class="sub_text">3 Hours/Day</div>
                                    </div>
                                </div>
                                <div class="subPara">
                                    <div class="box">
                                        <input type="checkbox" id="expanded5">
                                        <p>Children in this age group as identified as Toddlers. Children are born curious. They begin to explore the environment around them as soon as they are born. Kidzee’s playgroup age appropriate curriculum plays an important role in helping children continue their exploration.</p>
                                        <label for="expanded5" class="readMoreBtn" role="button" routerLink="playgroup">Read More</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-4 mt-5">
                            <div class="">
                                <img srcset="../../../assets/img/Nursery.png" loading="lazy" alt="" class="listImg1 responsive"
                                    align="center">

                            </div>
                        </div>
                        <div class="col-8 mt-5">
                            <div class="listText">
                                <div class="programTitle">Nursery</div>
                                <div class="infoContent">
                                    <div class="text1">
                                        <div class="sub_text">Age Group</div>
                                        <div class="sub_text">2.5 - 3.5 Years</div>
                                    </div>
                                    <div class="text2">
                                        <div class="sub_text">Duration</div>
                                        <div class="sub_text">3 Hours/Day</div>
                                    </div>
                                </div>
                                <div class="subPara">
                                    <div class="box">
                                        <input type="checkbox" id="expanded6">
                                        <p>The child is now identified as a Pre-schooler. (Mandatory level 1 of Foundational Stage as per NEP)
                                            To set a firm foundation in the early years of preschool, quality education is vital for the overall development of a child. Our nursery education has a right array of activities that helps a child move towards achieving their early learning goals.</p>
                                        <label for="expanded6" class="readMoreBtn" role="button" routerLink="nursery">Read More</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-4 mt-5">
                            <div class="">
                                <img srcset="../../../assets/img/Kindergarten.png" loading="lazy" alt="" class="listImg1 responsive"
                                    align="center">

                            </div>
                        </div>
                        <div class="col-8 mt-5">
                            <div class="listText">
                                <div class="programTitle">Kindergarten (Junior K.G / Lower K.G)</div>
                                <div class="infoContent">
                                    <div class="text1">
                                        <div class="sub_text">Age Group</div>
                                        <div class="sub_text">4 - 5 Years</div>
                                    </div>
                                    <div class="text2">
                                        <div class="sub_text">Duration</div>
                                        <div class="sub_text">4 Hours/Day</div>
                                    </div>
                                </div>
                                <div class="programTitle mt-4">Kindergarten (Senior K.G / Upper K.G)</div>
                                <div class="infoContent">
                                    <div class="text1">
                                        <div class="sub_text">Age Group</div>
                                        <div class="sub_text">4 - 5 Years</div>
                                    </div>
                                    <div class="text2">
                                        <div class="sub_text">Duration</div>
                                        <div class="sub_text">4 Hours/Day</div>
                                    </div>
                                </div>
                                <div class="subPara">
                                    <div class="box">
                                        <input type="checkbox" id="expanded7">
                                        <p>This is level 2 and 3 of preschool -Foundational Stage 1 - Mandatory as per NEP). This is in progression to the Kidzee Nursery Curriculum.</p>
                                        <p>At Kidzee, the Kindergarten (KG) curriculum ensures a smooth transition to formal schooling. Age-appropriate activities in various areas like language, numbers, general knowledge, music, and art are conducted in a planned manner throughout the program. Proprietary programs for Phonics and English Readiness makes us the one of the best preschools in India. A Child's development is measured thrice in a year using a well-designed assessment.</p>
                                        <label for="expanded7" class="readMoreBtn" role="button" routerLink="kindergarten">Read More</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-4 mt-5">
                            <div class="">
                                <img srcset="../../../assets/img/Teacher.png" loading="lazy" alt="" class="listImg1 responsive"
                                    align="center">

                            </div>
                        </div>
                        <div class="col-8 mt-5">
                            <div class="listText">
                                <div class="programTitle">Teacher Training Programme</div>
                                <div class="subPara">
                                    <div class="box">
                                        <input type="checkbox" id="expanded8">
                                        <p>Zee Learn Preschool Teacher Training Programme is a Zee Learn Ltd initiative. Kidzee (Preschool vertical of Zee Learn Ltd) has set unrivalled standards in the CDE (Child Development & Education) space as a leader in ECCE (Early Childhood Care & Education). Kidzee is one of the leading preschool chains in India.
                                            With the onset of NEP, which regulates preschool education in India; it is mandatory that all preschool teachers are trained in Early Child Education to be eligible to teach at the Foundational stage.</p>
                                        <label for="expanded8" class="readMoreBtn" role="button" routerLink="teacher-training-programme">Read More</label>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
