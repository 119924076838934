<div class="section">
    <!-- <header class="header" id="scroll_header">
        <div class="menu_list">
            <ul>
                <li><a href="researchanddevelopment#OverviewOfRAndDCell"> Overview of R&D cell </a></li>
                <li><a href="researchanddevelopment#goalsAndobjective">Major Goals/Objective</a></li>
                <li><a href="researchanddevelopment#aboutDean">About Dean Research</a></li>
                <li><a href="researchanddevelopment#TeamRandD">Team R&D cell</a></li>
            </ul>
        </div>
    </header> -->
    <div class="subMenu_content">
        <app-sub-menu></app-sub-menu>
    </div>
    <div class="sub_content">
        <section id="OverviewOfRAndDCell">
            <div class="overview">
                <div class="title">Sports Facilities</div>
                <div class="para">
                    <p>“We believe in training the students not only in their chosen academic discipline but beyond. Sports at University campus provide for the perfect setting to hone one's team skills and to maintain physical fitness. Apart from indoor games, there is a whole gamut of outdoor sports like Football, Volleyball, Lawn Tennis, Swimming, Cricket, Badminton etc. The JU is a vibrant campus full of healthy sports and activities.”</p>
                   
<p><b>Swimming Pool :</b>	JU is the only private university in Uttarakhand which has a state of art swimming pool. The facility is available for boys & girls at separate time and monitored.</p>
<p><b>Gymnasium :</b>	With state of the art equipment for boys and girls.</p>
<p><b>Lawn Tennis :</b>	A lawn tennis court is available close to hostels, open to all, both hostellers & day scholars.</p>
<p><b>Badminton Court :</b>	The University has badminton courts with lighting arrangement, both for boys & girls.</p>
<p><b>Basketball Court :</b>	JU has a basketball court as per the International Standards</p>
<p><b>Volleyball Court :</b>	JU promotes healthy activities and a volley ball court too.</p>
<p><b>Football Ground :</b>	A Football ground is available for the lovers of the game.</p>
<p><b>Cricket :</b>	India is country of cricket lovers. The fever grips here too.</p>
                </div>
            </div>
        </section>
        <section>
             <div class="row pb-5">
                <div class="col-md-4 mt-3">
                    <div class="gallery_img">
                        <a data-fancybox="" href="../../../assets/img/jigyasa/sports1.jpg">
                        <img src="../../../assets/img/jigyasa/sports1.jpg" width="100%" height="200px" alt="">
                        </a>
                    </div>
                </div>
                <div class="col-md-4 mt-3">
                    <div class="gallery_img">
                        <a data-fancybox="" href="../../../assets/img/jigyasa/sports2.jpg">
                        <img src="../../../assets/img/jigyasa/sports2.jpg" width="100%" height="200px" alt="">
                        </a>
                    </div>
                </div>
                <div class="col-md-4 mt-3">
                    <div class="gallery_img">
                        <a data-fancybox="" href="../../../assets/img/jigyasa/sports3.jpg">
                        <img src="../../../assets/img/jigyasa/sports3.jpg" width="100%" height="200px" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>