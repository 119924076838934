import { Component } from '@angular/core';

@Component({
  selector: 'app-medical-facility',
  templateUrl: './medical-facility.component.html',
  styleUrls: ['./medical-facility.component.css']
})
export class MedicalFacilityComponent {

}
