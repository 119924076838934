import { Pipe, PipeTransform } from "@angular/core"; 
import { orderBy } from 'lodash'; 

@Pipe({
  name: 'sorting'
})
export class SortingPipe implements PipeTransform {
  transform(array: any, arg2: any): any[] {   
    return orderBy(array, "sort", 'asc'); 
} 

}
