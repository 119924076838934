<section class="main_div">
  <div class="box1">
    <div id="carouselExampleIndicators" class="carousel slide" data-bs-ride="carousel">
      <div class="carousel-indicators">
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0" class="active"
          aria-current="true" aria-label="Slide 1"></button>
        <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1"
          aria-label="Slide 2"></button>
        <!-- <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2"
          aria-label="Slide 3"></button> -->
      </div>


      <div class="carousel-inner">
        <div class="carousel-item active">
          <div class="banner_footer_btn d-flex justify-content-between align-items-end">
            <div class="tab_btn d-flex justify-content-center align-items-center text-white"><a href="{{applayUrl}}"
                target="_blank"><i class="fa fa-globe mx-2"></i> Admission Open</a></div>
            <div class="tab_btn d-flex justify-content-center align-items-center text-white" routerLink="/hostel"><i
                class="fa fa-info mx-2"></i> JU Campus Tour</div>
            <div class="tab_btn d-flex justify-content-center align-items-center text-white" routerLink="/internship">
              <i class='fa fa-pencil mx-2'></i> Internship at JU
            </div>
          </div>
          <div class="subImg_content">
          </div>
          <img src="../../../assets/img/jigyasa/banner/Jigyasa-Main-banner.jpg" class="home_banner" alt="home">
        </div>
        <div class="carousel-item">
          <div class="banner_footer_btn d-flex justify-content-between align-items-end">
            <div class="tab_btn d-flex justify-content-center align-items-center text-white"><a href="{{applayUrl}}"
                target="_blank"><i class="fa fa-globe mx-2"></i> Admission Open</a></div>
            <div class="tab_btn d-flex justify-content-center align-items-center text-white" routerLink="/hostel"><i
                class="fa fa-info mx-2"></i> JU Campus Tour</div>
            <div class="tab_btn d-flex justify-content-center align-items-center text-white" routerLink="/internship">
              <i class='fa fa-pencil mx-2'></i> Internship at JU
            </div>
          </div>
          <div class="subImg_content">
           </div>
          <img src="../../../assets/img/jigyasa/banner/Jigyasa-Main-banner2.jpg" class="home_banner" alt="home">
         </div>
        <!-- <div class="carousel-item">
          <video controls id="homevideo" poster="../../../assets/img/jigyasa/video/starting zee.mp4"  autoplay="false" playsinline="" loop="">
            <source src="../../../assets/img/jigyasa/video/starting zee.mp4" type="video/mp4">
          </video>
        </div> -->
        
      </div>
    </div>
  </div>

  <div class="box2 mt-5">
    <div class="findProgramDiv">
      <div class="row">
        <div class="col-md-6" data-aos="fade-right">
          <div class="img_content">
            <div class="row">
              <div class="col-6">
 
                  <img src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1716553054754WhatsApp%20Image%202024-05-24%20at%205.32.06%20PM.jpeg" height="230px" width="100%" alt="">
                <!-- <div class="icon_content mt-3 d-flex justify-content-between align-items-center">
                  <div class="icon waw bounceInUp">
                    <img src="	https://themes.envytheme.com/falar/wp-content/uploads/2023/12/program-logo1.png"
                      width="80%" alt="" style="position: relative;">
                  </div>
                  <div class="num_content">
                    <div class="number">156+</div>
                    <p>Programs Available For Students</p>
                  </div>
                </div> -->
              </div>
              <div class="col-6">
                <img src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1716554151341WhatsApp%20Image%202024-05-24%20at%206.04.00%20PM%20%281%29.jpeg" height="230px" width="100%"
                alt="">
               </div>
            </div>

          </div>
        </div>
        <div class="col-md-6" data-aos="fade-left">
          <div class="title" data-aos="fade-up" data-aos-duration="3000">Find Your Program</div>
          <div class="para">
            <p>Welcome to our university website! Use the search bar below to explore our diverse range of programs
              and discover the perfect academic path that suits your interests and career goals.
            </p>
          </div>
          <div class="form-group">
            <img src="../../../assets/img/jigyasa/icon/searchIcon.svg" width="20px" height="20px" alt="">
            <input type="text" class="form-control" [ngModel]="searchtext" (ngModelChange)="getdata($event)"
              placeholder="Find your program like “B.Sc">

          </div>
          <ul class="list-group list-group-horizontal-lg">
            <li class="list-group-item" *ngFor="let a of programs" value="{{a.course_id}}"><a
                href="{{a.slug}}">{{a.name}}</a></li>
          </ul>

          <!-- <div class="search_content mt-3">
            <div class="row">
              <div class="col-3">
                <span class="search-list">Popular Search:</span>
              </div>
              <div class="col-9 d-flex justify-content-start padding_space">
                <ul class="program-list">
                  <li>
                    <a href="https://themes.envytheme.com/falar/tag/art-design/">
                      Art &amp; Design </a>
                  </li>
                  <li>
                    <a href="https://themes.envytheme.com/falar/tag/career/">
                      Career </a>
                  </li>
                  <li>
                    <a href="https://themes.envytheme.com/falar/tag/community/">
                      Community </a>
                  </li>
                  <li>
                    <a href="https://themes.envytheme.com/falar/tag/education/">
                      Education </a>
                  </li>
                  <li>
                    <a href="https://themes.envytheme.com/falar/tag/job/">
                      Job </a>
                  </li>
                  <li>
                    <a href="https://themes.envytheme.com/falar/tag/online-education/">
                      Online Education </a>
                  </li>
                </ul>
              </div>
            </div>
          </div> -->
          <!-- <div class="popular_Program">
            <div class="row">
              <div class="col-3">
                <img src="../../../assets/img/jigyasa/program-small1.jpg" width="100%" alt="program-small">
              </div>
              <div class="col-9">
                <div class="program_content">
                  <div class="rightErrow"><i class="fa fa-long-arrow-right"></i></div>
                  <span>Popular Program</span>
                  <h5 class="mt-2">Advertising and Marketing
                    Communications</h5>
                </div>
              </div>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>

  <div class="box3">
    <div class="findProgramDiv">
      <div class="row">
        <div class="col-md-6" data-aos="fade-right">
          <div class="img_content">
            <div class="row">
              <div class="col-6">
                <div class="missionList">
                 
                  <div class="listTab" (mouseenter)="selectTab(2)">Why JU ?<div class="rightErrow"><i
                        class="fa fa-long-arrow-right"></i></div>
                  </div>
                  <div class="listTab postionTop" (mouseenter)="selectTab(1)">Our Vision & Mission<div class="rightErrow"><i
                    class="fa fa-long-arrow-right"></i></div>
                 </div>
                  <div class="listTab postionTop2" (mouseenter)="selectTab(3)">Sports<div class="rightErrow"><i
                        class="fa fa-long-arrow-right"></i></div>
                  </div>
                  <div class="listTab postionTop3" (mouseenter)="selectTab(4)">Gallery<div class="rightErrow"><i
                        class="fa fa-long-arrow-right"></i></div>
                  </div><br>
                  <div class="listTab postionTop4" (mouseenter)="selectTab(5)">eXpression College Fest<div class="rightErrow"><i
                        class="fa fa-long-arrow-right"></i></div>
                  </div>
                  <!-- <br>
                  <div class="listTab postionTop5" (mouseenter)="selectTab(6)">Awards<div class="rightErrow"><i
                        class="fa fa-long-arrow-right"></i></div>
                  </div> -->
                </div>
              </div>
              <div class="col-6">
                <img *ngIf="display_div1" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000"
                  src="../../../assets/img/jigyasa/our_mission.jpg" width="100%" height="320px" alt="">
                <img *ngIf="display_div2" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000"
                  src="../../../assets/img/jigyasa/vision_img.JPG" width="100%" height="320px" alt="">
                <img *ngIf="display_div3" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000"
                  src="../../../assets/img/jigyasa/sports_img.jpg" width="100%" height="320px" alt="">
                <img *ngIf="display_div4" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000"
                  src="../../../assets/img/jigyasa/Campus_img.jpg" width="100%" height="320px" alt="">
                <img *ngIf="display_div5" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000"
                  src="../../../assets/img/jigyasa/Event_img.jpg" width="100%" height="320px" alt="">
                <img *ngIf="display_div6" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000"
                  src="../../../assets/img/jigyasa/award_img.jpg" width="100%" height="320px" alt="">
              </div>
            </div>

          </div>
        </div>
        <div class="col-md-6" data-aos="fade-left">
          <div class="our_mission title_space" *ngIf="display_div2">
            <div class="title" data-aos="fade-up" data-aos-duration="3000">About The Jigyasa University</div>
            <!-- <img src="../../../assets/img/jigyasa/mission_logo.svg" width="50px" height="50px" alt=""> -->
            <div class="para mt-4">
              <h5>Our Vision & Mission</h5>
             <p>To infuse transformation and foster indigenous values through excellence in teaching, research, and innovation, thereby serving society as a premier institution of higher education.</p>
                  <ol>
                    <li>Progressive educational proficiencies that stimulate holistic development.</li>
                    <li>Enhancing experiential learning through endorsing an inclusive mind-set.</li>
                    <li>Advancing research, nurturing innovations and catalyzing entrepreneurship.</li>
                    <li>Cultivation of leadership qualities with a strong sense of values and ethics.</li>
                </ol>  
            </div>
            <div class="search_content mt-3">
              <div class="row">
                <div class="col-3">
                 <a href="aboutUs#ourVision"><span class="search-list">View Details <i class="fa fa-long-arrow-right"></i></span></a>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="display_div1">
            <div class="title" data-aos="fade-up" data-aos-duration="3000">Why Jigyasa University?</div>
            <!-- <img src="../../../assets/img/jigyasa/mission_logo.svg" width="50px" height="50px" alt=""> -->
            <div class="para mt-4">
              <h5>Why JU ?</h5>
              <ul class="whyJU">
                <li>Faculty from premiere institutions</li>
                <li>Strong University – Industry linkage</li>
                <li>Full semester internship program in all programs</li>
                <li>Courses on Experiential Learning in all programs</li>
                <li>Soft skill training for better placement opportunities</li>
              </ul>
            </div>
            <div class="search_content mt-3">
              <div class="row">
                <div class="col-3">
                  <span class="search-list" routerLink="/WhyJU">View Details <i class="fa fa-long-arrow-right"></i></span>
                </div>
              </div>
            </div>
            <!-- <button class="btn btn_style mt-5">Take A Campus Tour
              <i class="fa fa-long-arrow-right"></i>
            </button> -->
          </div>
          <div class="title_space" *ngIf="display_div3">
            <div class="title" data-aos="fade-up" data-aos-duration="3000">Sports Facilities</div>
            <!-- <img src="../../../assets/img/jigyasa/mission_logo.svg" width="50px" height="50px" alt=""> -->
            <div class="para mt-4">
              <h5>Sports</h5>
              <p>We believe in training the students not only in their chosen academic discipline but beyond. Sports at University campus provide for the perfect setting to hone one's team skills and to maintain physical fitness.</p>
            </div>
            <div class="search_content mt-3">
              <div class="row">
                <div class="col-3">
                  <span class="search-list" routerLink="/sports">View Details <i class="fa fa-long-arrow-right"></i></span>
                </div>
              </div>
            </div>
            <!-- <button class="btn btn_style mt-5">Take A Campus Tour
              <i class="fa fa-long-arrow-right"></i>
            </button> -->
          </div>
          <div class="title_space" *ngIf="display_div4">
            <div class="title" data-aos="fade-up" data-aos-duration="3000">Extra-Curricular activities</div>
            <!-- <img src="../../../assets/img/jigyasa/mission_logo.svg" width="50px" height="50px" alt=""> -->
            <div class="para mt-4">
              <h5>Gallery</h5>
              <p>The university believes that extracurricular activities play a vital role in the personal and professional development of students. The clubs offered by the university provide students with a platform to explore their interests, develop new skills, and interact with like-minded peers.</p>
            </div>
            <div class="search_content mt-3">
              <div class="row">
                <div class="col-3">
                  <span class="search-list" routerLink="/gallery">View Details <i class="fa fa-long-arrow-right"></i></span>
                </div>
              </div>
            </div>
            <!-- <button class="btn btn_style mt-5">Take A Campus Tour
              <i class="fa fa-long-arrow-right"></i>
            </button> -->
          </div>
          <div class="title_space" *ngIf="display_div5">
            <div class="title" data-aos="fade-up" data-aos-duration="3000">Events at Jigyasa University</div>
            <!-- <img src="../../../assets/img/jigyasa/mission_logo.svg" width="50px" height="50px" alt=""> -->
            <div class="para mt-4">
              <h5>Events</h5>
              <p>The university organises Co-Curricular activities with Extra-Curricular activities</p>
            </div>
            <div class="search_content mt-3">
              <div class="row">
                <div class="col-3">
                  <span class="search-list" routerLink="/event/expression-college-fest-2023">View Details <i class="fa fa-long-arrow-right"></i></span>
                </div>
              </div>
            </div>
            <!-- <button class="btn btn_style mt-5">Take A Campus Tour
              <i class="fa fa-long-arrow-right"></i>
            </button> -->
          </div>
          <div class="title_space" *ngIf="display_div6">
            <div class="title" data-aos="fade-up" data-aos-duration="3000">Why Jigyasa University?</div>
            <!-- <img src="../../../assets/img/jigyasa/mission_logo.svg" width="50px" height="50px" alt=""> -->
            <div class="para mt-4">
              <h5>Why JU</h5>
              <ul>
                <li>Faculty from premiere institutions</li>
                <li>Strong University – Industry linkage</li>
                <li>Full semester internship program in all programs</li>
                <li>Courses on Experiential Learning in all programs</li>
                <li>Soft skill training for better placement opportunities</li>
              </ul>
            </div>
            <div class="search_content mt-3">
              <div class="row">
                <div class="col-3">
                  <span class="search-list" routerLink="/WhyJU">View Details <i class="fa fa-long-arrow-right"></i></span>
                </div>
              </div>
            </div>
            <!-- <button class="btn btn_style mt-5">Take A Campus Tour
              <i class="fa fa-long-arrow-right"></i>
            </button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="box4">
    <div class="coundList">
      <div class="row justify-content-center g-4">
        <div class="col-lg-3  col-sm-6 col-md-6 d-flex justify-content-center align-items-center" data-aos="fade-up">
          <div class="fun-fact-card">
            <div class="d-flex align-items-center">
              <div class="icon">
                <div class="icon style">
                  <img decoding="async"
                    src="https://themes.envytheme.com/falar/wp-content/uploads/2023/12/fun-fact1.svg"
                    class="mission-shape" alt="image">
                </div>
              </div>
              <h2 class="counter">3000</h2>
              <h2 class="plus">+</h2>
            </div>
            <p>BRIGHT STUDENTS</p>
          </div>
        </div>
        <div class="col-lg-3  col-sm-6 col-md-6 d-flex justify-content-center align-items-center" data-aos="fade-down">
          <div class="fun-fact-card">
            <div class="d-flex align-items-center">
              <div class="icon">
                <div class="icon style">
                  <img decoding="async"
                    src="https://themes.envytheme.com/falar/wp-content/uploads/2023/12/fun-fact2.svg"
                    class="mission-shape" alt="image">
                </div>
              </div>
              <h2 class="counter"> 200</h2>
              <h2 class="plus">+</h2>
            </div>
            <p> RECRUITERS</p>
          </div>
        </div>
        <div class="col-lg-3  col-sm-6 col-md-6 d-flex justify-content-center align-items-center" data-aos="fade-up">
          <div class="fun-fact-card">
            <div class="d-flex align-items-center">
              <div class="icon">
                <div class="icon style">
                  <img decoding="async"
                    src="https://themes.envytheme.com/falar/wp-content/uploads/2023/12/fun-fact3.svg"
                    class="mission-shape" alt="image">
                </div>
              </div>
              <h2 class="counter">20</h2>
              <h2 class="plus">+</h2>
            </div>
            <p>YEARS OF EDUCATIONAL<br>EXPERIENCE</p>
          </div>
        </div>
        <div class="col-lg-3  col-sm-6 col-md-6 d-flex justify-content-center align-items-center" data-aos="fade-down">
          <div class="fun-fact-card">
            <div class="d-flex align-items-center">
              <div class="icon">
                <div class="icon style">
                  <img decoding="async"
                    src="https://themes.envytheme.com/falar/wp-content/uploads/2023/12/fun-fact4.svg"
                    class="mission-shape" alt="image">
                </div>
              </div>
              <h2 class="counter">10,000</h2>
              <h2 class="plus">+</h2>
            </div>
            <p>GLORIUS ALUMNI</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="box5">
    <div class="title text-center" data-aos="fade-up" data-aos-duration="3000">OUR ACHIEVEMENTS</div>
    <div class="studentsOfFalar">
      <div class="row">
        <div class="col-md-6">
          <video autoplay="" muted="" loop="" id="video" width="100%">
            <source src="https://themes.envytheme.com/falar/wp-content/uploads/2023/12/luva.mp4" width="100%"
              type="video/mp4">
          </video><br><br>
          <div class="content">
            <span class="bg1 mt-2">Freshers’
              Guideline</span>

            <h3 class="mt2">
              Agriculture Project Patent
            </h3>
            <a href="https://themes.envytheme.com/falar/wp-content/uploads/2023/12/luva.mp4"
              class="features-link"><span>Watch Full Video</span>
              <i class="ti ti-arrow-narrow-right"></i>
            </a>
          </div>
        </div>
        <div class="col-md-6">
          <div class="features_content">
            <div class="features-item">
              <div class="row">
                <div class="col-md-3">
                  <div class="image">
                    <div class="image" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
                      <a href="https://themes.envytheme.com/falar/schedule-a-tour/">
                        <img decoding="async" width="100%"
                          src="https://themes.envytheme.com/falar/wp-content/uploads/2023/12/feature2.jpg"
                          class="mission-shape" alt="image">
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-md-9">
                  <div class="content"><span class="title bg1 ">Campus
                      Tour</span>
                    <h3>
                      Visit Jigyasa open house on Saturday, January 22, 2024
                    </h3>
                    <a href="https://themes.envytheme.com/falar/schedule-a-tour/" class="features-link"><span>Save The
                        Date</span>
                      <i class="ti ti-arrow-narrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="features-item mt-3">
              <div class="row">
                <div class="col-md-3">
                  <div class="image">
                    <div class="image" data-aos="flip-right" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
                      <a href="https://themes.envytheme.com/falar/transfer-credit/">
                        <img decoding="async" width="100%"
                          src="https://themes.envytheme.com/falar/wp-content/uploads/2023/12/feature3.jpg"
                          class="mission-shape" alt="image">
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-md-9">
                  <div class="content"><span class="title bg1 bg2">Roadmap</span>
                    <h3>
                      Follow these 10 steps to get into your dream program at Falar
                    </h3>
                    <a href="https://themes.envytheme.com/falar/transfer-credit/" class="features-link"><span>Admission
                        Roadmap</span>
                      <i class="ti ti-arrow-narrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="features-item mt-3">
              <div class="row">
                <div class="col-md-3">
                  <div class="image">
                    <div class="image" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
                      <a href="https://themes.envytheme.com/falar/about-overview/">
                        <img decoding="async" width="100%"
                          src="https://themes.envytheme.com/falar/wp-content/uploads/2023/12/feature4.jpg"
                          class="mission-shape" alt="image">
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-md-9">
                  <div class="content"><span class="title bg1 ">Roadmap</span>
                    <h3>
                      We are with you all the way!
                    </h3>
                    <a href="https://themes.envytheme.com/falar/about-overview/" class="features-link"><span>See
                        Guideline</span>
                      <i class="ti ti-arrow-narrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="features-item-link mt-3">
              <div class="row">
                <div class="col-md-3 d-flex justify-content-between align-items-center">
                  <div class="icon">
                    <div class="icon">
                      <a href="#">
                        <img decoding="async" width="50px"
                          src="https://themes.envytheme.com/falar/wp-content/uploads/2023/12/features1.svg" alt="image">
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-md-9">
                  <div class="content">
                    <p>You could win $5,000* toward your tuition fees.</p>
                    <a href="https://themes.envytheme.com/falar/about-overview/" class="features-link"><span>View
                        Details</span>
                      <i class="ti ti-arrow-narrow-right"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <!-- <div class="box6">
    <div class="InternationalStudents_content">
      <div class="row">
        <div class="col-md-6">
          <div class="title" data-aos="fade-up"
          data-aos-duration="3000">Information for International students</div>
          <div class="para">
            <p>The mission of Falar is to serve as a digital gateway and information hub for all members of the
              university community.</p>
            <p>Additionally, a university website seeks to foster a sense of community and collaboration by facilitating
              communication and engagement among students, faculty, alumni, and prospective students.</p>
            <ul class="ul_list">
              <li><i class="fa fa-check"></i> Full time programs for the International students.</li>
              <li><i class="fa fa-check"></i> Part time programs with stay.</li>
              <li><i class="fa fa-check"></i> Job opportunities for the best results.</li>
            </ul>
          </div>
          <button class="btn btn_style_color">Contact Form
            <i class="fa fa-long-arrow-right"></i>
          </button>
          <button class="btn btn_style">Program Availability
            <i class="fa fa-long-arrow-right"></i>
          </button>
          <div class="info-link-chat mt-4">
            <ul class="chat-list d-flex">
              <li>
                <div class="imageProfile" >
                  <img data-aos="flip-left"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration="2000" decoding="async" src="https://themes.envytheme.com/falar/wp-content/uploads/2023/12/user1.png"
                    alt="image">
                </div>
              </li>
              <li>
                <div class="imageProfile">
                  <img data-aos="flip-left"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration="2000" decoding="async" src="https://themes.envytheme.com/falar/wp-content/uploads/2023/12/user2.png"
                    alt="image">
                </div>
              </li>
              <li>
                <div class="imageProfile">
                  <img data-aos="flip-left"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration="2000" decoding="async" src="https://themes.envytheme.com/falar/wp-content/uploads/2023/12/user3.png"
                    alt="image">
                </div>
              </li>
            </ul>
            <div class="content">
              <p>Connect with a Falar International student via chat.</p>
              <a href="https://themes.envytheme.com/falar/contact/" class="features-link">
                <span>Chat Now <i class="fa fa-long-arrow-right"></i></span>

              </a>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row">
            <div class="col-4">
              <div class="stdImg_list">
                <img src="https://themes.envytheme.com/falar/wp-content/uploads/2023/12/main-log.svg" width="50px"
                  class="rotetIcon" alt="">
                <div class="rotetImg"> <img
                    src="https://themes.envytheme.com/falar/wp-content/uploads/2023/12/text-shape1.png" width="100%"
                    alt=""></div>
                <div> <img class="mt-5"
                    src="https://themes.envytheme.com/falar/wp-content/uploads/2023/12/information1.jpg" width="100%"
                    alt=""></div>
              </div>
            </div>
            <div class="col-8">
              <img src="https://themes.envytheme.com/falar/wp-content/uploads/2023/12/information2.jpg" width="100%"
                alt="">
            </div>
          </div>
        </div>
      </div>
    </div>

  </div> -->
  <!-- <div class="box7">
    <div class="banner_content">
      <div class="row">
        <div class="col-lg-6">
          <div class="culture-box" data-aos="fade-up-right">
            <img decoding="async" width="100%"
              src="https://themes.envytheme.com/falar/wp-content/uploads/2023/12/culture1.jpg" alt="image">
          </div>
        </div>
        <div class="col-lg-6">
          <div class="culture-box" data-aos="fade-up-left">
            <img decoding="async" width="100%"
              src="https://themes.envytheme.com/falar/wp-content/uploads/2023/12/culture2.jpg" alt="image">
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <div class="box9">
    <div class="banner_content mt-3">
      <div class="title text-center" data-aos="fade-up" data-aos-duration="3000">Students Corner</div>
      <div class="row mt-5">
        <div class="col-md-4">
          <div class="academic-item">
            <div class="academic-card pb-30">
              <div class="content_box9">
                <!-- <span>Finances</span> -->
                <h3>
                  Fee structure for courses at Jigyasa University (Formerly Himgiri Zee University)
                </h3>
                <a href="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1715854668612Fee%20Structure%20-%202024-25.pdf" target="_blank">
                <div class="default-btn">
                  Fee Structure <i class="fa fa-long-arrow-right"></i>
                </div>
              </a>
              </div>
            </div>
            <div class="academic-card mt-3">
              <div class="image" data-aos="flip-right" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
                <a href="https://themes.envytheme.com/falar/student-fees/">
                  <img decoding="async" width="100%"
                    src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1716553076022WhatsApp%20Image%202024-05-24%20at%205.40.10%20PM.jpeg"
                    class="mission-shape" alt="image">
                </a>
              </div>
              <div class="content_box9 mt-3">
                <!-- <span>Finances</span> -->
                <p>
                  The Primary aim of the IQAC, Jigyasa University is to develop a system for conscious, consistent and catalytic action to improve the academic and administrative performance of the University.
                </p>
                <div class="default-btn" routerLink="/iqac">
                  Internal Quality Assurance <i class="fa fa-long-arrow-right"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="academic-box">
            <div class="image" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration="2000">
              <a href="https://themes.envytheme.com/falar/contact-us/">
                <img decoding="async" width="100%"
                  src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1716553066892WhatsApp%20Image%202024-05-24%20at%205.34.22%20PM.jpeg" alt="image">
              </a>
            </div>
            <div class="content_box9 mt-2">
              <p>Himgiri Zee Excellence and Soft-skills Training Centre (Hi-ZEST Centre)</p>
              <div class="yellow_btn" routerLink="/hiZestCenter">
                Hi-ZEST Centre<i class="fa fa-long-arrow-right"></i>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-4">
          <div id="carouselExampleIndicators1" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-indicators">
              <button type="button" data-bs-target="#carouselExampleIndicators1" data-bs-slide-to="0" class="active"
                aria-current="true" aria-label="Slide 1"></button>
              <button type="button" data-bs-target="#carouselExampleIndicators1" data-bs-slide-to="1"
                aria-label="Slide 2"></button>
            </div>
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div class="academic-slider-warp">
                  <div class="swiper-slide swiper-slide-active active_slider" role="group" aria-label="1 / 2"
                    data-swiper-slide-index="0">
                    <div class="academic-option">
                      <div class="content">
                        <h3>Announcement</h3>

                        <div class="features-link mt-3" *ngFor="let a of AnnouncementdataList">
                          <div *ngFor="let b of a.files">
                            <a href="{{b.url}}" target="_blank">
                          <span>{{a.Title}}</span>
                          <i class="fa fa-long-arrow-right"></i>
                        </a>
                        </div>
                        </div>

                        <!-- <div class="features-link mt-3">
                          <span>View All Recruitment Events</span>
                          <i class="fa fa-long-arrow-right"></i>
                        </div> -->

                      </div>
                      <!-- <hr>
                      <div class="content">
                        <h3>Notice</h3>

                        <div class="features-link">
                          <span>Book A Program Ad</span>
                          <i class="fa fa-long-arrow-right"></i>
                        </div>
                        <div class="features-link mt-3">
                          <span>Book A General Campus Tour</span>
                          <i class="fa fa-long-arrow-right"></i>
                        </div>
                      </div>
                      <hr>
                      <div class="academic-date">
                        <h3>Download PDF</h3>
                       </div>
                      <div class="academic-date">
                        <h3>Jan 06</h3>
                        <div class="features-link">
                          <span>Tuition Deposit Due For Winter 2024</span>
                          <i class="fa fa-long-arrow-right"></i>
                        </div>
                      </div>
                      <div class="academic-date">
                        <h3>Jan 26</h3>
                        <div class="features-link">
                          <span>Balance Of Winter Tuition Fees Due</span>
                          <i class="fa fa-long-arrow-right"></i>
                        </div>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div class="carousel-item">
                <div class="academic-slider-warp">
                  <div class="swiper-slide swiper-slide-active" role="group" aria-label="1 / 2"
                    data-swiper-slide-index="0" style="width: 296px; margin-right: 20px;">
                    <div class="academic-option">
                      <div class="content">
                        <h3>Important Dates</h3>

                        <div class="features-link">
                          <span>View Full Academic Calendar</span>
                          <i class="fa fa-long-arrow-right"></i>
                        </div>

                        <div class="features-link mt-3">
                          <span>View All Recruitment Events</span>
                          <i class="fa fa-long-arrow-right"></i>
                        </div>

                      </div>
                      <hr>
                      <div class="content">
                        <h3>Book Now</h3>

                        <div class="features-link">
                          <span>Book A Program Ad</span>
                          <i class="fa fa-long-arrow-right"></i>
                        </div>
                        <div class="features-link mt-3">
                          <span>Book A General Campus Tour</span>
                          <i class="fa fa-long-arrow-right"></i>
                        </div>
                      </div>
                      <hr>
                      <div class="academic-date">
                        <h3>Jan 28</h3>
                        <p>Thanks giving - college closed.</p>
                      </div>
                      <div class="academic-date">
                        <h3>Jan 06</h3>
                        <div class="features-link">
                          <span>Tuition Deposit Due For Winter 2024</span>
                          <i class="fa fa-long-arrow-right"></i>
                        </div>
                      </div>
                      <div class="academic-date">
                        <h3>Jan 26</h3>
                        <div class="features-link">
                          <span>Balance Of Winter Tuition Fees Due</span>
                          <i class="fa fa-long-arrow-right"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="box10">
    <div class="banner_content">
      <div class="row">
        <div class="col-md-6">
          <div class="recognized-image reveal2 active" data-aos="fade-right" data-aos-offset="300"
            data-aos-easing="ease-in-sine">
            <img decoding="async" width="100%"
              src="../../../assets/img/jigyasa/Leaders.jpg" width="100%" alt="image">
          </div>
        </div>
        <div class="col-md-6">
          <div class="title" data-aos="fade-up" data-aos-duration="3000">Know Our Leaders</div>
          <div class="para">
            <p>The mission of JIGYASA is to serve as a digital gateway and information hub for all members of the
              university community.</p>
              <p>A small band of committed professionals have been involved in research of a high quality and have earned a good name in the field of Social Science and Health Communication research.</p>
            <!-- <div class="features-link">
              <span>Register Now For Fall 2024 <i class="fa fa-long-arrow-right"></i></span>
            </div> -->
          </div>
        </div>
      </div>

      <div class="row g-0">
        <div class="col-lg-3 col-md-6">
          <div class="choose-card bg-1">
            <h3>Lt. Gen. Gurmit Singh</h3>
            <p>Visitor</p>
            <p>PVSM, UYSM, AVSM, VSM (Retd)
              Governor of Uttarakhand</p>
            <!-- <div class="choose-link">
              <span>Professional Learning</span>
              <i class="fa fa-long-arrow-right"></i>
            </div> -->

          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="choose-card bg-2">
            <h3>Dr. Subhash Chandra</h3>
            <p>Patron</p>
            <p>Chairman Essel Group Ex. Member of Parliament (Rajya Sabha) </p>
            <!-- <div class="choose-link">
              <span>Faral Pro</span>
              <i class="fa fa-long-arrow-right"></i>
            </div> -->
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="choose-card bg-3">
            <h3>Prof (Dr.) Irfan Khan</h3>
            <p>President</p>
            <p>Ph.D. IIT (Kanpur)</p>
            <!-- <div class="choose-link">
              <span>See Funding Option</span>
              <i class="fa fa-long-arrow-right"></i>
            </div> -->

          </div>
        </div>  
        <div class="col-lg-3 col-md-6">
          <div class="choose-card bg-4">
           
            <h3>Prof. (Dr.) B.S. Nagendra Parashar</h3>
            <p>Vice Chancellor</p>
            <p>Ex. BITS, Pilani University </p>
            <!-- <div class="choose-link">
              <span>Degree With Work Experience</span>
              <i class="fa fa-long-arrow-right"></i>
            </div> -->

          </div>
        </div>
      </div>
    </div>
    <!-- <div class="newProgram">
      <div class="program_content">
        <div class="row">
          <div class="col-md-6">
            <div class="service-content">
              <div class="title active"  data-aos="fade-up"
              data-aos-duration="3000">
                The world is changing and so we are in the wave </div>
              <p>
                The mission of Falar is to serve as a digital gateway and information hub for all members of the
                university community. </p>
              <a href="https://themes.envytheme.com/falar/all-programs/" class="default-btn">
                New Programs <i class="fa fa-long-arrow-right"></i>
              </a>
              <ul class="service-list ul_list mt-3">
                <li>
                  <i class="fa fa-check"></i>
                  5 New programs on health sector.
                </li>
                <li>
                  <i class="fa fa-check"></i>
                  32 New programs based on bio-research.
                </li>
                <li>
                  <i class="fa fa-check"></i>
                  12 New IT programs.
                </li>
              </ul>
            </div>
          </div>

          <div class="col-md-6">
            <div class="row">
              <div class="col-md-6">
                <div class="dr_img1 d-flex justify-content-end">
                  <div class="drLogo">
                    <div class="d-flex align-items-center justify-content-center">
                      <div class="icon">
                        <div class="icon style">
                          <img decoding="async"
                            src="https://themes.envytheme.com/falar/wp-content/uploads/2023/12/service.svg" alt="image">
                        </div>
                      </div>
                      <h2 class="counter">15</h2>
                      <h2 class="plus">+</h2>
                    </div>
                    <p>AWARDS COLLECTED IN BIO RESEARCH FIELD</p>
                  </div>
                  <img decoding="async" width="80%" 
                    src="https://themes.envytheme.com/falar/wp-content/uploads/2023/12/service1.jpg" alt="image">
                </div>
              </div>
              <div class="col-md-6">
                <div class="dr_img1">
                  <img decoding="async" width="80%" class="mt-5"
                    src="https://themes.envytheme.com/falar/wp-content/uploads/2023/12/service2.jpg" alt="image">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div> -->
  </div>
  <!-- <div *ngFor="let data of pgdata;let i=index">
    <div>
    <div *ngFor="let dt of data.description"> -->
  <!-- *ngIf="dt.content=='Programs' && dt.Contenttype=='Component'" -->

  <div class="box8" *ngIf="this.alldata?.Faculty">
    <!-- *ngIf="dt.content=='Faculty' && dt.Contenttype=='Component'" -->
    <div class="banner_content">
      <app-faculty [facultydata]="this.alldata?.Faculty" [fromslug]="'home'"></app-faculty>
    </div>
  </div>
  <!-- <div class="box11">
        <div class="banner_content">
          <app-news></app-news>
        </div>
      </div> -->
  <div class="box13" *ngIf="this.alldata?.Testimonial">
    <!-- *ngIf="dt.content=='Testimonial' && dt.Contenttype=='Component'" -->
    <div class="banner_content">
      <app-testimonial [Testimonialdata]="this.alldata?.Testimonial" [fromslug]="'home'"></app-testimonial>
    </div>
  </div>
  <div class="box12" *ngIf="this.alldata?.Placement">
    <!-- *ngIf="dt.content=='Placement with stat' && dt.Contenttype=='Component'" -->
    <div class="banner_content">
      <app-placement-company [Placementdata]="this.alldata?.Placement" [fromslug]="'home'"></app-placement-company>
    </div>
  </div>
  <div class="box14" *ngIf="this.alldata?.FAQs">
    <!-- *ngIf="dt.content=='FAQs' && dt.Contenttype=='Component'" -->
    <div class="banner_content">
      <app-faqs [faqdata]="this.alldata?.FAQs" [fromslug]="'home'"></app-faqs>
    </div>
  </div>
  <div class="box11" *ngIf="this.alldata?.Blog">
    <div class="banner_content">
      <app-blogs [blogdata]="this.alldata?.Blog" [fromslug]="'home'"></app-blogs>
    </div>
  </div>
</section>

<ngx-spinner type="ball-climbing-dot"></ngx-spinner>
<!-- Modal -->
<div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <!-- <div class="modal-header">  
      </div> -->
      <div class="modal-body">
        <button type="button" (click)="closepopup()" class="btn-close" data-bs-dismiss="modal"
          aria-label="Close"></button>
        <div class="">
          <div class="slide_img_content">
            <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
              <div class="carousel-inner">
                <div class="carousel-item" [ngClass]="isFirst ? 'carousel-item active' : 'carousel-item' "
                  *ngFor="let img of AnnouncementGetData let i=index; first as isFirst">
                  <div *ngFor="let a of img.files">
                    <img src="{{a.url}}" class="d-block popup_slider" alt="Popup" (click)="imageClickHandler(img.slug)">
                  </div>
                </div>
              </div>
              <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls"
                data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Previous</span>
              </button>
              <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls"
                data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="visually-hidden">Next</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>