<section>
    <footer>
        <!-- <div class="wp_icon d-flex justify-content-center align-items-center">
        </div> -->
        <div class="footer_content">
            <div class="row">
                <div class=" col-md-3">
                    <h4>Info For</h4>
                    <div class="menu-footer-menu-one-container">
                        <ul id="menu-footer-menu-one" class="menu">
                            <!-- <li>Experimental Learning</li>
                        <li>Schedule A Tour</li>-->
                            <a href="/InternationalAdmission">
                                <li>International Admission</li>
                            </a>
                            <a href="/programme">
                                <li>All Programs</li>
                            </a>
                            <a href="/alumni">
                                <li>Alumni &amp; Donors</li>
                            </a>
                            <li routerLink="/carrer">Career at JU</li>
                            <a href="/leadership">
                                <li>Committee at JU</li>
                            </a>
                            <a href="{{applayUrl}}" target="_blank">
                                <li>Admission</li>
                            </a>
                        </ul>
                    </div>
                </div>
                <div class="col-md-3">
                    <h4 class="d-flex justify-content-between">Quick Links</h4>
                    <div class="footer-add-inner">
                        <ul id="menu-footer-menu-one" class="menu">
                            <a href="{{applayUrl}}" target="_blank">
                                <li>Apply Now</li>
                            </a>
                            <li><a href="../../../../assets/pdf/AcadCalendar_2023_24.pdf" target="_blank">Academic
                                    Calendar</a></li>
                            <li routerLink="./about-dehradun">About Dehradun</li>
                            <li routerLink="/terms-and-conditions">Term & Conditions</li>
                            <li routerLink="/refund-policy">Refund Policy</li>
                            <li routerLink="/disclaimer">Disclaimer</li>
                            <li routerLink="/privacy-policy">Privacy Policy</li>
                            <li><a href="https://www.mhrdnats.gov.in/" target="_blank">NATS</a></li>
                            <!-- <li routerLink="career">Career</li> -->
                        </ul>
                    </div>
                </div>
                <div class=" col-md-3">
                    <h4 class="d-flex justify-content-between">Student Corner</h4>
                    <div class="footer-add-inner">
                        <ul id="menu-footer-menu-one" class="menu">
                            <!-- <li>Admission procedure</li> -->
                            <a href="../../../../assets/pdf/Anti-Drug.pdf"
                                target="_blank">
                                <li>Anti Drug</li>
                            </a>
                            <li>Fees structure</li>
                            <li><a href="../../../../assets/pdf/StatementMon_Jun 03 11_46_57 GMT+05_30 2024.pdf" target="_blank"> Prospectus</a></li>
                            <a href="../../../../assets/pdf/Anti-Ragging.pdf"
                                target="_blank">
                                <li>Anti Ragging</li>
                            </a>
                            <a href="../../../../assets/pdf/Student-Code.pdf"
                                target="_blank">
                                <li>Student's COC</li>
                            </a>
                            <a href="../../../../assets/pdf/Code of conduct for staff.pdf"
                            target="_blank">
                            <li>General COC</li>
                        </a>
                            <li routerLink="students-grievance-redressal">Students Grievance Redressal</li>
                            </ul>
                    </div>

                </div>
                <div class="col-md-3">
                    <h4 class="d-flex justify-content-between">Campus Address</h4>
                    <div class="footer-add-inner">
                        <p>Jigyasa University<br> (Formerly Himgiri Zee University),<br>P.O. Sherpur, Chakrata Road,
                            <br>Dehradun (Uttarakhand) Pin-248197
                        </p>
                        <div>
                            <p>Toll Free : <a href="tel:18001804181">18001804181</a></p>
                            <p>Email : <a href="mailto:info@hzu.edu.in">info@hzu.edu.in</a></p>
                            <p>Admission Helpline : +91-8171819966 / +91-70601 17799</p>
                        </div>
                        <div class="map_url">
                            <iframe width="100%"
                                src="//maps.google.com/maps?q=30.34012115,77.87854632149144&z=15&output=embed"></iframe>
                        </div>
                    </div>
                </div>
            </div>
            <hr class="mt-3">

            <div class="col-md-6">
                <div class="social-list d-flex justify-content-start">
                    <ul class="social-list d-flex justify-content-start align-items-center">
                        <li>
                            <a href="https://www.facebook.com/Jigyasauniversity" target="_blank"><img
                                    src="../../../../assets/img/jigyasa/icon/facebook.svg" width="100%"
                                    alt="facebook"></a>
                        </li>
                        <li class=""><a href="https://www.linkedin.com/school/jigyasa-university/" target="_blank"><i
                                    class="fa fa-linkedin-square"></i></a></li>
                        <li class=""><a href="https://www.instagram.com/ju_ddn/" target="_blank"><img
                                    src="../../../../assets/img/jigyasa/icon/instagram.svg" alt="instagram"></a></li>
                        <li class=""><a href="https://www.youtube.com/@Jigyasauniversity" target="_blank"><img
                                    src="../../../../assets/img/jigyasa/icon/youtube.svg" alt="youtube"></a></li>
                    </ul>
                </div>
            </div>
        </div>


    </footer>
    <div class="copyright">
        <span>© Copyright 2023 Jigyasa University (Formerly Himgiri Zee University) All Rights Reserved.</span><br>
        <span class="modifided_date">The Website was last modified on : 04/15/2024 17:46:28</span>
    </div>

</section>