<section>
  <div class="subMenu_content">
    <app-sub-menu></app-sub-menu>
  </div>
  <div class="main_div">
    <div class="title" data-aos="fade-up" data-aos-duration="3000">Events</div>
    <div class="listSearchArea">
      <div class="row">
        <div class="col-md-8">
          <ul class="nav nav-tabs tabs-buttons" role="tablist">
            <li class="nav-item" role="presentation" id="boardOfGovernorsTab0" *ngIf="AssignCategory">
              <button id="click_btn" class="nav-link active" data-bs-toggle="tab" data-bs-target="#boardOfGovernors0"
                type="button" role="tab" aria-labelledby="board Of Governors Tab 0" aria-selected="true"
                (click)="filtercategory(0)">All</button>
            </li>
            <li class="nav-item" role="presentation" id="boardOfGovernorsTab0"
              *ngFor="let dt of AssignCategory;let i=index">
              <button id="click_btn" class="nav-link" data-bs-toggle="tab" data-bs-target="#boardOfGovernors0"
                type="button" role="tab" aria-labelledby="board Of Governors Tab 0" aria-selected="true"
                (click)="filtercategory(dt.categoryId)">{{dt.categoryname}}</button>
            </li>
          </ul>
        </div>
        <div class="col-md-4">
          <div class="search_box">
            <input type="text" class="form-control filter" id="inputdata" placeholder="Filter"
              (keyup)="fn_ClientFilter($event)" />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 mt-4" *ngFor="let item of eventList ;let i=index">
        <div class="card" data-aos="zoom-in">
          <div class="row">
            <div class="col-12">
              <div class="profile_img mt-3" *ngFor="let dt of item.logofiles">
                <img src={{dt.value}} loading="lazy" class="img" alt={{item.Title}}>
              </div>
              <!-- <div class="content-blog-card">
                          <ul class="ps-0 mb-0 list-unstyled cat">
                              <li><a href="https://themes.envytheme.com/falar/category/career/">{{item.Title}}</a></li>
                          </ul>
                          <div class="card_title"><p>{{item.short}}</p>
                          </div>
                          <ul class="blog-list d-flex justify-content-between mt-3">
                            <li>{{item.CreatedDate}}</li>
                            <li>2 min read</li>
                          </ul>
                          <span class="readMore" routerLink="/event/{{item.slug}}">Read More <i class="fa fa-long-arrow-right"></i></span>
                      </div> -->
            </div>
            <div class="content-blog-card">{{item.Title}}</div>
            <span class="readMore" routerLink="/event/{{item.slug}}">Read More <i
                class="fa fa-long-arrow-right"></i></span>
          </div>
        </div>

      </div>
      <div *ngIf="eventList.length==0">
        No Record Found!
      </div>
      <div class="showMore_content text-center mt-5">
        <label for="" class="count_label">Showing {{tindex}} records of {{this.totalrecord}}</label>
        <a class="showMore_btn" *ngIf="more" (click)="setcentrelistclick()">Show More</a>
      </div>

    </div>
  </div>
</section>
<ngx-spinner type="ball-climbing-dot"></ngx-spinner>