import { Component } from '@angular/core';

@Component({
  selector: 'app-hi-zest-centre',
  templateUrl: './hi-zest-centre.component.html',
  styleUrls: ['./hi-zest-centre.component.css']
})
export class HiZESTCentreComponent {

}
