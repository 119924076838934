import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject, debounceTime, distinctUntilChanged, fromEvent, switchMap } from 'rxjs';
import { ApicallService } from 'src/app/services/apicall.service';
import { HomeSeoService } from 'src/app/services/homeseo.service';
import { ProjectSeoService } from 'src/app/services/projectseo.service';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import * as AOS from 'aos';

@Component({
  selector: 'app-eventdetails',
  templateUrl: './eventdetails.component.html',
  styleUrls: ['./eventdetails.component.css']
})
export class EventdetailsComponent {
  eventdetails:any=[];
  filteredblogList: any;
  selectedindex!:any;
  selectedcategory:number=0;
  categorydata:any=[];
  input = new Subject<string>();
  imageObject = [];
  constructor(
    private route: ActivatedRoute,
    private seoService: HomeSeoService,
    private projectService: ProjectSeoService,
    private apiService: ApicallService,
    private ngxSpinner: NgxSpinnerService
  ) {
  }
  ngOnInit(): void {
    AOS.init();

    let params = this.route.snapshot.paramMap.get('Type')!;
    this.route.params.subscribe((params: any) => {
      params = params['Type'];      
    });
    this.getblogdata(params)
    
  }
  getblogdata(param:string) {
    let tbody = {
      slug: param,
      Projectid: environment.projectid,
      type:"Events"
    };
    this.apiService.getGetblog(tbody).subscribe((data: any) => {
      this.eventdetails=data.data;
       this.assigndata()
      //this.projectService.sendMessageblog(data.data.blog);
      this.projectService.setmetablog(this.eventdetails);
    });
  }
  assigndata(){
    let title = this.eventdetails?.Title;
    this.imageObject= this.eventdetails.files.filter((dt:any)=>{
       return dt
     }).map((object:any)=>{
       return {"image":object.url,"thumbImage":object.url,"title":title};
     })
   }
   imageClickHandler(e:any) {
    console.log('image click', e);
    }  
}
