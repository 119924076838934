<section>
    <div class="subMenu_content">
        <app-sub-menu></app-sub-menu>
    </div>
    <div class="main_div sub_content">
        <div class="listSearchArea d-flex justify-content-between align-items-center">

            <ul class="nav nav-tabs tabs-buttons" role="tablist">
              <li class="nav-item" role="presentation" id="boardOfGovernorsTab0" *ngIf="AssignCategory">
              <button id="click_btn" class="nav-link active"
                  data-bs-toggle="tab" data-bs-target="#boardOfGovernors0" type="button"
                  role="tab" aria-labelledby="board Of Governors Tab 0" aria-selected="true" (click)="filtercategory(0)">All</button>
          </li>
                <li class="nav-item" role="presentation" id="boardOfGovernorsTab0"
                    *ngFor="let dt of AssignCategory;let i=index">
                    <button id="click_btn" class="nav-link"
                        data-bs-toggle="tab" data-bs-target="#boardOfGovernors0" type="button"
                        role="tab" aria-labelledby="board Of Governors Tab 0" aria-selected="true"
                        (click)="filtercategory(dt.categoryId)">{{dt.categoryname}}</button>
                </li>
            </ul>
            <div class="search_box">
                <input type="text" class="form-control filter" id="inputdata" placeholder="Filter"
                (keyup)="fn_ClientFilter($event)" />
            </div>
        </div>        
        <div class="row">
            <div class="col-md-4" *ngFor="let data of facultyList">
                <div class="common-card">
                    <figure *ngFor="let a of data.logofiles"> 
                      <img class=" lazyloaded"
                            src="{{a.value}}"
                            data-src="../../../assets/img/jigyasa/blank-removebg-preview.png"
                            alt="{{data.Title}}" title="{{data.Title}}" width="485" height="400"> 
                           </figure>
                    <div class="common-card-body">
                      <div class="info_content">
                      <div class="common-card-body-heads">
                        <h3 class="mt-2">{{data.Title}}</h3>
                        <span>{{data.short}}</span>
                      </div>
                     <p class="line-clamp" [innerHTML]="data.Long1"></p>
                      </div>
                      <div class="readmore d-flex justify-content-end align-items-center">
                        <button class="btn" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="showAllDetails(data)"><i class="fa fa-long-arrow-right readmore"></i></button>
                       </div>
                     </div>
                  </div>
            </div>
            <div *ngIf="facultyList.length==0">
                No Record Found!
            </div>
            <div class="showMore_content text-center mt-5">
                <label for="" class="count_label">Showing {{tindex}} records of {{this.totalrecord}}</label>
                <a class="showMore_btn" *ngIf="more" (click)="setcentrelistclick()">Show More</a>
            </div>
            
        </div>
    </div>

    <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-xl">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel"><span class="title">Faculty</span></h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-md-5">
              <figure class="figure" *ngFor="let a of imgFile"> 
                <img class=" lazyloaded"
                      src="{{a.value}}"
                      data-src="../../../assets/img/jigyasa/blank-removebg-preview.png"
                      alt="{{a.key}}" title="{{a.key}}" width="485" height="400"> 
                     </figure>
                    </div>
                    <div class="col-md-7">
                     <div class="common-card-body-heads">
                      <h3 class="mt-2">{{getAllDetails.Title}}</h3>
                      <span>{{getAllDetails.short}}</span>
                    </div>
                   <p [innerHTML]="getAllDetails.Long1"></p>
                  </div>
            </div>
          </div>
          </div>
        </div>
      </div>
</section>
<ngx-spinner type="ball-climbing-dot"></ngx-spinner>
