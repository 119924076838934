<div class="section">
    <div class="subMenu_content">
        <app-sub-menu></app-sub-menu>
       </div>
    <div class="sub_content">
        <div class="patents_awards_content">
            <div class="row">
                <div class="col-md-3 d-flex justify-content-center align-items-center">
                    <img src="https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-10/DISR.webp"
                        alt="logo" width="130px" height="170px">
                </div>
                <div class="col-md-9 d-flex justify-content-start align-items-center">
                    <p class="slider-heads">DISR, Ministry of Science Technology</p>
                </div>
            </div>
        </div>

        <div class="patents_awards_content mt-4">
            <div class="row">
                <div class="col-md-3 d-flex justify-content-center align-items-center">
                    <img src="https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-10/IACBE%20%28International%20Assembly%20for%20Collegiate%20Business%20Education%29.webp"
                        alt="logo" width="130px" height="170px">
                </div>
                <div class="col-md-9 d-flex justify-content-start align-items-center">
                    <p class="slider-heads">IACBE (International Assembly for Collegiate Business Education)...</p>
                </div>
            </div>
        </div>
        <div class="patents_awards_content mt-4">
            <div class="row">
                <div class="col-md-3 d-flex justify-content-center align-items-center">
                    <img src="https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-10/National%20Board%20of%20Accreditation.webp"
                        alt="logo" width="130px" height="170px">
                </div>
                <div class="col-md-9 d-flex justify-content-start align-items-center">
                    <p class="slider-heads">National Board of Accreditation</p>
                </div>
            </div>
        </div>
        <div class="patents_awards_content mt-4">
            <div class="row">
                <div class="col-md-3 d-flex justify-content-center align-items-center">
                    <img src="https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-10/QS%20World%20University%20Ranking.webp"
                        alt="logo" width="130px" height="170px">
                </div>
                <div class="col-md-9 d-flex justify-content-start align-items-center">
                    <p class="slider-heads">QS World University Ranking</p>
                </div>
            </div>
        </div>
        <div class="patents_awards_content mt-4">
            <div class="row">
                <div class="col-md-3 d-flex justify-content-center align-items-center">
                    <img src="https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-10/University%20Grants%20Commission.webp"
                        alt="logo" width="130px" height="170px">
                </div>
                <div class="col-md-9 d-flex justify-content-start align-items-center">
                    <p class="slider-heads">University Grants Commission</p>
                </div>
            </div>
        </div>
        <div class="patents_awards_content my-4">
            <div class="row">
                <div class="col-md-3 d-flex justify-content-center align-items-center">
                    <img src="https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-10/Uttrakhand%20State%20Government.webp"
                        alt="logo" width="130px" height="170px">
                </div>
                <div class="col-md-9 d-flex justify-content-start align-items-center">
                    <p class="slider-heads">Established by Uttrakhand State Government</p>
                </div>
            </div>
        </div>
    </div>
</div>