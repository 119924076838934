<section>
    <div class="title">Placement</div>
    <div>
        <div class="listSearchArea d-flex justify-content-end align-items-center">

            <ul class="nav nav-tabs tabs-buttons" role="tablist">
                <li class="nav-item" role="presentation" id="boardOfGovernorsTab0">
                    <button id="click_btn" class="nav-link active" data-bs-toggle="tab"
                        data-bs-target="#boardOfGovernors0" type="button" role="tab"
                        aria-labelledby="board Of Governors Tab 0" aria-selected="true" *ngIf="assigncategory"
                        (click)="filtercategory(0)">All</button>
                </li>
                <li class="nav-item" role="presentation" id="boardOfGovernorsTab0"
                    *ngFor="let dt of assigncategory;let i=index">
                    <button id="click_btn" class="nav-link" data-bs-toggle="tab" data-bs-target="#boardOfGovernors0"
                        type="button" role="tab" aria-labelledby="board Of Governors Tab 0" aria-selected="true"
                        (click)="filtercategory(dt.categoryId)">{{dt.categoryname}}</button>
                </li>
            </ul>
        </div>
    </div>
    <div class="sub_content py-3">
        <owl-carousel-o [options]="customOptions">
            <ng-template carouselSlide *ngFor="let a of careerplacement">
                <div class="d-flex justify-content-center">

                    <div class="achivers_sub_content">
                        <div class="profile_pic" *ngFor="let img of a.files">
                            <img src="{{img.url}}" width="100%" height="auto" alt="{{img.filename}}">
                        </div>
                        <div class="sub_title text-center">{{a.Title}}</div>
                        <h6 class="text-center">{{a.short}}</h6>
                    </div>
                </div>    
            </ng-template>
        </owl-carousel-o>
    </div>
    <div class="d-flex justify-content-end">
        <button class="btn view_sm_btn mt-4" routerLink="/placement-statistics">View All</button>
      </div>   

</section>