<section>
    <div>
        <div>
               <div class="section">
               
                <div class="subMenu_content">
                    <app-sub-menu></app-sub-menu>
                </div>
                <div class="sub_content">
                    <section id="OverviewOfRAndDCell">
                        <div class="overview">
                            <div class="title">The Library</div>
                            <div class="para">
                                <img src="../../../assets/img/library-banner.jpg" width="100%" class="img-fluid" alt="Library"/>
                                <p class="mt-3">The Library has state - of the - art information systems and provides access to an extensive range of database, together with traditional printed material. The library is stocked with more 
                                    than 30,000 books, 4000 titles and a number of journals and reports that cover all subjects. Although the primary mission of the library is to support the curricular and research needs of 
                                    the students, the library also provides material and reference assistance for the faculty.</p>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div class="row pb-5">
                            <div class="col-md-4 mt-3">
                                <div class="gallery_img">
                                    <a data-fancybox="" href="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1717052494473WhatsApp%20Image%202024-05-30%20at%2012.25.01%20PM%20%283%29.jpeg">
                                    <img src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1717052494473WhatsApp%20Image%202024-05-30%20at%2012.25.01%20PM%20%283%29.jpeg" width="100%" height="200px" alt="">
                                    </a>
                                </div>
                            </div>
                            <div class="col-md-4 mt-3">
                                <div class="gallery_img">
                                    <a data-fancybox="" href="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1717052515724WhatsApp%20Image%202024-05-30%20at%2012.25.01%20PM%20%285%29.jpeg">
                                    <img src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1717052515724WhatsApp%20Image%202024-05-30%20at%2012.25.01%20PM%20%285%29.jpeg" width="100%" height="200px" alt="">
                                    </a>
                                </div>
                            </div>
                            <div class="col-md-4 mt-3">
                                <div class="gallery_img">
                                    <a data-fancybox="" href="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1717052483908WhatsApp%20Image%202024-05-30%20at%2012.25.01%20PM%20%282%29.jpeg">
                                    <img src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1717052483908WhatsApp%20Image%202024-05-30%20at%2012.25.01%20PM%20%282%29.jpeg" width="100%" height="200px" alt="">
                                    </a>
                                </div>
                            </div>
                            <div class="col-md-4 mt-3">
                                <div class="gallery_img">
                                    <a data-fancybox="" href="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1717052473274WhatsApp%20Image%202024-05-30%20at%2012.25.01%20PM%20%281%29.jpeg">
                                    <img src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1717052473274WhatsApp%20Image%202024-05-30%20at%2012.25.01%20PM%20%281%29.jpeg" width="100%" height="200px" alt="">
                                    </a>
                                </div>
                            </div>
                            <div class="col-md-4 mt-3">
                                <div class="gallery_img">
                                    <a data-fancybox="" href="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1717052505249WhatsApp%20Image%202024-05-30%20at%2012.25.01%20PM%20%284%29.jpeg">
                                    <img src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1717052505249WhatsApp%20Image%202024-05-30%20at%2012.25.01%20PM%20%284%29.jpeg" width="100%" height="200px" alt="">
                                    </a>
                                </div>
                            </div>
                            <div class="col-md-4 mt-3">
                                <div class="gallery_img">
                                    <a data-fancybox="" href="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1717052526279WhatsApp%20Image%202024-05-30%20at%2012.25.01%20PM.jpeg">
                                    <img src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1717052526279WhatsApp%20Image%202024-05-30%20at%2012.25.01%20PM.jpeg" width="100%" height="200px" alt="">
                                    </a>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>

        </div>
    </div>
</section>