<div class="section">
    <header class="header" id="scroll_header">
        <div class="menu_list">
            <ul>
                <li><a href="Ph.D#Awarded">Details of Ph.D-Awarded</a></li>
                <li><a href="https://www.hzu.edu.in/pdf/HZU%20Ph.D.%20Regulation%20HZU%20WEF%202022-2023.pdf" target="_blank">JU Ph.D Regulation HZU WEF 2022-2023</a></li>
                <li><a href="https://www.hzu.edu.in/pdf/PET-2023-2024.pdf" target="_blank">Ph.D. Entrance Examination Syllabus</a></li>
            </ul>
        </div>
    </header>
    
    <div class="subMenu_content">
        <app-sub-menu></app-sub-menu>
       </div>
    <div class="sub_content">
        <section id="Awarded">
            <div class="overview">
                <div class="title">List of Ph.D. Student (Awarded) Till July 2023</div>
                <div class="patents_table mt-5">
                    <table>
                        <thead>
                            <tr>
                                <th>S.N.</th>
                                <th>Name of Scholar(s)</th>
                                <th>Department</th>
                                <th>Date/Year of Registration</th>
                                <th>Status</th>
                                <th>Name of Supervisor</th>
                                <th>Topic of the thesis</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <p>1</p>
                                </td>
                                <td>
                                    <p>
                                        Ms. Abha Sharma

                                    </p>
                                </td>
                                <td>
                                    <p>Management</p>
                                </td>

                                <td>
                                    <p>2013</p>
                                </td>

                                <td>
                                    <p>2021</p>
                                </td>

                                <td>
                                    <p>Dr. Jai Jayant &amp; Prof. S. C. Bagri</p>
                                </td>

                                <td>
                                    <p>The-Socio Economic and Environmental Impact of Tourism Development on Dehradun
                                        District</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>2</p>
                                </td>
                                <td>
                                    <p>
                                        Mrs. Alka Gaur

                                    </p>
                                </td>
                                <td>
                                    <p>Education</p>
                                </td>

                                <td>
                                    <p>2013</p>
                                </td>

                                <td>
                                    <p>2018</p>
                                </td>

                                <td>
                                    <p>Dr. Neerja Dhankar</p>
                                </td>

                                <td>
                                    <p>A Study Of Adolescent Girls Towards Their Empowerment</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>3</p>
                                </td>
                                <td>
                                    <p>
                                        Deepti Sajwan
                                    </p>
                                </td>
                                <td>
                                    <p>Education</p>
                                </td>

                                <td>
                                    <p>2013</p>
                                </td>

                                <td>
                                    <p>2018</p>
                                </td>

                                <td>
                                    <p>Dr. Anoj Raj</p>
                                </td>

                                <td>
                                    <p>“स्वामी विवेकानन्द की स्त्री-शिक्षा, युवा - शिक्षा एवं जन-शिक्षा का आधुनिक
                                        परिप्रेक्ष्य में अध्ययन</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>4</p>
                                </td>
                                <td>
                                    <p>
                                        Mrs. Bharti Rawat

                                    </p>
                                </td>
                                <td>
                                    <p>Education</p>
                                </td>

                                <td>
                                    <p>2013</p>
                                </td>

                                <td>
                                    <p>2018</p>
                                </td>

                                <td>
                                    <p>Dr. Anoj Raj</p>
                                </td>

                                <td>
                                    <p>देहरादून जनपद के अन्तर्गत बी० एड छात्राध्यापकों और छात्राध्यापिकाओं के पारिवारिक
                                        वातावरण, समायोजन और अध्ययन आदतों का शैक्षिक उपलब्धि पर अध्ययनका आधुनिक
                                        परिप्रेक्ष्य में अध्ययन</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>5</p>
                                </td>
                                <td>
                                    <p>
                                        Mrs. Bimla Aswal

                                    </p>
                                </td>
                                <td>
                                    <p>Education</p>
                                </td>

                                <td>
                                    <p>2013</p>
                                </td>

                                <td>
                                    <p>2018</p>
                                </td>

                                <td>
                                    <p>Dr. Anoj Raj</p>
                                </td>
                                <td>
                                    <p>माध्यमिक स्तर में अध्ययनरत् छात्र एवं छात्राओं की सृजनशीलता, मानसिक स्वास्थ्य एवं
                                        शैक्षिक उपलब्धि के मध्य सम्बन्ध का अध्ययन</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>6</p>
                                </td>
                                <td>
                                    <p>
                                        Ms. Charu Sharma

                                    </p>
                                </td>
                                <td>
                                    <p>Education</p>
                                </td>

                                <td>
                                    <p>2014</p>
                                </td>
                                <td>
                                    <p>2018</p>
                                </td>

                                <td>
                                    <p> Dr. Dalbeer S. Gariya</p>
                                </td>

                                <td>
                                    <p>Ucchtar Madhyamik Vidhyalaon Mein Adhyayanrat Kishore- Kishoriyon Ke Naitik
                                        Mulyon Evm Unme Manav Adhikaron Ke Prati Jagrukta Ka Ek Vishleshnatmak Adhyayan
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>7</p>
                                </td>
                                <td>
                                    <p>
                                        Mrs. Seeta Juyal

                                    </p>
                                </td>
                                <td>
                                    <p>Education</p>
                                </td>

                                <td>
                                    <p>2014</p>
                                </td>

                                <td>
                                    <p>2018</p>
                                </td>

                                <td>
                                    <p> Dr. Deepti Uniyal</p>
                                </td>

                                <td>
                                    <p>श्रीमद्भागवत महापुराण में निहित शैक्षिक विचारों की आधुनिक शिक्षा में प्रासंगिकता
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>8</p>
                                </td>
                                <td>
                                    <p>
                                        Ms. Ankur Chauhan
                                    </p>
                                </td>
                                <td>
                                    <p>Education</p>
                                </td>
                                <td>
                                    <p>2014</p>
                                </td>
                                <td>
                                    <p>2018</p>
                                </td>

                                <td>
                                    <p> Dr. Dalbeer S. Gariya</p>
                                </td>

                                <td>
                                    <p>Impact Of Personality On Adjustment Of Female Students Studying In Coeducational
                                        And Unieducational Colleges Of Uttarakhand</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>9</p>
                                </td>
                                <td>
                                    <p>
                                        Mrs. Vandana Bhatt

                                    </p>
                                </td>
                                <td>
                                    <p>Education</p>
                                </td>

                                <td>
                                    <p>2014</p>
                                </td>

                                <td>
                                    <p>2018</p>
                                </td>

                                <td>
                                    <p>Dr. Dalbeer S. Gariya</p>
                                </td>

                                <td>
                                    <p>उत्तराखण्ड के निवासियों के पर्यावरणीय नैतिकता एवं पर्यावरणीय अभिवृत्ति पर
                                        पर्यावरणीय जागरूकता का प्रभाव</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>10</p>
                                </td>
                                <td>
                                    <p>
                                        Mrs. Shanu Maithani

                                    </p>
                                </td>
                                <td>
                                    <p>Education</p>
                                </td>

                                <td>
                                    <p>2014</p>
                                </td>

                                <td>
                                    <p>2021</p>
                                </td>

                                <td>
                                    <p>Dr. Dalbeer S. Gariya</p>
                                </td>

                                <td>
                                    <p>“The Impact of Home and School Environment on Personality, Adjustment and
                                        Academic performance of Secondary Students of Uttarakhand.”</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>11</p>
                                </td>
                                <td>
                                    <p>
                                        Mrs. Sonia Pal

                                    </p>
                                </td>
                                <td>
                                    <p>Education</p>
                                </td>

                                <td>
                                    <p>2014</p>
                                </td>

                                <td>
                                    <p>2018</p>
                                </td>

                                <td>
                                    <p>Dr. Dalbeer S. Gariya</p>
                                </td>

                                <td>
                                    <p>गढवाल मण्डल में स्थित सरकारी अध्यापक शिक्षण महाविद्यालयों एवं स्ववित्तपोषित
                                        विश्वविद्यालयों में अध्ययनरत बी० एड० प्रशिक्षणार्थियों में शिक्षण के प्रति रुचि
                                        व अभिवृति का तुलनात्मक अध्ययन"</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>12</p>
                                </td>
                                <td>
                                    <p>
                                        Mrs. Anita Verma

                                    </p>
                                </td>
                                <td>
                                    <p>Education</p>
                                </td>

                                <td>
                                    <p>2015</p>
                                </td>

                                <td>
                                    <p>2021</p>
                                </td>
                                <td>
                                    <p>Dr. Dalbeer S. Gariya</p>
                                </td>
                                <td>
                                    <p>“Personality and Social Intelligence of the students of under graduate
                                        professional course: A Study” </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>13</p>
                                </td>
                                <td>
                                    <p>
                                        Mrs. Kamla Thathola

                                    </p>
                                </td>
                                <td>
                                    <p>Education</p>
                                </td>

                                <td>
                                    <p>2015</p>
                                </td>
                                <td>
                                    <p>2021</p>
                                </td>

                                <td>
                                    <p>Dr. Dalbeer S. Gariya</p>
                                </td>

                                <td>
                                    <p>"उत्तराखण्ड राज्य के ग्रामीण (पिछड़े) क्षेत्र की किशोरियों की उच्च शिक्षा प्राप्त
                                        करने में अनुभूत मौतिक एवं मनोवैज्ञानिक बाधाओं का तथा उनके मध्य पारस्परिक संबंध
                                        का विश्लेषणात्मक अध्ययन'
                                        An analytical study of the physical and psychological barriers and their"</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>14</p>
                                </td>
                                <td>
                                    <p>
                                        Mrs. Rakhi Sharma

                                    </p>
                                </td>
                                <td>
                                    <p>Education</p>
                                </td>

                                <td>
                                    <p>2015</p>
                                </td>

                                <td>
                                    <p>2020</p>
                                </td>

                                <td>
                                    <p>Dr. Dalbeer S. Gariya</p>
                                </td>

                                <td>
                                    <p>शहरी एवं ग्रामीण क्षेत्रों में रहने वाली महिलाओं एवं पुरुषों में जनसंख्या शिक्षा
                                        के प्रति अभिवृत्ति पर उनके सामाजिक-आर्थिक स्तर के प्रभाव का एक विश्लेषणात्मक
                                        अध्ययन</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>15</p>
                                </td>
                                <td>
                                    <p>
                                        Mrs. Bhawana Gupta

                                    </p>
                                </td>
                                <td>
                                    <p>Education</p>
                                </td>

                                <td>
                                    <p>2015</p>
                                </td>

                                <td>
                                    <p>2021</p>
                                </td>

                                <td>
                                    <p>Dr. Dalbeer S. Gariya</p>
                                </td>

                                <td>
                                    <p>“Attitude towards E-learning and Book learning of students of higher education of
                                        different disciplines : A comparative study”</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>16</p>
                                </td>
                                <td>
                                    <p>
                                        Mrs. Pooja Gupta
                                    </p>
                                </td>
                                <td>
                                    <p>Education</p>
                                </td>

                                <td>
                                    <p>2015</p>
                                </td>

                                <td>
                                    <p>2021</p>
                                </td>

                                <td>
                                    <p>Dr. Dalbeer S. Gariya</p>
                                </td>

                                <td>
                                    <p>पुरस्कृत शिक्षक-शिक्षिकाओं में अभिप्रेरणा स्तर और व्यवसाय के प्रति दृष्टिकोण का
                                        एक विश्लेष्णात्मक अध्ययन</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>17</p>
                                </td>
                                <td>
                                    <p>
                                        Ms. Gangotri Rawat

                                    </p>
                                </td>
                                <td>
                                    <p>Education</p>
                                </td>

                                <td>
                                    <p>2015</p>
                                </td>

                                <td>
                                    <p>2020</p>
                                </td>

                                <td>
                                    <p>Dr. Charu Sharma</p>
                                </td>

                                <td>
                                    <p>A Study Of School Environment And Leadership Effectiveness Of Principals Of The
                                        Government And Private Schools</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>18</p>
                                </td>
                                <td>
                                    <p>
                                        Mrs. Sudha Verma

                                    </p>
                                </td>
                                <td>
                                    <p>Education</p>
                                </td>

                                <td>
                                    <p>2015</p>
                                </td>

                                <td>
                                    <p>2022</p>
                                </td>

                                <td>
                                    <p>Dr. Anup Pokhariyal</p>
                                </td>

                                <td>
                                    <p>“गांधीजी व गीजू भाई के शिक्षा दर्शन का तुलनात्मक अध्ययन” </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>19</p>
                                </td>
                                <td>
                                    <p>
                                        Mr. Ramdhan Nautiyal
                                    </p>
                                </td>
                                <td>
                                    <p>Education</p>
                                </td>

                                <td>
                                    <p>2015</p>
                                </td>

                                <td>
                                    <p>2019</p>
                                </td>

                                <td>
                                    <p>Dr. Dalbeer S. Gariya</p>
                                </td>

                                <td>
                                    <p>माध्यमिक स्तर पर अध्ययनरत् छात्र-छात्राओं की सामाजिक-आर्थिक स्थिति व्यक्तित्व एवं
                                        सामाजिक समायोजन का कम्प्यूटर अभिवृत्ति के संदर्भ में एक अध्ययन</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>20</p>
                                </td>
                                <td>
                                    <p>
                                        Mr. Santosh Dabral

                                    </p>
                                </td>
                                <td>
                                    <p>Education</p>
                                </td>

                                <td>
                                    <p>2015</p>
                                </td>

                                <td>
                                    <p>2019</p>
                                </td>

                                <td>
                                    <p>Dr. Dalbeer S. Gariya</p>
                                </td>

                                <td>

                                    <p>स्नातक स्तर के अन्य पिछड़ा वर्ग एवं अनुसूचित जाति की छात्राओं की शैक्षिक आकांक्षा
                                        स्तर, शैक्षिक उपलब्धि एवं अभिप्रेरणा का तुलनात्मक अध्ययन'</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>21</p>
                                </td>
                                <td>
                                    <p>
                                        Ms. Anju

                                    </p>
                                </td>
                                <td>
                                    <p>Education</p>
                                </td>

                                <td>
                                    <p>2015</p>
                                </td>

                                <td>
                                    <p>2023</p>
                                </td>

                                <td>
                                    <p>Dr. Charu Sharma</p>
                                </td>

                                <td>
                                    <p>महिलाओं के शिक्षा के स्तर एवं उनका अपने अधिकारों के प्रति जागरूकता के संबन्ध का
                                        एक विश्लेषणात्मक अध्ययन”</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>22</p>
                                </td>
                                <td>
                                    <p>
                                        Mr. Sumer Chand

                                    </p>
                                </td>
                                <td>
                                    <p>Education</p>
                                </td>

                                <td>
                                    <p>2016</p>
                                </td>

                                <td>
                                    <p>2020</p>
                                </td>

                                <td>
                                    <p>Dr. Anoop Pokhriyal</p>
                                </td>

                                <td>
                                    <p>स्नातक स्तर के अनुसूचित जाति एवं जनजाति के विद्यार्थियों के संवैधानिक शैक्षिक
                                        प्रावधानों के प्रति जागरूकता का विश्लेषणात्मक अध्ययन</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>23</p>
                                </td>
                                <td>
                                    <p>
                                        Mr. Harish Kumar Yadav
                                    </p>
                                </td>
                                <td>
                                    <p>Education</p>
                                </td>

                                <td>
                                    <p>2016</p>
                                </td>

                                <td>
                                    <p>2020</p>
                                </td>

                                <td>
                                    <p>Dr. Dalbeer S. Gariya</p>
                                </td>

                                <td>
                                    <p>Attitudes of Secondary School Teachers and Adolescents Towards Inclusive
                                        Education: A Comparative Study</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>24</p>
                                </td>
                                <td>
                                    <p>
                                        Mr. Pancham Singh
                                    </p>
                                </td>
                                <td>
                                    <p>Education</p>
                                </td>

                                <td>
                                    <p>2016</p>
                                </td>

                                <td>
                                    <p>2020</p>
                                </td>

                                <td>
                                    <p>Dr. Anoop Pokhriyal</p>
                                </td>

                                <td>
                                    <p>मध्य हिमालय के वैदिक कालीन आश्रमों क आध्यात्मिक, सांस्कृतिक एवं शैक्षिक अध्ययन
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>25</p>
                                </td>
                                <td>
                                    <p>
                                        Mrs. Kaneez F. Siddiquai

                                    </p>
                                </td>
                                <td>
                                    <p>Education</p>
                                </td>

                                <td>
                                    <p>2016</p>
                                </td>

                                <td>
                                    <p>2019</p>
                                </td>

                                <td>
                                    <p>Dr. Anoop Pokhriyal</p>
                                </td>

                                <td>

                                    <p>“मुस्लिम लड़के, लड़कियों के शिक्षा में बाधक विभिन्न कारकों का विश्लेषणात्मक
                                        अध्ययन"</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>26</p>
                                </td>
                                <td>
                                    <p>
                                        Mr. Rakesh Negi
                                    </p>
                                </td>
                                <td>
                                    <p>Education</p>
                                </td>

                                <td>
                                    <p>2016</p>
                                </td>

                                <td>
                                    <p>2020</p>
                                </td>

                                <td>
                                    <p>Dr. Charu Sharma</p>
                                </td>
                                <td>
                                    <p>रुद्रप्रयाग जनपद में प्राथमिक शिक्षा के विकास का अध्ययन</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>27</p>
                                </td>
                                <td>
                                    <p>
                                        Mrs. Vedangi Bhatt

                                    </p>
                                </td>
                                <td>
                                    <p>Education</p>
                                </td>

                                <td>
                                    <p>2016</p>
                                </td>

                                <td>
                                    <p>2021</p>
                                </td>

                                <td>
                                    <p>Dr. Dalbeer S. Gariya</p>
                                </td>

                                <td>
                                    <p>किशोरियों को सशक्तिकरण एवं मनोवैज्ञानिक परामर्श की आवश्यकता और इसमें अभिभावकों
                                        तथा राज्य सरकार की प्रदत्त भूमिका का एक विश्लेषणात्मक अध्ययन'</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>28</p>
                                </td>
                                <td>
                                    <p>
                                        Mr. Kuldeep Singh

                                    </p>
                                </td>
                                <td>
                                    <p>Education</p>
                                </td>

                                <td>
                                    <p>2016</p>
                                </td>

                                <td>
                                    <p>2019</p>
                                </td>

                                <td>
                                    <p>Dr. Anoop Pokhriyal</p>
                                </td>

                                <td>
                                    <p>राजकीय आश्रम पद्धति के अन्तर्गत संचालित विद्यालयों में अध्ययनरत माध्यमिक कक्षाओं
                                        के छात्र-छात्राओं के अध्ययन आदतों और समायोजन स्तर का उनकी शैक्षिक उपलब्धि पर
                                        पड़ने वाले प्रभाव का अध्ययन</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>29</p>
                                </td>
                                <td>
                                    <p>
                                        Mr. Anant Sharma

                                    </p>
                                </td>
                                <td>
                                    <p>Management</p>
                                </td>

                                <td>
                                    <p>2016</p>
                                </td>

                                <td>
                                    <p>2023</p>
                                </td>

                                <td>
                                    <p>Dr. Parikshit Kala</p>
                                </td>

                                <td>
                                    <p>“Performance of micro, small and medium enterprises in Dehradun district : A
                                        financial case study”</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>30</p>
                                </td>
                                <td>
                                    <p>
                                        Ms. Ayesha Afroz

                                    </p>
                                </td>
                                <td>
                                    <p>Management</p>
                                </td>

                                <td>
                                    <p>2016</p>
                                </td>

                                <td>
                                    <p>2021</p>
                                </td>

                                <td>
                                    <p>Dr. Prikshit Kala &amp; Dr. Viplav Pandey</p>
                                </td>

                                <td>
                                    <p>“Relationship of Emotional Intelligence with Leadership styles and Leadership
                                        Effectiveness: An Evaluative Study of Retail Sector”</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>31</p>
                                </td>
                                <td>
                                    <p>
                                        Mr. Samresh Benjwal

                                    </p>
                                </td>
                                <td>
                                    <p>Management</p>
                                </td>

                                <td>
                                    <p>2016</p>
                                </td>

                                <td>
                                    <p>2021</p>
                                </td>

                                <td>
                                    <p>Dr. Jai Jayant</p>
                                </td>

                                <td>
                                    <p>“Training Need Analysis: A Study of Small&amp; Micro Enterprises in Garhwal
                                        Region”</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>32</p>
                                </td>
                                <td>
                                    <p>
                                        Ar. Anil Bharti

                                    </p>
                                </td>
                                <td>
                                    <p>Architecture</p>
                                </td>

                                <td>
                                    <p>2016</p>
                                </td>

                                <td>
                                    <p>2019</p>
                                </td>

                                <td>
                                    <p>Prof. Kulkarni</p>
                                </td>

                                <td>
                                    <p>Rejuvenation of Dashashwamegh Ghat at Varanasi.</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>33</p>
                                </td>
                                <td>
                                    <p>
                                        Ar. Bindu Agrawal

                                    </p>
                                </td>
                                <td>
                                    <p>Architecture</p>
                                </td>

                                <td>
                                    <p>2016</p>
                                </td>

                                <td>
                                    <p>2020</p>
                                </td>

                                <td>
                                    <p>Prof. Kulkarni</p>
                                </td>

                                <td>
                                    <p>Socio Economic Impact Assessment Of City Growth On Residents Of Dehradun,
                                        Uttrakhand, India</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>34</p>
                                </td>
                                <td>
                                    <p>
                                        Vijaylakshmi Sajwan

                                    </p>
                                </td>
                                <td>
                                    <p>Engineering</p>
                                </td>

                                <td>
                                    <p>2016</p>
                                </td>

                                <td>
                                    <p>2023</p>
                                </td>

                                <td>
                                    <p>Prof. Rakesh Ranjan</p>
                                </td>

                                <td>
                                    <p>“Rapid image retrieval system technique using new distance metrics”</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>35</p>
                                </td>
                                <td>
                                    <p>
                                        Rakesh Kumar
                                    </p>
                                </td>
                                <td>
                                    <p>Engineering</p>
                                </td>

                                <td>
                                    <p>2016</p>
                                </td>

                                <td>
                                    <p>2023</p>
                                </td>

                                <td>
                                    <p>Prof. Rakesh Ranjan</p>
                                </td>

                                <td>
                                    <p>A new Long Term Electricity Load Forecasting For Uttarakhand State Power
                                        Utilities using Artificial Neural Network.</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>36</p>
                                </td>
                                <td>
                                    <p>
                                        Mr. Sandeep Kumar Joshi

                                    </p>
                                </td>
                                <td>
                                    <p>Education</p>
                                </td>

                                <td>
                                    <p>2017</p>
                                </td>

                                <td>
                                    <p>2023</p>
                                </td>

                                <td>
                                    <p>Dr. Poonam Thapliyal</p>
                                </td>

                                <td>
                                    <p>“Study of government school teachers: Attitude and awareness towards continuous
                                        and comprehensive evaluation”</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>37</p>
                                </td>
                                <td>
                                    <p>
                                        Sanjay Gururani
                                    </p>
                                </td>
                                <td>
                                    <p>Education</p>
                                </td>

                                <td>
                                    <p>2017</p>
                                </td>

                                <td>
                                    <p>2021</p>
                                </td>

                                <td>
                                    <p>Dr. Dalbeer S. Gariya</p>
                                </td>

                                <td>
                                    <p>उपनिषदों में निहित नैतिक एवं आध्यात्मिक शिक्षा की आधुनिक शिक्षा में उपादेयता</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>38</p>
                                </td>
                                <td>
                                    <p>
                                        Mr. Sumit Pandey

                                    </p>
                                </td>
                                <td>
                                    <p>Education</p>
                                </td>
                                <td>
                                    <p>2017</p>
                                </td>

                                <td>
                                    <p>2021</p>
                                </td>

                                <td>
                                    <p>Dr. Dalbeer S. Gariya</p>
                                </td>

                                <td>
                                    <p>मनोवैज्ञानिक कठोरता के सम्बन्ध में समस्या निवारण कौशल का एक विश्लेषणात्मक अध्ययन
                                    </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>39</p>
                                </td>
                                <td>
                                    <p>
                                        Mr. Mithilesh k Shukla

                                    </p>
                                </td>
                                <td>
                                    <p>Education</p>
                                </td>

                                <td>
                                    <p>2017</p>
                                </td>

                                <td>
                                    <p>2021</p>
                                </td>

                                <td>
                                    <p>Dr. Charu Sharma</p>
                                </td>

                                <td>
                                    <p>स्मृति ग्रन्थ (मनु, याज्ञवल्क्य) में शिक्षा व्यवस्था का समीक्षात्मक अध्ययन एवं
                                        वर्तमान शिक्षा में प्रासंगिकता</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>40</p>
                                </td>
                                <td>
                                    <p>
                                        Mr.Satyen Chettri

                                    </p>
                                </td>
                                <td>
                                    <p>Forestry</p>
                                </td>

                                <td>
                                    <p>2017</p>
                                </td>

                                <td>
                                    <p>2022</p>
                                </td>

                                <td>
                                    <p>Dr. Prakash Rai &amp; Dr. Sharad Pandey</p>
                                </td>

                                <td>
                                    <p>“Study on effect of growth regulators and potting media on vegetative propagation
                                        of Populus Deltoides L. Marsh”</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>41</p>
                                </td>
                                <td>
                                    <p>
                                        Mr.Ramanjyot

                                    </p>
                                </td>
                                <td>
                                    <p>Architect</p>
                                </td>

                                <td>
                                    <p>2017</p>
                                </td>

                                <td>
                                    <p>2020</p>
                                </td>

                                <td>
                                    <p>Prof. Kulkarni</p>
                                </td>
                                <td>
                                    <p>“An Investigative Study of Built Cultural Heritage and Resurrecting precinct of
                                        Guru Ram Rai Darbar Dehradun” </p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>42</p>
                                </td>
                                <td>
                                    <p>
                                        Deepti Uniyal

                                    </p>
                                </td>
                                <td>
                                    <p>Education</p>
                                </td>

                                <td>
                                    <p>2013</p>
                                </td>

                                <td>
                                    <p>2016</p>
                                </td>

                                <td>
                                    <p>Dr. Anoj Raj</p>
                                </td>

                                <td>
                                    <p>A Study Of Emotional Intelligence In Relation To Teaching Aptitude And Job
                                        Satisfaction Among The Senior Secondary Teachers Of Dehradun District</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>43</p>
                                </td>
                                <td>
                                    <p>
                                        Anup K Pokhriyal

                                    </p>
                                </td>
                                <td>
                                    <p>Education</p>
                                </td>

                                <td>
                                    <p>2013</p>
                                </td>

                                <td>
                                    <p>2016</p>
                                </td>

                                <td>
                                    <p>Dr. Anoj Raj</p>
                                </td>

                                <td>
                                    <p>A Study Of The Development Of Primary Education In Dehradun District From 2001 To
                                        2013</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>44</p>
                                </td>
                                <td>
                                    <p>
                                        Rishabh Bhardwaj

                                    </p>
                                </td>
                                <td>
                                    <p>Mass Comm</p>
                                </td>

                                <td>
                                    <p>2017</p>
                                </td>

                                <td>
                                    <p>2023</p>
                                </td>

                                <td>
                                    <p>Dr. Ritesh Chaudhary</p>
                                </td>

                                <td>
                                    <p>उत्तराखण्ड में समुदाय, संस्कृति और सामुदायिक रेडियो में परस्पर सम्बंध का एक
                                        विश्लेष्णात्मक अध्ययन</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>45</p>
                                </td>
                                <td>
                                    <p>
                                        Rohit Kumar

                                    </p>
                                </td>
                                <td>
                                    <p>Management</p>
                                </td>

                                <td>
                                    <p>2018</p>
                                </td>

                                <td>
                                    <p>2022</p>
                                </td>

                                <td>
                                    <p>Dr. Jai Jayant</p>
                                </td>
                                <td>
                                    <p>“Impact of digital marketing on consumer buying behaviour: A study of Garhwal
                                        region”</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>46</p>
                                </td>
                                <td>
                                    <p>
                                        Asha Kotnala
                                    </p>
                                </td>
                                <td>
                                    <p>Education</p>
                                </td>

                                <td>
                                    <p>2018</p>
                                </td>

                                <td>
                                    <p>2023</p>
                                </td>

                                <td>
                                    <p>Dr. Bhopal S. Rawat</p>
                                </td>

                                <td>
                                    <p>A Study of the Impact of Emotional Intelligence of Pre-Service Teachers on their
                                        Teaching Competency and Adjustment</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>47</p>
                                </td>
                                <td>
                                    <p>
                                        Suman Chauhan

                                    </p>
                                </td>
                                <td>
                                    <p>Education</p>
                                </td>

                                <td>
                                    <p>2018</p>
                                </td>

                                <td>
                                    <p>2023</p>
                                </td>

                                <td>
                                    <p>Dr. Bhopal S. Rawat</p>
                                </td>

                                <td>
                                    <p>उच्चतर माध्यमिक स्तर पर अध्ययनरत विद्यार्थियों की जीवनशैली पर विद्यालय वातावरण,
                                        आधुनिकीकरण एंव सामाजिक --आर्थिक स्तर के प्रभाव का अध्ययन</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>48</p>
                                </td>
                                <td>
                                    <p>
                                        Sudha Verma

                                    </p>
                                </td>
                                <td>
                                    <p>Education</p>
                                </td>

                                <td>
                                    <p>2015</p>
                                </td>

                                <td>
                                    <p>2022</p>
                                </td>

                                <td>
                                    <p>Dr. Anup Pokhariyal</p>
                                </td>

                                <td>
                                    <p>गांधीजी व गीजू भाई के शिक्षा दर्शन का तुलनात्मक अध्ययन” --आर्थिक स्तर के प्रभाव
                                        का अध्ययन</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>49</p>
                                </td>
                                <td>
                                    <p>
                                        Samresh kumar
                                    </p>
                                </td>
                                <td>
                                    <p>Management</p>
                                </td>

                                <td>
                                    <p>2016</p>
                                </td>

                                <td>
                                    <p>2023</p>
                                </td>

                                <td>
                                    <p>Dr. Jai Jayant</p>
                                </td>

                                <td>
                                    <p>“Training need analysis: A study of small &amp; micro enterprises in Garhwal
                                        region"न</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>50</p>
                                </td>
                                <td>
                                    <p>
                                        Anju

                                    </p>
                                </td>
                                <td>
                                    <p>Education</p>
                                </td>

                                <td>
                                    <p>2015</p>
                                </td>

                                <td>
                                    <p>2023</p>
                                </td>

                                <td>
                                    <p>Dr. Charu Sharma</p>
                                </td>

                                <td>
                                    <p>“महिलाओं के शिक्षा के स्तर एवं उनका अपने अधिकारों के प्रति जागरूकता के संबन्ध का
                                        एक विश्लेषणात्मक अध्ययन”</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>51</p>
                                </td>
                                <td>
                                    <p>
                                        Pushpendra Kumar

                                    </p>
                                </td>
                                <td>
                                    <p>Engineering</p>
                                </td>

                                <td>
                                    <p>2016</p>
                                </td>

                                <td>
                                    <p>2023</p>
                                </td>

                                <td>
                                    <p>Dr. Manish Sharma and Prof. Rakesh Ranjan</p>
                                </td>

                                <td>
                                    <p>Novel Approach to Locate the Deployed Under Water Sensory Nodes with Power
                                        Consumption Technique</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>52</p>
                                </td>
                                <td>
                                    <p>
                                        Amit Kumar

                                    </p>
                                </td>
                                <td>
                                    <p>Engineering</p>
                                </td>

                                <td>
                                    <p>2016</p>
                                </td>

                                <td>
                                    <p>2023</p>
                                </td>

                                <td>
                                    <p>Dr. Manish Sharma and Prof. Rakesh Ranjan</p>
                                </td>

                                <td>
                                    <p>Noval algorothim for performance Improvement of Spectrum Sensing Techniques for
                                        Cognitive Radio System.</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p>53</p>
                                </td>
                                <td>
                                    <p>
                                        Rama Solanki
                                    </p>
                                </td>
                                <td>
                                    <p>Journalism</p>
                                </td>

                                <td>
                                    <p>2018</p>
                                </td>

                                <td>
                                    <p>2023</p>
                                </td>
                                <td>
                                    <p>Dr. Garima</p>
                                </td>
                                <td>
                                    <p>“Conventional Broadcasting versus Social Media-Studying Interdependence and
                                        Correlation of TV News Channels and Twitter ”</p>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    </div>
</div>