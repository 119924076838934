<div class="section">
    <div class="subMenu_content">
     <app-sub-menu></app-sub-menu>
    </div>
    <div class="sub_content">
        <section id="OverviewOfRAndDCell">
            <div class="overview">
                <div class="title">The Library</div>
                <div class="para">
                    <p>The Library has state - of the - art information systems and provides access to an extensive range of database, together with traditional printed material. The library is stocked with more than 30,000 books, 4000 titles and a number of journals and reports that cover all subjects. Although the primary mission of the library is to support the curricular and research needs of the students, the library also provides material and reference assistance for the faculty.</p>
                </div>
            </div>
        </section>

    </div>
</div>