<div class="section">
    <div class="subMenu_content">
     <app-sub-menu></app-sub-menu>
    </div>
    <div class="sub_content">
        <section id="OverviewOfRAndDCell">
            <div class="overview">
                <div class="title">Transportation Facility</div>
                <div class="para">
                    <p>Transportation facilities for the students are available. We run a fleet of buses to help students and staff members commute to and fro from regions around the city. The bus service is available over a 35 km radius around the city. In view of safety issues on the highways, parents are urged to convince their wards to avail the services of University buses only. The facility is available at a charge of INR 20000 or USD 550 per year.</p>
                </div>
            </div>
        </section>
        <section>
            <div class="transport py-4">
                <img src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1717058230707WhatsApp%20Image%202024-05-30%20at%2012.29.13%20PM.jpeg" width="100%" alt="Transport">
            </div>
        </section>

    </div>
</div>