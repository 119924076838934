<section class="box">
    <div class="banner">
        <div class="banner_text_content">
            <h1>OUR <br> LEADERSHIP</h1>
        </div>
        <img src="https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-07/desk-leadership.webp"
            width="100%" alt="">
    </div>
</section>
<section>
    <div class="leaders_content mt-2 pb-5">
        <div class="sub_content">
            <div class="subMenu_content">
                <app-sub-menu></app-sub-menu>
            </div>
            <h1 class="title">Our Leadership</h1>
            <div class="row">
                <div class="col-md-6 mt-5" *ngFor="let a of filterdataOurLeaderShip">
                    <div class="item" role="button" tabindex="0">
                        <div class="box-section">
                            <div class="image-section">
                                <figure>
                                    <picture class="d-flex justify-content-center align-items-center">
                                        <img src="{{a.logofiles[0]?.value}}" data-src="{{a.logofiles[0]?.value}}"
                                            width="60%" alt="{{a.title}}" title="" class=" ls-is-cached lazyloaded">
                                    </picture>
                                </figure>
                            </div>
                            <div class="content-section">
                                <h3 class="sub-title">{{a.Title}}</h3>
                                <p>{{a.short}}</p>
                                <p [innerHTML]="a.Long1"></p>
                                <!-- <div class="readmore">
                                    <i class="fa fa-long-arrow-right text-white"></i>
                                </div> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section>
    <div class="sub_content padding_top" id="Academic_Council">
        <h1 class="title">Our Committee</h1>
        <div class="findYourProgram-wrap governance-structure-wrap">
            <form action="" siq_id="autopick_2124" __bizdiag="1810509538" __biza="WJ__">
                <div class="findYourProgram">
                    <div class="program-tab-outer">
                        <div class="listSearchArea">

                            <ul class="nav nav-tabs tabs-buttons" role="tablist">

                                <li class="nav-item" role="presentation" id="boardOfGovernorsTab0"
                                    *ngFor="let b of careersAssignCategory;let i=index">
                                    <button id="click_btn" class="nav-link" [ngClass]="(i === 0) ? 'active' : ''"
                                        data-bs-toggle="tab" data-bs-target="#boardOfGovernors0" type="button"
                                        role="tab" aria-labelledby="board Of Governors Tab 0" aria-selected="true"
                                        (click)="getCatagory(b.categoryId)"
                                        *ngIf="b.categoryname != 'Our Leadership'">{{b.categoryname}}</button>
                                </li>
                            </ul>

                        </div>
                    </div>
                    <div class="tab-content" id="findYourProgramTabs-content">

                        <div class="tab-pane fade active show" id="boardOfGovernors0" role="tabpanel"
                            aria-labelledby="board Of Governors Tab 0">
                            <div class="row committee-box-wrap">
                                <div class="committee-box col-md-6 col-lg-4 mt-4"
                                    *ngFor="let c of careersPlacementData">
                                    <div class="common-card">
                                        <div class="img_content">
                                        <figure class="d-flex justify-content-center align-items-center" *ngFor="let a of c.logofiles"> 
                                          <img class="lazyloaded"
                                                src="{{a.value}}"
                                                data-src="../../../assets/img/jigyasa/blank-removebg-preview.png"
                                                alt="{{c.Title}}" title="{{c.Title}}" width="100%" height="400px"> 
                                               </figure>
                                            </div>
                                        <div class="common-card-body">
                                          <div class="common-card-body-heads">
                                            <h5 class="mt-2">{{c.Title}}</h5>
                                            <span>{{c.short}}</span>
                                          </div>
                                         <p [innerHTML]="c.Long1"></p>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    class="d-flex justify-content-center justify-content-md-end pt-4 pb-5 committee-box col-md-12">
                                    <button class="btn viewAll_btn">View All Members</button>
                                </div>

                            </div>
                        </div>

                        <div class="tab-pane fade" id="boardOfGovernors1" role="tabpanel"
                            aria-labelledby="board Of Governors Tab 1">
                            <div class="row committee-box-wrap">
                                <div class="committee-box col-md-6 col-lg-4 ">
                                    <div class="inner-box-committee">

                                        <span class="post-name">Chairperson</span>

                                        <div class="top-section">

                                            <div class="left-text">
                                                <h3>Dr. Sunil Rai</h3>
                                            </div>


                                        </div>

                                        <p>President</p>

                                    </div>
                                </div>

                                <div class="committee-box col-md-6 col-lg-4 ">
                                    <div class="inner-box-committee">

                                        <span class="post-name">Government Nominee</span>

                                        <div class="top-section">

                                            <div class="left-text">
                                                <h3>Mr. Shailesh Bagauli</h3>
                                            </div>


                                        </div>

                                        <p>Secretary (Higher Education) Government of Uttarakhand</p>

                                    </div>
                                </div>

                                <div class="committee-box col-md-6 col-lg-4 ">
                                    <div class="inner-box-committee">

                                        <span class="post-name">Member Secretary</span>

                                        <div class="top-section">

                                            <div class="left-text">
                                                <h3>Dr. Ram Sharma</h3>
                                            </div>


                                        </div>

                                        <p>Vice Chancellor</p>

                                    </div>
                                </div>

                                <div class="committee-box col-md-6 col-lg-4 ">
                                    <div class="inner-box-committee">

                                        <div class="top-section">

                                            <div class="left-text">
                                                <h3>Dr. Sharad Mehra</h3>
                                            </div>


                                        </div>

                                        <p>Chairman, Hydrocarbons Education and Research Society

                                        </p>

                                    </div>
                                </div>

                                <div class="committee-box col-md-6 col-lg-4 ">
                                    <div class="inner-box-committee">

                                        <div class="top-section">

                                            <div class="left-text">
                                                <h3>Dr. Deepak Seth</h3>
                                            </div>


                                        </div>

                                        <p>Group Chairman, Pearl Group of Companies

                                        </p>

                                    </div>
                                </div>

                                <div class="committee-box col-md-6 col-lg-4 ">
                                    <div class="inner-box-committee">

                                        <div class="top-section">

                                            <div class="left-text">
                                                <h3>Mr. Rajat Mukherjee</h3>
                                            </div>


                                        </div>

                                        <p>Partner, Merger and Acquisition, Practice Group, - Khaitan &amp; Co. Law Firm

                                        </p>

                                    </div>
                                </div>

                                <div class="committee-box col-md-6 col-lg-4 ">
                                    <div class="inner-box-committee">

                                        <div class="top-section">

                                            <div class="left-text">
                                                <h3>Dr. Himendu P. Mathur</h3>
                                            </div>


                                        </div>

                                        <p>Allahabad Bank Chair Professor at FMS


                                        </p>

                                    </div>
                                </div>

                                <div class="committee-box col-md-6 col-lg-4 ">
                                    <div class="inner-box-committee">

                                        <div class="top-section">

                                            <div class="left-text">
                                                <h3>Prof. Surendra Prasad</h3>
                                            </div>


                                        </div>

                                        <p>Professor, Indian Institute of Technology Delhi

                                        </p>

                                    </div>
                                </div>

                                <div class="committee-box col-md-6 col-lg-4 ">
                                    <div class="inner-box-committee">

                                        <div class="top-section">

                                            <div class="left-text">
                                                <h3>Dr. K.K Aggarwal</h3>
                                            </div>


                                        </div>

                                        <p>Chairman, National Board of Accreditation (NBA)

                                        </p>

                                    </div>
                                </div>

                                <div class="committee-box col-md-6 col-lg-4 hide">
                                    <div class="inner-box-committee">

                                        <div class="top-section">

                                            <div class="left-text">
                                                <h3>Justice U.C. Dhyani</h3>
                                            </div>


                                        </div>

                                        <p>Former Judge,
                                            Uttarakhand High Court</p>

                                    </div>
                                </div>

                                <div class="committee-box col-md-6 col-lg-4 hide">
                                    <div class="inner-box-committee">

                                        <div class="top-section">

                                            <div class="left-text">
                                                <h3>Mr. Satish Gokhale</h3>
                                            </div>


                                        </div>

                                        <p>Director Industrial Design, Design Directions Pvt. Ltd.


                                        </p>

                                    </div>
                                </div>

                                <div class="committee-box col-md-6 col-lg-4 hide">
                                    <div class="inner-box-committee">

                                        <div class="top-section">

                                            <div class="left-text">
                                                <h3>Lt. Gen. (Dr.) T.S. Shergill</h3>
                                            </div>


                                        </div>

                                        <p>(Retd.), PVSM</p>

                                    </div>
                                </div>

                                <div class="committee-box col-md-6 col-lg-4 hide">
                                    <div class="inner-box-committee">

                                        <div class="top-section">

                                            <div class="left-text">
                                                <h3>Mr. Bharat Kharbanda</h3>
                                            </div>


                                        </div>

                                        <p>Chief Operating Officer, GUS Global Services India Pvt Ltd.

                                        </p>

                                    </div>
                                </div>

                                <div class="committee-box col-md-6 col-lg-4 hide">
                                    <div class="inner-box-committee">

                                        <div class="top-section">

                                            <div class="left-text">
                                                <h3>Mr. Manoj Barthwal</h3>
                                            </div>


                                        </div>

                                        <p>Former Executive Director, ONGC</p>

                                    </div>
                                </div>
                                <div
                                    class="d-flex justify-content-center justify-content-md-end pt-4 pb-5 committee-box col-md-12">
                                    <button class="btn viewAll_btn">View All Members</button>
                                </div>

                            </div>
                        </div>

                        <div class="tab-pane fade" id="boardOfGovernors2" role="tabpanel"
                            aria-labelledby="board Of Governors Tab 2">
                            <div class="row committee-box-wrap">
                                <div class="committee-box col-md-6 col-lg-4 ">
                                    <div class="inner-box-committee">

                                        <span class="post-name">Chairperson</span>

                                        <div class="top-section">

                                            <div class="left-text">
                                                <h3>Dr. Ram Sharma</h3>
                                            </div>


                                        </div>

                                        <p>Vice-Chancellor

                                        </p>

                                    </div>
                                </div>

                                <div class="committee-box col-md-6 col-lg-4 ">
                                    <div class="inner-box-committee">

                                        <span class="post-name">Government Nominee</span>

                                        <div class="top-section">

                                            <div class="left-text">
                                                <h3>Mr. Shailesh Bagauli</h3>
                                            </div>


                                        </div>

                                        <p>Secretary (Higher Education) Government of Uttarakhand


                                        </p>

                                    </div>
                                </div>

                                <div class="committee-box col-md-6 col-lg-4 ">
                                    <div class="inner-box-committee">

                                        <span class="post-name">Secretary</span>

                                        <div class="top-section">

                                            <div class="left-text">
                                                <h3>Mr. Manish Madaan</h3>
                                            </div>


                                        </div>

                                        <p>Registrar</p>

                                    </div>
                                </div>

                                <div class="committee-box col-md-6 col-lg-4 ">
                                    <div class="inner-box-committee">

                                        <div class="top-section">

                                            <div class="left-text">
                                                <h3>Dr. Sharad Mehra</h3>
                                            </div>


                                        </div>

                                        <p>Chairman, Hydrocarbons Education and Research Society

                                        </p>

                                    </div>
                                </div>

                                <div class="committee-box col-md-6 col-lg-4 ">
                                    <div class="inner-box-committee">

                                        <div class="top-section">

                                            <div class="left-text">
                                                <h3>Mr. Piyush Arora</h3>
                                            </div>


                                        </div>

                                        <p>Director, Business Strategy

                                        </p>

                                    </div>
                                </div>

                                <div class="committee-box col-md-6 col-lg-4 ">
                                    <div class="inner-box-committee">

                                        <div class="top-section">

                                            <div class="left-text">
                                                <h3>Mr. Navneet Mehta</h3>
                                            </div>


                                        </div>

                                        <p>Finance Officer

                                        </p>

                                    </div>
                                </div>

                                <div class="committee-box col-md-6 col-lg-4 ">
                                    <div class="inner-box-committee">

                                        <div class="top-section">

                                            <div class="left-text">
                                                <h3>Mr. Ravi S Kumar</h3>
                                            </div>


                                        </div>

                                        <p>Head HR, GUS Global Services India Pvt Ltd.

                                        </p>

                                    </div>
                                </div>

                                <div class="committee-box col-md-6 col-lg-4 ">
                                    <div class="inner-box-committee">

                                        <div class="top-section">

                                            <div class="left-text">
                                                <h3>Ms. Stuti Gandhi</h3>
                                            </div>


                                        </div>

                                        <p>Senior Director Marketing, GUS Global Services India Ltd.</p>

                                    </div>
                                </div>

                                <div class="committee-box col-md-6 col-lg-4 ">
                                    <div class="inner-box-committee">

                                        <div class="top-section">

                                            <div class="left-text">
                                                <h3>Dr. Atri Nautiyal</h3>
                                            </div>


                                        </div>

                                        <p>Director, School of Liberal Studies</p>

                                    </div>
                                </div>

                                <div class="committee-box col-md-6 col-lg-4 hide">
                                    <div class="inner-box-committee">

                                        <div class="top-section">

                                            <div class="left-text">
                                                <h3>Dr. Abhishek Sinha</h3>
                                            </div>


                                        </div>

                                        <p>Dean, School of Law
                                        </p>

                                    </div>
                                </div>

                                <div class="committee-box col-md-6 col-lg-4 hide">
                                    <div class="inner-box-committee">

                                        <div class="top-section">

                                            <div class="left-text">
                                                <h3>Dr. Ravi Shankar Iyer</h3>
                                            </div>


                                        </div>

                                        <p>Dean, School of Computer Science

                                        </p>

                                    </div>
                                </div>

                                <div class="committee-box col-md-6 col-lg-4 hide">
                                    <div class="inner-box-committee">

                                        <div class="top-section">

                                            <div class="left-text">
                                                <h3>Dr. Padmavathy Venkat</h3>
                                            </div>


                                        </div>

                                        <p>Dean, School of Health Sciences &amp; Technology

                                        </p>

                                    </div>
                                </div>

                                <div class="committee-box col-md-6 col-lg-4 hide">
                                    <div class="inner-box-committee">

                                        <div class="top-section">

                                            <div class="left-text">
                                                <h3>Dr. Neelu Jyoti Ahuja</h3>
                                            </div>


                                        </div>

                                        <p>Professor, School of Computer Science</p>

                                    </div>
                                </div>

                                <div class="committee-box col-md-6 col-lg-4 hide">
                                    <div class="inner-box-committee">

                                        <div class="top-section">

                                            <div class="left-text">
                                                <h3>Prof. Debkumar Chakrabarti</h3>
                                            </div>


                                        </div>

                                        <p>Distinguished Professor, School of Design

                                        </p>

                                    </div>
                                </div>

                                <div class="committee-box col-md-6 col-lg-4 hide">
                                    <div class="inner-box-committee">

                                        <div class="top-section">

                                            <div class="left-text">
                                                <h3>Prof. (Dr.) Bodh Raj Mehta</h3>
                                            </div>


                                        </div>

                                        <p>Vice Chancellor, Jaypee Institute of Information Technology, Former Dean
                                            (R&amp;D); Head, Department of Physics; &amp; Schlumberger Chair Professor
                                        </p>

                                    </div>
                                </div>
                                <div
                                    class="d-flex justify-content-center justify-content-md-end pt-4 pb-5 committee-box col-md-12">
                                    <button class="btn viewAll_btn">View All Members</button>
                                </div>

                            </div>
                        </div>

                        <div class="tab-pane fade" id="boardOfGovernors3" role="tabpanel"
                            aria-labelledby="board Of Governors Tab 3">
                            <div class="row committee-box-wrap">



                                <div class="committee-box col-md-6 col-lg-4 ">
                                    <div class="inner-box-committee">

                                        <span class="post-name">Chairperson</span>

                                        <div class="top-section">

                                            <div class="left-text">
                                                <h3>Dr. Ram Sharma</h3>
                                            </div>


                                        </div>
                                        <p>Vice-Chancellor
                                        </p>
                                    </div>
                                </div>
                                <div class="committee-box col-md-6 col-lg-4 ">
                                    <div class="inner-box-committee">
                                        <span class="post-name">Government Nominee</span>
                                        <div class="top-section">
                                            <div class="left-text">
                                                <h3>Mr. Shailesh Bagauli</h3>
                                            </div>
                                        </div>
                                        <p>Secretary (Higher Education), Government of Uttarakhand
                                        </p>
                                    </div>
                                </div>
                                <div class="committee-box col-md-6 col-lg-4 ">
                                    <div class="inner-box-committee">

                                        <span class="post-name">Secretary</span>

                                        <div class="top-section">
                                            <div class="left-text">
                                                <h3>Mr. Navneet Mehta</h3>
                                            </div>
                                        </div>
                                        <p>Finance Officer
                                        </p>
                                    </div>
                                </div>
                                <div class="committee-box col-md-6 col-lg-4 ">
                                    <div class="inner-box-committee">

                                        <span class="post-name">Secretary</span>

                                        <div class="top-section">

                                            <div class="left-text">
                                                <h3>Mr. Manish Madaan</h3>
                                            </div>
                                        </div>
                                        <p>Registrar</p>
                                    </div>
                                </div>
                                <div class="committee-box col-md-6 col-lg-4 ">
                                    <div class="inner-box-committee">

                                        <div class="top-section">

                                            <div class="left-text">
                                                <h3>Dr. Atri Nautiyal</h3>
                                            </div>
                                        </div>
                                        <p>Director, School of Liberal Studies
                                        </p>
                                    </div>
                                </div>
                                <div class="committee-box col-md-6 col-lg-4 ">
                                    <div class="inner-box-committee">

                                        <div class="top-section">

                                            <div class="left-text">
                                                <h3>Mr. Amit Sharma</h3>
                                            </div>
                                        </div>

                                        <p>Senior Director - Sales Operations, GUS Global Services India Pvt Ltd.

                                        </p>

                                    </div>
                                </div>
                                <div class="committee-box col-md-6 col-lg-4 ">
                                    <div class="inner-box-committee">

                                        <div class="top-section">

                                            <div class="left-text">
                                                <h3>Mr. Ravi S Kumar</h3>
                                            </div>


                                        </div>

                                        <p>Head HR, GUS Global services India Pvt Ltd.

                                        </p>

                                    </div>
                                </div>



                            </div>
                        </div>

                        <div class="tab-pane fade" id="boardOfGovernors4" role="tabpanel"
                            aria-labelledby="board Of Governors Tab 4">
                            <div class="row committee-box-wrap">



                                <div class="committee-box col-md-6 col-lg-4 ">
                                    <div class="inner-box-committee">

                                        <div class="top-section">

                                            <div class="left-text">
                                                <h3>Lieutenant General Gurmit Singh PVSM, UYSM, AVSM, VSM</h3>
                                            </div>


                                        </div>

                                        <p>Visitor</p>

                                    </div>
                                </div>

                                <div class="committee-box col-md-6 col-lg-4 ">
                                    <div class="inner-box-committee">

                                        <div class="top-section">

                                            <div class="left-text">
                                                <h3>Dr. Sunil Rai</h3>
                                            </div>


                                        </div>

                                        <p>President</p>

                                    </div>
                                </div>

                                <div class="committee-box col-md-6 col-lg-4 ">
                                    <div class="inner-box-committee">

                                        <div class="top-section">

                                            <div class="left-text">
                                                <h3>Dr. Ram Sharma</h3>
                                            </div>


                                        </div>

                                        <p>Vice-Chancellor</p>

                                    </div>
                                </div>

                                <div class="committee-box col-md-6 col-lg-4 ">
                                    <div class="inner-box-committee">

                                        <div class="top-section">

                                            <div class="left-text">
                                                <h3>Mr. Manish Madaan</h3>
                                            </div>


                                        </div>

                                        <p>Registrar</p>

                                    </div>
                                </div>

                                <div class="committee-box col-md-6 col-lg-4 ">
                                    <div class="inner-box-committee">

                                        <div class="top-section">

                                            <div class="left-text">
                                                <h3>Mr. Navneet Mehta</h3>
                                            </div>


                                        </div>

                                        <p>Finance Officer

                                        </p>

                                    </div>
                                </div>

                                <div class="committee-box col-md-6 col-lg-4 ">
                                    <div class="inner-box-committee">

                                        <div class="top-section">

                                            <div class="left-text">
                                                <h3>Prof. Rahul Nainwal</h3>
                                            </div>


                                        </div>

                                        <p>Director, School of Business</p>

                                    </div>
                                </div>

                                <div class="committee-box col-md-6 col-lg-4 ">
                                    <div class="inner-box-committee">

                                        <div class="top-section">

                                            <div class="left-text">
                                                <h3>Dr. Padmavathy Venkat</h3>
                                            </div>


                                        </div>

                                        <p>Dean, School of Health Sciences and Technology</p>

                                    </div>
                                </div>

                                <div class="committee-box col-md-6 col-lg-4 ">
                                    <div class="inner-box-committee">

                                        <div class="top-section">

                                            <div class="left-text">
                                                <h3>Dr. Ravi S Iyer</h3>
                                            </div>


                                        </div>

                                        <p>Dean, School of Computer Science</p>

                                    </div>
                                </div>

                                <div class="committee-box col-md-6 col-lg-4 ">
                                    <div class="inner-box-committee">

                                        <div class="top-section">

                                            <div class="left-text">
                                                <h3>Dr. Abhishek Sinha</h3>
                                            </div>


                                        </div>

                                        <p>Dean, School of Law</p>

                                    </div>
                                </div>

                                <div class="committee-box col-md-6 col-lg-4 hide">
                                    <div class="inner-box-committee">

                                        <div class="top-section">

                                            <div class="left-text">
                                                <h3>Prof. Phani Tetali</h3>
                                            </div>


                                        </div>

                                        <p>Dean, School of Design</p>

                                    </div>
                                </div>

                                <div class="committee-box col-md-6 col-lg-4 hide">
                                    <div class="inner-box-committee">

                                        <div class="top-section">

                                            <div class="left-text">
                                                <h3>Dr. Atri Nautiyal</h3>
                                            </div>
                                        </div>
                                        <p>Director, School of Liberal Studies</p>
                                    </div>
                                </div>
                                <div class="committee-box col-md-6 col-lg-4 hide">
                                    <div class="inner-box-committee">
                                        <div class="top-section">
                                            <div class="left-text">
                                                <h3>Dr. Devesh Kumar</h3>
                                            </div>
                                        </div>
                                        <p>Dean, Research and Development</p>
                                    </div>
                                </div>
                                <div class="committee-box col-md-6 col-lg-4 hide">
                                    <div class="inner-box-committee">
                                        <div class="top-section">
                                            <div class="left-text">
                                                <h3>Dr. Nishant Mishra</h3>
                                            </div>
                                        </div>
                                        <p>Dean, Student Welfare</p>
                                    </div>
                                </div>
                                <div class="committee-box col-md-6 col-lg-4 hide">
                                    <div class="inner-box-committee">
                                        <div class="top-section">
                                            <div class="left-text">
                                                <h3>Dr. Mohammed Yaqoot</h3>
                                            </div>
                                        </div>
                                        <p>Controller of Examination</p>
                                    </div>
                                </div>
                                <div
                                    class="d-flex justify-content-center justify-content-md-end pt-4 pb-5 committee-box col-md-12">
                                    <button class="btn viewAll_btn">View All Members</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</section>

<section>
    <div class="sub_content pb-5">
        <div class="needMoreInfoWrap2">
            <div class="row">
                <div class="col-md-2">
                    <img src="	https://www.upes.ac.in/assets/images/icon-first-step-white.svg" width="80%" alt="">
                </div>
                <div class="col-md-6">
                    <h4>TAKE THE FIRST STEP TOWARDS YOUR CAREER</h4>
                </div>
                <div class="col-md-4">
                    <a href="https://api.whatsapp.com/send?phone=7060137722&text=Welcom to Jigyasa University" target="_blank"><button class="btn view_btn  counsellor_btn text-uppercase w-100">Talk to Our Counsellors</button></a>
                    <a href="{{applayUrl}}" target="_blank"><button class="btn counsellor_btn1 mt-3">Apply
                            Now</button></a>
                </div>
            </div>
        </div>
    </div>
</section>