import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Observable, of, switchMap, tap } from 'rxjs';
import { ApicallService } from 'src/app/services/apicall.service';
import { HomeSeoService } from 'src/app/services/homeseo.service';
import { ProjectSeoService } from 'src/app/services/projectseo.service';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import * as AOS from 'aos';
import { AboutUsComponent } from '../about-us/about-us.component';
import { AwardsAndRecognitionComponent } from '../awards-and-recognition/awards-and-recognition.component';
import { DayCareComponent } from '../day-care/day-care.component';
declare var window: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})

export class HomeComponent implements OnInit {
  formModal:any;
  applayUrl = environment.applay_url
  project$: Observable<any> | undefined;
  programs:any=[];
  Announcementdata:any=[];
  popupdata:any=[];
  alldata:any=[];
  display_div1:boolean=true
  display_div2:boolean=false
  display_div3:boolean=false
  display_div4:boolean=false
  display_div5:boolean=false
  display_div6:boolean=false
  searchtext:string=""
  norecoredtext:boolean=false;
  pgdata:any=[];
  title: any;
  imageObject = []
  AnnouncementdataList:any =[];
  AnnouncementGetData:any =[];
  constructor(
    private route: ActivatedRoute,private dialog:MatDialog,
    private seoService: HomeSeoService,
    private projectService: ProjectSeoService,
    private _ApicallService: ApicallService,private spinnerservice:NgxSpinnerService,
  ) {

  }
  ngOnInit(): void {
    this.formModal = new window.bootstrap.Modal(
      document.getElementById('exampleModal2')
    );
    AOS.init();
    this.getseo();
  }
  getseo() {
    let tbody = {
      slug:'home',
      //Projectid:environment.projectid
    };
    this._ApicallService.getGetseo(tbody).subscribe((data: any) => {
      this.alldata=data?.data;
        this.pgdata=data?.data?.pagedata;  
        this.projectService.sendMessageblog(data?.data?.Blog);
        this.projectService.sendMessagenews(data?.data?.News)
        this.projectService.setFacultysubject(data?.data?.Faculty);
        this.projectService.setPlacementsubject(data?.data?.Placement);      
        this.projectService.setprogramssubject(data?.data?.Programs);//
        this.projectService.sendMessageseo(data?.data?.Testimonial);
        this.projectService.sendMessageFaqs(data?.data?.FAQs);
        this.projectService.setmeta(data?.data);
       
        if(data?.data?.Announcement[0].data){
          this.Announcementdata=data?.data?.Announcement[0].data;
          this.AnnouncementGetData =  this.Announcementdata.filter((dt:any)=>{
            return dt.category == 39
          })
          this.AnnouncementdataList =  this.Announcementdata.filter((dt:any)=>{
            return dt.category == 40
          })
          console.log('accument1',this.AnnouncementdataList);
          console.log('accument2',this.AnnouncementGetData);
          this.assigndata();          
        }
    });
  }
  assigndata(){
    this.imageObject= this.Announcementdata.filter((dt:any)=>{
       return dt.categoryId = 39
     }).map((object:any)=>{
       return {"image":object?.files[0]?.url,"thumbImage":object?.files[0]?.url,"title":object?.Title,"categoryId":object?.categoryId};
     })
     if(sessionStorage.getItem('isshow')!='1'){
      this.formModal.show(); 
     }     
   }
   imageClickHandler(e:any) {
    window.open(e, "_blank");
  }  
  closepopup(){ 
   
    sessionStorage.setItem('isshow','1');       
  }
  // getseo() {
  //   let tbody = {
  //     slug: 'home',
  //     Projectid: environment.projectid,
  //   };
  //   this.apiService.getGetseo(tbody).subscribe((data: any) => {
  //     this.projectService.sendMessageblog(data?.data?.blog);
  //     this.projectService.sendMessageseo(data?.data?.testimony);
  //     this.projectService.sendMessageFaqs(data?.data?.faq);
  //     this.projectService.setmeta(data?.data);

  //   });
  // }
  selectTab(val:any){
   if(val == 1){
    this.display_div2=true
    this.display_div1=false
    this.display_div3=false
    this.display_div4=false
    this.display_div5=false
    this.display_div6=false
   }
   if(val == 2){
    this.display_div2=false
    this.display_div1=true
    this.display_div3=false
    this.display_div4=false
    this.display_div5=false
    this.display_div6=false
   }
   if(val == 3){
    this.display_div2=false
    this.display_div1=false
    this.display_div3=true
    this.display_div4=false
    this.display_div5=false
    this.display_div6=false
   }
   if(val == 4){
    this.display_div2=false
    this.display_div1=false
    this.display_div3=false
    this.display_div4=true
    this.display_div5=false
    this.display_div6=false
   }
   if(val == 5){
    this.display_div2=false
    this.display_div1=false
    this.display_div3=false
    this.display_div4=false
    this.display_div5=true
    this.display_div6=false
   }
   if(val == 6){
    this.display_div2=false
    this.display_div1=false
    this.display_div3=false
    this.display_div4=false
    this.display_div5=false
    this.display_div6=true
   }
  }
  getdata(event:Event){
    this.spinnerservice.show();
    let jdata={
      "slug":"",
      "searchtext":event
    }
    this._ApicallService.getByName(jdata).subscribe({
      next:(resp:any)=>{
        this.spinnerservice.hide();
        this.programs=resp.data;
        if(this.programs.length==0){
          this.norecoredtext=true;       
        }
      },error:(error)=>{
        this.programs=[];
        this.norecoredtext=true;         
        this.spinnerservice.hide();
        console.log(error);
      }
    })
  }

}
