import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription, of, switchMap, tap } from 'rxjs';
import { ApicallService } from 'src/app/services/apicall.service';
import { HomeSeoService } from 'src/app/services/homeseo.service';
import { ProjectSeoService } from 'src/app/services/projectseo.service';
import { environment } from 'src/environments/environment';
import * as AOS from 'aos';

@Component({
  selector: 'app-testimonial',
  templateUrl: './testimonial.component.html',
  styleUrls: ['./testimonial.component.css'],
})
export class TestimonialComponent implements OnInit {
  customOptions:any = {
    loop:true,
    margin:10,
    nav:true,
    dots: false,
    navText : ["<i class='fa fa-chevron-left'></i>","<i class='fa fa-chevron-right'></i>"],
    responsive:{
        0:{
            items:1
        },
        600:{
            items:3
        },
        1000:{
            items:3
        }
    },
    
  }

  testindex:number=3
  project$: Observable<any> | undefined;
  @Input() Testimonialdata:any=[];
  @Input() fromslug:string="";
  subscriptionnav!: Subscription;
  testimonycategory:any=[];
  testimonydata: any;
  testCountlength: any;
  getAllDetails: any=[];
  imgFile: any;
  constructor(
    private route: ActivatedRoute,
    private seoService: HomeSeoService,
    private projectService: ProjectSeoService,
    private apiService: ApicallService
  ) {
    //
  }
  
  ngOnInit(): void {
    AOS.init();
    if(this.Testimonialdata){
      this.testimonydata=this.Testimonialdata[0].data;
      this.testimonycategory=this.Testimonialdata[0].AssignCategory;
      console.log(this.testimonycategory);
    }    

    this.subscriptionnav = this.projectService
      .onseoMessage()
      .subscribe((message) => {
        if (message) {
          this.Testimonialdata = message.text;
          if(this.Testimonialdata){
            this.testimonydata=this.Testimonialdata[0].data;
            this.testimonycategory=this.Testimonialdata[0].AssignCategory;
            this.testCountlength = this.testimonydata.length
          }
        }
      });
  }

  filtercategory(id:number){
    if(id!=0){
      this.testimonydata=this.Testimonialdata[0].data.filter((dt:any)=>{
        return dt.category.includes(id)
      }).map((obj:any)=>{
        return obj;
      })
    }
    else{
      this.testimonydata=this.Testimonialdata[0].data;
    }
    
  }
  showAllDetails(data:any){
    this.getAllDetails = data
    this.imgFile = this.getAllDetails.files
  }
}
