import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, of, switchMap, tap } from 'rxjs';
import { ApicallService } from 'src/app/services/apicall.service';
import { HomeSeoService } from 'src/app/services/homeseo.service';
import { ProjectSeoService } from 'src/app/services/projectseo.service';

@Component({
  selector: 'app-coursespeciality',
  templateUrl: './coursespeciality.component.html',
  styleUrls: ['./coursespeciality.component.css']
})
export class CoursespecialityComponent {
  Title:string="";
  secondtitle:string="";
  thirdtitle:string="";
  project$: Observable<any> | undefined;
  leveldesc:String="";
  academindata:any=[];
  visible:number=-1;
  isshow:boolean=false;
  slug:string="";
  alldata:any=[];
  constructor(private route:ActivatedRoute,private seoService: HomeSeoService,private apiservice:ApicallService,
    private projectService: ProjectSeoService){}

  ngOnInit():void{
  this.route.params.subscribe((params: any) => {      
    let routevalue1 = params['school'];      
    this.Title=routevalue1;
    this.route.params.subscribe((params: any) => { 
      this.secondtitle = params['course'];
      this.route.params.subscribe((params: any) => {    
        this.thirdtitle = params['speciality'];
        //console.log(routevalue3,'routevalue3');
        this.slug=this.Title+'/'+this.secondtitle+'/'+this.thirdtitle
      })
    })
    this.getseo();
  })
  }
  getseo() {
    let tbody = {
      slug:this.slug,
    
    };
    this.apiservice.getGetseo(tbody).subscribe((data: any) => {
      this.alldata=data?.data;
        this.academindata=data?.data?.pagedata;       
        this.projectService.sendMessageblog(data?.data?.Blog);
        this.projectService.sendMessagenews(data?.data?.News)
        this.projectService.setFacultysubject(data?.data?.Faculty);
        this.projectService.setPlacementsubject(data?.data?.Placement);      
        this.projectService.setprogramssubject(data?.data?.Programs);//
        this.projectService.sendMessageseo(data?.data?.Testimonial);
        this.projectService.sendMessageFaqs(data?.data?.FAQs);
        this.projectService.setmeta(data?.data);
    });
  }

  showhide(data:any){
    this.leveldesc="";
    if(data){
      this.leveldesc=data;
    }
  }
}
