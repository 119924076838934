<section>
    <div class="sub_content">
        <div class="data_msg text-danger text-center" *ngIf="AssignCategory.length == 0 || contentData.length == 0"><h4>Data Not Available...</h4></div>
    <div class="">
        <div class="">
            <!-- <div class="nav-tabs-left">
                <ul class="nav nav-tabs ul_list">
                    <li class="nav-item" *ngFor="let data of AssignCategory">
                        <a class="nav-link waves-effect active" data-toggle="tab" (click)="getimage(data.categoryId)">{{data.categoryname}}</a>
                    </li>
                </ul>
            </div> -->
            <div class="listSearchArea d-flex justify-content-start align-items-center">
                <ul class="nav nav-tabs tabs-buttons" role="tablist">
                    <li class="nav-item" role="presentation" id="boardOfGovernorsTab0"
                    *ngFor="let data of AssignCategory">
                        <button id="click_btn" class="nav-link"
                            data-bs-toggle="tab" data-bs-target="#boardOfGovernors0" type="button"
                            role="tab" aria-labelledby="board Of Governors Tab 0" aria-selected="true"
                            (click)="getimage(data.categoryId)">{{data.categoryname}}</button>
                    </li>
                </ul>
            </div>
        </div>
        <div class="">
            <div class="row"> 
                <div class="col-md-4" *ngFor="let dt of contentData">
                  <div class="common-card" >
                    <div *ngFor="let a of dt.logofiles">
                        <div class="gallery_img" *ngFor="let b of dt.OtherFiles">
                            <a data-fancybox="" href="{{b.value}}">
                                <img src="{{a.value}}" alt="{{b.FileName}}" height="400px" width="400px">
                            </a>
                        </div>
                    </div>
                    <div class="para">
                     <div>{{dt.Title}}</div>
                     <div>{{dt.CreatedDate}}</div>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</section>
