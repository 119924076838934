<section>
    <div class="main_div">
    <div class="title" data-aos="fade-up"
    data-aos-duration="3000">Blogs Details</div>
    <div class="box1">
        <div class="sub_title">{{blogdetails?.dateCreated}}</div>
        <div class="innerBlogCOntent">
              <div class="blog_img_content mt-4 d-flex justify-content-center align-items-center">
                <img src={{blogdetails?.files[0]?.url}} width="50%" alt={{blogdetails?.files[0]?.filename}}>
              </div>
              <div class="blog_text_content text-white mt-5">
              <p [innerHTML]="blogdetails?.long1"></p>
            </div>
        </div>
      </div>
</div>
  </section>