import { Component, Input, OnInit } from '@angular/core';
  import { ActivatedRoute } from '@angular/router';
  import { Observable, of, switchMap, tap } from 'rxjs';
  import { ApicallService } from 'src/app/services/apicall.service';
  import { HomeSeoService } from 'src/app/services/homeseo.service';
  import { ProjectSeoService } from 'src/app/services/projectseo.service';
  import { environment } from 'src/environments/environment';
  import { NgxSpinnerService } from 'ngx-spinner';
  import * as AOS from 'aos';
  import { CommonService } from '../service/common.service';

@Component({
  selector: 'app-eventcommon',
  templateUrl: './eventcommon.component.html',
  styleUrls: ['./eventcommon.component.css']
})
export class EventcommonComponent {
  customOptions: any = {
    loop: true,
    margin: 10,
    nav: true,
    dots: false,
    navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 3
      },
      1000: {
        items: 3
      }
    },
  }
  items = [...Array(100).keys()].map((x) => ({
    title: `Slide ${x + 1}`,
  }));
 
    @Input() eventdata: any = [];
    @Input() fromslug:string="";
    project_id = environment.projectid;
  
    eventList:any=[];
    AssignCategory:any=[];
    alldata:any=[];
    findex:number=0;
    tindex:number=20;
    totalrecord:number=0;
    more:boolean=true;
    title:string=""; 
    pgdata: any;
    constructor(
      private route: ActivatedRoute,
      private seoService: HomeSeoService,
      private projectService: ProjectSeoService,
      private apiService: ApicallService,    
      private _service: CommonService,
      private spinner:NgxSpinnerService,
      private _ApicallService: ApicallService,
    ) {}
    ngOnInit(): void {
      AOS.init();    
      console.log('this.eventdata',this.eventdata);
      if (this.eventdata) {        
        this.alldata=this.eventdata[0].data;
        this.AssignCategory = this.eventdata[0].AssignCategory;  
        this.setcentrelist();   
      }
      this.route.params.subscribe((params: any) => {      
        this.title = params['slug'];         
      });
      //this.getseo();
       
    }
  
  
    // getslug(){
    //   let slug;
    //   if(this.title==null || this.title=="" || this.title == undefined)
    //   {
    //     slug='home';
    //   }
    //   else if(this.fromslug!="")
    //   {      
    //     slug=this.fromslug;
    //   }
    //   else{
    //       slug=this.title;   
    //   }    
     // this.getplacementData(slug); 
    //}
  
    getseo() {
      let tbody = {
        slug:this.fromslug//'events',
      };
      this._ApicallService.getGetseo(tbody).subscribe((data: any) => {
        //this.getslug();
        this.alldata = data?.data;
        this.pgdata = data?.data?.pagedata;
        this.projectService.sendMessageblog(data?.data?.Blog);
        this.projectService.sendMessagenews(data?.data?.News)
        this.projectService.setFacultysubject(data?.data?.Faculty);
        this.projectService.setPlacementsubject(data?.data?.Placement);
        this.projectService.setprogramssubject(data?.data?.Programs);//
        this.projectService.sendMessageseo(data?.data?.Testimonial);
        this.projectService.sendMessageFaqs(data?.data?.FAQs);
        this.projectService.setmeta(data?.data);
        this.projectService.sendMessagebread(data?.data.breadcrumb)
      });
    }
  
    // getplacementData(slug:string) {
    //   this.spinner.show();
    //   let obj = {
    //     "Project_Id": this.project_id,
    //     "Type": "Events",
    //     "pageurl":slug
    //   }
    //   this._service.getContentbytype(obj).subscribe(
    //     res => {    
    //       this.spinner.hide();   
    //       if(res.data){
    //         this.AssignCategory = JSON.parse(res.data[0].AssignCategory);
    //         this.alldata=JSON.parse(res.data[0].contentData);
    //         console.log(this.alldata);
    //         //this.PlacementList = JSON.parse(res.data[0].contentData);   
    //         this.setcentrelist();  
    //       }else{
            
    //       }          
    //     }
    //   )
    // }
    fn_ClientFilter(event: any) {
      let val = event.target.value.toLowerCase();
      if (!val) {
        this.eventList = this.alldata;
      }
      this.eventList = this.alldata.filter(function (dt: any): any {
        if (
          dt?.Title?.toString().toLowerCase().indexOf(val) !== -1 ||
          !val
        ) {
          // found match, return true to add to result set
          return true;
        }
        else if (dt.slug) {
          if (dt?.slug?.toString().toLowerCase().indexOf(val) !== -1 ||
            !val
          ) {
            // found match, return true to add to result set
            return true;
          }
        }      
      })
    }
    setcentrelist()
  {
    let dlenth=this.alldata.length;
    this.totalrecord=dlenth;
    if(dlenth>20)
    {
      this.tindex=20;
    }
    else
    {
      this.tindex=dlenth;
      this.more=false;
    }
  
    this.eventList=this.alldata.slice(this.findex,this.tindex);
    
  }
  filtercategory(id:number){
    if(id!=0){
      this.eventList=this.alldata.filter((dt:any)=>{
        return dt.category.includes(id)
      }).map((obj:any)=>{
        return obj;
      })
    }
    else{
      this.eventList=this.alldata;
  
    }
    
  }
    setcentrelistclick()
  {
    let dlenth=this.alldata.length;
    let nindex=this.tindex+20;
  
    if( dlenth> this.tindex)
    {
  
  
      if(nindex>dlenth)
      {
         this.tindex=dlenth;
          this.more=false;
      }
      else
      {
      this.tindex += 20;
      }
    }
    else
    {
  
    this.more=false;
    }
  
    this.eventList=this.alldata.slice(this.findex,this.tindex);
    //this.centerList=data;
  }
  }
  

