<div class="section">
    <div class="subMenu_content">
        <app-sub-menu></app-sub-menu>
       </div>
    <div class="sub_content">
        <section id="PhDAwarded">
            <div class="overview">
                <div class="title">Research Updates</div>
                <div class="patents_table mt-5">
                    <table>
                        <thead>
                            <tr>
                                <th>S.N.</th>
                                <th>Update</th>
                                <th>Use the Link</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <p>1</p>
                                </td>
                                <td>
                                    <p>
                                        Research Publications Update_JU
                                    </p>
                                </td>
                                <td>
                                    <p><a href="https://forms.gle/oRsuxz627NLAEi3m7" target="_blank">Click Here</a></p>
                                </td>

                            </tr>
                            <tr>
                                <td>
                                    <p>2</p>
                                </td>
                                <td>
                                    <p>
                                        Award and Recognition Received by JU Faculty
                                    </p>
                                </td>
                                <td>
                                    <p><a href="https://forms.gle/gd4pnxHUehvNuweT6" target="_blank">Click Here</a></p>

                                </td>

                            </tr>
                            <tr>
                                <td>
                                    <p>3</p>
                                </td>
                                <td>
                                    <p>
                                        The participation in Conference/Seminar/Workshop
                                        by JU faculty members
                                    </p>
                                </td>
                                <td>
                                    <p><a href="https://forms.gle/amwVhcnCgXw1yKF48" target="_blank">Click Here</a></p>

                                </td>

                            </tr>
                            <tr>
                                <td>
                                    <p>4</p>
                                </td>
                                <td>
                                    <p>
                                        Organization of Conference/Seminar/Workshop/other events
                                        by Jigyasa University
(Formerly Himgiri Zee University)
                                    </p>
                                </td>
                                <td>
                                    <p><a href="https://forms.gle/8ksUTPQbVX8zPsnGA" target="_blank">Click Here</a></p>

                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    </div>
</div>