import { Component, Input, OnInit } from '@angular/core';
import { ApicallService } from 'src/app/services/apicall.service';
import { ProjectSeoService } from 'src/app/services/projectseo.service';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription, debounceTime, distinctUntilChanged, filter, of, switchMap, tap } from 'rxjs';
import { HomeSeoService } from 'src/app/services/homeseo.service';
import { FormBuilder } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Pipe, PipeTransform } from '@angular/core'; 

@Component({
  selector: 'app-commonprograms',
  templateUrl: './commonprograms.component.html',
  styleUrls: ['./commonprograms.component.css']
})
export class CommonprogramsComponent implements OnInit{
  project$: Observable<any> | undefined;
  @Input() slugdata:any=[];
  @Input() slug!:string;
  programsdata:any=[];
  courses:any=[];
  currentslug:string="";
  programs:any=[];
  descriptiondt:any=[]; 
  subscriptionnav!: Subscription;
  searchtext:string="";
  spinner:boolean=true
  norecoredtext:boolean=false;
  countries$: any=[];
  form = this.formBuilder.group({
    name: [''],
  });

  
  constructor( private route: ActivatedRoute,private formBuilder: FormBuilder,
    private _ApicallService: ApicallService,private spinnerservice:NgxSpinnerService,
    private seoService: HomeSeoService,private _apiservice:ApicallService,private projectService: ProjectSeoService){
      this.countries$ = this.form.get('name')!.valueChanges.pipe(
        distinctUntilChanged(),
        debounceTime(1000),
        filter((name) => !!name),
        switchMap(name => this._ApicallService.getByName(name))
      );
    }

  ngOnInit(): void {
    this.programsdata=this.slugdata;  
    this.getcourses(this.programsdata[0].ProgramsType);
    this.subscriptionnav = this.projectService
    .getprogramssubject()
    .subscribe((message) => {
      this.spinner=false
      if (message) {
    
        this.slugdata = message.text;
        
      }
    });    
  }
  getdata(event:Event){
    this.spinnerservice.show();
    let jdata={
      "slug":this.slug,
      "searchtext":event
    }
    this._ApicallService.getByName(jdata).subscribe({
      next:(resp:any)=>{
        this.spinnerservice.hide();       
        this.programs=resp.data;
        if(this.programs.length==0){
          this.norecoredtext=true;
          setTimeout(() => {
            this.norecoredtext=false;
          }, 3000);         
        }
      },error:(error)=>{
        this.programs=[];
        this.norecoredtext=true;
        setTimeout(() => {
          this.norecoredtext=false;
        }, 3000);
        this.spinnerservice.hide();
        console.log(error);
      }
    })
  }
  
 

  // getprograms(){
  //   this.programsdata=[];
  //   let jdata={
  //     "slug":this.slug,
	//     "projectid":1
  //   }
  //   return this._apiservice.GetProgramsbyschool(jdata).subscribe({
  //     next:(resp:any)=>{
  //       if(resp){
  //         console.log(resp.data);
  //         this.programsdata=resp.data;
  //         this.getcourses(this.programsdata[0].ProgramsType);
  //       }
  //     },
  //     error:(error)=>{
  //       console.log(error);
  //     }
  //   })
  // }

  getcourses(protype:string){
    this.courses=[];
    let dt=this.programsdata.filter((dt:any)=>{
      return dt.ProgramsType==protype
    }).map((obj:any)=>{
      return obj?.data;
    })
    this.courses=dt[0];
    
    //console.log(this.courses);
    this.getdescription(this.courses[0]?.name);
  }

  getdescription(nm:string){
    this.descriptiondt=[];
    let dt=this.courses.filter((dt:any)=>{
      return dt.name==nm
    }).map((obj:any)=>{
      return obj//?.speciality;
    })
    this.currentslug=dt[0]?.slug;
    this.descriptiondt.push(dt[0]);
  }
}
