<div class="btn_header" id="header_btn">
    <div class="header_logo" id="headerLogo" routerLink=""></div>
      <div class="btn_content">
          <button class="white_btn" routerLink="/admissions">Admission Enquiry</button>
          <button class="startBtn" routerLink="/partner-with-kidzee">Start a Franchise</button>
      </div>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
        <div class="menu_icon"></div>
      </button>
  </div>
  <nav class="navbar navbar-expand-lg bg-body-tertiary navbar1">
    <div class="">
      <div class="collapse navbar-collapse nav_body" id="navbarNavDropdown">
        <ul class="navbar-nav ul_content">
          <li class="nav-item">
            <a class="nav-link active menu1" aria-current="page" routerLink="">Home</a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              About Us
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
              <a class="dropdown-item" routerLink="/legacy">Legacy</a>
              <a class="dropdown-item" routerLink="/kidzee-advantage">Kidzee Advantage</a>
              <a class="dropdown-item" routerLink="/vision">Vision & Mission</a>
              <a class="dropdown-item" routerLink="/awards-and-recognition">Awards & Recognized</a>
            </div>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              Programmes
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink" id="target">
              <a class="dropdown-item" routerLink="/playgroup">Playgroup</a>
              <a class="dropdown-item" routerLink="/nursery">Nursery</a>
              <a class="dropdown-item" routerLink="/kindergarten">Kindergarten</a>
              <a class="dropdown-item" routerLink="/teacher-training-programme">Teacher Training Programme</a>
              <a class="dropdown-item" routerLink="/Daycare">Daycare</a>
            </div>

          </li>
          <li class="nav-item">
            <a class="nav-link active menu1" aria-current="page" routerLink="/pentemind">Péntemind</a>
          </li>
          <!-- <li class="nav-item">
            <a class="nav-link active menu4" aria-current="page" routerLink="Daycare">DayCare</a>
          </li> -->
          <li class="nav-item">
            <a class="nav-link active menu5" aria-current="page" routerLink="/admissions">Admissions</a>
          </li>
          <li class="nav-item">
            <a class="nav-link active menu" aria-current="page" routerLink="/partner-with-kidzee">Franchise Opportunity</a>
          </li>
          <li class="nav-item">
            <a class="nav-link active" aria-current="page" routerLink="/locateUs">Locate Us</a>
          </li>

        </ul>
      </div>
    </div>
  </nav>
  <div class="whiteHeader" id="whiteHeader">
      <nav class="nav navbar navbar-expand-lg">
          <div class="container-fluid">
            <div class="navbar-brand">
              <div class="header_logo" routerLink=""></div>
            </div>
            <div class="menu_icon" data-bs-toggle="collapse" data-bs-target="#navbarNav"></div>
            <div class="collapse navbar-collapse " id="navbarNav">
              <ul class="navbar-nav ul_content">
                <li class="nav-item">
                  <a class="nav-link active menu1" aria-current="page" routerLink="">Home</a>
                </li>
                <li class="nav-sub-item dropdown">
                  <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    About Us
                  </a>
                  <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                    <a class="dropdown-item selectMenu" routerLink="/legacy">Legacy test</a>
                    <a class="dropdown-item" routerLink="/kidzee-advantage">Kidzee Advantage</a>
                    <a class="dropdown-item" routerLink="/vision">Vision & Mission</a>
                    <a class="dropdown-item" routerLink="/awards-and-recognition">Awards & Recognized</a>
                  </div>
                </li>

                <li class="nav-sub-item dropdown">
                  <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Programmes
                  </a>
                  <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                    <a class="dropdown-item" routerLink="/playgroup">Playgroup</a>
                    <a class="dropdown-item" routerLink="/nursery">Nursery</a>
                    <a class="dropdown-item" routerLink="/kindergarten">Kindergarten</a>
                    <a class="dropdown-item" routerLink="/teacher-training-programme">Teacher Training Programme</a>
                    <a class="dropdown-item" routerLink="/Daycare">Daycare</a>
                  </div>
                </li>
                <li class="nav-item">
                  <a class="nav-link active menu1" aria-current="page" routerLink="/pentemind">Péntemind</a>
                </li>
                <!-- <li class="nav-item">
                  <a class="nav-link active menu4" aria-current="page" routerLink="Daycare">DayCare</a>
                </li> -->
                <li class="nav-item">
                  <a class="nav-link active menu5" aria-current="page" routerLink="/admissions">Admissions</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link active menu" aria-current="page" routerLink="/partner-with-kidzee">Franchise Opportunity</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link active" aria-current="page" routerLink="/locateUs">Locate Us</a>
                </li>

              </ul>
            </div>
          </div>
        </nav>

  </div>


  <div class="whiteHeader_dark" id="whiteHeader_dark">
    <nav class="nav navbar navbar-expand-lg">
        <div class="container-fluid">
          <div class="navbar-brand">
            <div class="header_logo" routerLink=""></div>
          </div>
          <div class="menu_icon" data-bs-toggle="collapse" data-bs-target="#navbarNav"></div>
          <div class="collapse navbar-collapse " id="navbarNav">
            <ul class="navbar-nav ul_content">
              <li class="nav-item">
                <a class="nav-link active" aria-current="page" routerLink="">Home</a>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  About Us
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                  <a class="dropdown-item" routerLink="/legacy">Legacy</a>
                  <a class="dropdown-item" routerLink="/kidzee-advantage">Kidzee Advantage</a>
                  <a class="dropdown-item" routerLink="/vision">Vision & Mission</a>
                  <a class="dropdown-item" routerLink="/awards-and-recognition">Awards & Recognized</a>
                </div>
              </li>
              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  Programmes
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                  <a class="dropdown-item" routerLink="/playgroup">Playgroup</a>
                  <a class="dropdown-item" routerLink="/nursery">Nursery</a>
                  <a class="dropdown-item" routerLink="/kindergarten">Kindergarten</a>
                  <a class="dropdown-item" routerLink="/teacher-training-programme">Teacher Training Programme</a>
                  <a class="dropdown-item" routerLink="/Daycare">Daycare</a>
                </div>
              </li>
              <!-- <li class="nav-item">
                <a class="nav-link active" aria-current="page" routerLink="Daycare">DayCare</a>
              </li> -->
              <li class="nav-item">
                <a class="nav-link active menu1" aria-current="page" routerLink="/pentemind">Péntemind</a>
              </li>
              <li class="nav-item">
                <a class="nav-link active" aria-current="page" routerLink="/admissions">Admissions</a>
              </li>
              <li class="nav-item">
                <a class="nav-link active" aria-current="page" routerLink="/partner-with-kidzee">Franchise Opportunity</a>
              </li>
              <li class="nav-item">
                <a class="nav-link active" aria-current="page" routerLink="/locateUs">Locate Us</a>
              </li>

            </ul>
          </div>
        </div>
      </nav>
  </div>
