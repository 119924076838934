import { Component,Input,OnInit} from '@angular/core';
import { CommonService } from '../service/common.service';
import { environment } from 'src/environments/environment';
import { json } from 'express';
import {Router, ActivatedRoute, Params} from '@angular/router';
import { ApicallService } from 'src/app/services/apicall.service';
import { ProjectSeoService } from 'src/app/services/projectseo.service';

@Component({
  selector: 'app-career-counselling-cell',
  templateUrl: './career-counselling-cell.component.html',
  styleUrls: ['./career-counselling-cell.component.css']
})
export class CareerCounsellingCellComponent implements OnInit{
  @Input() ishide!:number;
  project_id = environment.projectid
  alldata: any;
  Announcementdata: any;
  pgdata: any;
  getCategoryId:any=[];
  getcareer_counselling_cell:any=[]
  careerPlacementList: any;
  careerContentData: any;
  constructor(private _service: CommonService,
    private activatedRoute: ActivatedRoute,
      private _ApicallService: ApicallService,
      private projectService: ProjectSeoService,
  ){
  
  }
  ngOnInit(): void {
    this.getLidershipData();
    // this.getseo();
  }

  getseo() {
    let tbody = {
      slug:'placement-statistics',
      };
    this._ApicallService.getGetseo(tbody).subscribe((data: any) => {
      this.alldata=data?.data;
        this.pgdata=data?.data?.pagedata;  
        this.projectService.sendMessageblog(data?.data?.Blog);
        this.projectService.sendMessagenews(data?.data?.News)
        this.projectService.setFacultysubject(data?.data?.Faculty);
        this.projectService.setPlacementsubject(data?.data?.Placement);      
        this.projectService.setprogramssubject(data?.data?.Programs);//
        this.projectService.sendMessageseo(data?.data?.Testimonial);
        this.projectService.sendMessageFaqs(data?.data?.FAQs);
        this.projectService.setmeta(data?.data);
        this.projectService.sendMessagebread(data?.data.breadcrumb)
        
        this.getLidershipData();
    });
  }
  getLidershipData() {
    let obj = {
      "Project_Id": this.project_id,
      "Type": "Committee"
    }
    this._service.getContentbytype(obj).subscribe(
      res => {
        this.careerPlacementList = JSON.parse(res.data[0].AssignCategory);
        this.careerContentData = JSON.parse(res.data[0].contentData);
        this.getcareer_counselling_cell=this.careerContentData.filter((x:any)=>{
          return x.category.includes('34')//'Career Counselling Cell'
        }).map((obj:any)=>{
          return obj;
        })
    })
  }
}
