import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, of, switchMap, tap } from 'rxjs';
import { ApicallService } from 'src/app/services/apicall.service';
import { HomeSeoService } from 'src/app/services/homeseo.service';
import { ProjectSeoService } from 'src/app/services/projectseo.service';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import * as AOS from 'aos';
import { CommonService } from '../service/common.service';

@Component({
  selector: 'app-alumni',
  templateUrl: './alumni.component.html',
  styleUrls: ['./alumni.component.css']
})
export class AlumniComponent {
  alldata:any=[];
  alumnidata:any=[];
  pagedata:any=[];
  AssignCategory:any=[];
  project_id = environment.projectid;   
  constructor(
    private route: ActivatedRoute,
    private seoService: HomeSeoService,
    private projectService: ProjectSeoService,
    private apiService: ApicallService,    
    private _service: CommonService,
    private spinner:NgxSpinnerService,
    private _ApicallService: ApicallService,
  ) {}
  ngOnInit(): void {
    this.getseo();
  }

  getseo() {
    let tbody = {
      slug:'Alumni',
    };
    this._ApicallService.getGetseo(tbody).subscribe((data: any) => {      
      this.alldata = data?.data;
      this.pagedata = data?.data?.pagedata;
      this.projectService.sendMessageblog(data?.data?.Blog);
      this.projectService.sendMessagenews(data?.data?.News)
      this.projectService.setFacultysubject(data?.data?.Faculty);
      this.projectService.setPlacementsubject(data?.data?.Placement);
      this.projectService.setprogramssubject(data?.data?.Programs);//
      this.projectService.sendMessageseo(data?.data?.Testimonial);
      this.projectService.sendMessageFaqs(data?.data?.FAQs);
      this.projectService.setmeta(data?.data);
      this.projectService.sendMessagebread(data?.data.breadcrumb)
    });
  }
  
}
