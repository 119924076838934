import { Component, OnInit } from '@angular/core';
import { CommonService } from '../service/common.service';
import {Router, ActivatedRoute, Params} from '@angular/router';
import { ApicallService } from 'src/app/services/apicall.service';
import { environment } from 'src/environments/environment';
import { ProjectSeoService } from 'src/app/services/projectseo.service';
@Component({
  selector: 'app-leadership',
  templateUrl: './leadership.component.html',
  styleUrls: ['./leadership.component.css']
})
export class LeadershipComponent implements OnInit {
  classParam:any;
  alldata: any;
  Announcementdata: any;
  IsAscdesc:number=0;
  pgdata: any;
  applayUrl = environment.applay_url
  project_id = environment.projectid
  careersAssignCategory: any;
  careersPlacementList: any = [];
  getOuerLeaderShip: any;
  filterdataOurLeaderShip: any;
  filterCatogryList: any;
  careersPlacementData: any;
  constructor(private _service: CommonService,
    private activatedRoute: ActivatedRoute,
    private _ApicallService: ApicallService,
    private projectService: ProjectSeoService,
  
  ) {
  }

  ngOnInit(): void {
    
    this.getseo();
  }


  getseo() {
    let tbody = {
      slug:'leadership',
      };
    this._ApicallService.getGetseo(tbody).subscribe((data: any) => {
      this.getLidershipData();
      this.alldata=data?.data;
        this.pgdata=data?.data?.pagedata;  
        this.projectService.sendMessageblog(data?.data?.Blog);
        this.projectService.sendMessagenews(data?.data?.News)
        this.projectService.setFacultysubject(data?.data?.Faculty);
        this.projectService.setPlacementsubject(data?.data?.Placement);      
        this.projectService.setprogramssubject(data?.data?.Programs);//
        this.projectService.sendMessageseo(data?.data?.Testimonial);
        this.projectService.sendMessageFaqs(data?.data?.FAQs);
        this.projectService.setmeta(data?.data);
        this.projectService.sendMessagebread(data?.data.breadcrumb)
    });
  }

  getLidershipData() {
    let obj = {
      "Project_Id": this.project_id,
      "Type": "committee"
    }
    this._service.getContentbytype(obj).subscribe(
      res => {
        this.careersAssignCategory = JSON.parse(res.data[0].AssignCategory)
        this.careersPlacementList = JSON.parse(res.data[0].contentData)
        this.getOuerLeaderShip = this.careersAssignCategory.filter((x: any) => {
          return x.categoryname == 'Our Leadership'
        })
        this.getOuerLeaderShip.forEach((element: any) => {
          this.filterdataOurLeaderShip = this.careersPlacementList.filter((x: any) => {
            return x.category.includes(parseInt(element.categoryId)) 
          })
        });
        
        //let short_daata =  this.getOuerLeaderShip.sort((a:any, b:any) => a.sort - b.sort);
        const key = 'NewsID'
        this.filterdataOurLeaderShip = [...new Map(this.filterdataOurLeaderShip?.map((item:any) => [item[key], item])).values()]
        this.filterCatogryList = this.careersPlacementList.filter((x: any) => {
          return x.category.includes(this.careersAssignCategory[1].categoryId)
        })
        this.filterCatogryList = [...new Map(this.filterCatogryList?.map((item:any) => [item[key], item])).values()]
        this.careersPlacementData = this.filterCatogryList
        // this.filterdataOurLeaderShip.sort((a:any, b:any) => {
          
        //   return a.serialno.localeCompare(b.serialno);
        // });
        const compare = (
          v1: string | number | boolean | Date,
          v2: string | number | boolean | Date
        ) => (v1 < v2 ? -1 : v1 > v2 ? 1 : 0);

        this.filterdataOurLeaderShip = [...this.filterdataOurLeaderShip].sort((a, b) => {
          const res = compare(a.serialno, b.serialno);
          return this.IsAscdesc == 0 ? res : -res;
        });
      }
    )
  }

  getCatagory(catId: any) {
    this.filterCatogryList = this.careersPlacementList.filter((x: any) => {
      return x.category.includes(catId)
    })
    
    const key = 'NewsID'
    this.filterCatogryList = [...new Map(this.filterCatogryList?.map((item:any) => [item[key], item])).values()]
    this.careersPlacementData = this.filterCatogryList
   }

}
