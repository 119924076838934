<section>
    <div class="subMenu_content">
        <app-sub-menu></app-sub-menu>
       </div>
    <div class="sub_content">
        <div class="title">Training and Placement Cell</div>
        <div class="main_content_para my-4">
            <p>At Jigyasa University
(Formerly Himgiri Zee University), we understand and value the importance of placement assistance in shaping the
                career of our students. We would like to be the preferred source of hiring for top recruiters and
                believe in making dedicated efforts to build long term relationships with our recruiting partners. The
                excellent infrastructure of the University facilitates different activities of placement like
                pre-placement talks, written tests, group discussions, and interviews as per the requirements of the
                organizations.</p>
            <p>The JU training & placement team works to identify skills and strengths of students for prospective
                placements and ensure all students receive best guidance and support for developing their respective
                careers. The extensive cross-industry linkages of Zee Group assist our students in opening the door to
                their successful future.</p>
            <p>Our academic programs are designed to meet the needs of the ever-changing market. Students are exposed to
                academics, sports and cultural activities in campus. The Training & placement cell of Himgiri Zee
                University is a centralized body working in coordination with senior faculty coordinators from within
                the university.
            </p>
            <div class="para">
                <h5>Objectives</h5>
                <ul>
                    <li>To provide ample opportunities for placement of students.</li>
                    <li>To manage Centralized Placement activities for ALL courses.</li>
                    <li>To organize campus recruitment for the students with industries and various organizations of
                        repute from all over India.</li>
                    <li>To prepare students for campus recruitment by arranging training in Aptitude tests, group
                        discussions, preparing for Technical and HR interviews through professional trainers.</li>
                    <li>To promote career counselling by organizing guest speakers by senior corporate personnel and
                        most importantly by the immediately placed senior students.</li>
                    <li>To enter into tie-up arrangements with Industries & Corporates of repute for Campus recruitment.
                    </li>
                </ul>
                <h5>Recruitment Process</h5>
                <ul>
                    <li>Companies are invited to the University by the Training and Placement Cell. The companies can
                        also contact the Training and Placement Officer for their requirement.</li>
                    <li>To the responding companies, the details of the final year students opting for placement are
                        sent as per their requirement.</li>
                    <li>The company is assigned a mutually agreed date for campus recruitment.</li>
                    <li>A pre-placement talk is arranged on the assigned date before starting the recruitment process.
                    </li>
                    <li>The company conducts Aptitude/Technical test/ Group discussion/Personal interview as part of
                        their preferred recruitment process.</li>
                    <li>After the completion of the recruitment process, the company is expected to furnish the final
                        list of selected students on the day of recruitment itself.</li>
                </ul>

                <h5>Industrial training/Internships</h5>
                <p>It is mandatory for all our students to undertake the Industrial training/internship program to
                    complete their degree program. Our Training & Placement Cell assists students in identifying and
                    obtaining internships and industrial training opportunities. Training/ Internship is conducted
                    onsite and the organization provides students with an opportunity to learn about the
                    industrial/corporate practices and gain industrial experience. All through the process, students are
                    guided by dedicated faculty mentors as well as mentor from the organization. To evaluate the
                    student, Faculty mentor, in contact with industry mentor, monitors student’s progress. This
                    practical approach ensures that our students pass out with hand-on experience, knowledge and skills
                    sought by employers worldwide.
                </p>
            </div>

        </div>
    </div>
</section>