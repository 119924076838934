<section>
    <div class="subMenu_content">
        <app-sub-menu></app-sub-menu>
    </div>
    <div class="sub_content">
        <div class="title">Privacy Policy</div>
        <div class="main_content_para my-4">
            <p>Thank you for visiting our Career Page. Interested candidates need to share their resume at <a href="mailto:hr@hzu.edu.in"><span>hr@hzu.edu.in</span></a> along with duly filled <b>JOB APPLICATION FORM</b>. Shortlisted candidates will be contacted by our HR.</p>
           <p>Download the <b>JOB APPLICATION FORM:</b> <a href="https://www.hzu.edu.in/uploads/2021/2/Application-Form.pdf" target="_blank"><span>Click here for Application Form</span></a></p>
          <p><b>CURRENT OPENINGS:</b> <a href="/career" target="_blank"><span>Click here for Current Openings</span></a></p>
        </div>
    </div>
</section>