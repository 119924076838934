import { Component } from '@angular/core';

@Component({
  selector: 'app-kidzee-pree-school',
  templateUrl: './kidzee-pree-school.component.html',
  styleUrls: ['./kidzee-pree-school.component.css']
})
export class KidzeePreeSchoolComponent {

}
