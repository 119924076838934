import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription, of, switchMap, tap } from 'rxjs';
import { ApicallService } from 'src/app/services/apicall.service';
import { HomeSeoService } from 'src/app/services/homeseo.service';
import { ProjectSeoService } from 'src/app/services/projectseo.service';
import { environment } from 'src/environments/environment';
import * as AOS from 'aos';
import { CommonService } from '../service/common.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-all-news',
  templateUrl: './all-news.component.html',
  styleUrls: ['./all-news.component.css']
})
export class AllNewsComponent implements OnInit{
  @Input() newsdata:any=[];
  project$: Observable<any> | undefined;
  project_id = environment.projectid
  NewsList:any=[];
  AssignCategory:any=[];
  subscriptionnav!: Subscription;
  testimonydata: any;
  alldata:any=[];
  findex:number=0;
  tindex:number=20;
  totalrecord:number=0;
  more:boolean=true;
  pgdata:any;
  title: any;
  constructor(
    private route: ActivatedRoute,
    private seoService: HomeSeoService,
    private projectService: ProjectSeoService,
    private apiService: ApicallService,
    private _service: CommonService,
    private spinner:NgxSpinnerService,
    private _ApicallService: ApicallService,
  ) {
    
  }
  
  ngOnInit(): void {    
    this.route.params.subscribe((params: any) => {      
      this.title = params['slug'];  
      this.getslug();
       
    });
    AOS.init();
   
  }
  
  getseo() {
    let tbody = {
      slug:'news',
      };
    this._ApicallService.getGetseo(tbody).subscribe({
      next:(data:any)=>{
        
        this.alldata=data?.data;
          this.pgdata=data?.data?.pagedata;  
          this.projectService.sendMessageblog(data?.data?.Blog);
          this.projectService.sendMessagenews(data?.data?.News)
          this.projectService.setFacultysubject(data?.data?.Faculty);
          this.projectService.setPlacementsubject(data?.data?.Placement);      
          this.projectService.setprogramssubject(data?.data?.Programs);//
          this.projectService.sendMessageseo(data?.data?.Testimonial);
          this.projectService.sendMessageFaqs(data?.data?.FAQs);
          this.projectService.setmeta(data?.data);
          this.projectService.sendMessagebread(data?.data.breadcrumb);
      },
      error:(error)=>{
        
      }
    });
      
   
  }
  
  getslug(){
    let slug;
    slug=this.title;   
    this.getplacementData(slug); 
  }

  getplacementData(slug:string) {
    this.spinner.show();
    let obj = {
      "Project_Id": this.project_id,
      "Type": "News",
      "pageurl":slug
    }
    this._service.getContentbytype(obj).subscribe(
      res => {    
        this.getseo();
        this.spinner.hide();   
        if(res.data){
          this.AssignCategory = JSON.parse(res.data[0].AssignCategory);
          this.alldata=JSON.parse(res.data[0].contentData);
          //this.PlacementList = JSON.parse(res.data[0].contentData);   
          this.setcentrelist();  
        }else{
          
        }
          
      }
    )
  }
  fn_ClientFilter(event: any) {
    let val = event.target.value.toLowerCase();
    if (!val) {
      this.NewsList = this.alldata;
    }
    this.NewsList = this.alldata.filter(function (dt: any): any {
      if (
        dt?.Title?.toString().toLowerCase().indexOf(val) !== -1 ||
        !val
      ) {
        // found match, return true to add to result set
        return true;
      }
      else 
        if (dt?.short?.toString().toLowerCase().indexOf(val) !== -1 ||
          !val
        ) {
          // found match, return true to add to result set
          return true;
        }
        else 
        if (dt?.Long1?.toString().toLowerCase().indexOf(val) !== -1 ||
          !val
        ) {
          // found match, return true to add to result set
          return true;
        }   
    })
  }
  setcentrelist()
{
  let dlenth=this.alldata.length;
  this.totalrecord=dlenth;
  if(dlenth>20)
  {
    this.tindex=20;
  }
  else
  {
    this.tindex=dlenth;
    this.more=false;
  }

  this.NewsList=this.alldata.slice(this.findex,this.tindex);
}
filtercategory(id:number){
  if(id!=0){
    this.NewsList=this.alldata.filter((dt:any)=>{
      return dt.category.includes(id)
    }).map((obj:any)=>{
      return obj;
    })
  }
  else{
    this.NewsList=this.alldata;
  }
  
}
  setcentrelistclick()
{
  let dlenth=this.alldata.length;
  let nindex=this.tindex+20;

  if( dlenth> this.tindex)
  {


    if(nindex>dlenth)
    {
       this.tindex=dlenth;
        this.more=false;
    }
    else
    {
    this.tindex += 20;
    }
  }
  else
  {

  this.more=false;
  }

  this.NewsList=this.alldata.slice(this.findex,this.tindex);
}
}
