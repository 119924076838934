<section>
    <div>
        <div>
            <div class="section">

                <div class="subMenu_content">
                    <app-sub-menu></app-sub-menu>
                </div>
                <div class="sub_content">
                    <section id="OverviewOfRAndDCell">
                        <div class="overview">
                            <div class="title">Contact Us</div>
                            <div class="para">
                                <div class="text_content">
                                    <div class="col-md-12 col-flex margin-l-in">
                                        <div class="contact-us-pont-blue">
                                            <h3>Jigyasa University
                                                (Formerly Himgiri Zee University)</h3>
                                            <div class="text-center">
                                                <p class="address-n">P.O. Selaqui, Chakrata Road<br>

                                                    <span>Dehradun 248011, (Uttarakhand)</span><br>INDIA
                                                </p>
                                                <b>
                                                    <p>For admissions : director.admissions@hzu.edu.in <br><span>Mob: +91
                                                            8171819966, <br>Toll Free : 18001804181 </span></p>
                                                    <p> Career : hr@hzu.edu.in</p>
                                                    <p> Examination : coe@hzu.edu.in</p>
                                                </b>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="contact-us-pont-map py-5">
                                    <iframe
                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d13773.442521973291!2d77.86660176725135!3d30.340699211472625!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390f2a7ca8035085%3A0xa9e07df451cf75d6!2sHimgiri+Zee+University!5e0!3m2!1sen!2sin!4v1529933686508"
                                        width="100%" height="400px" frameborder="0" style="border:0"
                                        allowfullscreen=""></iframe>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>

        </div>
    </div>
</section>