<section>
    <div class="subMenu_content" *ngIf="ishide!=1">
        <app-sub-menu></app-sub-menu>
    </div>
    <div [ngClass]="ishide==1 ? '' :'main_div'">
        <div class="listSearchArea d-flex justify-content-between align-items-center">

            <ul class="nav nav-tabs tabs-buttons" role="tablist">
              <li class="nav-item" role="presentation" id="boardOfGovernorsTab0" *ngIf="AssignCategory">
              <button id="click_btn" class="nav-link active"
                  data-bs-toggle="tab" data-bs-target="#boardOfGovernors0" type="button"
                  role="tab" aria-labelledby="board Of Governors Tab 0" aria-selected="true" (click)="filtercategory(0)">All</button>
          </li>
                <li class="nav-item" role="presentation" id="boardOfGovernorsTab0"
                    *ngFor="let dt of AssignCategory;let i=index">
                    <button id="click_btn" class="nav-link"
                        data-bs-toggle="tab" data-bs-target="#boardOfGovernors0" type="button"
                        role="tab" aria-labelledby="board Of Governors Tab 0" aria-selected="true"
                        (click)="filtercategory(dt.categoryId)">{{dt.categoryname}}</button>
                </li>
            </ul>
            <div class="search_box">
                <input type="text" class="form-control filter" id="inputdata" placeholder="Filter"
                (keyup)="fn_ClientFilter($event)" />
            </div>
        </div>        
        <div class="row">
            <div class="col-md-3" *ngFor="let dt of PlacementList">
                <div class="custom-box d-flex justify-content-center align-items-center" *ngFor="let a of dt.logofiles" >
                   <a href="{{dt.slug}}" target="_blank"> <img class=" lazyloaded" i
                        src="{{a.value}}"
                        data-src="{{a.value}}"
                        alt="{{dt.Title}}" title="{{dt.Title}}" width="100%">                        
                    </a>
                </div>
                {{dt.Title}}
            </div>
            <div *ngIf="PlacementList.length==0">
                No Record Found!
            </div>
            <div class="showMore_content text-center mt-5">
                <label for="" class="count_label">Showing {{tindex}} records of {{this.totalrecord}}</label>
                <a class="view_sm_btn" *ngIf="more" (click)="setcentrelistclick()">Show More</a>
            </div>
            
        </div>
    </div>
</section>
<ngx-spinner type="ball-climbing-dot"></ngx-spinner>