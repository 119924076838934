import { Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subject, debounceTime, distinctUntilChanged, fromEvent, switchMap } from 'rxjs';
import { ApicallService } from 'src/app/services/apicall.service';
import { HomeSeoService } from 'src/app/services/homeseo.service';
import { ProjectSeoService } from 'src/app/services/projectseo.service';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import * as AOS from 'aos';
@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.css'],
})
export class BlogDetailsComponent {
  blogdetails: any;
  filteredblogList: any;
  selectedindex!:any;
  selectedcategory:number=0;
  categorydata:any=[];
  slug:string="";
  input = new Subject<string>();
  constructor(
    private route: ActivatedRoute,
    private seoService: HomeSeoService,
    private projectService: ProjectSeoService,
    private apiService: ApicallService,
    private ngxSpinner: NgxSpinnerService
  ) {
  }
  ngOnInit(): void {
    AOS.init();

    let params = this.route.snapshot.paramMap.get('name')!;
    this.route.params.subscribe((params: any) => {
      params = params['name'];
      this.slug=params;
       if (params == 'all') {
        this.getblogdata(params)
      } else {
        this.getblogdata(params)
      }
    });
    // this.getseo();
  }
  // onChange(input: string) {
  //   if (input != '' && input != null && input.length > 3) {
  //     this.input.next(input);
  //   }
  //   else {
  //     this.filteredblogList = this.blogdetails;
  //   }
  // }
  getseo() {
    let tbody = {
      //slug: 'blogs',
      slug:this.slug,
      Projectid: environment.projectid,
    };
    this.apiService.getGetseo(tbody).subscribe((data: any) => {     
      if (data.data != null && data.data != '' && data.data != undefined) {
        this.projectService.sendMessagebread(data.data.breadcrumb);
        this.projectService.sendMessageblog(data.data.blog);
        this.projectService.sendMessageseo(data.data.testimony);
        this.projectService.setmeta(data.data);
      }
      
    });
  }
  // getblogdata(param: string,categoryid:number) {
  //   let tbody = {
  //     slug: param,
  //     Projectid: environment.projectid,
  //     CategoryID:categoryid
  //   };
  //   this.apiService.getGetblog(tbody).subscribe((data: any) => {
  //     this.ngxSpinner.hide();
  //     this.blogdetails = data.data.blog;
  //     this.filteredblogList = this.blogdetails;
  //     this.categorydata=data?.data?.Category;
  //   });
  // }

  // filterResults(text: string) {
  //   if (!text) {
  //     this.filteredblogList = this.blogdetails;
  //     return;
  //   }

  //   this.filteredblogList = this.blogdetails.filter((blog: { Title: string }) =>
  //     blog?.Title.toLowerCase().includes(text.toLowerCase())
  //   );
  // }
  // getblogbycategory(val:any,ind:any){
  //     this.selectedindex=ind;
  //     this.selectedcategory=val;
  //     this.getblogdata('',val);

  // }

  getblogdata(param:string) {
    let tbody = {
      slug: param,
      Projectid: environment.projectid,
    };
    this.apiService.getGetblog(tbody).subscribe((data: any) => {
      this.getseo();
      this.blogdetails=data.data;
      this.projectService.sendMessageblog(data.data.blog);
      this.projectService.setmetablog(this.blogdetails);
    });
  }
}
