
<section>

<div class="title">FAQs</div>
<div>
  <div class="listSearchArea d-flex justify-content-end align-items-center">
    <ul class="nav nav-tabs tabs-buttons" role="tablist">
      <li class="nav-item" role="presentation" id="boardOfGovernorsTab0">
      <button id="click_btn" class="nav-link active"
          data-bs-toggle="tab" data-bs-target="#boardOfGovernors0" type="button"
          role="tab" aria-labelledby="board Of Governors Tab 0" aria-selected="true" *ngIf="faqcategory" (click)="filtercategory(0)">All</button>
       </li>
        <li class="nav-item" role="presentation" id="boardOfGovernorsTab0"
            *ngFor="let dt of faqcategory;let i=index">
            <button id="click_btn" class="nav-link"
                data-bs-toggle="tab" data-bs-target="#boardOfGovernors0" type="button"
                role="tab" aria-labelledby="board Of Governors Tab 0" aria-selected="true"
                (click)="filtercategory(dt.categoryId)">{{dt.categoryname}}</button>
        </li>
    </ul>
</div>
</div>
<div class="accordion" id="accordionExample">
  <div class="accordion-item mt-3" *ngFor="let a of faq;let i = index">
    <div>
    <h2 class="accordion-header" [attr.id]="'headingOne'+i">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapseOne'+i" aria-expanded="true" [attr.aria-controls]="'collapseOne'+i">
          {{a.Title}}
      </button>
    </h2>
    <div [attr.id]="'collapseOne'+i" class="accordion-collapse collapse" [attr.aria-labelledby]="'headingOne'+i" data-bs-parent="#accordionExample">
      <div class="accordion-body">
        <p [innerHTML]="a.Short"></p>
      </div>
    </div>
  </div>
  </div>
</div>

<!-- <div class="accordion" id="accordionExample">
    
    <div class="accordion-item" *ngFor="let data of faqdata;let i=index">
      <h2 class="accordion-header" id="headingTwo">
        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
            {{data.Title}}
        </button>
      </h2>
      <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
        <div class="accordion-body">
         <p [innerHTML]="data.Short"></p>
        </div>
      </div>
    </div>
    
  </div> -->
  <div class="d-flex justify-content-end">
    <button class="btn view_sm_btn my-4" routerLink="/faqs/{{fromslug}}">View All</button>
  </div>
</section>