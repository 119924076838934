import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription, of, switchMap, tap } from 'rxjs';
import { ApicallService } from 'src/app/services/apicall.service';
import { HomeSeoService } from 'src/app/services/homeseo.service';
import { ProjectSeoService } from 'src/app/services/projectseo.service';
import { environment } from 'src/environments/environment';
import * as AOS from 'aos';

@Component({
  selector: 'app-placement-company',
  templateUrl: './placement-company.component.html',
  styleUrls: ['./placement-company.component.css']
})
export class PlacementCompanyComponent {
@Input() Placementdata:any=[];
Placement:any=[];
Placementcategory:any=[];
subscriptionnav!: Subscription;
@Input() fromslug:string="";
constructor(private route: ActivatedRoute,
  private seoService: HomeSeoService,
  private projectService: ProjectSeoService,
  private apiService: ApicallService){}

ngOnInit(): void {
  AOS.init();
  console.log(this.Placementdata);
  if(this.Placementdata){
    this.Placement=this.Placementdata[0].data;    
    this.Placementcategory=this.Placementdata[0].AssignCategory;
  }    
  this.subscriptionnav = this.projectService
    .getPlacementsubject()
    .subscribe((message) => {
      if (message) {
        this.Placementdata = message.text;
        if(this.Placementdata){
          this.Placement=this.Placementdata[0].data;
          this.Placementcategory=this.Placementdata[0].AssignCategory;
        }    
      }
    });
}
}
