<div class="section">
    <header class="header" id="scroll_header">
        <div class="menu_list">
            <ul>
                <li><a href="researchanddevelopment#OverviewOfRAndDCell"> Overview of R&D cell </a></li>
                <li><a href="researchanddevelopment#goalsAndobjective">Major Goals/Objective</a></li>
                <li><a href="researchanddevelopment#aboutDean">About Dean Research</a></li>
                <li><a href="researchanddevelopment#TeamRandD">Team R&D cell</a></li>
            </ul>
        </div>
    </header>
    <div class="subMenu_content">
     <app-sub-menu></app-sub-menu>
    </div>
    <div class="sub_content">
        <section id="OverviewOfRAndDCell">
            <div class="overview">
                <div class="title">Overview of Research and Development Cell</div>
                <div class="para">
                    <p>In order to reinforce and strength, the research based accomplishments in the University; R&D
                        cell has been established in the Jigyasa University
(Formerly Himgiri Zee University).Research is the crucial footstep of
                        innovation that plays a noteworthy role in the Universities. Jigyasa University
(Formerly Himgiri Zee University), Dehradun,
                        UK, India, is a research and innovation driven University and committed to promote research,
                        extension, entrepreneurship and innovation, through certifying, the outcomes of research useful
                        for the benefit of humanity at both national and global levels. Research and Development Cell
                        (RDC), Jigyasa University
(Formerly Himgiri Zee University) has made enthusiastic efforts to align its focus through
                        maintaining a research based eco system in the University through involving all staff, faculty,
                        Research Scholars, and students.</p>
                    <p>The Research and Development Cell (RDC), Jigyasa University
(Formerly Himgiri Zee University) believe in promoting and
                        supporting to all of the students and faculty to conduct various research based activities,
                        which include publications in international and national reputed indexed journals and
                        conferences of repute, book publications, patents, projects, organization of
                        conference/workshops/seminars in various emerging areas.</p>
                </div>
            </div>
        </section>

        <section id="goalsAndobjective" class="pt-5">
            <div class="objective">
                <div class="object_img">
                    <img src="../../../assets/img/jigyasa/banner/RD-banner.jpg" width="100%" alt="">
                </div>
                <div class="para">
                    <div class="sub_title mt-5">Goals / Objective</div>
                    <ul>
                        <li>
                            <p>To encourage research activities in various frontier areas of science and technology and
                                others.</p>
                        </li>
                        <li>
                            <p>Encourage and incentivize faculty for research publications in SCI and Scopus indexed or
                                other equivalent peer-reviewed / reputed and indexed journals.</p>
                        </li>
                        <li>
                            <p>To augment the research output of the University by research paper publication in
                                referred journals and reputed conferences.</p>
                        </li>
                        <li>
                            <p>To promote University-Industry Collaboration.</p>
                        </li>
                        <li>
                            <p>To encourage Core Proficiency and Consultancy work.</p>
                        </li>
                        <li>
                            <p>To organize various workshops/ seminar/ trainings related to promotion of research.</p>
                        </li>
                        <li>
                            <p>To create awareness about filing Patents & IPR and to motivate all faculty and students
                            </p>
                        </li>
                        <li>
                            <p>To coordinate with different Schools/Departments for preparing and finalized the six
                                monthly /annual research activity plans.</p>
                        </li>
                        <li>
                            <p>To Create MoU’s with Industries, Institutions and R&D organizations for joint research
                                work and consultancy projects.</p>
                        </li>
                        <li>
                            <p>To organize brainstorming session through talks with eminent personalities from industry,
                                R&D organizations and institutions of repute.</p>
                        </li>
                    </ul>
                </div>
            </div>
        </section>

        <section id="aboutDean" class="mt-5">
            <div class="aboutDean">
                <div class="title">Prof. (Dr.) Manish Sharma</div>
                <div class="row">
                    <div class="col-md-3">
                        <img class="mt-2" src="https://www.JU.edu.in/images/manish%20sharma.png" alt="">
                    </div>
                    <div class="col-md-9">
                        <div class="sub_title">(Dr.) Manish Sharma</div>
                        <h6>Dean Research</h6>
                        <h6>Jigyasa University
(Formerly Himgiri Zee University)</h6>
                        <div class="para">
                            <p>Prof. (Dr.) Manish Sharma, Professor of Physics, has rich industrial and academic
                                research experience of more than 22 years. He worked for more than one decade in various
                                multinational companies like VCPIL, MARS AUTO and MOSER BAER INDIA LIMITED. He has
                                received various awards and reorganizations like Roll of honor, National Teacher
                                Award-2020, Social Educationist Award, Leadership in Research, Appreciation Award for
                                Contribution of Women Entrepreneur, Performance reward of excellence, Best Poster award,
                                Recognition of the Valuable Contribution towards Admission, Performance appraisal award,
                                Six Sigma Green Belt Certification award, Research Innovation Award, Best employee award
                                etc.
                            </p>
                        </div>
                    </div>
                </div>
                <div class="para">
                    <p>About more than 17 Nos. of published and one granted Patents are also in his credit. He has
                        published more than 35 research papers in high impact journals indexed in Scopus and SCI. Prof.
                        Manish has more than 1000 citations with his h-index 10. Dr. Manish handled various
                        international and Industry sponsored research projects.
                    </p>

                    <p>Prof. Sharma has the collaborations with different National and International research groups and
                        Universities for joint research projects, some of them are; Max Planck Institute of Chemistry
                        (MPI), Mainz, Germany, Chapman University, CA, USA, University of South Africa, SA, National
                        Physical Laboratories (NPL), HNB Garhwal University Srinagar, Aryabhata Research Institute of
                        Observations Sciences (ARIES), etc.
                    </p>
                    <p>Presently Prof. Sharma is also associated with the Satellite Remote Sensing Group, Max Planck
                        Institute, Mainz, Germany in one the International Project i.e. Monitoring and analysis of NOx
                        using differential Optical Absorption Spectroscopy. He has attended more than 40 National and
                        International Conferences and Workshop in India as well as Abroad. The successful organization
                        of more than 35 National conferences and 10 International conferences are also in his credit. He
                        has also organized a number of faculty development programs as well as scientific training
                        programs.
                    </p>
                    <p>Prof. Sharma has visited Germany, South Africa, Scotland (UK), etc., for research projects as
                        well as to attend scientific conferences and training programs. He is also a member of various
                        scientific societies like Materials Research Society of India (MRSI), Atmospheric Geological
                        Union (AGU), World Environmental Organization, International Association of Engineers (IAENG),
                        International Society for Environmental Information Sciences (ISEIS), and Citizen Science
                        Association. Prof. Sharma is also in the editorial board of various reputed journals indexed in
                        Scopus/SCI/UGC.
                    </p>
                </div>
            </div>
        </section>

        <section id="TeamRandD" class="mt-4">
            <div class="objective py-5">
                <div class="object_img">
                    <img src="../../../assets/img/jigyasa/banner/RD-banner.jpg" width="100%" alt="">
                </div>
                <div class="para mt-5">
                    <div class="title mt-5">Team R&D Cell</div>
                    <div class="row mt-5">
                        <div class="col-md-6">
                            <div class="facultyWrapperItem">
                                <div class="faculty-banner">
                                    <div class="row">
                                        <div class="col-md-5">
                                            <img src="../../../assets/img/jigyasa/DrJaiJayant.png"
                                                class="img-responsive">
                                        </div>
                                        <div class="col-md-7">
                                            <div class="profile">
                                                <h3>Dr. Jai Jayant </h3>
                                                <p>Ph. D Coordinator, Research and Development Cell, JU</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="facultyContentWrapper_para">
                                    <p>Dr. Jai Jayant is a passionate Teacher, Learner, and Researcher. He did his PhD
                                        in Finance from Banaras Hindu University, Varanasi. His teaching interest areas
                                        include Accounting, Finance &amp; Research and he has 5 years teaching
                                        experience. He has published more than 20 research papers in reputed National
                                        and International Journals as well as two National patents. He is well versed
                                        with research techniques of carrying out effective research and has been the
                                        resource person in workshops related to research methods. He has successfully
                                        supervised two Ph.D. research scholars with good publications and has been
                                        guiding four more currently in the various research domains. According to him,
                                        teaching profession is not just a job but also a service to assist in building
                                        healthy and ethical nation</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="facultyWrapperItem">
                                <div class="faculty-banner">
                                    <div class="row">
                                        <div class="col-md-5">
                                            <img src="../../../assets/img/jigyasa/DrGideon.png" class="img-responsive">
                                        </div>
                                        <div class="col-md-7">
                                            <div class="profile">
                                                <h3>Dr. Gideon Jackson Synrem</h3>
                                                <p>Assistant Professor (Department of Agriculture)</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="facultyContentWrapper_para">
                                    <p>Dr. Gideon did his Masters (Agriculture) specialization in Genetics and Plant
                                        Breeding in 2011 and Ph. D (Agriculture) specialization in Genetics and Plant
                                        Breeding in 2017, from Sam Higginbottom Institute of Agriculture, Technology and
                                        Sciences, Prayagraj (Formerly Allahabad Agriculture Institute), Uttar Pradesh.
                                        He has engaged in Teaching and Research since 2017 (5 years) presently serving
                                        as an Assistant Professor at Department of Agriculture, Jigyasa University
(Formerly Himgiri Zee University)
                                        Jigyasa University
(Formerly Himgiri Zee University), Dehradun. He has taught students of B.Sc (Hons)
                                        Agriculture, and M.Sc (Agriculture) Genetics and Plant Breeding and guided
                                        several Post Graduate students in Genetics and Plant Breeding. Dr. Gideon has
                                        published more than 15 research papers and two books chapters in UGC Care
                                        Journals/NAAS-National Academy of Agriculture Sciences rated Journals/Scopus
                                        Journals. He has received the Rajiv Gandhi National Fellowship award for
                                        pursuing Ph. D in Genetics and Plant Breeding and Certificate of Excellence in
                                        Reviewing as recognition of an outstanding contribution to the quality of the
                                        International Journal of Plant and Soil Science awarded. His area of Interest
                                        are in Agriculture Sciences, Life sciences, Biotechnology, Statistical
                                        Software’s like R studio, SPSS analysis, Soft Skills (Speech and Communication
                                        Skills), Music, Outdoor and Indoor Sports, Athletics, travelling and
                                        Counselling.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 mt-4">
                            <div class="facultyWrapperItem">
                                <div class="faculty-banner">
                                    <div class="row">
                                        <div class="col-md-5">
                                            <img src="../../../assets/img/jigyasa/DrBhopalSingh.png"
                                                class="img-responsive">
                                        </div>
                                        <div class="col-md-7">
                                            <div class="profile">
                                                <h3>Dr. Bhopal Singh Rawat</h3>
                                                <p>Assistant Professor, Department of Education</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="facultyContentWrapper_para">

                                    <p>Dr. Bhopal Singh Rawat did Ph.D. Degree in Science Education from Zakir Hussain
                                        Centre for Educational Studies, Jawaharlal Nehru University, New Delhi. Dr.
                                        Rawat is an excellent teacher with more than fourteen years of experience of
                                        teaching D.Ed., B.E.ED. , B.Ed., Diploma and B.Tech. Students. He has worked as
                                        a project fellow in NCERT and presently is guiding Ph.D. Students at Himgiri Zee
                                        University, Dehradun. A good number of research papers in journal of repute. His
                                        areas of teaching are Teaching of Science, Teaching of Mathematics, Educational
                                        Guidance &amp; Counseling, and Curriculum Development. He has also been teaching
                                        physics to B.Tech and Diploma courses in the Department of Engineering, Research
                                        and Publication Ethics to Ph.D. students at Jigyasa University
(Formerly Himgiri Zee University). His area of
                                        research interests are science education, mathematics education, educational
                                        philosophy, educational psychology, educational guidance and counseling.</p>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>
    </div>
</div>