import { Component, OnInit } from '@angular/core';
import { ProjectSeoService } from 'src/app/services/projectseo.service';
import { environment } from 'src/environments/environment';
import { ApicallService } from 'src/app/services/apicall.service';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
  selector: 'app-day-care',
  templateUrl: './day-care.component.html',
  styleUrls: ['./day-care.component.css']
})
export class DayCareComponent implements OnInit {
  headerTitle = "DayCare"
  constructor(
    private projectService: ProjectSeoService,
    private apiService: ApicallService,
    private ngxSpinner: NgxSpinnerService
  ) {

  }
  ngOnInit(): void {
    this.getseo();
  }

  getseo() {
   let tbody = {
      slug: 'Daycare',
      Projectid: environment.projectid,
    };
    this.apiService.getGetseo(tbody).subscribe((data: any) => {
     this.projectService.sendMessagebread(data?.data?.breadcrumb);
     this.projectService.sendMessageblog(data?.data?.blog);
     this.projectService.sendMessageseo(data?.data?.testimony);
     this.projectService.sendMessageFaqs(data?.data?.faq);
     this.projectService.setmeta(data.data);
    });
  }
}
