<section>
    <div class="mainDiv">
        <div class="banner_content">
            <div class="whiteTitle">{{headerTitle}}</div>
            <div class="subMenu_content">
                <app-sub-menu></app-sub-menu>
            </div>
        </div>

        <div class="sub_content">
            <div class="row">
                <div class="col-md-4">
                    <div class="img_content">
                        <img srcset="../../../assets/img/Kindergarten.png" loading="lazy" class="faqImg_style" alt="">
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="text_content">
                        <div class="title mt-3">Kindergarten</div>
                        <div class="infoContent mt-3">
                            <div class="text1">
                                <div class="sub_text">Junior K.G.</div>
                                <div class="sub_text">4 - 5 Years</div>
                            </div>
                            <div class="text2 rightBorder">
                                <div class="sub_text">Senior K.G.</div>
                                <div class="sub_text">5 - 6 Years</div>
                            </div>
                            <div class="text2">
                                <div class="sub_text">Duration</div>
                                <div class="sub_text">4 hour/day</div>
                            </div>
                        </div>
                        <div class="paraText mt-4">
                            <p>*As per RTE- Right to Education and NEP- National Education Policy from state to state.
                            </p>
                            <p><b>This is level 2 and 3 of preschool -Foundational Stage 1 - Mandatory as per NEP)</b>. This is in progression to the Kidzee Nursery Curriculum.</p>
                       <p>This is level 2 and 3 of preschool -Foundational Stage 1 - Mandatory as per NEP). This is in progression to the Kidzee Nursery Curriculum.
                        At Kidzee, the Kindergarten (KG) curriculum ensures a smooth transition to formal schooling. Age-appropriate activities in various areas like language, numbers, general knowledge, music, and art are conducted in a planned manner throughout the program. Proprietary programs for Phonics and English Readiness makes us the one of the best preschools in India. A Child's development is measured thrice in a year using a well-designed assessment.</p>
                    </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="contentList">
            <div class="row">
                <div class="col-md-4">
                    <div class="listTitle">Key Areas in Playgroup Curriculum</div>
                    <div class="text_list">
                        <ul>
                            <li> <span class="doth">.</span><span class="liText">Developing oral and written communication skills</span></li>
                            <li> <span class="doth">.</span><span class="liText">English Readiness Programme</span></li>
                            <li> <span class="doth">.</span><span class="liText">Math Operations</span></li>
                            <li> <span class="doth">.</span><span class="liText">Personal, Social, and Emotional Growth</span></li>
                            <li> <span class="doth">.</span><span class="liText">Promoting cognitive development (Sorts using multiple attributes)</span></li>
                            <li> <span class="doth">.</span><span class="liText">Visual & Performance Art</span></li>
                            <li> <span class="doth">.</span><span class="liText">'Panchkosha' integrated</span></li>
                        </ul>
                    </div>
                </div>
                    <div class="col-md-4 kidzee_session">
                        <div class="listTitle">Kidzee Sessions</div>
                        <div class="text_list">
                            <ul>
                                <li> <span class="doth">.</span><span class="liText">Circle Time</span></li>
                                <li> <span class="doth">.</span><span class="liText">Free Play</span></li>
                                <li> <span class="doth">.</span><span class="liText">Knowledge Time</span></li>
                                <li> <span class="doth">.</span><span class="liText">Language Time</span></li>
                                <li> <span class="doth">.</span><span class="liText">Numeracy Time</span></li>
                                <li> <span class="doth">.</span><span class="liText">Talk Time</span></li>
                                <li> <span class="doth">.</span><span class="liText">Outdoor</span></li>
                                <li> <span class="doth">.</span><span class="liText">Indoor</span></li>
                                <li> <span class="doth">.</span><span class="liText">Celebration of festivals and special days</span></li>
                                <li> <span class="doth">.</span><span class="liText">Field Trips</span></li>
                                <li> <span class="doth">.</span><span class="liText">Puppet shows and skits</span></li>
                                <li> <span class="doth">.</span><span class="liText">Phonics</span></li>
                                <li> <span class="doth">.</span><span class="liText">Hindi*</span></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="listTitle">Kidzee Special</div>
                        
                        <div class="text_list">
                            <ul>
                                <li> <span class="doth">.</span><span class="liText">English Readiness Programme</span></li>
                                <li> <span class="doth">.</span><span class="liText">Artsy</span></li>
                                <li> <span class="doth">.</span><span class="liText">Personality Development</span></li>
                                <li> <span class="doth">.</span><span class="liText">Showstopper</span></li>
                                <li> <span class="doth">.</span><span class="liText">Mental Might</span></li>
                                <li> <span class="doth">.</span><span class="liText">Eco-conscious</span></li>
                                <li> <span class="doth">.</span><span class="liText">Tell-a-Tale</span></li>
                                <li> <span class="doth">.</span><span class="liText">Library</span></li>
                                <li> <span class="doth">.</span><span class="liText">Sciencify</span></li>
                                <li> <span class="doth">.</span><span class="liText">Whirl & Twirl</span></li>
                                <li> <span class="doth">.</span><span class="liText">Sensorium</span></li>
                                <li> <span class="doth">.</span><span class="liText">Critical Thinking - Problem solving</span></li>
                                <li> <span class="doth">.</span><span class="liText">Intergrated Sanskar</span></li>
                                <li> <span class="doth">.</span><span class="liText">Augmented Reality - Fantasy Box</span></li>
                            </ul>
                        </div>
                    </div>
                    </div>
        </div>
        <div class="faqs_content">
            <app-faqs></app-faqs>
        </div>
        <div class="blogs_content">
            <app-blogs></app-blogs>
        </div>
       
    </div>
    <!-- <div class="bottomMonkey">
        <img srcset="../../../assets/img/monkey2.png" loading="lazy" class="monkeyImgList" alt="">
    </div> -->
</section>