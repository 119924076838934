<section>
    <div class="mainDiv">
        <div class="banner_content">
            <div class="whiteTitle">{{headerTitle}}</div>
            <div class="subMenu_content">
                <app-sub-menu></app-sub-menu>
            </div>
        </div>

        <div class="sub_content">
            <div class="row">
                <div class="col-md-4">
                    <div class="img_content">
                        <img srcset="../../../assets/img/PlayGroup.png" loading="lazy" loading="lazy" class="faqImg_style" alt="">
                    </div>
                </div>
                <div class="col-md-8">
                    <div class="text_content">
                        <div class="title mt-3">PlayGroup</div>
                        <div class="infoContent mt-3">
                            <div class="text1">
                                <div class="sub_text">Age Group</div>
                                <div class="sub_text">1.5 - 2.5 Years</div>
                            </div>
                            <div class="text2">
                                <div class="sub_text">Duration</div>
                                <div class="sub_text">3 Hours/Day</div>
                            </div>
                        </div>
                        <div class="paraText mt-4">
                            <p>*As per RTE- Right to Education and NEP- National Education Policy from state to state.
                            </p>
                            <p>Children in this age group as identified as <b>Toddlers</b>. Children are born curious. They begin to explore the environment around them as soon as they are born. Kidzee’s playgroup <b>age appropriate</b> curriculum plays an important role in helping children continue their exploration.</p>
                           <p>Kidzee Playgroup prepares a child developmentally and academicaly for regular school <b>(age 3 years and above) based on NEP 2020.</b></p>
                           <p>The first few years of a child’s life are crucial and critical when it comes to the acquisition of skills and brain based learning. In the playgroup class, the young learners develop their latent language and math skills which is core to successful future learning.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="contentList">
            <div class="row">
                <div class="col-md-4">
                    <div class="listTitle">Key Areas in Playgroup Curriculum</div>
                    <div class="text_list">
                        <ul>
                            <li> <span class="doth">.</span><span class="liText">Promoting positive peer social interaction</span></li>
                            <li> <span class="doth">.</span><span class="liText">Opportunities for basic motor development</span></li>
                            <li> <span class="doth">.</span><span class="liText">Exploration based learning</span></li>
                            <li> <span class="doth">.</span><span class="liText">Math Readiness (Number Recognition)</span></li>
                            <li> <span class="doth">.</span><span class="liText">Language Readiness (Letter Recognition)</span></li>
                            <li> <span class="doth">.</span><span class="liText">Promoting cognitive development (Sorts using one attribute)</span></li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-4 kidzee_session">
                    <div class="listTitle">Kidzee Sessions</div>
                    <div class="text_list">
                        <ul>
                            <li> <span class="doth">.</span><span class="liText">Circle Time</span></li>
                            <li> <span class="doth">.</span><span class="liText">Free Play</span></li>
                            <li> <span class="doth">.</span><span class="liText">Knowledge Time</span></li>
                            <li> <span class="doth">.</span><span class="liText">Language Time</span></li>
                            <li> <span class="doth">.</span><span class="liText">Numeracy Time</span></li>
                            <li> <span class="doth">.</span><span class="liText">Talk Time</span></li>
                            <li> <span class="doth">.</span><span class="liText">Outdoor</span></li>
                            <li> <span class="doth">.</span><span class="liText">Indoor</span></li>
                            <li> <span class="doth">.</span><span class="liText">Celebration of <br>festivals and special days</span></li>
                            <li> <span class="doth">.</span><span class="liText">Field Trips</span></li>
                            <li> <span class="doth">.</span><span class="liText">Puppet shows and skits</span></li>
                        </ul>
                    </div>

                </div>
                
    <div class="col-md-4">
        <div class="listTitle">Kidzee Special</div>
        <div class="text_list">
            <ul>
                <li> <span class="doth">.</span><span class="liText">Artsy</span></li>
                <li> <span class="doth">.</span><span class="liText">Personality Development</span></li>
                <li> <span class="doth">.</span><span class="liText">Showstopper</span></li>
                <li> <span class="doth">.</span><span class="liText">Mental Might</span></li>
                <li> <span class="doth">.</span><span class="liText">Eco-conscious</span></li>
                <li> <span class="doth">.</span><span class="liText">Tell-a-Tale</span></li>
                <li> <span class="doth">.</span><span class="liText">Library</span></li>
                <li> <span class="doth">.</span><span class="liText">Sciencify</span></li>
                <li> <span class="doth">.</span><span class="liText">Whirl & Twirl</span></li>
                <li> <span class="doth">.</span><span class="liText">Sensorium</span></li>
                <li> <span class="doth">.</span><span class="liText">Critical Thinking - Problem solving</span></li>
                <li> <span class="doth">.</span><span class="liText">Intergrated Sanskar </span></li>
                <li> <span class="doth">.</span><span class="liText">Augmented Reality - Fantasy Box </span></li>
            </ul>
        </div>
    </div>
            </div>
        </div>
        <div class="faqs_content">
            <app-faqs></app-faqs>
        </div>
        <div class="blogs_content">
            <app-blogs></app-blogs>
        </div>
       
    </div>
    <!-- <div class="bottomMonkey">
        <img srcset="../../../assets/img/monkey2.png" loading="lazy" class="monkeyImgList" alt="">
    </div> -->
</section>