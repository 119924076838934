<section>
    <div class="banner">
    <div class="col-md-12">
        <div class="mx-auto my-auto justify-content-center">
          <div id="recipeCarousel" class="carousel slide" data-bs-ride="carousel">
            <div class="carousel-inner" role="listbox">
                <div class="carousel-item active mt-3" *ngFor="let data of academindata;let i=index">
                    <div>
                        <div class="banner_text_content">
                          <button class="btn btn_style_color mt-3">Apply</button>
                        </div>
                        <img src="{{data?.media_url}}" routerLink="/admissions" width="100%" loading="lazy" class="d-block slider_img responsive" alt="...">
                    </div>
                </div>              
            </div>
            <a class="carousel-control-prev bg-transparent w-aut" href="#recipeCarousel" role="button" data-bs-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            </a>
            <a class="carousel-control-next bg-transparent w-aut" href="#recipeCarousel" role="button" data-bs-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
            </a>
          </div>
        </div>
    </div>
   
    </div>
    <div class="subMenu_content">
        <app-sub-menu></app-sub-menu>
    </div>
    <div class="maindiv">
        <!-- <div class="title">
            Home/{{Title}}
        </div> -->
       
        <div *ngFor="let data of academindata;let i=index">
            <div>
                <div class="title">{{data.name}}</div>
                <div [innerHTML]="data.Description"></div>
            </div>
            <div *ngFor="let dt of data.description">
                <div *ngIf="dt.Type=='Speciality' && dt.Contenttype=='Descriptive'">
                    <h5>{{dt.Title}}</h5>
                    <div class="description">
                    <div class="mt-5" [innerHTML]="dt.content"></div>
                </div>
                    <mat-accordion *ngIf="dt.level2">
                                <mat-expansion-panel *ngFor="let dt1 of dt.level2">                    
                                        <mat-expansion-panel-header>
                                            <h5>{{dt1.Title}}</h5>                  
                                        </mat-expansion-panel-header>
                                        <div class="description">
                                            <span class="mt-5" [innerHTML]="dt1.content"></span>  
                                        </div>
                                        <ul class="list-group list-group-horizontal">
                                            <li class="list-group-item" *ngFor="let d of dt1.level3;let i=index" value="{{d.Title}}">
                                                <span (click)="showhide(d.content)">
                                                    <h5>{{d.Title}}</h5>
                                                </span>                                                
                                            </li>
                                        </ul>  
                                        <div class="description">
                                        <p class="mt-5" [innerHTML]="leveldesc"></p>      
                                    </div>          
                                </mat-expansion-panel>
                    </mat-accordion>
                </div> 
                <div>                  
                    <div *ngIf="dt.content=='Programs' && dt.Contenttype=='Component' && this.alldata?.Programs[0]?.data">
                      
                        <app-commonprograms [slugdata]="this.alldata?.Programs"></app-commonprograms> 
                    </div>
                    <div *ngIf="dt.content=='Testimonial' && dt.Contenttype=='Component' && this.alldata?.Testimonial[0]?.data">
                        <app-testimonial [Testimonialdata]="this.alldata?.Testimonial" [fromslug]="slug"></app-testimonial>
                    </div>
                    <div *ngIf="dt.content=='FAQs' && dt.Contenttype=='Component' && this.alldata?.FAQs[0]?.data">
                        <!-- <h3>FAQs</h3> -->
                        <app-faqs [faqdata]="this.alldata?.FAQs" [fromslug]="slug"></app-faqs>
                    </div>  
                    <!-- <div *ngIf="dt.content=='News' && dt.Contenttype=='Component'">
                        <app-news [newsdata]="this.alldata?.News"></app-news>
                    </div>           -->
                    <div *ngIf="dt.content=='Faculty' && dt.Contenttype=='Component' && this.alldata?.Faculty[0]?.data">
                        <!-- <h3>Faculty</h3> -->
                        <app-faculty [facultydata]="this.alldata?.Faculty" [fromslug]="slug"></app-faculty>
                    </div>  
                    <div *ngIf="dt.content=='Placement' && dt.Contenttype=='Component' && this.alldata?.Placement[0]?.data">
                        <!-- <h3>Placement</h3> -->
                        <app-placements [Placementdata]="this.alldata?.Placement" [fromslug]="slug"></app-placements>
                    </div>
                    <div *ngIf="dt.content=='Blog' && dt.Contenttype=='Component' && this.alldata?.Blog[0]?.data">
                        <app-blogs [blogdata]="this.alldata?.Blog" [fromslug]="slug"></app-blogs>
                    </div>    
                    <div *ngIf="dt.content=='Events' && dt.Contenttype=='Component' && this.alldata?.Events[0]?.data">
                        <!-- <h3>Faculty</h3> -->
                        <app-eventcommon [eventdata]="this.alldata?.Events" [fromslug]="Title"></app-eventcommon>
                    </div>  
                    <div *ngIf="dt.content=='Career Placement' && dt.Contenttype=='Component' && this.alldata?.careerplacement[0]?.data">
                        <!-- <h3>Faculty</h3> -->
                        <app-careerplacementcommon [careerplacementdata]="this.alldata?.careerplacement" [fromslug]="Title"></app-careerplacementcommon>
                    </div>   
                </div> 
            </div>
        </div>
    </div>
</section>