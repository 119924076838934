import { Component } from '@angular/core';

@Component({
  selector: 'app-library-facilities',
  templateUrl: './library-facilities.component.html',
  styleUrls: ['./library-facilities.component.css']
})
export class LibraryFacilitiesComponent {

}
