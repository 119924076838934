<section>
    <div class="blogsDiv">
        <div class="spinner" *ngIf="spinner">
        </div> 
        <div class="title">Blogs</div>
        <div>
            <div >
              <ul class="list-group list-group-horizontal-md" >
                <li class="list-group-item" *ngIf="blogcategory" (click)="filtercategory(0)">All</li>
                <li class="list-group-item" *ngFor="let dt of blogcategory" value="{{dt.categoryId}}">          
                 <span (click)="filtercategory(dt.categoryId)">{{dt.categoryname}}</span>
                </li>
              </ul>
            </div>
          </div>
        <div class="row mt-5">

            <div class="col-md-4" *ngFor="let item of bdata ;let i=index">
                <div class="card" data-aos="zoom-in">
                    <div class="row">
                        <div class="col-2">
                            <div class="profile_img mt-3" *ngFor="let dt of item.files">
                                <img src={{dt.url}} height="80px" width="80px" loading="lazy" class="img" alt={{dt.filename}}>
                            </div>
                        </div>
                        <div class="col-10">
                            <div class="content-blog-card">
                                <ul class="ps-0 mb-0 list-unstyled cat">
                                    <!-- <li><a href="https://themes.envytheme.com/falar/category/career/">Career</a></li> -->
                                </ul>
                                <div class="card_title"><p>{{item.Short}}</p>
                                </div>
                                <ul class="blog-list d-flex justify-content-between mt-3">
                                    <li>{{item.FromDate}}</li>
                                    <li>2 min read</li>
                                </ul>
                                <span class="readMore" routerLink="/blog/{{item.slug}}">Read More <i class="fa fa-long-arrow-right"></i></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <!-- <div class="col-md-4" *ngFor="let item of bdata ;let i=index">
                <div class="blogsImgContent">
                   <div class="upperBg" *ngFor="let dt of item.files">
                    <img src={{dt.url}} loading="lazy" class="img" alt={{dt.filename}}>
                   </div>
                   <div class="infoContent">
                    <div class="date">{{item.FromDate}}</div>
                    <div class="text1">{{item.Title}}</div>
                    <div class="para">
                        <div class="box">
                        <input type="checkbox" id="{{i}}">
                        <p>{{item.Short}}</p>
                        </div>
                        <a class="" role="button" routerLink="/blog/{{item.slug}}"><button class="btn readMoreBtn">Read More</button></a>
                    </div>
                </div>
                </div>
            </div> -->
            <!-- <div class="col-md-4">
                <div class="blogsImgContent">
                   <div class="upperBg">
                    <img srcset="../../../assets/img/littleGirl.png" loading="lazy" class="img" alt="">
                   </div>
                   <div class="infoContent">
                    <div class="date">January 9, 2023</div>
                    <div class="text1">A dozen must ask questions about Preschools</div>
                    <div class="para">
                        <div class="box">
                            <input type="checkbox" id="expanded10">
                        <p>Parents of toddlers are cautious about choosing the first school for the apple of their
                            eye. </p>
                            <label for="expanded10" class="readMoreBtn" role="button" routerLink="blog/blogs_details">Read More</label>
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="blogsImgContent">
                   <div class="upperBg">
                    <img srcset="../../../assets/img/littleGirl.png" loading="lazy" class="img" alt="">
                   </div>
                   <div class="infoContent">
                    <div class="date">January 9, 2023</div>
                    <div class="text1">A dozen must ask questions about Preschools</div>
                    <div class="para">
                        <div class="box">
                            <input type="checkbox" id="expanded11">
                        <p>Parents of toddlers are cautious about choosing the first school for the apple of their
                            eye. </p>
                            <label for="expanded11" class="readMoreBtn" role="button" routerLink="blog/blogs_details">Read More</label>
                        </div>
                    </div>
                </div>
                </div>
            </div> -->

        </div>
    </div>
    <div class="d-flex justify-content-end">
        <button class="btn view_sm_btn my-4" routerLink="/blogs/{{fromslug}}">View All</button>
    </div>
</section>
