<section>
    <div class="mainDiv">
        <div class="subContent">
            <div class="centers_title text-center">Our Preschool Centers in Agartala</div>
                    <div class="dropDownList mt-4">
                        <form action="">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                 <select name="" id="" class="form-control  dropdown-toggle">
                                    <option value="">Search by Areaname</option>
                                 </select>
                                    </div>
                                </div>
                                <div class="col-md-4 res_input">
                                    <div class="form-group">
                                 <select name="" id="" class="form-control  dropdown-toggle">
                                    <option value="">Search by Pincode</option>
                                 </select>
                                    </div>
                                </div>
                                <div class="col-md-4 res_input">
                                    <div class="form-group d-flex justify-content-end">
                                    <button class="btn map_btn search_btn">Search</button>  
                                    </div>
                                </div>
                            </div>
                        </form>
            </div>
            <div class="card_content mt-5">
                <div class="card_title">Kidzee- Tollygunge- Kolkata-One Step Up</div>
                <div class="location_content">
                    <div class="locationIcon mt-4">
                        <img src="../../../assets/img/location.png" alt="" class="location_img"><p>111/1, N.S.C. Bose Road, (Near Ranikuthi),</p>
                    </div>
                    <div class="row">
                        <div class="col-6">
                            <div class="three_icon mt-3">
                                <img src="../../../assets/img/WhatsApp Icon.png" class="iconList" alt="">
                                <img src="../../../assets/img/Call Icon.png" class="iconList mx-3" alt="">
                                <img src="../../../assets/img/Website Icon.png" class="iconList" alt="">
                            </div>
                        </div>
                        <div class="col-6">
                            <button class="btn map_btn mt-3 mx-2">Map Direction</button>
                            <button class="btn map_btn mt-3" (click)="gotTopage('Kidzee-Tollygunge')">Enquiry</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</div>
</section>