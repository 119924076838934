<section>
    <div>
      <div class="title" data-aos="fade-up" data-aos-duration="3000">Events</div>
      <div class="listSearchArea">
        <div class="row">
          <div class="listSearchArea d-flex justify-content-end align-items-center">
            <ul class="nav nav-tabs tabs-buttons" role="tablist">
              <li class="nav-item" role="presentation" id="boardOfGovernorsTab0" *ngIf="AssignCategory">
                <button id="click_btn" class="nav-link active" data-bs-toggle="tab" data-bs-target="#boardOfGovernors0"
                  type="button" role="tab" aria-labelledby="board Of Governors Tab 0" aria-selected="true"
                  (click)="filtercategory(0)">All</button>
              </li>
              <li class="nav-item" role="presentation" id="boardOfGovernorsTab0"
                *ngFor="let dt of AssignCategory;let i=index">
                <button id="click_btn" class="nav-link" data-bs-toggle="tab" data-bs-target="#boardOfGovernors0"
                  type="button" role="tab" aria-labelledby="board Of Governors Tab 0" aria-selected="true"
                  (click)="filtercategory(dt.categoryId)">{{dt.categoryname}}</button>
              </li>
            </ul>
          </div>
          
        </div>
      </div>
      <div class="row">
        <owl-carousel-o [options]="customOptions">
          <ng-template carouselSlide *ngFor="let item of eventList ;let i=index">
            <div class="d-flex justify-content-center">
                <div class="common-card">
            <div class="row">
              <div class="col-12">
                <div class="profile_img mt-3" *ngFor="let dt of item.files">
                  <img src={{dt.url}} loading="lazy" class="img" alt={{item.filename}}>
                </div>               
              </div>
              <div class="content-blog-card">{{item.Title}}</div>
              <span class="readMore" routerLink="/event/{{item.slug}}">Read More <i
                  class="fa fa-long-arrow-right"></i></span>
            </div>
          </div>
  
        </div>
    </ng-template>  
</owl-carousel-o>
<div class="d-flex justify-content-end">
  <button class="btn view_sm_btn mt-4" routerLink="/events/{{fromslug}}">View All</button>
</div>   
        <!-- <div class="showMore_content text-center mt-5">
          <label for="" class="count_label">Showing {{tindex}} records of {{this.totalrecord}}</label>
          <a class="showMore_btn" *ngIf="more" (click)="setcentrelistclick()">Show More</a>
        </div>
   -->
      </div>
    </div>
  </section>
  