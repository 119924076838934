import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription, of, switchMap, tap } from 'rxjs';
import { ApicallService } from 'src/app/services/apicall.service';
import { HomeSeoService } from 'src/app/services/homeseo.service';
import { ProjectSeoService } from 'src/app/services/projectseo.service';
import { environment } from 'src/environments/environment';
import * as AOS from 'aos';

@Component({
  selector: 'app-placements',
  templateUrl: './placements.component.html',
  styleUrls: ['./placements.component.css']
})
export class PlacementsComponent implements OnInit{
  @Input() Placementdata:any=[];
  @Input() fromslug:string="";
  project$: Observable<any> | undefined;
  subscriptionnav!: Subscription;
  Placement:any=[];
  Placementcategory:any=[];
  imageObject = []
//     {
//     image: 'https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-09/airbus.webp',
//     thumbImage: 'https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-09/airbus.webp',
//     title: 'Hummingbirds are amazing creatures'
// }, {
//     image: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/9.jpg',
//     thumbImage: 'https://sanjayv.github.io/ng-image-slider/contents/assets/img/slider/9.jpg'
// }, {
//     image: 'https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-09/airbus.webp',
//     thumbImage: 'https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-09/airbus.webp',
//     title: 'Example with title.'
// },{
//     image: 'https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-09/airbus.webp',
//     thumbImage: 'https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-09/airbus.webp',
//     title: 'Hummingbirds are amazing creatures'
// }, {
//     image: 'https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-09/airbus.webp',
//     thumbImage: 'https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-09/airbus.webp'
// }, {
//     image: 'https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-09/airbus.webp',
//     thumbImage: 'https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-09/airbus.webp',
//     title: 'Example two with title.'
// }];
  constructor(
    private route: ActivatedRoute,
    private seoService: HomeSeoService,
    private projectService: ProjectSeoService,
    private apiService: ApicallService
  ) {
    
  }
  
  ngOnInit(): void {
    AOS.init();
    if(this.Placementdata){
      this.Placement=this.Placementdata[0].data;
      
      this.assigndata();
      this.Placementcategory=this.Placementdata[0].AssignCategory;
    }    
    this.subscriptionnav = this.projectService
      .getPlacementsubject()
      .subscribe((message) => {
        if (message) {
          this.Placementdata = message.text;
          if(this.Placementdata){
            this.Placement=this.Placementdata[0].data;
             this.assigndata();
            this.Placementcategory=this.Placementdata[0].AssignCategory;
          }    
        }
      });
  }

  assigndata(){
   this.imageObject= this.Placementdata[0].data.filter((dt:any)=>{
      return dt
    }).map((object:any)=>{
      return {"image":object?.files[0]?.url,"thumbImage":object?.files[0]?.url,"title":object?.Title};
    })
  }

  imageClickHandler(e:any) {
    console.log('image click', e);
  }  
  filtercategory(id:number){
    if(id!=0){
      this.Placement=this.Placementdata[0].data.filter((dt:any)=>{
        return dt.category.includes(id)
      }).map((obj:any)=>{
        return obj;
      })
    }
    else{
      this.Placement=this.Placementdata[0].data;
    }
    
  }
}
