<div class="section">
    <div class="subMenu_content">
        <app-sub-menu></app-sub-menu>
    </div>
    <div class="sub_content">
        <section id="OverviewOfRAndDCell">
            <div class="overview">
                <div class="title">Students Grievance Redressal.</div>
                <div class="container">
                    <img src="../../../assets/img/jigyasa/Ombudsman.jpg" width="40%" alt="Ombudsman">
                    <p>
                    Email id of Students grievance redressal committee-sgrc@hzu.edu.in
                    <br>
                    Email ID of OMBUDSPERSON -ombudsperson@hzu.edu.in
                    </p>
                    <div class="pdf_tab">
                        <a href="../../../assets/pdf/Committee.pdf" target="_blank">
                        <button class="btn pdf_btn">Commitee</button></a>
                       <a href="../../../assets/pdf/Regulation.pdf" target="_blank"><button class="btn pdf_btn" >Students Grievance Redressal Regulations</button></a>
                    </div>                   
                </div>
            </div>
        </section>
    </div>
</div>