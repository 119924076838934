<div class="section">
    <!-- <header class="header" id="scroll_header">
        <div class="menu_list">
            <ul>
                <li><a href="researchanddevelopment#OverviewOfRAndDCell"> Overview of R&D cell </a></li>
                <li><a href="researchanddevelopment#goalsAndobjective">Major Goals/Objective</a></li>
                <li><a href="researchanddevelopment#aboutDean">About Dean Research</a></li>
                <li><a href="researchanddevelopment#TeamRandD">Team R&D cell</a></li>
            </ul>
        </div>
    </header> -->
    <div class="subMenu_content">
        <app-sub-menu></app-sub-menu>
    </div>
    <div class="sub_content">
        <section id="OverviewOfRAndDCell">
            <div class="overview">
                <div class="video_content mt-2">
                    <video width="100%" height="400px" loop="true" autoplay="autoplay" controls="">
                        <source src="../../../assets/img/jigyasa/video/Short video on Universityhzu.mp4">
                      </video>
                </div>
                <div class="title mt-2">Hostel Facility</div>

            

                <div class="para">
                    <p>The University has made the provision of separate AC / Non AC hostels for boys and girls. The
                        students are provided basis amenities like a cot, study table, chair, bedside table and a
                        cupboard in their respective rooms. Laundry facility is available on payment basis. The charges
                        for various categories of accommodation including mess facility are:</p>
                    <ul>
                        <li>Transport facility for hostellers on Saturday every week</li>
                        <li>Film screening on a day every week.</li>
                        <li>Swimming pool for hostelers with separate time slots for Boys and Girls.</li>
                        <li>Prompt action on any maintenance related discrepancy</li>
                        <li>Excursions for all the hostellers, twice in a semester in Nearby areas.</li>
                        <li>Arrangement of Cultural programs especially for hostelers</li>
                        <li>Sports fest proposed especially for Hostelers- TT Room and Carrom are prepared in both the
                            hostels.</li>
                    </ul>
                </div>
            </div>
        </section>
        <section>
            <div class="patents_table mt-5">
                <table>
                    <thead>
                        <tr>
                            <th>Category</th>
                            <th>Type of Accommodation</th>
                            <th>Annual Fee ( Accommodation & Food) for Indian Student (INR)</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <p>A</p>
                            </td>
                            <td>
                                <p>3 sharing Non AC Room</p>
                            </td>
                            <td>
                                <p>70,000</p>
                            </td>
                        </tr>
                        <tr>
                            <td>B</td>
                            <td>3 sharing Non AC Room with attached toilet</td>
                            <td>80,000</td>
                        </tr>
                        <tr>
                            <td>C</td>
                            <td>3 sharing AC room</td>
                            <td>90,000</td>
                        </tr>
                        <tr>
                            <td>D</td>
                            <td>2 sharing with A/C & attached Toilet</td>
                            <td>1,00000</td>
                        </tr>
                        <tr>
                            <td>E</td>
                            <td>Single room with A/C & attached toilet</td>
                            <td>110,000</td>
                        </tr>

                    </tbody>
                </table>
                <h6 class="mt-3">Note-Students must bring their own Mattress, quilt, bedsheet and blanket when joining the hostel.
                </h6>
            </div>
        </section>
        
        <section>
            <div class="title mt-5">Hostel Gallery</div>
            <div class="row pb-5">
                <div class="col-md-4 mt-3">
                    <div class="gallery_img">
                        <a data-fancybox="" href="../../../assets/img/jigyasa/gallery1.jpg">
                        <img src="../../../assets/img/jigyasa/gallery1.jpg" width="100%" height="200px" alt="">
                        </a>
                    </div>
                </div>
                <div class="col-md-4 mt-3">
                    <div class="gallery_img">
                        <a data-fancybox="" href="../../../assets/img/jigyasa/gallery2.jpg">
                        <img src="../../../assets/img/jigyasa/gallery2.jpg" width="100%" height="200px" alt="">
                        </a>
                    </div>
                </div>
                <div class="col-md-4 mt-3">
                    <div class="gallery_img">
                        <a data-fancybox="" href="../../../assets/img/jigyasa/gallery3.jpg">
                        <img src="../../../assets/img/jigyasa/gallery3.jpg" width="100%" height="200px" alt="">
                        </a>
                    </div>
                </div>
                <div class="col-md-4 mt-3">
                    <div class="gallery_img">
                        <a data-fancybox="" href="../../../assets/img/jigyasa/gallery4.jpg">
                        <img src="../../../assets/img/jigyasa/gallery4.jpg" width="100%" height="200px" alt="">
                        </a>
                    </div>
                </div>
                <div class="col-md-4 mt-3">
                    <div class="gallery_img">
                        <a data-fancybox="" href="../../../assets/img/jigyasa/gallery5.jpeg">
                        <img src="../../../assets/img/jigyasa/gallery5.jpeg" width="100%" height="200px" alt="">
                        </a>
                    </div>
                </div>
                <div class="col-md-4 mt-3">
                    <div class="gallery_img">
                        <a data-fancybox="" href="../../../assets/img/jigyasa/gallery6.jpeg">
                        <img src="../../../assets/img/jigyasa/gallery6.jpeg" width="100%" height="200px" alt="">
                        </a>
                    </div>
                </div>
                <div class="col-md-4 mt-3">
                    <div class="gallery_img">
                        <a data-fancybox="" href="../../../assets/img/jigyasa/gallery7.jpeg">
                        <img src="../../../assets/img/jigyasa/gallery7.jpeg" width="100%" height="200px" alt="">
                        </a>
                    </div>
                </div>
                <div class="col-md-4 mt-3">
                    <div class="gallery_img">
                        <a data-fancybox="" href="../../../assets/img/jigyasa/gallery8.jpeg">
                        <img src="../../../assets/img/jigyasa/gallery8.jpeg" width="100%" height="200px" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>