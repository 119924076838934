<section>
    <div class="subMenu_content">
        <app-sub-menu></app-sub-menu>
    </div>
    <div class="main_div">
    <div class="title" data-aos="fade-up"
    data-aos-duration="3000">Explore Latest News</div>
    <div class="main_div sub_content">
        
    <div class="listSearchArea">
        <div class="row">
            <div class="col-md-8">
        <ul class="nav nav-tabs tabs-buttons" role="tablist">
          <li class="nav-item" role="presentation" id="boardOfGovernorsTab0" *ngIf="AssignCategory">
          <button id="click_btn" class="nav-link active"
              data-bs-toggle="tab" data-bs-target="#boardOfGovernors0" type="button"
              role="tab" aria-labelledby="board Of Governors Tab 0" aria-selected="true" (click)="filtercategory(0)">All</button>
      </li>
            <li class="nav-item" role="presentation" id="boardOfGovernorsTab0"
                *ngFor="let dt of AssignCategory;let i=index">
                <button id="click_btn" class="nav-link"
                    data-bs-toggle="tab" data-bs-target="#boardOfGovernors0" type="button"
                    role="tab" aria-labelledby="board Of Governors Tab 0" aria-selected="true"
                    (click)="filtercategory(dt.categoryId)">{{dt.categoryname}}</button>
            </li>
        </ul>
    </div>
    <div class="col-md-4">
        <div class="search_box">
            <input type="text" class="form-control filter" id="inputdata" placeholder="Filter"
            (keyup)="fn_ClientFilter($event)" />
        </div>
    </div>
    </div> 
</div>
    <div class="row">
        <div class="col-md-4 mt-4" *ngFor="let dt of NewsList">
            <div class="card" data-aos="zoom-in">
                <div class="row">
                    <div class="col-2">
                        <figure *ngFor="let img of dt.logofiles">
                        <div class="profile_img mt-3">
                            <img class="profile_img" src="{{img.value}}" alt="">
                        </div>
                    </figure>
                    </div>
                    <div class="col-10">
                        <div class="content-blog-card">
                            <ul class="ps-0 mb-0 list-unstyled cat">
                                <li><a href="{{dt.slug}}" target="_blank">{{dt.Title}}</a></li>
                            </ul>
                            <div class="card_title">{{dt.short}}
                            </div>
                            <!-- <ul class="blog-list d-flex justify-content-between mt-3">
                                <li>{{dt.StartDate}}</li>
                                <li>2 min read</li>
                            </ul> -->
                            
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6 justify-content-start align-items-center">
                        <label for="">{{dt.CreatedDate}}</label>
                    </div>
                    <div class="col-6 d-flex justify-content-end align-items-center">2 min read</div>
                </div>
                <div class="readMore mt-3"><a href="{{dt.slug}}" target="_blank">Read More <i class="fa fa-long-arrow-right"></i></a></div>
            </div>
        </div>
        <div *ngIf="NewsList.length==0">
            No Record Found!
        </div>
        <div class="showMore_content text-center mt-5">
            <label for="" class="count_label">Showing {{tindex}} records of {{this.totalrecord}}</label>
            <a class="showMore_btn" *ngIf="more" (click)="setcentrelistclick()">Show More</a>
        </div>
        <!-- <div class="col-md-4 mt-4">
            <div class="card" data-aos="zoom-in">
                <div class="row">
                    <div class="col-2">
                        <div class="profile_img mt-3"></div>
                    </div>
                    <div class="col-10">
                        <div class="content-blog-card">
                            <ul class="ps-0 mb-0 list-unstyled cat">
                                <li><a href="https://themes.envytheme.com/falar/category/career/">Career</a></li>
                            </ul>
                            <div class="card_title">Nominate
                                a teacher who made a difference through Faral’s Greatest High School Teacher
                                contest
                            </div>
                            <ul class="blog-list d-flex justify-content-between mt-3">
                                <li>December 18, 2023</li>
                                <li>2 min read</li>
                            </ul>
                            <span class="readMore">Read More <i class="fa fa-long-arrow-right"></i></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4 mt-4">
            <div class="card" data-aos="zoom-in">
                <div class="row">
                    <div class="col-2">
                        <div class="profile_img mt-3"></div>
                    </div>
                    <div class="col-10">
                        <div class="content-blog-card">
                            <ul class="ps-0 mb-0 list-unstyled cat">
                                <li><a href="https://themes.envytheme.com/falar/category/career/">Career</a></li>
                            </ul>
                            <div class="card_title">Nominate
                                a teacher who made a difference through Faral’s Greatest High School Teacher
                                contest
                            </div>
                            <ul class="blog-list d-flex justify-content-between mt-3">
                                <li>December 18, 2023</li>
                                <li>2 min read</li>
                            </ul>
                            <span class="readMore">Read More <i class="fa fa-long-arrow-right"></i></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4 mt-4">
            <div class="card" data-aos="zoom-in">
                <div class="row">
                    <div class="col-2">
                        <div class="profile_img mt-3"></div>
                    </div>
                    <div class="col-10">
                        <div class="content-blog-card">
                            <ul class="ps-0 mb-0 list-unstyled cat">
                                <li><a href="https://themes.envytheme.com/falar/category/career/">Career</a></li>
                            </ul>
                            <div class="card_title">Nominate
                                a teacher who made a difference through Faral’s Greatest High School Teacher
                                contest
                            </div>
                            <ul class="blog-list d-flex justify-content-between mt-3">
                                <li>December 18, 2023</li>
                                <li>2 min read</li>
                            </ul>
                            <span class="readMore">Read More <i class="fa fa-long-arrow-right"></i></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4 mt-4">
            <div class="card" data-aos="zoom-in">
                <div class="row">
                    <div class="col-2">
                        <div class="profile_img mt-3"></div>
                    </div>
                    <div class="col-10">
                        <div class="content-blog-card">
                            <ul class="ps-0 mb-0 list-unstyled cat">
                                <li><a href="https://themes.envytheme.com/falar/category/career/">Career</a></li>
                            </ul>
                            <div class="card_title">Nominate
                                a teacher who made a difference through Faral’s Greatest High School Teacher
                                contest
                            </div>
                            <ul class="blog-list d-flex justify-content-between mt-3">
                                <li>December 18, 2023</li>
                                <li>2 min read</li>
                            </ul>
                            <span class="readMore">Read More <i class="fa fa-long-arrow-right"></i></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4 mt-4">
            <div class="card" data-aos="zoom-in">
                <div class="row">
                    <div class="col-2">
                        <div class="profile_img mt-3"></div>
                    </div>
                    <div class="col-10">
                        <div class="content-blog-card">
                            <ul class="ps-0 mb-0 list-unstyled cat">
                                <li><a href="https://themes.envytheme.com/falar/category/career/">Career</a></li>
                            </ul>
                            <div class="card_title">Nominate
                                a teacher who made a difference through Faral’s Greatest High School Teacher
                                contest
                            </div>
                            <ul class="blog-list d-flex justify-content-between mt-3">
                                <li>December 18, 2023</li>
                                <li>2 min read</li>
                            </ul>
                            <span class="readMore">Read More <i class="fa fa-long-arrow-right"></i></span>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->

    </div>
</div>
</div>
</section>