<section>
    <div class="subMenu_content">
        <app-sub-menu></app-sub-menu>
    </div>
    <div class="sub_content">
        <div class="row">
            <div class="col-md-5">
                <img  src="../../../../assets/img/jigyasa/dh1.jpg" class="inner-auth-image img-responsive mt-2" style="    margin-top: 0;">
            </div>
            <div class="col-md-7">
                <div class="para">
                    <p>Nestled in the Doon Valley in the foothills of the Great Himalayas and flanked by two very
                        beautiful as well as mighty rivers the Ganges and the Yamuna on its eastern and western side,
                        Dehradun is one of the most ancient cities of India. Serving as the capital city of Uttaranchal,
                        Dehradun got its name by the union of two words 'Dera' and 'Dun' that means Camp in the Valley.
                        It is also stated that the place got its name from 'Guru Dronacharya' of Mahabharata, as the
                        city is also popularly known as the 'Adobe of Drona'.</p>
                    <p>The moment you step in the city the first thing that catches your eyes here is its picturesque
                        unbeatable beauty and the perfect amalgamation of the city and hilly culture. Located at an
                        altitude of 960m, Dehradun has earned accolades for being a perfect place to get respite from
                        the hustle-bustle and scorching heat of the plains below. Its close proximity to the other much
                        popular surrounding destinations such as Auli, Mussoorie, Rishikesh, Haridwar and Nainital also
                        adds to its charm.</p>
                </div>
            </div>
        </div>
        <div class="para">
            <p>This place possesses a strong connection with the Hindu mythological characters Rama and Laxmana
            </p>
            <p>who are believed to perform an atonement in Doon Valley. According to a popular legend 'Pandava brothers
                of Mahabharata' took a rest here while they were on the way to the top of the mountains.</p>
            <p>The great Mauryan Emperor Ashoka ruled the city during the 1st century BC. Even a rock edict of this
                ruler still exists just outside the town near Kalsi. Times passed and the rule of the city too passed
                from one hand to another that included Sikhs, Mughals, Gurkhas and finally to the British. You can
                easily observe that some of the parts of the city are still British in style in character. Since the
                British era ended, after independence, the city has drastically changed itself from a small, quiet town
                to a commercial and educational center that is also a bustling tourist destination from the world over.
            </p>
            <p>Owing to this extremely fast pace of development and its abundant natural resources, Dehradun is
                Favorited as a destination for industrialization by several big and developing private and government
                companies. It is home to several highly reputed and important national foundations such as the Indian
                Institute of Petroleum and Energy Studies, Wildlife Institute of India, Natural Gas Corporation, the
                Forest Research Institute, Survey of India, Instruments Research and Development (DRDO), Indian
                Institute of Remote Sensing and the Ordnance Factory Dehradun.</p>
            <p>Being the home to some of the very prestigious educational institutes such as the Doon school and the
                Indian Military Academy and some other popular boarding schools also makes Dehradun an important point
                of interest.</p>
        </div>
        <div class="row">
            <div class="col-md-5">
                <div class="para">
                    <p>The culture of the city is unique as people from different cultural backgrounds, communities and
                        religions dwell here with ease. This cultural diversity has also left its impression on the
                        cuisine, language and art of the city. The city has already made it to the list of the cities
                        growing at a very fast pace in terms of industrialization, mode of transportation, communication
                        and education in India. A clock tower that is situated in the centre of the city is a prominent
                        landmark of the Dehradun city.
                    </p>
                    <p>Dehradun is a trendy hilly tourist destination with its impeccable beauty and charismatic
                        sceneries would not let you unimpressed</p>
                </div>
            </div>
            <div class="col-md-7">
                <img src="../../../../assets/img/jigyasa/Dehradun3.jpg" style="    max-width: 700px; width:100%;" class="inner-auth-image-right img-responsive">
            </div>
        </div>
        <div class="para mt-2">
            <p>once you step down on its land. Whether you are a traveller, an adventure seeker, looking for romantic
                escapes or are just in search of a spiritual adobe, the city has a lot to offer to everyone. Along with
                other tourist spots, famous scientific museums and lush green sprawling parks, Dehradun is adorned with
                several famous historic buildings that have great architectural value and a number of temples that have
                spell binding beauty and at the same time are considered to be very auspicious. If you visit the city,
                keep a tourist map at hand, it would prove to be a great boon while travelling within the city. Dehradun
                has a subtropical climate with cool winters, hot summers and heavy monsoons. The city also receives a
                good amount of snowfall during winters which is a major attraction for tourists.</p>
        </div>
    </div>
</section>