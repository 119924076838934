<section>
    <div class="title" data-aos="fade-up"
    data-aos-duration="3000">Explore Latest News test</div>
    <div>
        <div >
          <ul class="list-group list-group-horizontal-md" >
            <li class="list-group-item" *ngIf="newscategory" (click)="filtercategory(0)">All</li>
            <li class="list-group-item" *ngFor="let dt of newscategory" value="{{dt.categoryId}}">          
             <span (click)="filtercategory(dt.categoryId)">{{dt.categoryname}}</span>
            </li>
          </ul>
        </div>
      </div>
    <div class="row">
        <div class="col-md-4" *ngFor="let dt of news">
            <div class="card" data-aos="zoom-in">
                <div class="row">
                    <div class="col-2">
                        <div class="profile_img mt-3"></div>
                    </div>
                   
                    <div class="col-10" >
                        <div class="content-blog-card">
                            <ul class="ps-0 mb-0 list-unstyled cat">
                                <li><a href="{{dt.slug}}" target="_blank">{{dt.Title}}</a></li>
                            </ul>
                            <div class="card_title">{{dt.Short}}
                            </div>
                            <ul class="blog-list d-flex justify-content-between mt-3">
                                <li>{{dt.FromDate}}</li>
                                <li>2 min read</li>
                            </ul>
                            <span class="readMore">Read More <i class="fa fa-long-arrow-right"></i></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>     

    </div>
   
    <div class="d-flex justify-content-end">
        <button class="btn view_sm_btn mt-4" routerLink="/news">View All</button>
      </div>
</section>