<div class="section">
    <header class="header" id="scroll_header">
        <div class="menu_list">
            <ul>
                <li><a href="alumni#NotableAlumni"> Notable Alumni </a></li>
                <!-- <li><a href="alumni#Nest">Nest</a></li>
                <li><a href="alumni#NewsEvents">News & Events</a></li> -->
              </ul>
        </div>
    </header>
    <div class="subMenu_content">
     <app-sub-menu></app-sub-menu>
    </div>
    <div class="sub_content">
        <section id="NotableAlumni">
            <div class="overview">
              <app-notable-alumni></app-notable-alumni>
            </div>
        </section>
    <section id="Nest" class="py3">
        <!-- <div class="title">Nest - Alumni Support For Life</div>
        <div class="para">
            <p>JIGYASA is more than just an institution where students spend a few years earning a degree. It’s a place where lifelong connections are made. Connections that transform into helpful professional bonds, or simply, friendships that stand the test of time</p>
            <p>We’ve ensured our alumni always feel safe and welcomed at JIGYASA. Our dedicated team helps manage more than 10 Alumni Regional Chapters that meet at regular intervals for various events. Today, we support over 22,000 JIGYASA alumni spread across the world, to become the focal point where they can converge and interact.</p>
            <p>And to ensure our alumni network is safeguarded from industry upheavals in a post-Covid world, we are proud to have launched NEST – a unique alumni connect platform, a resource base the alumni can always depend on to seek advice and receive support as they progress in their careers.</p>
        </div> -->
        <!-- <div class="d-flex justify-content-end">
            <button class="btn view_sm_btn mt-4">Alumni Connect</button>
          </div> -->

          <!-- <div class="faq_content py-5">
            <div class="accordion" id="accordionExample1">
                <div class="accordion-item mt-3">
                  <div>
                  <h2 class="accordion-header" [attr.id]="'headingOne'">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapseOne'" aria-expanded="true" [attr.aria-controls]="'collapseOne'">
                        Skills
                    </button>
                  </h2>
                  <div [attr.id]="'collapseOne'" class="accordion-collapse collapse" [attr.aria-labelledby]="'headingOne'" data-bs-parent="#accordionExample1">
                    <div class="accordion-body">
                      <div class="para">
                        <p>We have created avenues for our alumni to upskill by enrolling for various courses in <b>Deep Tech, the Metaverse, Digital Transformation, and Leadership</b> at four locations in India – Delhi, Mumbai, Bengaluru, and Dehradun.</p>
                       <p>To meet the needs of the industry, companies need highly skilled ‘Techno-Managers’ who work at the intersection of business and technology to help fuel business growth and become future-ready.</p>
                    <p>Edology, in collaboration with KPMG, is offering an online PG program in Technology Management that is hybrid, industry-oriented and focused on career outcomes. Applicable for both tech and non-tech working professionals, our learners can <b>transition into managerial roles within the tech industry</b> as recommended by industry experts.</p>
                    </div>
                    </div>
                  </div>
                </div>
                </div>

                <div class="accordion-item mt-3">
                    <div>
                    <h2 class="accordion-header" [attr.id]="'headingOne1'">
                      <button class="accordion-button" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapseOne1'" aria-expanded="true" [attr.aria-controls]="'collapseOne1'">
                        Careers
                      </button>
                    </h2>
                    <div [attr.id]="'collapseOne1'" class="accordion-collapse collapse" [attr.aria-labelledby]="'headingOne1'" data-bs-parent="#accordionExample1">
                      <div class="accordion-body">
                        <div class="para">
                          <p>We are the first university in the world to offer <b>lifetime placements/career opportunities to</b> our alumni. We are setting up a special ninja team that will assist our alumni in their job transitions. There shall be numerous networking opportunities and guidance on personal branding that will help them make a mark as competent professionals.</p>
                      </div>
                      </div>
                    </div>
                  </div>
                  </div>

                  <div class="accordion-item mt-3">
                    <div>
                    <h2 class="accordion-header" [attr.id]="'headingOne2'">
                      <button class="accordion-button" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapseOne2'" aria-expanded="true" [attr.aria-controls]="'collapseOne2'">
                        Incubation
                      </button>
                    </h2>
                    <div [attr.id]="'collapseOne2'" class="accordion-collapse collapse" [attr.aria-labelledby]="'headingOne2'" data-bs-parent="#accordionExample1">
                      <div class="accordion-body">
                        <div class="para">
                          <p>For the alums who are keen to make a mark as entrepreneurs, our start-up incubator, Runway, will run 5 incubation boot camps, especially for them, and have a yearly incubation cohort for up to 40 alumni startups with assured funding for at least 5 start-ups.</p>
                         <p>Please contact Mr. Mohit Nagpal, Incubation Senior Manager, Runway, for more details at +91-8077660434 or mail at contact@runwayincubator.com</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
                  <div class="accordion-item mt-3">
                    <div>
                    <h2 class="accordion-header" [attr.id]="'headingOne3'">
                      <button class="accordion-button" type="button" data-bs-toggle="collapse" [attr.data-bs-target]="'#collapseOne3'" aria-expanded="true" [attr.aria-controls]="'collapseOne3'">
                        Further Education
                      </button>
                    </h2>
                    <div [attr.id]="'collapseOne3'" class="accordion-collapse collapse" [attr.aria-labelledby]="'headingOne3'" data-bs-parent="#accordionExample1">
                      <div class="accordion-body">
                        <div class="para">
                          <p>For the alumni interested in higher studies, we offer 50% scholarship for their Masters, preferential allotments for sponsored PhDs, and up to 35% special scholarships at our partner universities.
                          </p>
                         <p>We look forward to your involvement as NEST progresses. Please do not hesitate to reach out to us if you have questions, concerns, or ideas you would like to share. To know about NEST, write to us at alumni@JIGYASA.ac.in
                         </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  </div>
              </div>
          </div> -->
    </section>
    <section id="NewsEvents">
        
    </section>
    </div>
</div>