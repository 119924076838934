<section>
    <div class="main_div">
    <div class="title" data-aos="fade-up"
    data-aos-duration="3000">Event Details</div>
    <div class="box1">
        <div class="sub_title">{{eventdetails?.Title}}</div>
        <div class="sub_title">{{eventdetails?.dateCreated}}</div>
        <div class="innerBlogCOntent d-flex justify-content-center align-items-center">
              <!-- <div class="blog_img_content mt-4 d-flex justify-content-center align-items-center">
                <img src={{dt.url}} width="50%" alt={{eventdetails?.files[0]?.filename}}>
              </div> -->
              <div class="slide_img_content col-8 offset-1">
                <ng-image-slider #nav
                  [images]="imageObject"
                  [imagePopup]="false"
                  [infinite]="false"
                  [autoSlide]="1"
                  [imageSize]="{width: '100%', height: 500}"
                  slideImage="1"
                  (imageClick)="imageClickHandler($event)"></ng-image-slider>
              </div>
        </div>
        <div class="para mt-4">
          <p [innerHTML]="eventdetails?.long1"></p>
        </div>
      </div>
</div>
  </section>
