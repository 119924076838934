import { HttpClient } from '@angular/common/http';
import { Component,OnInit} from '@angular/core';
import { CommonService } from '../service/common.service';
import { environment } from 'src/environments/environment';
import { json } from 'express';
import {Router, ActivatedRoute, Params} from '@angular/router';
import { ApicallService } from 'src/app/services/apicall.service';
import { ProjectSeoService } from 'src/app/services/projectseo.service';
@Component({
  selector: 'app-policiesandnotices',
  templateUrl: './policiesandnotices.component.html',
  styleUrls: ['./policiesandnotices.component.css']
})
export class PoliciesandnoticesComponent {
  project_id = environment.projectid
  noticecategory: any;
  noticeList: any = [];
  getOuerLeaderShip: any;
  filterdataOurLeaderShip: any;
  filterCatogryList: any;
  careersPlacementData: any;
  careerPlacementList: any=[];
  filterPlacementList:any=[]
  classParam:any;
  alldata: any;
  Announcementdata: any;
  pgdata: any;

  constructor(private _service: CommonService,private http: HttpClient,
    private activatedRoute: ActivatedRoute,
    private _ApicallService: ApicallService,
    private projectService: ProjectSeoService,
  ){}

  ngOnInit(): void {
    this.getData();
  }

  getseo() {
    let tbody = {
      slug:'policiesandnotices',
      };
    this._ApicallService.getGetseo(tbody).subscribe((data: any) => {
      this.alldata=data?.data;
        this.pgdata=data?.data?.pagedata;  
        this.projectService.sendMessageblog(data?.data?.Blog);
        this.projectService.sendMessagenews(data?.data?.News)
        this.projectService.setFacultysubject(data?.data?.Faculty);
        this.projectService.setPlacementsubject(data?.data?.Placement);      
        this.projectService.setprogramssubject(data?.data?.Programs);//
        this.projectService.sendMessageseo(data?.data?.Testimonial);
        this.projectService.sendMessageFaqs(data?.data?.FAQs);
        this.projectService.setmeta(data?.data);
        this.projectService.sendMessagebread(data?.data.breadcrumb)
        });
  }

  getData() {
    let obj = {
      "Project_Id": this.project_id,
      "Type": "Policies and Notices"
    }
    this._service.getContentbytype(obj).subscribe(
      res => {
        this.noticecategory = JSON.parse(res.data[0].AssignCategory)
        this.noticeList = JSON.parse(res.data[0].contentData)
        this.getseo();
      }
    )
  }
  // downloadPDF(data:any) {   
  //   this.http.get(data.value, { responseType: 'blob' }).subscribe(blob => {
  //     const link = document.createElement('a');
  //     link.href = URL.createObjectURL(blob);      
  //     link.download = data.FileName;
  //     link.click();
  //   });
  //     // this.downloadpdf=true;
  //     // this.pdfurl=this.srcurl;
  //     // this.pdflbl=this.PdfName;
  // }
}
