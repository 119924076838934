import { Component,OnInit } from '@angular/core';
import { ProjectSeoService } from 'src/app/services/projectseo.service';
import { environment } from 'src/environments/environment';
import { ApicallService } from 'src/app/services/apicall.service';
@Component({
  selector: 'app-teacher-training',
  templateUrl: './teacher-training.component.html',
  styleUrls: ['./teacher-training.component.css']
})
export class TeacherTrainingComponent implements OnInit {
  headerTitle="Teacher Training Programme";


  constructor(
    private projectService: ProjectSeoService,
    private apiService: ApicallService
  ) {

  }
  ngOnInit(): void {
    this.getseo();
  }
  getseo() {
    let tbody = {
      slug: 'teacher-training-programme',
      Projectid: environment.projectid,
    };
    this.apiService.getGetseo(tbody).subscribe((data: any) => {
     this.projectService.sendMessagebread(data?.data?.breadcrumb);
     this.projectService.sendMessageblog(data?.data?.blog);
     this.projectService.sendMessageseo(data?.data?.testimony);
     this.projectService.sendMessageFaqs(data?.data?.faq);
     this.projectService.setmeta(data.data);
     console.log("data?.data?.faq",data?.data?.faq)
    });
  }
}
