<section>
    <div class="sub_content">
        <div class="main_div">
            <div class="title" data-aos="fade-up" data-aos-duration="3000">Alumni Coordinators</div>
        </div>
      <div class="row"> 
        <div class="col-md-4" *ngFor="let a of alumnidata;let i=index">
          <div class="common-card" >
            <figure>
              <div class="gallery_img" *ngFor="let dt of a.logofiles">
                <a data-fancybox="" href="{{a.url}}" *ngIf="a.slug">
                  <img src="{{dt.value}}" alt="{{a.Title}}"/>
                  <!-- <iframe width="335" height="188" class="video_list" [src]="dt.url" frameborder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
                </a>
                <div *ngIf="!a.slug">
                  <img src="{{dt.value}}" alt="{{a.Title}}"/>
                </div>
              </div>
             
              <!-- <iframe width="335" height="188" class="video_list" [src]="a.url" frameborder="0"
                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
            </figure>
            <div class="common-card-body">
              <div class="common-card-body-heads">
                <h3 class="mt-2">{{a.Title}}</h3>
                <hr>
                <!-- <span>{{data.Short}}</span> -->
              </div>
              <!-- <p [innerHTML]="data.long1"></p> -->
              <p [innerHTML]="a.short"></p>
            </div>
          </div>
        </div>
        <!-- <div class="col-md-4">
              <div class="common-card">
                  <figure>
                    <iframe width="335" height="188" src="https://www.youtube.com/embed/VENtoJ6VzA0"
                      title="Success Story: Shreya Singh, UPES, Dehradun, B. Tech. Alumna | I Am Tomorrow" frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  </figure>
                  <div class="common-card-body">
                    <div class="common-card-body-heads">
                      <h3 class="mt-2">Sharya Shing</h3>
                      <hr>
                     
                    </div>
                    <p>UPES B.Tech. Alumna<br>Lead Business Development, SLB (Schlumberger Limited)</p>
                  </div>
                </div>
          </div>
          <div class="col-md-4">
              <div class="common-card">
                  <figure>
                    <iframe width="335" height="188" src="https://www.youtube.com/embed/VENtoJ6VzA0"
                      title="Success Story: Shreya Singh, UPES, Dehradun, B. Tech. Alumna | I Am Tomorrow" frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                  </figure>
                  <div class="common-card-body">
                    <div class="common-card-body-heads">
                      <h3 class="mt-2">Sharya Shing</h3>
                      <hr>
                    
                    </div>
                    
                    <p>UPES B.Tech. Alumna<br>Lead Business Development, SLB (Schlumberger Limited)</p>
                  </div>
                </div>
          </div> -->
      </div>
    </div>
  </section>