<section>
    <div class="subMenu_content">
        <app-sub-menu></app-sub-menu>
    </div>
    <div class="main_div">
     
      <div class="listSearchArea">
     <div class="row">
      <div class="col-md-8">
        <ul class="nav nav-tabs tabs-buttons" role="tablist">
          <li class="nav-item" role="presentation" id="boardOfGovernorsTab0">
          <button id="click_btn" class="nav-link active"
              data-bs-toggle="tab" data-bs-target="#boardOfGovernors0" type="button"
              role="tab" aria-labelledby="board Of Governors Tab 0" aria-selected="true" (click)="filtercategory(0)">All</button>
      </li>
            <li class="nav-item" role="presentation" id="boardOfGovernorsTab0"
                *ngFor="let dt of getAssignCategory;let i=index">
                <button id="click_btn" class="nav-link"
                    data-bs-toggle="tab" data-bs-target="#boardOfGovernors0" type="button"
                    role="tab" aria-labelledby="board Of Governors Tab 0" aria-selected="true"
                    (click)="filtercategory(dt.categoryId)">{{dt.categoryname}}</button>
            </li>
        </ul>
      </div>
      <div class="col-md-4">
        <div class="search_box">
          <input type="text" class="search_input form-control" placeholder="Search..."  (keyup)="fn_ClientFilter($event)">
        </div>
      </div>
      </div>
    </div>
        <div class="row">
        <div class="col-md-4 mt-4 d-flex justify-content-center" *ngFor="let dt of getAllcontentData; let i = index">
          <div class="common-card">
            <figure *ngFor="let a of dt.OtherFiles"> <img class=" lazyloaded"
                src="{{a.value}}"
                data-src="{{a.value}}"
                alt="{{a.key}}" title="{{a.key}}" width="485" height="400"> </figure>
            <div class="common-card-body">
              <div class="common-card-body-heads">
                <h3 class="mt-2">{{dt.Title}}</h3>
              </div>
              <p class="line-clamp" [innerHTML]="dt.short"></p>
              <div class="readmore d-flex justify-content-end align-items-center">
                <button class="btn" data-bs-toggle="modal" data-bs-target="#exampleModal1" (click)="showAllDetails(dt)"><i class="fa fa-long-arrow-right readmore"></i></button>
               </div>
             </div>
          </div>
        </div>
    </div>
    <div class="showMore_content text-center mt-5">
      <label for="" class="count_label">Showing {{tindex}} records of {{this.totalrecord}}</label>
      <button class="btn view_sm_btn mx-2" *ngIf="more" (click)="setcentrelistclick()">Show More</button>
  </div>
    </div>

    <div class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel"><span class="title">Stories of excellence</span></h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-5">
            <figure class="figure" *ngFor="let a of imgFile"> 
              <img class=" lazyloaded"
                    src="{{a.value}}"
                    data-src="../../../assets/img/jigyasa/blank-removebg-preview.png"
                    alt="{{a.key}}" title="{{a.key}}" width="485" height="400"> 
                   </figure>
                  </div>
                  <div class="col-md-7">
                   <div class="common-card-body-heads">
                    <h3 class="mt-2">{{getAllDetails.Title}}</h3>
                    <span>{{getAllDetails.short}}</span>
                  </div>
                 <p [innerHTML]="getAllDetails.Long1"></p>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
</section>