import { Component } from '@angular/core';

@Component({
  selector: 'app-franchise-thankyou',
  templateUrl: './franchise-thankyou.component.html',
  styleUrls: ['./franchise-thankyou.component.css']
})
export class FranchiseThankyouComponent {

}
