import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription, of, switchMap, tap } from 'rxjs';
import { ApicallService } from 'src/app/services/apicall.service';
import { HomeSeoService } from 'src/app/services/homeseo.service';
import { ProjectSeoService } from 'src/app/services/projectseo.service';
import { environment } from 'src/environments/environment';
import * as AOS from 'aos';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {
  @Input() newsdata:any=[];
  project$: Observable<any> | undefined;
  subscriptionnav!: Subscription;
  alldata: any;
  Announcementdata: any;
  pgdata: any;
  news:any=[];
  newscategory:any=[];
  
  constructor(
    private route: ActivatedRoute,
    private seoService: HomeSeoService,
    private projectService: ProjectSeoService,
    private apiService: ApicallService,
    private _ApicallService: ApicallService,
  ) {
    
  }
 ngOnInit(): void {
  AOS.init();
  if(this.newsdata){
    this.news=this.newsdata[0].data;
    this.newscategory=this.newsdata[0].AssignCategory;
  }   
    this.subscriptionnav = this.projectService
      .onnewsMessage()
      .subscribe((message) => {
        if (message) {
          this.newsdata = message.text;
          if(this.newsdata){
            this.news=this.newsdata[0].data;
            this.newscategory=this.newsdata[0].AssignCategory;
          }  
        }
      });
  }

  filtercategory(id:number){
    if(id!=0){
      this.news=this.newsdata[0].data.filter((dt:any)=>{
        return dt.category.includes(id)
      }).map((obj:any)=>{
        return obj;
      })
    }
    else{
      this.news=this.newsdata[0].data;
    }
    
  }
}
