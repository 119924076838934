import { Component } from '@angular/core';

@Component({
  selector: 'app-transport-facilities',
  templateUrl: './transport-facilities.component.html',
  styleUrls: ['./transport-facilities.component.css']
})
export class TransportFacilitiesComponent {

}
