<section>
  <div>
      <div>
             <div class="section">
             
              <div class="subMenu_content">
                  <app-sub-menu></app-sub-menu>
              </div>
              <div class="sub_content pb-5">
                  <section id="OverviewOfRAndDCell">
                      <div class="overview">
                          <div class="title">Programmes</div>
                          <div class="para">
                            <div class="row">
                              <div class="col-md-6" data-aos="fade-right">
                                <div class="img_content">
                                  <div class="row">
                                    <div class="col-6">
                       
                                        <img src="../../../assets/img/jigyasa/studentImg.jpg" height="230px" width="100%" alt="">
                                     
                                    </div>
                                    <div class="col-6">
                                      <img src="../../../assets/img/jigyasa/program1.JPG" height="230px" width="100%"
                                      alt="">
                                     </div>
                                  </div>
                      
                                </div>
                              </div>
                              <div class="col-md-6" data-aos="fade-left">
                                <div class="title" data-aos="fade-up" data-aos-duration="3000">Find Your Program</div>
                                <div class="para">
                                  <p>Welcome to our university is website! Use the search bar below to explore our diverse range of programs
                                    and discover the perfect academic path that suits your interests and career goals.
                                  </p>
                                </div>
                                <div class="form-group">
                                  <img src="../../../assets/img/jigyasa/icon/searchIcon.svg" width="20px" height="20px" alt="">
                                  <input type="text" class="form-control" [ngModel]="searchtext" (ngModelChange)="getdata($event)"
                                    placeholder="Find your program like “B.Sc">
                      
                                </div>
                                <ul class="list-group list-group-horizontal-lg">
                                  <li class="list-group-item" *ngFor="let a of programs" value="{{a.course_id}}"><a
                                      href="{{a.slug}}">{{a.name}}</a></li>
                                </ul>
                      
                              </div>
                            </div>

                          </div>
                      </div>
                  </section>
              </div>
          </div>

      </div>
  </div>
</section>
