<section>
    <div>
        <div>
               <div class="section">
               
                <div class="subMenu_content">
                    <app-sub-menu></app-sub-menu>
                </div>
                <div class="sub_content">
                    <section id="OverviewOfRAndDCell">
                        <div class="overview">
                            <div class="title">Why Jigyasa University?</div>
                            <div class="para">
                                <ul>
                                    <li>Faculty from premiere institutions</li>
                                    <li>Strong University – Industry linkage</li>
                                    <li>Full semester internship program in all programs</li>
                                    <li>Courses on Experiential Learning in all programs</li>
                                    <li>Soft skill training for better placement opportunities</li>
                                    <li>Placement opportunity for all eligible students</li>
                                    <li>Flexible & Transparent Academic System</li>
                                    <li>Student Centric Education</li>
                                    <li>More hands-on experience</li>
                                    <li>Electives within and across the discipline</li>
                                    <li>Industry tailored curriculum</li>
                                    <li>Curriculum based on NEP</li>
                                    <li>State-of-the art infrastructure</li>
                                    <li>Research as a part of curriculum</li>
                                    <li>Platforms for developing entrepreneur skills</li>
                                    <li>Encouragement for co-curricular and extra-curricular activities.</li>
                                </ul>
                            </div>
                        </div>
                    </section>
                </div>
            </div>

        </div>
    </div>
</section>