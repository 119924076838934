import { Component, OnInit } from '@angular/core';
import { ApicallService } from 'src/app/services/apicall.service';
import { ProjectSeoService } from 'src/app/services/projectseo.service';
import { NgxSpinnerService } from 'ngx-spinner';
import * as AOS from 'aos';

@Component({
  selector: 'app-flexibility',
  templateUrl: './flexibility.component.html',
  styleUrls: ['./flexibility.component.css']
})
export class FlexibilityComponent {
  alldata:any=[];
  pagedata:any=[];
  categorystring:string='Elective courses';
  constructor(
    private _ApicallService: ApicallService,private spinnerservice:NgxSpinnerService,    
    private projectService: ProjectSeoService,){
  }
  ngOnInit():void{
    AOS.init();
    this.getseo();
  }

  getseo() {
    let tbody = {
      slug:'flexibility',
      };
    this._ApicallService.getGetseo(tbody).subscribe((data: any) => {
      this.alldata=data?.data;
        this.pagedata=data?.data?.pagedata;  
        this.projectService.sendMessageblog(data?.data?.Blog);
        this.projectService.sendMessagenews(data?.data?.News)
        this.projectService.setFacultysubject(data?.data?.Faculty);
        this.projectService.setPlacementsubject(data?.data?.Placement);      
        this.projectService.setprogramssubject(data?.data?.Programs);//
        this.projectService.sendMessageseo(data?.data?.Testimonial);
        this.projectService.sendMessageFaqs(data?.data?.FAQs);
        this.projectService.setmeta(data?.data);
        this.projectService.sendMessagebread(data?.data.breadcrumb)
        });
  }
  filtercategory(str:string){
    this.categorystring=str;
  }
}
