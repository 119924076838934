import { Component } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { ApicallService } from 'src/app/services/apicall.service';
import { ProjectSeoService } from 'src/app/services/projectseo.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {
  displayMenu:boolean = false
  subMenu11:boolean = true
  subMenu1:boolean =false
  subMenu2:boolean =false
  subMenu3:boolean =false
  subMenu4:boolean =false
  subMenu5:boolean =false
  subMenu6:boolean =false
  subMenu7:boolean =false
  subMenu8:boolean =false
  subMenu9:boolean =false

  academiclist:any=[];
  alldata:any=[];
  sublist:any=[];  subMenuType1:boolean = false
  subMenuType2:boolean = false
  subMenuType3:boolean = false
  subMenuType4:boolean = false
  subMenuType5:boolean = false
  subMenuType6:boolean = false
  subMenuType7:boolean = false
  subMenuType02:boolean = false
  subMenuType03:boolean = false
  subMenuType04:boolean = false
  subMenuType05:boolean = false
  acc_item0:boolean = true;
  acc_item1:boolean = true;
  acc_item2:boolean = true;
  acc_item3:boolean = true;
  acc_item4:boolean = true;
  acc_item5:boolean = true;
  acc_item6:boolean = true;
  acc_item7:boolean = true;
  acc_item8:boolean = true;
  back_btn:boolean = false;
constructor(private _router:Router,private ActivatedRoute:ActivatedRoute,private dataservice:ProjectSeoService,private apiservice:ApicallService){}

  ngOnInit(){
  
  }
  gotopage(url:string){
    this._router.navigateByUrl(url);
  }
//not use
  // Getcourse(){
  //   this.academiclist=[];
  //   let jdata={
  //     "projectId":1
  //   }
  //   this.apiservice.Getcourse(jdata).subscribe({
  //     next:(resp:any)=>{
  //       console.log(resp);
  //       if(resp){
  //         this.alldata=resp.data;
  //         this.getdata();
  //       }
  //     },
  //     error:(error)=>{
  //       console.log(error);
  //     }
  //   });
  // }
  // getdata(){
  //   this.academiclist=this.alldata.filter((dt:any)=>{
  //     return dt.pcourse_id==0//data.course_id
  //   }).map((obj:any)=>{
  //     return obj;
  //   })
   
  // }
  // selecsubMenu(data:any){
    
  //   this.sublist=[];
  //   if(data){
  //       this.sublist=this.alldata.filter((dt:any)=>{
  //         return dt.pcourse_id==0
  //       }).map((obj:any)=>{
  //         return obj;
  //       })
  //   }
  // }
gotPage(){
  document.getElementById("trigger-overlay")?.click();
}

selectTab(val:any){
this.displayMenu = true
}

selecMenu(val:any){
if(val == 1){
  this.subMenu1 =true
  this.subMenu2 =false
  this.subMenu3 =false
  this.subMenu4 =false
  this.subMenu5 =false
  this.subMenu6 =false
  this.subMenu7 =false
  this.subMenu8 =false
  this.subMenu9 =false 
  this.subMenu11 = true 
}
if(val == 2){
  this.subMenu1 =false
  this.subMenu2 =true
  this.subMenu3 =false
  this.subMenu4 =false
  this.subMenu5 =false
  this.subMenu6 =false
  this.subMenu7 =false
  this.subMenu8 =false
  this.subMenu9 =false 
  this.subMenu11 = false 
}
if(val == 3){
  this.subMenu1 =false
  this.subMenu2 =false
  this.subMenu3 =true
  this.subMenu4 =false
  this.subMenu5 =false
  this.subMenu6 =false
  this.subMenu7 =false
  this.subMenu8 =false
  this.subMenu9 =false  
  this.subMenu11 = false
}
if(val == 4){
  this.subMenu1 =false
  this.subMenu2 =false
  this.subMenu3 =false
  this.subMenu4 =true
  this.subMenu5 =false
  this.subMenu6 =false
  this.subMenu7 =false
  this.subMenu8 =false
  this.subMenu9 =false  
  this.subMenu11 = false
}
if(val == 5){
  this.subMenu1 =false
  this.subMenu2 =false
  this.subMenu3 =false
  this.subMenu4 =false
  this.subMenu5 =true
  this.subMenu6 =false
  this.subMenu7 =false
  this.subMenu8 =false
  this.subMenu9 =false 
  this.subMenu11 = false 
}
if(val == 6){
  this.subMenu1 =false
  this.subMenu2 =false
  this.subMenu3 =false
  this.subMenu4 =false
  this.subMenu5 =false
  this.subMenu6 =true
  this.subMenu7 =false
  this.subMenu8 =false
  this.subMenu9 =false 
  this.subMenu11 = false 
}
if(val == 7){
  this.subMenu1 =false
  this.subMenu2 =false
  this.subMenu3 =false
  this.subMenu4 =false
  this.subMenu5 =false
  this.subMenu6 =false
  this.subMenu7 =true
  this.subMenu8 =false
  this.subMenu9 =false 
  this.subMenu11 = false 
}
if(val == 8){
  this.subMenu1 =false
  this.subMenu2 =false
  this.subMenu3 =false
  this.subMenu4 =false
  this.subMenu5 =false
  this.subMenu6 =false
  this.subMenu7 =false
  this.subMenu8 =true
  this.subMenu9 =false 
  this.subMenu11 = false 
}
if(val == 9){
  this.subMenu1 =false
  this.subMenu2 =false
  this.subMenu3 =false
  this.subMenu4 =false
  this.subMenu5 =false
  this.subMenu6 =false
  this.subMenu7 =false
  this.subMenu8 =false
  this.subMenu9 =true 
  this.subMenu11 = false 
}
}

selecSubMenu(val:any){
if(val == 1){
  this.subMenuType1 = true
  this.subMenuType2 = false
  this.subMenuType3 = false
  this.subMenuType4 = false
  this.subMenuType5 = false
}
if(val == 2){
  this.subMenuType1 = false
  this.subMenuType2 = true
  this.subMenuType3 = false
  this.subMenuType4 = false
  this.subMenuType5 = false
}
if(val == 3){
  this.subMenuType1 = false
  this.subMenuType2 = false
  this.subMenuType3 = true
  this.subMenuType4 = false
  this.subMenuType5 = false
}
if(val == 4){
  this.subMenuType1 = false
  this.subMenuType2 = false
  this.subMenuType3 = false
  this.subMenuType4 = true
  this.subMenuType5 = false
}
if(val == 5){
  this.subMenuType1 = false
  this.subMenuType2 = false
  this.subMenuType3 = false
  this.subMenuType4 = false
  this.subMenuType5 = true
}
if(val == 0){
  this.subMenuType1 = false
  this.subMenuType2 = false
  this.subMenuType3 = false
  this.subMenuType4 = false
  this.subMenuType5 = false
}
// if(val == 6){
//   this.subMenuType1 = false
//   this.subMenuType2 = false
//   this.subMenuType3 = false
//   this.subMenuType4 = false
//   this.subMenuType5 = false
//   this.subMenuType6 = true
//   this.subMenuType7 = false
// }
// if(val == 7){
//   this.subMenuType1 = false
//   this.subMenuType2 = false
//   this.subMenuType3 = false
//   this.subMenuType4 = false
//   this.subMenuType5 = false
//   this.subMenuType6 = false
//   this.subMenuType7 = true
// }
}
selecSubMenu1(val:any){
  if(val == 1){
    this.subMenuType02 = true;
    this.subMenuType3 = false
  } 
  if(val == 2){
    this.subMenuType02 = false;
    this.subMenuType03 = true;
    this.subMenuType3 = false;
  }
  if(val == 3){
    this.subMenuType02 = false;
    this.subMenuType03 = false;
    this.subMenuType3 = false;
    this.subMenuType04 = true;
    this.subMenuType02 = false
    this.subMenuType03 = false
    this.subMenuType05 = false
  } 
  if(val == 4){
    this.subMenuType02 = false;
    this.subMenuType03 = false;
    this.subMenuType3 = false;
    this.subMenuType04 = false;
    this.subMenuType05 = true;
  } 
  if(val == 0){
    this.subMenuType02 = false;
    this.subMenuType03 = false;
    this.subMenuType3 = false;
    this.subMenuType04 = false;
    this.subMenuType05 = false;
  } 
}
// leaveMouse(){

// }

// dynamicroute(str:string){
//   this.dataservice.setdynamicroute(str);
// }

closeAccordian(val:any){
  this.back_btn = true
if(val == 0){
  this.acc_item0 = true;
  this.acc_item1 = false;
  this.acc_item2 = false;
  this.acc_item3 = false;
  this.acc_item4 = false;
  this.acc_item5 = false;
}
if(val == 1){
  this.acc_item0 = false;
  this.acc_item1 = true;
  this.acc_item2 = false;
  this.acc_item3 = false;
  this.acc_item4 = false;
  this.acc_item5 = false;
}
if(val == 2){
  this.acc_item0 = false;
  this.acc_item1 = false;
  this.acc_item2 = true;
  this.acc_item3 = false;
  this.acc_item4 = false;
  this.acc_item5 = false;
}
if(val == 3){
  this.acc_item0 = false;
  this.acc_item1 = false;
  this.acc_item2 = false;
  this.acc_item3 = true;
  this.acc_item4 = false;
  this.acc_item5 = false;
}
if(val == 4){
  this.acc_item0 = false;
  this.acc_item1 = false;
  this.acc_item2 = false;
  this.acc_item3 = false;
  this.acc_item4 = true;
  this.acc_item5 = false;
}
if(val == 5){
  this.acc_item0 = false;
  this.acc_item1 = false;
  this.acc_item2 = false;
  this.acc_item3 = false;
  this.acc_item4 = false;
  this.acc_item5 = true;
}
}

goto_back(){
  // this.back_btn = false
  this.acc_item0 = true;
  this.acc_item1 = true;
  this.acc_item2 = true;
  this.acc_item3 = true;
  this.acc_item4 = true;
  this.acc_item5 = true;
}
}
