<section>
    <div class="subMenu_content">
        <app-sub-menu></app-sub-menu>
    </div>
    <div class="sub_content">
        <div class="title">Terms and Conditions</div>
        <div class="main_content_para my-4">
          <h5>Admission and Enrollment</h5>
            <div class="para">
                <ol>
                    <li><p> Candidates who satisfy the requisite qualifications and other criteria prescribed in curriculum of the course(s) or such qualifications recognized as equivalent to either of the requisite qualifications prescribed by the University, shall be considered for admission to the concerned course(s). The University may decide to prescribe single or multiple criterion for admission to various courses including qualifying marks, entrance test, interview, counseling, direct admission or any other basis as per the specific requirements of the particular course(s) and the weightage to be assigned for various admission criterion shall be as prescribed by the University from time to time.</p></li>
                    <li><p>The University may decide to make admission of the students to the various courses on the basis of merit, and/or on the counseling or entrance tests or interviews conducted by the University or any other University or Board or any other body, and/or on any other basis, as decided by the University. The University may decide to make admission to the resultant vacant seats, due to any reason, of any course(s) on First-Come-First-Serve basis or otherwise, as deemed fit.</p></li>
                    <li><p>The University may decide to conduct entrance tests, interviews, counseling or any other test or examination for admission of the students on its own or with the help of any other University, Board, Institution or any other body, as deemed fit.</p></li>
                    <li><p>Unless otherwise provided, only such candidates shall be eligible for admission to the Faculty(s) or Departments of the University who qualify the prescribed eligibility criteria from a University/Board/Council/Institute or any other body recognized as equivalent as decided by the University from time to time.</p></li>
                    <li><p>The admission to the various courses will be made once or twice in the year or as decided by the University.</p></li>
                    <li><p>The admission of Foreign Nationals, NRIs, PIOs or such other category, as decided by the University, for a following term/academic year may be made in the preceding term/academic year or otherwise as deemed necessary, to manage the procedural and related formalities well in time before the start of the following term/academic year.</p></li>
                    <li><p> The schedule for the admission, application form and other documents for admission to the Faculty(s) or Departments shall be as decided by the University, subject to the amendments made from time to time.</p></li>
                    <li><p> The University reserves the right to discontinue any course(s) or to cancel admissions made in any course(s) or not to make admission to any course or to take any other appropriate decision for any academic year, due to lesser number of admissions or direction of any statutory authority or any other reason, whatsoever, as deemed fit. The students, in such a case, already admitted to such course(s) shall have no other right to claim except the fee deposited with the University.</p></li>
                    <li><p>The University may decide to reserve certain number of seats out of the General/Open Category seats or to create such number of additional seats for such students like, NRIs, Foreign Nationals, PIOs, Industry Personnel and Students Sponsored by NRIs, Foreign Nationals, PIOs and Industry and such other students, as deemed necessary, to be precisely termed as NRI and allied Category seats, in any or all the course(s) offered at the University and/or its faculty(s) or department(s) in any academic session, at any time.</p></li>
                    <li><p>An overseas student belonging to Foreign Nationals, NRIs, PIOs or such other category shall himself/herself be responsible to obtain the educational visa and necessary clearances from the competent authorities and to fulfill other requirements, necessary for his/her study in India for pursuing course(s) of study at the University.</p></li>
                    <li><p>Minimum qualifications and procedure for admission to the various courses shall be as laid down in the Act, Statutes and curriculum of the University made from time to time. Provided that the University may decide to make any amendment/ modification regarding the eligibility criteria prescribed for the particular course(s) as deemed necessary from time to time.</p></li>
                    <li><p>The University may make mandatory the submission of any certificate or document for the students before or at the time of admission or afterwards, for the purpose of admission or otherwise, as decided by the University.</p></li>
                    <li><p>.(a) The University shall adhere to the State Policy in respect of reservation made by the State Government under the Constitution of India or any other law for the time being in force for reserving seats for different categories of candidates.</p></li>
                    <li><p>If sufficient numbers of candidates are not available in a particular Reserved Category(s) (by whatever name it may be called), such vacant seat shall be transferred to the General Category seats (by whatever name it may be called).</p></li>
                    <li><p>The University may decide to make provisions for the reservation of seats out of the Open/General Category seats or to create additional number of seats, in all or any of the courses, for employees of the University and/or their wards, candidates sponsored by the placement-oriented industry and institutions and such other categories as decided by the University, from time to time, to be termed as Privileged Category seats or Specific Category seats.</p></li>
                    <li><p>Candidates seeking admission under the reserved categories shall be required to fulfill the prescribed conditions and eligibility criteria of the admission to a particular course, as detailed in the admission brochure, prospectus, booklets, rules, order, instructions and any other informative material or document published or issued from time to time.</p></li>
                    <li><p>A candidate shall only be admitted to the course on his enrolment as a student of the University after paying the fee prescribed for the concerned course and fulfilling all the conditions and requirements laid down by the University.</p></li>
                   <li><p>Every student, at the time of admission or afterwards as required by the University, may be required to sign a declaration on oath that he or she shall submit himself or herself to the disciplinary jurisdiction of the authorities, officers, employees and persons of the University and such other persons who may be vested with such powers under the Act, Statues, Rules, Orders, Codes, Manuals, Circulars, Memos, Instructions, Guidelines, Procedures, Policies, Schemes etc., that have been framed thereunder by the University.</p></li>
                   <li><p>Every student shall himself be responsible for, any sort of damage or disability or loss arising out of personal injury or death or due to any reason during the course of his stay in the University (within or outside the precincts of the University). The University shall not be liable for any claim or compensation or any legal action or in any other manner to the student or legal representatives of the students or any other person or authority, in any manner.</p></li>
                   <li><p>Every student shall himself be responsible for any illegal or fraudulent action or malpractice or likewise done by the student for any purpose, whatsoever, during his stay in the University (within or outside the precincts of the University). No suit or any other legal proceedings shall lie against the University or any officer or employee or person of the University for any such act of the student or likewise, in any manner.</p></li>
                   <li><p>Every student, at the time of admission or afterwards as required by the University, may be required to submit an indemnity bond or otherwise to indemnify to the University against all rights and claims by the student himself, his dependants, next of kin and other legal representatives or any other individual, authority or body, for any legal action or compensation or in another manner for, any fraudulent or illegal act or malpractice, and any other reason, whatsoever, during and after the completion of his study in the University.</p></li>
                   <li><p>Every student, at the time of admission or afterwards, may be required to submit such declaration(s), undertaking(s) and/or endorsement(s), by the student himself or his parent(s) or guardian or other legal representatives or any other person for any sort of compliance, conduct, payment of fees, penalty, dues etc., and to any other effect, whatsoever, as decided by the University.</p></li>
                   <li><p>Any information or statement related to the University like collaborations, tie-ups, agreement, future plans, etc., in the prospectus, websites, advertisement etc., or otherwise provided by the University, at any time, shall only be for the general information and should not be construed as the basis of admission of any student in the University and the student shall himself be responsible for acting on the basis of such information or statement. The University shall not be responsible at any time for any kind of compensation or otherwise.</p></li>
                   <li><p> The University may decide to make mandatory, the use of laptop and/or other study equipments, dress code and such other facilities/provisions at the University, and/or enrollment in certain workshops, training, project, visits etc., arranged by the University for the students or otherwise, on payment of such fee and/or charges as prescribed by the University from time to time. The University may alternatively decide to collect such fee and/or charges on lump sum basis at the time of admission or in installments afterwards, as deemed necessary.</p></li>
                   <li><p> The University may decide to make mandatory the participation of any or all the student(s) in non-academic, academic support, cultural, sports, extra curricular, social work, such other activities, as deemed necessary from time to time.</p></li>
                   <li><p> The provisions related to the enrollment and allied matters of the students shall be laid down in the Rules and/or Orders, Codes, Manuals, Circulars, Memos, Procedures, Policies, Schemes and such other documents of the University as may be framed from time to time.</p></li>
                   <li><p> Notwithstanding anything contained in the provisions relating to these Rules, the University may conduct various entrance tests, examinations or any other test on its own or with the help of any other University/ Institution/ Body for admission to the courses of the other Universities/Institutions or any other body, as decided by the University from time to time.</p></li>
                   <li><p>The admission of the student(s) to the respective course(s), as applied for, shall stand provisional unless and until confirmed by the University and shall also be subject to the cancellation as per the Statutes, Rules, Disciplinary conduct, Orders, Instructions, Systems, Policies, Guidelines etc., of the University, as are in force and amended from time to time.</p></li>
                 </ol>
            </div>
            <h5>The use of this website is subject to the following terms of use</h5>
            <div class="para">
                <ol>
                    <li><p>The content of the pages of this website is for your general information and use only. It is subject to change without notice.
                    </p></li>
                    <li><p>Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.</p></li>
                    <li><p>Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services or information available through this website meet your specific requirements.</p></li>
                    <li><p>This website contains material which is owned by or licensed to us. This material includes, but is not limited to, the design, layout, look, appearance and graphics. Reproduction is prohibited other than in accordance with the copyright notice, which forms part of these terms and conditions.</p></li>
                    <li><p>Unauthorized use of this website may give rise to a claim for damages and/or be a criminal offence.</p></li>
                    <li><p>From time to time this website may also include links to other websites. These links are provided for your convenience to provide further information. They do not signify that we endorse the website(s). We have no responsibility for the content of the linked website(s).</p></li>
                    <li><p>Your use of this website and any dispute arising out of such use of the website is subject to the laws of India or other regulatory authority.</p></li>
                </ol>
            </div>
            <h6>"We shall be under no liability whatsoever in respect of any loss or damage arising directly or indirectly out of the decline of authorization for any Transaction, on Account of the Cardholder having exceeded the preset limit mutually agreed by us with our acquiring bank from time to time".</h6>
        </div>
     </div>
</section>