<section>
   <div class="subMenu_content">
      <app-sub-menu></app-sub-menu>
  </div>
    <div class="sub_content">
        <div class="title">Refund Policy</div>
        <div class="main_content_para my-4">
          <h5>Fee & Refund Policy</h5>
            <div class="para">
                <ol>
                    <li><p>All applications for admission withdrawals will be submitted in prescribed format to the Registrar, who would duly acknowledge the receipt of such applications by recording the submission date.</p></li>
                    <li><p> Prospectus fee of Rs. 1000/- is not- refundable.</p></li>
                    <li><p>If a student withdraws his/her admission before the last date of admission i:e 14 August, fee will be refunded after deducting the nonrefundable admission/registration fee.</p></li>
                    <li><p> In case the admission is withdrawn after the last date of admission i:e 14 August, the fees will not be refunded.</p></li>
                    <li><p>A candidate after depositing the fee (in part or full amount), if desires to withdraw his/her admission or does not join the University or does not deposit the balance amount of fee (if any) by the due date, shall be liable for the cancellation of his/her admission without any compensation by the University. The University shall be at liberty to fill his seat by offering to any other candidate and the fee and any other charges deposited with the University shall stand forfeited.</p></li>
                  </ol>
            </div>
             <h5>Cancellation of Admission in lieu of Delay or Default in Payment of Fee</h5>
             <ul>
                <li><p> A student, admitted to the course(s) of the University by default, who in fact has been found ineligible or unfit later on, his/her admission shall be cancelled and such a student will not be entitled for the refund of fee or any other compensation by the University and shall have no claim against the University.</p></li>
                <li><p>In case of failure to pay fee by the prescribed date, the students will be liable to pay the prescribed fine and/or penalty to face any other disciplinary action. In case of non-payment of fee, the name of the student may be struck off the rolls by the University. However, he/she may be allowed for re-admission as per the Rules of the University.</p></li>
             </ul>
             <h5>Cancellation of admission for furnishing Incorrect Information</h5>
             <div class="para">
                <p>The admission of a student is liable to be cancelled at any time, on being found, ineligible or unfit for admission, or of using fraudulent means for securing admission or otherwise or if any of the statements made by him/her is found false or incorrect or otherwise and the University shall have the right to take such other disciplinary action and/or penalty, as decided by University, against such a student as per the Statutes and the Rules of the University.</p>
                <p>In such case, the fee deposited by the student shall be forfeited and he/she shall also be liable to pay the pending fee for the remaining duration of the course and dues, penalty etc., if any.</p>
             </div>
        </div>
     </div>
</section>