<section>
    <div class="title mt-3" data-aos="fade-up" data-aos-duration="3000">Our Recruiters</div>
    <div class="row">
        <div class="col md-6">
            <div class="row box_content1">
                <div class="sub_title">Placement Highlights 2023</div>
                <div class="col-md-6">
                    <div class="big">
                        <div class="num_box">
                            <p class=""><span class="count percent" data-count="480">480</span></p>
                            <p class="desc">Number of Students Placed</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="big">
                        <div class="num_box">
                            <p class=""><span class="count percent" data-count="33">33</span><sup class="text-sec">LPA</sup></p>
                            <p class="desc">Highest CTC</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 mt-2">
                    <div class="big">
                        <div class="num_box">
                            <p class=""><span class="count percent" data-count="7.02">7.02</span><sup class="text-sec">LPA</sup></p>
                            <p class="desc">Average Salary</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 mt-2">
                    <div class="big">
                        <div class="num_box">
                            <p class=""><span class="count percent" data-count="16.51">16.51</span><sup class="text-sec">LPA</sup></p>
                            <p class="desc">Top 10% Average CTC</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            <div class="col-md-6">
                <div class="placement_company">
                    <div class="sub_title">Top Recruiters</div>
                <div class="row box_content2">
                    <div class="col-md-6" *ngFor="let dt of Placement; let i = index">
                        <div class="img-sec" *ngIf="i < 4">
                            <figure *ngFor="let a of dt.files"> 
                            <img class=" lazyloaded" src="{{a.url}}" alt="{{dt.filename}}" title="{{dt.filename}}" width="64" height="64"> 
                            </figure>
                        </div>
                    </div>
                </div>
               
            </div>
            </div>
       
    </div>
    <div class="d-flex justify-content-end">
        <button class="btn view_sm_btn mt-4" routerLink="/placements/{{fromslug}}">View All</button>
    </div>
</section>