import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-placement-list',
  templateUrl: './placement-list.component.html',
  styleUrls: ['./placement-list.component.css']
})
export class PlacementListComponent {
  
  selectedclub='Welcome'

  filtercategory(str:any){
    this.selectedclub=str
  }
}
