<section>
  <div class="title">Alumni Coordinators</div>
  <div>
  </div>
  <div class="banner_content_slider">
    <owl-carousel-o [options]="customOptions">
      <ng-template carouselSlide *ngFor="let dt of alumnicoordinatedata">
        <div class="d-flex justify-content-center">
          <div class="common-card">
            <figure>
              <div class="gallery_img" *ngFor="let a of dt.logofiles">
                <a data-fancybox="" href="{{dt.url}}" *ngIf="dt.slug">
                  <img src="{{a.value}}" alt="{{dt.Title}}"/>                  
                </a>
                <div *ngIf="!dt.slug">
                  <img src="{{a.value}}" alt="{{dt.Title}}"/>
                </div>
              </div>
            </figure>
            <div class="common-card-body">
              <div class="common-card-body-heads">
                <h3 class="mt-2">{{dt.Title}}</h3>
                <hr>               
              </div>              
              <p [innerHTML]="dt.short"></p>
            </div>
          </div>
        </div>
      </ng-template>     
    </owl-carousel-o>
  </div>
  <div class="d-flex justify-content-end">
    <button class="btn view_sm_btn mt-4" routerLink="/allcoordinateAlumni">View All</button>
  </div>
</section>
<section>
  <div class="title">Notable Alumni</div>
  <div>
  </div>
  <div class="banner_content_slider">
    <owl-carousel-o [options]="customOptions">
      <ng-template carouselSlide *ngFor="let dt of alumnidt">
        <div class="d-flex justify-content-center">
          <div class="common-card">
            <figure>
              <div class="gallery_img" *ngFor="let a of dt.logofiles">
                <a data-fancybox="" href="{{dt.url}}" *ngIf="dt.slug">
                  <img src="{{a.value}}" alt="{{dt.Title}}"/>
                  <!-- <iframe width="335" height="188" class="video_list" [src]="dt.url" frameborder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
                </a>
                <div *ngIf="!dt.slug">
                  <img src="{{a.value}}" alt="{{dt.Title}}"/>
                </div>
              </div>
              
            </figure>
            <div class="common-card-body">
              <div class="common-card-body-heads">
                <h3 class="mt-2">{{dt.Title}}</h3>
                <hr>
                <!-- <span>{{data.Short}}</span> -->
              </div>
              <!-- <p [innerHTML]="data.long1"></p> -->
              <p [innerHTML]="dt.short"></p>
            </div>
          </div>
        </div>
      </ng-template>
      
    </owl-carousel-o>
  </div>
  <div class="d-flex justify-content-end">
    <button class="btn view_sm_btn mt-4" routerLink="/allAlumni">View All</button>
  </div>
</section>