import { Component } from '@angular/core';

@Component({
  selector: 'app-admission-thankyou',
  templateUrl: './admission-thankyou.component.html',
  styleUrls: ['./admission-thankyou.component.css']
})
export class AdmissionThankyouComponent {

}
