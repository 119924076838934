<section>
 <div class="title">Faculty</div> 
  <div>
    <div class="listSearchArea d-flex justify-content-end align-items-center">

      <ul class="nav nav-tabs tabs-buttons" role="tablist">
        <li class="nav-item" role="presentation" id="boardOfGovernorsTab0">
        <button id="click_btn" class="nav-link active"
            data-bs-toggle="tab" data-bs-target="#boardOfGovernors0" type="button"
            role="tab" aria-labelledby="board Of Governors Tab 0" aria-selected="true" *ngIf="facultycategory" (click)="filtercategory(0)">All</button>
    </li>
          <li class="nav-item" role="presentation" id="boardOfGovernorsTab0"
              *ngFor="let dt of facultycategory;let i=index">
              <button id="click_btn" class="nav-link"
                  data-bs-toggle="tab" data-bs-target="#boardOfGovernors0" type="button"
                  role="tab" aria-labelledby="board Of Governors Tab 0" aria-selected="true"
                  (click)="filtercategory(dt.categoryId)">{{dt.categoryname}}</button>
          </li>
      </ul>
  </div>
  </div>
    <div class="banner_content_slider">
        <owl-carousel-o [options]="customOptions">
          <ng-template carouselSlide *ngFor="let data of faculty">
            <div class="d-flex justify-content-center">
              <div class="common-card">
                <figure *ngFor="let a of data.files"> 
                  <img class=" lazyloaded"
                        src="{{a.url}}"
                        data-src="../../../assets/img/jigyasa/blank-removebg-preview.png"
                        alt="{{a.filename}}" title="{{a.filename}}" width="485" height="400"> 
                       </figure>
                <div class="common-card-body">
                  <div class="info_content">
                  <div class="common-card-body-heads">
                    <h3 class="mt-2">{{data.Title}}</h3>
                    <span>{{data.Short}}</span>
                  </div>
                 <p class="line-clamp" [innerHTML]="data.long1"></p>
                </div>
                  <div class="readmore d-flex justify-content-end align-items-center">
                   <button class="btn" data-bs-toggle="modal" data-bs-target="#exampleModal" (click)="showAllDetails(data)"><i class="fa fa-long-arrow-right readmore"></i></button>
                  </div>
                 </div>
              </div>
            </div>
          </ng-template>  
            </owl-carousel-o>
      </div>
      <div class="d-flex justify-content-end">
        <button class="btn view_sm_btn mt-4" routerLink="/faculty/{{fromslug}}">View All</button>
      </div>
      
<div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel"><span class="title">Faculty</span></h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-md-5">
        <figure *ngFor="let a of imgFile"> 
          <img class=" lazyloaded"
                src="{{a.url}}"
                data-src="../../../assets/img/jigyasa/blank-removebg-preview.png"
                alt="{{a.filename}}" title="{{a.filename}}" width="50%"> 
               </figure>
              </div>
              <div class="col-md-7">
               <div class="common-card-body-heads">
                <h3 class="mt-2">{{getAllDetails.Title}}</h3>
                <span>{{getAllDetails.Short}}</span>
              </div>
             
             <p [innerHTML]="getAllDetails.long1"></p>
              </div>
            </div>
            </div>
    </div>
  </div>
</div>
</section>