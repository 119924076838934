import { Component } from '@angular/core';

@Component({
  selector: 'app-hostel-facilities',
  templateUrl: './hostel-facilities.component.html',
  styleUrls: ['./hostel-facilities.component.css']
})
export class HostelFacilitiesComponent {

}
