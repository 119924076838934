import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { from, Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root',
})
export class ApicallService {
  apiurl:string=environment.cmsapi_url;
  constructor(private httpClient: HttpClient) {}

  getGetseo(tbody: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('dbid', '0');
    return this.httpClient
      .post(environment.cmsapi_url + `CMS/Getseo`, tbody, { headers: headers })
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError((error) => {
          return throwError('Something went wrong!');
        })
      );
  }
  getGetblog(tbody: any) {
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Accept', 'application/json');
    headers = headers.append('dbid', '0');
    return this.httpClient
      .post(environment.cmsapi_url + `CMS/Getblogdata`, tbody, { headers: headers })
      .pipe(
        map((data: any) => {
          return data;
        }),
        catchError((error) => {
          return throwError('Something went wrong!');
        })
      );
  }
  
  checkMicrosite(data:any):Observable<any>{
    return this.httpClient.post( this.apiurl+'checkIsmicrosite',data);    
  }
  Getcourse(jdata:any):Observable<any>{
    return this.httpClient.post(this.apiurl+'CMS/Getcourse',jdata)
  }
  Getcoursedatabyslug(data:any):Observable<any>{
    return this.httpClient.post(this.apiurl+'CMS/Getcoursedatabyslug',data)
  }
  GetProgramsbyschool(jdata:any):Observable<any>{
    return this.httpClient.post(this.apiurl+'CMS/GetProgramsbyschool',jdata)
  }
  getByName(search: any): Observable<any> {
    return this.httpClient.post(this.apiurl + 'CMS/Getcoursebysearch',search)
        // .pipe(
        // map(countryList => countryList.map( ({ name }) => name )),
        // catchError( (err) => {
        //     if (err.error.status === 404) {
        //         return of([`--- No results for: ${countryName} ---`]);
        //     }
        // })
        // );
  }
}
