<div>
    <header class="header" id="scroll_header">
      <div class="menu_list">
        <ul>
            <li><a href="aboutUs#UniversityofTomorrow"> The University </a></li>
            <li><a href="aboutUs#ourVision">Vision and Mission</a></li>
            <li><a href="aboutUs#leadershipGovernance">Academic Council</a></li>
            <li><a href="aboutUs#ranksAccreditations">Statutory Approvals</a></li>
            <li><a href="aboutUs#leadershipGovernance">Committees</a></li>
            <!-- <li><a href="aboutUs#leadershipGovernance">NSS</a></li> -->
            <li><a href="aboutUs#leadershipGovernance">Our Leadership</a></li>
        </ul>
      </div>
    </header>
   
      <section class="box">
        <div class="banner">
            <!-- <div class="banner_text_content">
                <h1>ABOUT US</h1>
                <h5 class="mt-3">Exploring the possibilities of a JIGYASA education</h5>
            </div> -->
            <img src="../../../assets/img/jigyasa/banner/Jigyasa-Main-banner.jpg" width="100%" alt="Jigyasa">
        </div>
      </section>
      <div class="subMenu_content">
        <app-sub-menu></app-sub-menu>
    </div>
      <section class="box1" id="ranksAccreditations">
       <div class="slider_box mt-5">
        <div class="title">Statutory Approvals</div>
        <div id="carouselExampleControls" class="carousel slide d-none d-xl-block" data-bs-ride="carousel"  data-bs-touch="false">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div class="row">
                    <div class="col-md-4 d-flex justify-content-center">
                        <a href="../../../assets/pdf/ugc.pdf" target="_blank">
                        <div class="item ranking-slide-item">
                           <div class="row">
                            <div class="col-5 d-flex justify-content-center">
                                <img src="../../../assets/img/jigyasa/StatutoryApprovals/UGC.png" width="100%" height="110px" alt="Jigyasa">
                            </div>
                            <div class="col-md-7">
                                <p class="slider-heads">UGC</p>
                            </div>
                           </div>
                        </div>
                    </a>
                    </div>
                    <div class="col-md-4 d-flex justify-content-center">
                        <a href="../../../assets/pdf/hnvactlatest.pdf" target="_blank">
                        <div class="item ranking-slide-item">
                           <div class="row">
                            <div class="col-5 d-flex justify-content-center">
                                <img src="../../../assets/img/jigyasa/StatutoryApprovals/HZU.png" width="100%" height="110px" alt="Jigyasa">
                            </div>
                            <div class="col-md-7">
                                <p class="slider-heads">JU act</p>
                            </div>
                           </div>
                        </div>
                    </a>
                    </div>
                    <div class="col-md-4 d-flex justify-content-center">
                        <a href="../../../assets/pdf/ncte_approval.pdf" target="_blank">
                        <div class="item ranking-slide-item">
                           <div class="row">
                            <div class="col-5 d-flex justify-content-center">
                                <img src="../../../assets/img/jigyasa/StatutoryApprovals/NCTE.png" width="100%" height="auto" alt="Jigyasa">
                            </div>
                            <div class="col-md-7">
                                <p class="slider-heads">NCTE</p>
                            </div>
                           </div>
                        </div>
                    </a>
                    </div>
                   
                </div>
              </div>
              <div class="carousel-item">
                <div class="row">
                    <div class="col-md-4 d-flex justify-content-center">
                        <a href="../../../assets/pdf/COA-UA03.pdf" target="_blank">
                        <div class="item ranking-slide-item">
                           <div class="row">
                            <div class="col-5 d-flex justify-content-center">
                                <img src="../../../assets/img/jigyasa/StatutoryApprovals/COA.png" width="100%" height="auto" alt="Jigyasa">
                            </div>
                            <div class="col-md-7">
                                <p class="slider-heads">COA</p>
                            </div>
                           </div>
                        </div>
                    </a>
                    </div>
                    <div class="col-md-4 d-flex justify-content-center">
                        <a href="../../../assets/pdf/BCI 2022 approval letter.pdf" target="_blank">
                        <div class="item ranking-slide-item">
                           <div class="row">
                            <div class="col-5 d-flex justify-content-center">
                                <img src="../../../assets/img/jigyasa/StatutoryApprovals/BCI.png" width="100%" height="110px" alt="Jigyasa">
                            </div>
                            <div class="col-md-7">
                                <p class="slider-heads">BCI</p>
                            </div>
                           </div>
                        </div>
                    </a>
                    </div>
                    <div class="col-md-4 d-flex justify-content-center">
                        <a href="../../../assets/pdf/PCI.pdf" target="_blank">
                        <div class="item ranking-slide-item">
                           <div class="row">
                            <div class="col-5 d-flex justify-content-center">
                                <img src="../../../assets/img/jigyasa/StatutoryApprovals/PCI.png" width="100%" height="115px" alt="Jigyasa">
                            </div>
                            <div class="col-md-7">
                                <p class="slider-heads">PCI</p>
                            </div>
                           </div>
                            </div>
                        </a>
                    </div>
                </div>
              </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>

          <div id="carouselExampleControls1" class="carousel slide d-xl-none d-md-block" data-bs-ride="carousel"  data-bs-touch="false">
            <div class="carousel-inner">
              <div class="carousel-item active">
                <div class="row">
                    <div class="col-md-4 d-flex justify-content-center">
                        <a href="../../../assets/pdf/ugc.pdf" target="_blank">
                        <div class="item ranking-slide-item">
                           <div class="row">
                            <div class="col-md-5 d-flex justify-content-center">
                                <img src="../../../assets/img/jigyasa/StatutoryApprovals/UGC.png" width="100%" alt="Jigyasa">
                            </div>
                            <div class="col-md-7">
                                <p class="slider-heads text-center mt-2">UGC</p>
                            </div>
                           </div>
                        </div>
                    </a>
                    </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="row">
                    <div class="col-md-4 d-flex justify-content-center">
                        <a href="../../../assets/pdf/hnvactlatest.pdf" target="_blank">
                        <div class="item ranking-slide-item">
                           <div class="row">
                            <div class="col-md-5 d-flex justify-content-center">
                                <img src="../../../assets/img/jigyasa/StatutoryApprovals/HZU.png" width="100%" alt="Jigyasa">
                            </div>
                            <div class="col-md-7">
                                <p class="slider-heads text-center mt-2">JU act</p>
                            </div>
                           </div>
                        </div>
                    </a>
                    </div>
                    </div>
                    </div>
                    <div class="carousel-item">
                        <div class="row">
                            <div class="col-md-4 d-flex justify-content-center">
                                <a href="../../../assets/pdf/ncte_approval.pdf" target="_blank">
                                <div class="item ranking-slide-item">
                                   <div class="row">
                                    <div class="col-md-5 d-flex justify-content-center">
                                        <img src="../../../assets/img/jigyasa/StatutoryApprovals/NCTE.png" width="100%" alt="Jigyasa">
                                    </div>
                                    <div class="col-md-7">
                                        <p class="slider-heads text-center mt-2">NCTE</p>
                                    </div>
                                   </div>
                                </div>
                            </a>
                            </div>
                            </div>
                            </div>  
              <div class="carousel-item">
                <div class="row">
                    <div class="col-md-4 d-flex justify-content-center">
                        <a href="../../../assets/pdf/COA-UA03.pdf" target="_blank">
                        <div class="item ranking-slide-item">
                           <div class="row">
                            <div class="col-md-5 d-flex justify-content-center">
                                <img src="../../../assets/img/jigyasa/StatutoryApprovals/COA.png" width="100%" alt="Jigyasa">
                            </div>
                            <div class="col-md-7">
                                <p class="slider-heads text-center mt-2">COA</p>
                            </div>
                           </div>
                        </div>
                    </a>
                    </div>
                </div>
              </div>
              <div class="carousel-item">
                <div class="row">
                    <div class="col-md-4 d-flex justify-content-center">
                        <a href="../../../assets/pdf/BCI 2022 approval letter.pdf" target="_blank">
                        <div class="item ranking-slide-item">
                           <div class="row">
                            <div class="col-md-5 d-flex justify-content-center">
                                <img src="../../../assets/img/jigyasa/StatutoryApprovals/BCI.png" width="100%" alt="Jigyasa">
                            </div>
                            <div class="col-md-7">
                                <p class="slider-heads text-center mt-2">BCI</p>
                            </div>
                           </div>
                        </div>
                    </a>
                    </div>
                    </div>
                    </div>
                    <div class="carousel-item">
                        <div class="row">
                            <div class="col-md-4 d-flex justify-content-center">
                                <a href="../../../assets/pdf/PCI.pdf" target="_blank">
                                <div class="item ranking-slide-item">
                                   <div class="row">
                                    <div class="col-md-5 d-flex justify-content-center">
                                        <img src="../../../assets/img/jigyasa/StatutoryApprovals/PCI.png" width="100%" alt="Jigyasa">
                                    </div>
                                    <div class="col-md-7">
                                        <p class="slider-heads text-center mt-2">PCI</p>
                                    </div>
                                   </div>
                                    </div>
                                </a>
                            </div>
                            </div>
                            </div>
            </div>
            <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls1" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
            <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls1" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
          </div>
          <div class="d-flex justify-content-end">
            <button class="btn view_sm_btn mt-4" routerLink="/patents_awards">View All</button>
          </div>
      </div>
       
      </section>
      <section class="box1 pt-3" id="UniversityofTomorrow">
        <div class="sub_content">
            <!-- <div class="title">University of Tomorrow</div> -->
            <p>Jigyasa University ( Formerly Himgiri Zee University) has been established to meet the growing demand for trained human resources in various social, educational, scientific, technical and professional organizations so as to channel human energy towards sustainable economic and social development.</p>
            <p>In order to make a positive difference to education and employment, Shri Subhash Chandra, Chairman, Essel Group of Industries and Zee Telefilms had brought together in 1995 a group of committed anthropologists, sociologists, economists and development communications professionals to set up TALEEM Research Foundation in Ahmedabad. One of the mandates of Taleem Research Foundation was to set up the Jigyasa University ( Formerly Himgiri Zee University). Based on the motto `Vasudhaiva Kutumbakam’ (World as Family), Jigyasa University ( Formerly Himgiri Zee University) has been established for the vocationalisation of higher education which is national in character and transnational in jurisdiction. The University is characterized by its resolve not to discriminate on grounds of caste, creed, residence, community, gender and religion. Jigyasa University has been established under Uttarakhand Private University ( Amendment) Act, 2024 ( Act No. 5 of 2024)</p>
            </div>
        <div class="row">
            <div class="col-12">
            <div class="videoContainer">
                <div class="banner_text_content">
                    <!-- <h5 class="">UNIVERSITY OF</h5>
                    <h1>TOMORROW</h1>
                   <p>Innovative ideas, lifelong learning & a global perspective. At JIGYASA,<br> we foster a vibrant & dynamic ecosystem.
                   </p> -->
                </div>
                <video controls id="homevideo" poster="../../../assets/img/jigyasa/video/starting zee.mp4"  autoplay="false" playsinline="" loop="">
                  <source src="../../../assets/img/jigyasa/video/starting zee.mp4" type="video/mp4">
                </video>
            </div>
            </div>
        </div>
      </section>
      <section class="box1" id="ourVision">
        <div class="sub_content mt-3">
            <div class="row">
                <div class="col-md-12">
                <div class="title padding_top">Our Vision</div>
                <p>To infuse transformation and foster indigenous values through excellence in teaching, research, and innovation, thereby serving society as a premier institution of higher education.</p>
                    <div class="title">Our Mission</div>
                <ol>
                    <li>Progressive educational proficiencies that stimulate holistic development.</li>
                    <li>Enhancing experiential learning through endorsing an inclusive mind-set.</li>
                    <li>Advancing research, nurturing innovations and catalyzing entrepreneurship.</li>
                    <li>Cultivation of leadership qualities with a strong sense of values and ethics.</li>
                </ol>
                </div>
            </div>
        </div>
      </section>

      <section class="box1" id="coreValues">
        <div class="sub_content">
            <div class="title">JIGYASA Core Values</div>
            <div class="coreValues">
            <div>
            <span>Respect</span>
            <img src="https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-07/core-value-step1.webp" alt="JIGYASA Core Values">
        </div>
        <div>
            <span>Innovation</span>
            <img src="https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-07/core-value-step2.webp" alt="JIGYASA Core Values">
        </div>
        <div>
            <span>Passion</span>
            <img src="https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-07/core-value-step3.webp" alt="JIGYASA Core Values">
        </div>
        <div>
            <span>Inclusivity</span>
            <img src="https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-07/core-value-step4.webp" alt="JIGYASA Core Values">
        </div>
        <div>
            <span>Trust</span>
            <img src="https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-07/core-value-step5.webp" alt="JIGYASA Core Values">
        </div>
        </div>
        <div class="core_card">     
        </div>
    </div>
      </section>

      <section class="box1 pt-5" id="ourHistory">
        <div class="banner_content">
                  <img fetchpriority="high" src="../../../assets/img/jigyasa/Jigyasa-sub-banner.png" width="100%" alt="Our History" title="Our History" class=" ls-is-cached lazyloaded"> 
            <div class="year_content">
                <div id="carouselExampleFade2" class="carousel slide" data-bs-ride="carousel">
                    <div class="carousel-inner">
                      <!-- <div class="carousel-item active">
                        <div class="item-content">
                            <div class="row">
                                <div class="col-md-3 text-center">
                                   <img src="../../../assets/img/jigyasa/icon/best.png" width="130px" height="130px" alt="">
                                </div>
                                <div class="col-md-9 text-left">
                                    <p>JIGYASA was established  in 2003 as Asia's first energy and core sector university</p>
                                </div>
                            </div>
                          </div>
                      </div> -->
                      <div class="carousel-item active">
                        <div class="item-content">
                            <div class="row">
                                <div class="col-md-3 text-center">
                                    <img src="../../../assets/img/jigyasa/icon/cegr.png" width="130px" height="130px" alt="">
                        </div>
                        <div class="col-md-9 text-left">
                            <p>No. 1 Private University in Uttarakhand 2018</p>
                            <h5>by Integrated Chambers of Commerce and Industry</h5>
                            <span>(ICCI)</span>
                            </div>
                            </div>
                          </div>
                      </div>
                      <div class="carousel-item">
                        <div class="item-content">
                            <div class="row">
                                <div class="col-md-3 text-center">
                                    <img src="../../../assets/img/jigyasa/icon/chambers.png" width="130px" height="130px" alt="">
                        </div>
                        <div class="col-md-9 text-left">
                            <p>A  new identity and rebranding exercise was undertaken to reflect expansion of courses in the EIT sector. </p>
                          </div>
                          </div>
                          </div>
                      </div>
                      <div class="carousel-item">
                        <div class="item-content">
                            <div class="row">
                                <div class="col-md-3 text-center">
                                    <img src="../../../assets/img/jigyasa/icon/knowledge-rev.png" width="130px" height="130px" alt="">
                        </div>
                        <div class="col-md-9 text-left">
                            <p>Jigyasa University (Formerly Himgiri Zee University) Awarded</p>
                            <h5>Private University of the year 2015</h5>
                            <span>(Uttarakhand)</span>
                          </div>
                          </div>
                          </div>
                      </div>
                      </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleFade2" data-bs-slide="prev">
                      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                      <span class="visually-hidden">Previous</span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleFade2" data-bs-slide="next">
                      <span class="carousel-control-next-icon" aria-hidden="true"></span>
                      <span class="visually-hidden">Next</span>
                    </button>
                  </div>
            </div>
                </div>
      </section>

      <section class="box1"  id="leadershipGovernance">
        <div class="sub_content">
       <div class="title">And More...</div>
       <div class="row">
        <div class="col-md-4 d-flex">
            <div class="common-card1 common-card">
                <div class="common-card-body">
                    <div class="common-card-body-heads">
                        <img src="../../../assets/img/jigyasa/icon-industry-impact.webp" class="py-3" width="70%" alt="Jigyasa">
                        <h3>Industry impact</h3>
                        <hr>
                    </div>
                    <div class="para_text">
                        <p>Explore integration of learning &amp; application that defines our educational approach.</p>
                </div>
                    <div class="readmore">
                        <i class="fa fa-long-arrow-right"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4 d-flex">
            <div class="common-card1 common-card">
                <div class="common-card-body">
                    <div class="common-card-body-heads">
                        <img src="../../../assets/img/jigyasa/icon-faq.svg" class="py-3" width="27%" alt="Jigyasa">
                        <h3>FAQs</h3>
                        <hr>
                    </div>
                    <div class="para_text">
                        <p>Simplify your journey by accessing the information you need right here.</p>
                </div>
                    <div class="readmore">
                        <i class="fa fa-long-arrow-right"></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-4 d-flex">
            <div class="common-card_dard common-card">
                <div class="common-card-body">
                    <div class="common-card-body-heads">
                        <img src="../../../assets/img/jigyasa/icon-leadership-govern.svg" class="py-3" width="34%" alt="Jigyasa">
                        <h3>Leadership & Governance</h3>
                        <hr>
                    </div>
                    <div class="para_text">
                        <p>Welcome to JIGYASA - a university whose top priority is to help students fulfil their aspirations and dreams.</p>
                </div>
                    <div class="readmore" routerLink="/leadership">
                        <i class="fa fa-long-arrow-right text-white"></i>
                    </div>
                </div>
            </div>
        </div>
       </div>
       </div>

       <!-- <div class="sub_content mt-5 pb-5">
        <div class="needMoreInfoWrap2">
            <div class="row">
                <div class="col-md-2">
                    <img src="	https://www.upes.ac.in/assets/images/icon-first-step-white.svg" width="80%" alt="Jigyasa">
                </div>
                <div class="col-md-6">
                    <h4>TAKE THE FIRST STEP TOWARDS YOUR CAREER</h4>
                </div>
                <div class="col-md-4">
                 <button class="btn view_btn counsellor_btn text-uppercase">Talk to Our Counsellors</button>
                 <button class="btn counsellor_btn1 mt-3">Apply Now</button>
                </div>
            </div>
        </div>
       </div> -->
      </section>
    </div>