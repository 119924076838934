<section>
    <div>
        <div>
            <div class="section">

                <div class="subMenu_content">
                    <app-sub-menu></app-sub-menu>
                </div>
                <div class="sub_content">
                    <section id="OverviewOfRAndDCell">
                        <div class="overview">
                            <div class="title">IQAC</div>
                            <div class="para">
                                <h5>Aim of IQAC -</h5>
                                <p>The Primary aim of the IQAC, Jigyasa University is to develop a system for conscious, consistent
                                    and catalytic action to improve the academic and administrative performance of the University.</p>

                                <h6>OBJECTIVES -</h6>
                                <p>To achieve a heightened level of clarity and focus in institutional functioning towards
                                    quality enhancement and facilitate the internalization of the quality culture.</p>
                                <p>To enhance and integrate various activities of the institution and institutionalize good
                                    practices.</p>
                                <p>To provide a sound basis for decision-making to improve institutional functioning.</p>
                                <p>To act as a change agent in the institution.</p>
                                <p> To improve internal communication.</p>
                                <h6>STRATEGIES-</h6>
                                <P>Ensuring timely, efficient, and progressive performance of academic, administrative,
                                    and financial tasks.</P>
                                <p>Enhancing the relevance and quality of academic and research programs.</p>
                                <p>Developing faculty to acquire the necessary knowledge and technology for participatory
                                    teaching and learning processes.</p>
                                <p>Establishing mechanisms for feedback from all stakeholders, i.e., students, parents,
                                    alumni, and employers, on quality-related institutional processes.</p>

                                <p>Disseminating information on various quality parameters of higher education.</p>
                                <p>Organizing inter- and intra-institutional workshops, seminars, lectures, etc., on quality-
                                    related themes and promoting quality circles.</p>
                                    <p>Documenting various programs/activities leading to quality improvement.</p>
                                    <p>Acting as a nodal agency of the University for coordinating quality-related activities,
                                        including the adoption and dissemination of best practices.</p>
                                        <p>Developing and maintaining an institutional database through MIS/Central Repository
                                            System to maintain/enhance institutional quality.</p>
                                            <p>Developing a quality culture and a research-based ecosystem in the institution.</p>
                                            <p>Preparing the University for Accreditations and ranking.</p>
                             
                            </div>
                        </div>
                    </section>
                </div>
            </div>

        </div>
    </div>
</section>