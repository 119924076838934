import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription, of, switchMap, tap } from 'rxjs';
import { ApicallService } from 'src/app/services/apicall.service';
import { HomeSeoService } from 'src/app/services/homeseo.service';
import { ProjectSeoService } from 'src/app/services/projectseo.service';
import { environment } from 'src/environments/environment';
import * as AOS from 'aos';
import { CommonService } from '../service/common.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-all-placements',
  templateUrl: './all-placements.component.html',
  styleUrls: ['./all-placements.component.css']
})
export class AllPlacementsComponent {
  @Input() ishide!:number;
  @Input() Placementdata:any=[];
  project_id = environment.projectid
  project$: Observable<any> | undefined;
  subscriptionnav!: Subscription;
  testimonydata: any;
  PlacementList:any=[];
  AssignCategory:any=[];
  alldata:any=[];
  findex:number=0;
  tindex:number=20;
  totalrecord:number=0;
  more:boolean=true;
  title:string="";
  secondtitle:string="";
  thirdtitle:string="";
  constructor(
    private route: ActivatedRoute,
    private seoService: HomeSeoService,
    private projectService: ProjectSeoService,
    private apiService: ApicallService,
     private _service: CommonService,
    private spinner:NgxSpinnerService
  ) {
    
  }
  
  ngOnInit(): void {
    this.route.params.subscribe((params: any) => {      
      this.title = params['type'];    
      this.route.params.subscribe((params: any) => { 
        this.secondtitle = params['type2'];
        this.route.params.subscribe((params: any) => {    
          this.thirdtitle = params['type3'];      
        })
      })            
    })
    AOS.init();
    this.getslug();
  }
  
  getslug(){
    let slug;
    if(this.thirdtitle!=undefined){
      slug=this.title+'/'+this.secondtitle+'/'+this.thirdtitle;
    }
    else if (this.secondtitle!=undefined){
      slug=this.title+'/'+this.secondtitle;
    }
    else{
      slug=this.title;
    }
    if(this.ishide==1){
      slug='home';
    }
    this.getplacementData(slug); 
  }

  getplacementData(slug:string) {
    this.spinner.show();
    let obj = {
      "Project_Id": this.project_id,
      "Type": "Placement",
      "pageurl":slug
    }
    this._service.getContentbytype(obj).subscribe(
      res => {    
        this.spinner.hide();   
        if(res.data){
          this.AssignCategory = JSON.parse(res.data[0].AssignCategory);
          this.alldata=JSON.parse(res.data[0].contentData);
          //this.PlacementList = JSON.parse(res.data[0].contentData);   
          this.setcentrelist();  
        }else{
          
        }
          
      }
    )
  }
  fn_ClientFilter(event: any) {
    let val = event.target.value.toLowerCase();
    if (!val) {
      this.PlacementList = this.alldata;
    }
    this.PlacementList = this.alldata.filter(function (dt: any): any {
      if (
        dt?.Title?.toString().toLowerCase().indexOf(val) !== -1 ||
        !val
      ) {
        // found match, return true to add to result set
        return true;
      }
      else if (dt.slug) {
        if (dt?.slug?.toString().toLowerCase().indexOf(val) !== -1 ||
          !val
        ) {
          // found match, return true to add to result set
          return true;
        }
      }      
    })
  }
  setcentrelist()
{
  let dlenth=this.alldata.length;
  this.totalrecord=dlenth;
  if(dlenth>20)
  {
    this.tindex=20;
  }
  else
  {
    this.tindex=dlenth;
    this.more=false;
  }

  this.PlacementList=this.alldata.slice(this.findex,this.tindex);
  
}
filtercategory(id:number){
  if(id!=0){
    this.PlacementList=this.alldata.filter((dt:any)=>{
      return dt.category.includes(id)
    }).map((obj:any)=>{
      return obj;
    })
  }
  else{
    this.PlacementList=this.alldata;

  }
  
}
  setcentrelistclick()
{
  let dlenth=this.alldata.length;
  let nindex=this.tindex+20;

  if( dlenth> this.tindex)
  {


    if(nindex>dlenth)
    {
       this.tindex=dlenth;
        this.more=false;
    }
    else
    {
    this.tindex += 20;
    }
  }
  else
  {

  this.more=false;
  }

  this.PlacementList=this.alldata.slice(this.findex,this.tindex);
  //this.centerList=data;
}


}
