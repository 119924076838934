<section>
  <header class="desktop_header">
    <div class="side_menu">
      <div class="side_icon d-flex justify-content-center align-items-center">
        <a href="https://apply.jigyasauniversity.com/" target="_blank">
        <img src="../../../../assets/img/jigyasa/icon/icon-sidebar-form.svg" width="22px" height="22px" alt="">
      </a>
      </div>
      <div class="side_icon d-flex justify-content-center align-items-center">
        <a href="tel:8171819966">
        <img src="../../../../assets/img/jigyasa/icon/icon-sidebar-call.svg" width="22px" height="22px" alt="">
      </a>
      </div>
      <div class="side_icon d-flex justify-content-center align-items-center">
        <a href="../../../../assets/pdf/StatementMon_Jun 03 11_46_57 GMT+05_30 2024.pdf" target="_blank">
        <img src="../../../../assets/img/jigyasa/icon/icon-sidebar-download.svg" width="22px" height="22px" alt="">
        </a>
      </div>
      <div class="side_icon d-flex justify-content-center align-items-center">
        <a href="https://api.whatsapp.com/send?phone=7060137722&text=Welcom to Jigyasa University" target="_blank">
        <img src="../../../../assets/img/jigyasa/icon/wp_logo.png" width="22px" height="22px" alt="">
      </a>
      </div>
    </div>
    <div class="top_header">
      <div class="row">
        <div class="col-md-6 d-flex justify-content-between">
          <div class="social-list d-flex justify-content-between">
            <ul class="social-list d-flex justify-content-between align-items-center">
              <li>
               <a href="https://www.facebook.com/Jigyasauniversity" target="_blank"><img src="../../../../assets/img/jigyasa/icon/facebook.svg" width="100%" alt="facebook"></a>
              </li>
              <li><a href="https://www.linkedin.com/school/jigyasa-university/" target="_blank"><i class="fa fa-linkedin-square"></i></a></li>
              <li><a href="https://www.instagram.com/ju_ddn/" target="_blank"><img src="../../../../assets/img/jigyasa/icon/instagram.svg" alt="instagram"></a></li>
              <li><a href="https://www.youtube.com/@Jigyasauniversity" target="_blank"><img src="../../../../assets/img/jigyasa/icon/youtube.svg" alt="youtube"></a></li>
            </ul>
          </div>
          <div class="header_title">
            <a href="https://apply.jigyasauniversity.com/" target="_blank"><marquee behavior="" direction="">Admissions Open 2024 at Jigyasa University (Formerly Himgiri Zee University)<span class="click_text">"Click here"</span></marquee></a>
          </div>
        </div>
        <div class="col-md-6">
          <div class="menu_list">
            <ul class="d-flex justify-content-between align-items-center top_ul">
              <!-- <li>Portal</li> -->
              <li routerLink="/gallery">Gallery</li>
              <li routerLink="/alumni">Alumni</li>
              <li routerLink="/news/home">News</li>
              <li routerLink="/events/home">Events</li>
              <li routerLink="/iqac">IQAC</li>
              <li routerLink="/contactus"><div class="contact_us"> Contact Us </div></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="header">
      <nav>
        <div class="navbar">
          <i class='bx bx-menu'></i>
          <div class="logo">
            <a href="">
            <img src="../../../../assets/img/jigyasa/banner/JigyasaUniversityLogo.png" width="190px" height="70px" alt="jigyasa">
          </a>
          </div>
          <div class="nav-links">
            <ul class="links">
              <li>
                <a href="#" class="menuList" (mouseenter)="selectTab(1)" routerLink="/aboutUs">About Us</a>
               <div class="menu_list_content">
                <div class="menu_content">
                  <div class="row d-flex justify-content-center align-items-center">
                    <div class="col-12">
                      <div class="row">
                        <div class="col-5">
                        <img *ngIf="subMenu11" width="100%" src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1716555078028WhatsApp%20Image%202024-05-24%20at%206.18.38%20PM%20%281%29.jpeg" alt="About Us">
                       <img *ngIf="subMenu2" width="100%" src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1716555078028WhatsApp%20Image%202024-05-24%20at%206.18.38%20PM%20%281%29.jpeg" alt="About Us">
                       <img *ngIf="subMenu3" width="100%" src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1716555078028WhatsApp%20Image%202024-05-24%20at%206.18.38%20PM%20%281%29.jpeg" alt="About Us">
                       <img *ngIf="subMenu4" width="100%" src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1716555078028WhatsApp%20Image%202024-05-24%20at%206.18.38%20PM%20%281%29.jpeg" alt="About Us">
                       <img *ngIf="subMenu5" width="100%" src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1716555078028WhatsApp%20Image%202024-05-24%20at%206.18.38%20PM%20%281%29.jpeg" alt="About Us">
                       <img *ngIf="subMenu6" width="100%" src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1716555078028WhatsApp%20Image%202024-05-24%20at%206.18.38%20PM%20%281%29.jpeg" alt="About Us">
                       <img *ngIf="subMenu7" width="100%" src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1716555078028WhatsApp%20Image%202024-05-24%20at%206.18.38%20PM%20%281%29.jpeg" alt="About Us">
                       <img *ngIf="subMenu8" width="100%" src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1716555078028WhatsApp%20Image%202024-05-24%20at%206.18.38%20PM%20%281%29.jpeg" alt="About Us"> 
                       <img *ngIf="subMenu9" width="100%" src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1716555078028WhatsApp%20Image%202024-05-24%20at%206.18.38%20PM%20%281%29.jpeg" alt="About Us">
                      </div>
                        <div class="col-7 px-5">
                        <ul class="subUl">
                          <li (mouseenter)="selecMenu(1)"><a href="aboutUs#UniversityofTomorrow">The University</a></li>
                          <li (mouseenter)="selecMenu(2)"><a href="aboutUs#ourVision"> Vision & Mission </a></li>
                          <!-- <li (mouseenter)="selecMenu(3)"><a href="aboutUs#ourVision">  </a></li> -->
                        
                          <li (mouseenter)="selecMenu(5)"><a href="/leadership#Academic_Council"> Academic Council</a></li>
                          <li (mouseenter)="selecMenu(6)"><a href="aboutUs#ranksAccreditations"> Statutory Approvals</a></li>
                          <li (mouseenter)="selecMenu(7)"><a href="/leadership#Academic_Council"> Committees</a></li>
                          <li><a href="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1716384544031BOM.pdf" target="_blank"> Board of Management</a></li>
                          <li><a href="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1716384533578BOG.pdf" target="_blank"> Board of Governor </a></li>
                          <li (mouseenter)="selecMenu(8)"><a href="/nss"> NSS</a></li>
                          <li (mouseenter)="selecMenu(4)"><a href="/leadership"> Our Leadership</a></li>
                          <!-- <li (mouseenter)="selecMenu(9)">UPES Online</li> -->
                        </ul>
                        <!-- <ul class="subUl">
                          <li *ngFor="let dt of academiclist;let i=index" value="{{dt.name}}" routerLink="academics/{{dt.slug}}">{{dt.name}}</li>
                        </ul> -->
                        </div>
                      </div>
                    </div>
                    <!-- <div class="col-3">
                      <div *ngIf="subMenu1">
                        <ul class="subUl"> 
                         <li (mouseenter)="selecSubMenu(1)">Video- Banner</li>
                        </ul>
                      </div>
                      <div *ngIf="subMenu2">
                        <ul class="subUl">   
                        <li>Road Map</li>
                        </ul>
                      </div>
                      <div *ngIf="subMenu3">
                        <ul class="subUl">   
                          <li>B.Sc.</li>
                          <li>B.Tech. CSE</li>
                          <li>BCA</li>
                          <li>M.Tech. CSE</li>
                          <li>MCA</li>
                        </ul>
                      </div>
                      <div *ngIf="subMenu4">
                        <ul class="subUl">   
                          <li>B.Sc.</li>
                          <li>B.Tech. CSE</li>
                          <li>BCA</li>
                          <li>M.Tech. CSE</li>
                        </ul>
                      </div>
                      <div *ngIf="subMenu5">
                        <ul class="subUl">   
                          <li>B.Sc.</li>
                          <li>B.Tech. CSE</li>
                          <li>BCA</li>
                          <li>M.Tech. CSE</li>
                        </ul>
                      </div>
                      <div *ngIf="subMenu6">
                        <ul class="subUl">   
                          <li>B.Sc.</li>
                          <li>B.Tech. CSE</li>
                          <li>BCA</li>
                          <li>M.Tech. CSE</li>
                        </ul>
                      </div>
                      <div *ngIf="subMenu7">
                        <ul class="subUl">   
                          <li>B.Sc.</li>
                          <li>B.Tech. CSE</li>
                          <li>BCA</li>
                          <li>M.Tech. CSE</li>
                        </ul>
                      </div>
                      <div *ngIf="subMenu8">
                        <ul class="subUl">   
                          <li>B.Sc.</li>
                          <li>B.Tech. CSE</li>
                          <li>BCA</li>
                          <li>M.Tech. CSE</li>
                        </ul>
                      </div>
                      <div *ngIf="subMenu9">
                        <ul class="subUl">   
                          <li>B.Sc.</li>
                          <li>B.Tech. CSE</li>
                          <li>BCA</li>
                          <li>M.Tech. CSE</li>
                        </ul>
                      </div>
                    </div> -->
                  </div>
                </div>
               </div>
              </li>
              <li>
                <a href="#" class="menuList" (mouseenter)="selectTab(1)">Academics</a>
                <div class="menu_list_content">
                  <div class="menu_content">
                    <div class="row">
                      <div class="col-7">
                        <div class="row">
                          <div class="col-5">
                          <img *ngIf="subMenu11" width="100%" src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1716555092738WhatsApp%20Image%202024-05-24%20at%206.18.38%20PM%20%283%29.jpeg" alt="Academics">
                         <img *ngIf="subMenu2" width="100%" src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1716555092738WhatsApp%20Image%202024-05-24%20at%206.18.38%20PM%20%283%29.jpeg" alt="Academics">
                         <img *ngIf="subMenu3" width="100%" src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1716555092738WhatsApp%20Image%202024-05-24%20at%206.18.38%20PM%20%283%29.jpeg" alt="Academics">
                         <img *ngIf="subMenu4" width="100%" src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1716555092738WhatsApp%20Image%202024-05-24%20at%206.18.38%20PM%20%283%29.jpeg" alt="Academics">
                         <img *ngIf="subMenu5" width="100%" src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1716555092738WhatsApp%20Image%202024-05-24%20at%206.18.38%20PM%20%283%29.jpeg" alt="Academics">
                         <img *ngIf="subMenu6" width="100%" src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1716555092738WhatsApp%20Image%202024-05-24%20at%206.18.38%20PM%20%283%29.jpeg" alt="Academics">
                         <img *ngIf="subMenu7" width="100%" src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1716555092738WhatsApp%20Image%202024-05-24%20at%206.18.38%20PM%20%283%29.jpeg" alt="Academics">
                         <img *ngIf="subMenu8" width="100%" src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1716555092738WhatsApp%20Image%202024-05-24%20at%206.18.38%20PM%20%283%29.jpeg" alt="Academics"> 
                         <img *ngIf="subMenu9" width="100%" src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1716555092738WhatsApp%20Image%202024-05-24%20at%206.18.38%20PM%20%283%29.jpeg" alt="Academics">
                        </div>
                          <div class="col-7">
                          <ul class="subUl">
                            <!-- <li class="academic">Academics</li> -->
                            <li (mouseenter)="selecMenu(1)" ><a href="/school-of-agriculture-forestry-and-fisheries">School of Agriculture, Forestry and Fisheries</a></li>
                            <li (mouseenter)="selecMenu(2)"><a href="/school-of-science-and-technology">School of Science and Technology</a></li>
                            <li (mouseenter)="selecMenu(3)"><a href="/school-of-education-and-humanities">School of Education and Humanities</a></li>
                            <li (mouseenter)="selecMenu(4)"><a href="/school-of-journalism-and-mass-communication">School of Journalism and Mass Communication</a></li>
                            <li (mouseenter)="selecMenu(5)"><a href="/school-of-business-studies">School Of Business Studies</a></li>
                            <li (mouseenter)="selecMenu(6)"><a href="/school-of-hotel-and-hospitality-management">School of Hotel and Hospitality Management</a></li>
                            <li (mouseenter)="selecMenu(7)"><a href="/school-of-legal-studies">School of Legal Studies</a></li>
                            <li (mouseenter)="selecMenu(8)"><a href="/school-of-pharmaceutical-sciences">School of Pharmaceutical Sciences</a></li>
                           
                            <!-- <li (mouseenter)="selecMenu(9)">UPES Online</li> -->
                          </ul>
                          </div>
                        </div>
                      </div>
                      <div class="col-4">
                        <div *ngIf="subMenu1">
                          <ul class="subUl ul_padding"> 
                           <li><a href="/school-of-agriculture-forestry-and-fisheries/bsc-hons-agriculture">B.Sc. (Hons.) Agriculture</a></li>
                           <li><a href="/school-of-agriculture-forestry-and-fisheries/msc-agriculture-agronomy">M.Sc Agriculture (Agronomy)</a></li>
                           <li><a href="/school-of-agriculture-forestry-and-fisheries/msc-agriculture-genetics-and-plant-breeding">M.Sc Agriculture (Genetics and Plant Breeding)</a></li>
                           <li><a href="/school-of-agriculture-forestry-and-fisheries/phd-genetics-and-plant-breeding">Ph.D (Genetics and Plant Breeding)</a></li>
                          </ul>
                        </div>
                        <div *ngIf="subMenu2">
                          <ul class="subUl ul_padding">   
                          <li><a href="/school-of-science-and-technology/btech-cse">B.Tech - CSE</a></li>
                          <li><a href="/school-of-science-and-technology/bca">BCA</a></li>
                          <li><a href="/school-of-science-and-technology/ph-d">Ph. D.</a></li>
                          </ul>
                        </div>
                        <div *ngIf="subMenu3">
                          <ul class="subUl ul_padding">   
                            <li (mouseenter)="selecSubMenu(2)"><a href="/school-of-education-and-humanities/b-ed">B. Ed.</a></li>
                            <li (mouseenter)="selecSubMenu(1)"><a href="/school-of-education-and-humanities/b-a">B. A.</a></li>
                            <li (mouseenter)="selecSubMenu(3)"><a href="/school-of-education-and-humanities/m-a">M. A.</a> </li>
                            <li (mouseenter)="selecSubMenu(0)"><a href="/school-of-education-and-humanities/ph-d">Ph. D.</a></li>
                          </ul>
                        </div>
                        <div *ngIf="subMenu4">
                          <ul class="subUl ul_padding">   
                            <li (mouseenter)="selecSubMenu1(1)"><a href="/school-of-journalism-and-mass-communication/b-a">B. A. </a></li>
                            <!-- <li (mouseenter)="selecSubMenu(4)"><a href="/school-of-journalism-and-mass-communication/b-sc">B. Sc. </a></li> -->
                            <li (mouseenter)="selecSubMenu1(2)"><a href="/school-of-journalism-and-mass-communication/m-a">M. A. </a></li>
                            <li (mouseenter)="selecSubMenu1(0)"><a href="/school-of-journalism-and-mass-communication/zicazima">ZICA/ZIMA</a></li>
                            <li (mouseenter)="selecSubMenu1(0)"><a href="/school-of-journalism-and-mass-communication/ph-d">Ph. D.</a></li>
                          </ul>
                        </div>
                        <div *ngIf="subMenu5">
                          <ul class="subUl ul_padding">   
                            <li><a href="/school-of-business-studies/b-com">B. Com</a></li>
                            <li (mouseenter)="selecSubMenu1(3)"><a href="/school-of-business-studies/bba">B.B.A</a></li>
                            <li (mouseenter)="selecSubMenu1(4)"><a href="/school-of-business-studies/mba">M.B.A</a></li>
                            <li (mouseenter)="selecSubMenu1(0)"><a href="/school-of-business-studies/phd">Ph. D.</a></li>
                          </ul>
                        </div>
                        <div *ngIf="subMenu6">
                          <ul class="subUl ul_padding">   
                            <li><a href="/school-of-hotel-and-hospitality-management/certificate-in-hotel-management-chm">Certificate in Hotel Management (CHM)</a></li>
                            <li><a href="/school-of-hotel-and-hospitality-management/diploma-in-hotel-management-dhm">Diploma in Hotel Management (DHM)</a></li>
                            <li><a href="/school-of-hotel-and-hospitality-management/bachelor-of-hotel-management-bhm">Bachelor of Hotel Management (BHM)</a></li>
                            <li><a href="/school-of-hotel-and-hospitality-management/ba-restaurant-management">B.A Restaurant Management</a></li>
                          </ul>
                        </div>
                        <div *ngIf="subMenu7">
                          <ul class="subUl ul_padding">   
                            <li><a href="/school-of-legal-studies/ba-llb">BA LL.B</a></li>
                            <li><a href="/school-of-legal-studies/bba-llb">BBA LL.B</a></li>
                            <li><a href="/school-of-legal-studies/llb">LL.B</a></li>
                            <li><a href="/school-of-legal-studies/ba-llb/llm1-yearcriminal-laws-constitutional-law-and-human-rights-and-corporate-laws">
                              LL.M-1 Year(Criminal Laws, Constitutional Law and 
                              Human Rights and Corporate Laws)</a></li>
                            <li><a href="/school-of-legal-studies/ba-llb/llm2-yearcriminal-laws-constitutional-law-and-human-rights-and-corporate-laws">
                              LL.M-2 Year(Criminal Laws, Constitutional Law and
                              Human Rights and Corporate Laws)</a></li>
                            <li><a href="/school-of-legal-studies/ph-d">Ph.D.</a></li>
                          </ul>
                        </div>
                        <div *ngIf="subMenu8">
                          <ul class="subUl ul_padding">   
                            <li><a href="/school-of-pharmaceutical-sciences/d-pharm">D. Pharm</a></li>
                            <li><a href="/school-of-pharmaceutical-sciences/b-pharm">B. Pharm</a></li>
                            <li><a href="/school-of-pharmaceutical-sciences/b-pharm-lateral">B. Pharm (Lateral)</a></li>
                            <li><a href="/school-of-pharmaceutical-sciences/bsc-clinical-research">B.Sc. Clinical Research</a> </li>
                            <li><a href="/school-of-pharmaceutical-sciences/msc-clinical-research">M.Sc. Clinical Research</a></li>
                            <li><a href="/school-of-pharmaceutical-sciences/master-in-public-health">Master in Public Health</a></li>
                            <li><a href="/school-of-pharmaceutical-sciences/mba-health-care-and-hospital-management">MBA Health Care and Hospital Management</a></li>
                            <li><a href="/school-of-pharmaceutical-sciences/ph-d">Ph. D.</a></li>
                          </ul>
                        </div>
                      </div>
                      <!-- <div class="col-1">
                         <div *ngIf="subMenuType1">
                          <ul class="subUl"> 
                           <li><a href="/school-of-education-and-humanities/b-a-liberal-arts/liberal-arts"> Liberal Arts</a></li>
                           <li><a href="/school-of-education-and-humanities/b-a-liberal-arts/psychology"> Psychology</a></li>
                           <li><a href="/school-of-education-and-humanities/b-a-liberal-arts/international-relations"> International Relations</a></li>
                          </ul>
                        </div>
                        <div *ngIf="subMenuType2">
                          <ul class="subUl"> 
                           <li><a href="/school-of-education-and-humanities/b-ed/education">Education</a></li>
                          </ul>
                        </div>
                        <div *ngIf="subMenuType02">
                          <ul class="subUl"> 
                           <li><a href="/school-of-journalism-and-mass-communication/b-a/journalism-and-mass-comm">Journalism and Mass Comm</a></li>
                           <li><a href="/school-of-journalism-and-mass-communication/b-a/digital-journalism">Digital Journalism</a></li>
                          </ul>
                        </div>

                        <div *ngIf="subMenuType03">
                          <ul class="subUl"> 
                           <li><a href="/school-of-journalism-and-mass-communication/m-a-/journalism-and-mass-comm">Journalism and Mass Comm</a></li>
                          </ul>
                        </div>
                        <div *ngIf="subMenuType04">
                          <ul class="subUl"> 
                           <li><a href="/school-of-business-studies/bba/logistic-and-supply-chain-management">Logistic and Supply chain management</a></li>
                           <li><a href="/school-of-business-studies/bba/aviation-management">Aviation Management</a></li>
                          </ul>
                        </div>

                        <div *ngIf="subMenuType05">
                          <ul class="subUl"> 
                           <li><a class="anchr_height" href="/school-of-business-studies/mba/hr">HR</a></li>
                           <li><a class="anchr_height" href="/school-of-business-studies/mba/finance">Finance</a></li>
                           <li><a class="anchr_height" href="/school-of-business-studies/mba/marketing">Marketing</a></li>
                           <li><a class="anchr_height" href="/school-of-business-studies/mba/production-and-operation-management">Production and Operation Management</a></li>
                           <li><a class="anchr_height" href="/school-of-business-studies/mba/data-analytics">Data Analytics</a></li>
                           <li><a class="anchr_height" href="/school-of-business-studies/mba/entrepreneur-development-management">Entrepreneur Development Management</a></li>
                           <li><a class="anchr_height" href="/school-of-business-studies/mba/labour-and-legal-management">Labour and Legal Management</a></li>
                           <li><a class="anchr_height" href="/school-of-business-studies/mba/tourism-management">Tourism Management</a></li>
                          </ul>
                        </div>
                        <div *ngIf="subMenuType3">
                          <ul class="subUl"> 
                           <li><a href="/school-of-education-and-humanities/m-a/education">Education</a></li>
                           <li><a href="/school-of-education-and-humanities/m-a/clinical-psychology">Clinical Psychology</a></li>
                          </ul>
                        </div>
                      </div> -->
                      <div class="col-md-2"></div>
                      <div class="col-md-10 d-flex justify-content-end">
                        <a href="../../../../assets/pdf/AcadCalendar_2023_24.pdf" target="_blank"><button class="btn menu_tab_btn">Academic Calender</button></a>
                        <a href="../../../../assets/pdf/AcadRegulations_2023-24_18thJulyfinal.pdf" target="_blank"><button class="btn menu_tab_btn">Academic Regulations and Policies</button></a>
                        <a href="/flexibility"><button class="btn menu_tab_btn">Flexibility</button></a>
                        <a href="/policiesandnotices"><button class="btn menu_tab_btn">Policies and Notices</button></a>
                       </div>
                    </div>
                  </div>
                 </div>
              </li>
              <li>
                <a href="#"  class="menuList" (mouseenter)="selectTab(1)">Research</a>
                <div class="menu_list_content">
                  <div class="menu_content">
                    <div class="row justify-content-center align-items-center">
                      <div class="col-12">
                        <div class="row">
                          <div class="col-5">
                          <img *ngIf="subMenu11" width="100%" src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1716555085686WhatsApp%20Image%202024-05-24%20at%206.18.38%20PM%20%282%29.jpeg" alt="Reasearch">
                         <img *ngIf="subMenu2" width="100%" src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1716555085686WhatsApp%20Image%202024-05-24%20at%206.18.38%20PM%20%282%29.jpeg" alt="Reasearch">
                         <img *ngIf="subMenu3" width="100%" src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1716555085686WhatsApp%20Image%202024-05-24%20at%206.18.38%20PM%20%282%29.jpeg" alt="Reasearch">
                         <img *ngIf="subMenu4" width="100%" src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1716555085686WhatsApp%20Image%202024-05-24%20at%206.18.38%20PM%20%282%29.jpeg" alt="Reasearch">
                         <img *ngIf="subMenu5" width="100%" src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1716555085686WhatsApp%20Image%202024-05-24%20at%206.18.38%20PM%20%282%29.jpeg" alt="Reasearch">
                         <img *ngIf="subMenu6" width="100%" src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1716555085686WhatsApp%20Image%202024-05-24%20at%206.18.38%20PM%20%282%29.jpeg" alt="Reasearch">
                         <img *ngIf="subMenu7" width="100%" src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1716555085686WhatsApp%20Image%202024-05-24%20at%206.18.38%20PM%20%282%29.jpeg" alt="Reasearch">
                         <img *ngIf="subMenu8" width="100%" src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1716555085686WhatsApp%20Image%202024-05-24%20at%206.18.38%20PM%20%282%29.jpeg" alt="Reasearch"> 
                         <img *ngIf="subMenu9" width="100%" src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1716555085686WhatsApp%20Image%202024-05-24%20at%206.18.38%20PM%20%282%29.jpeg" alt="Reasearch">
                        </div>
                          <div class="col-7 px-5">
                          <ul class="subUl">
                            <li (mouseenter)="selecMenu(1)"><a href="/researchanddevelopment">R&D Cell</a></li>
                            <li (mouseenter)="selecMenu(2)"><a href="/intellectualProppertyRightCell">Intellectual Property Rights cell</a></li>
                            <li (mouseenter)="selecMenu(3)"><a href="/ph-d">Ph.D.@ JU</a></li>
                            <li (mouseenter)="selecMenu(4)"><a href="/patentsawards">Patents and Awards</a></li>
                            <li (mouseenter)="selecMenu(5)"><a href="/Research-Updates">Research Updates</a></li>
                            <!-- <li (mouseenter)="selecMenu(6)">School of Hotel and Hospitality Management</li>
                            <li (mouseenter)="selecMenu(7)">School of Legal Studies</li>
                            <li (mouseenter)="selecMenu(8)">School of Pharmaceutical Sciences</li> -->
                          </ul>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="col-3">
                        <div *ngIf="subMenu1">
                          <ul class="subUl"> 
                           <li><a href="/school-of-agriculture-forestry-and-fisheries/bsc-hons-agriculture">B.Sc. (Hons.) Agriculture</a></li>
                           <li><a href="/school-of-agriculture-forestry-and-fisheries/msc-agriculture-agronomy">M.Sc Agriculture (Agronomy)</a></li>
                           <li><a href="/school-of-agriculture-forestry-and-fisheries/msc-agriculture-genetics--plant-breeding">M.Sc Agriculture (Genetics & Plant Breeding)</a></li>
                           <li><a href="/school-of-agriculture-forestry-and-fisheries/phd-genetics--plant-breeding">Ph.D (Genetics & Plant Breeding)</a></li>
                          </ul>
                        </div>
                        <div *ngIf="subMenu2">
                          <ul class="subUl">   
                          <li><a href="/school-of-science--technology/btech--cse">B.Tech - CSE</a></li>
                          <li><a href="/school-of-science--technology/bca">BCA</a></li>
                          <li><a href="/school-of-science--technology/ph-d">Ph. D.</a></li>
                          </ul>
                        </div>
                        <div *ngIf="subMenu3">
                          <ul class="subUl">   
                            <li><a href="/school-of-education-and-humanities/b-ed">B. Ed.</a></li>
                            <li><a href="/school-of-education-and-humanities/b-a">B. A.</a></li>
                            <li><a href="/school-of-education-and-humanities/m-a">M. A. </a></li>
                            <li><a href="/school-of-education-and-humanities/ph-d">Ph. D.</a></li>
                          </ul>
                        </div>
                        <div *ngIf="subMenu4">
                          <ul class="subUl">   
                            <li><a href="/school-of-journalism-and-mass-communication/b-a">B. A. </a></li>
                            <li><a href="/school-of-journalism-and-mass-communication/b-sc">B. Sc.</a></li>
                            <li><a href="/school-of-journalism-and-mass-communication/m-a">M. A. </a></li>
                            <li><a href="/school-of-journalism-and-mass-communication/zicazima">ZICA/ZIMA</a></li>
                            <li><a href="/school-of-journalism-and-mass-communication/ph-d">Ph. D.</a></li>
                          </ul>
                        </div>
                        <div *ngIf="subMenu5">
                          <ul class="subUl">   
                            <li><a href="/school-of-business-studies/b-com">B. Com</a></li>
                            <li><a href="/school-of-business-studies/bba">B.B.A.</a></li>
                            <li><a href="/school-of-business-studies/mba">M.B.A.</a></li>
                            <li><a href="/school-of-business-studies/ph-d">Ph. D.</a></li>
                          </ul>
                        </div>
                        <div *ngIf="subMenu6">
                          <ul class="subUl">   
                            <li><a href="/school-of-hotel-and-hospitality-management/certificate-in-hotel-management-chm">Certificate in Hotel Management (CHM)</a></li>
                            <li><a href="/school-of-hotel-and-hospitality-management/diploma-in-hotel-management-dhm">Diploma in Hotel Management (DHM)</a></li>
                            <li><a href="/school-of-hotel-and-hospitality-management/bachelor-of-hotel-management-bhm">Bachelor of Hotel Management (BHM)</a></li>
                            <li><a href="/school-of-hotel-and-hospitality-management/ba-restaurant-management">B.A Restaurant Management</a></li>
                          </ul>
                        </div>
                        <div *ngIf="subMenu7">
                          <ul class="subUl">   
                            <li><a href="/school-of-legal-studies/ba-llb"></a>BA LL.B</li>
                            <li><a href="/school-of-legal-studies/bba-llb"></a>BBA LL.B</li>
                            <li><a href="/school-of-legal-studies/llb"></a>LL.B</li>
                            <li><a href="/school-of-legal-studies/llm1-yearcriminal-laws-constitutional-law--human-rights--corporate-laws">LL.M-1 Year(Criminal Laws, Constitutional Law &
                                                                    Human Rights & Corporate Laws)</a></li>
                            <li><a href="/school-of-legal-studies/llm2-yearcriminal-laws-constitutional-law--human-rights--corporate-laws">LL.M-2 Year(Criminal Laws, Constitutional Law &
                                                                    Human Rights & Corporate Laws)</a></li>
                            <li><a href="/school-of-legal-studies/ph-d">Ph.D.</a></li>
                          </ul>
                        </div>
                        <div *ngIf="subMenu8">
                          <ul class="subUl">   
                            <li><a href="/school-of-pharmaceutical-sciences/d-pharm">D. Pharm</a></li>
                            <li><a href="/school-of-pharmaceutical-sciences/b-pharm">B. Pharm</a></li>
                            <li><a href="/school-of-pharmaceutical-sciences/b-pharm-lateral">B. Pharm (Lateral)</a></li>
                            <li><a href="/school-of-pharmaceutical-sciences/bsc-clinical-research">B.Sc. Clinical Research </a></li>
                            <li><a href="/school-of-pharmaceutical-sciences/msc-clinical-research">M.Sc. Clinical Research</a></li>
                            <li><a href="/school-of-pharmaceutical-sciences/master-in-public-health">Master in Public Health</a></li>
                            <li><a href="/school-of-pharmaceutical-sciences/mba-health-care--hospital-management">MBA Health Care & Hospital Management</a></li>
                            <li><a href="/school-of-pharmaceutical-sciences/ph-d">Ph.D.</a></li>
                          </ul>
                        </div>
                      </div> -->
                    </div>
                  </div>
                 </div>
              </li>
              <li>
                <a href="#" class="menuList" (mouseenter)="selectTab(1)">Placement</a>
                <div class="menu_list_content">
                  <div class="menu_content">
                    <div class="row justify-content-center align-items-center">
                      <div class="col-12">
                        <div class="row">
                          <div class="col-5">
                          <img *ngIf="subMenu11" width="100%" src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1716555370330WhatsApp%20Image%202024-05-24%20at%206.24.07%20PM%20%281%29.jpeg" alt="Placement">
                         <img *ngIf="subMenu2" width="100%" src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1716555370330WhatsApp%20Image%202024-05-24%20at%206.24.07%20PM%20%281%29.jpeg" alt="Placement">
                         <img *ngIf="subMenu3" width="100%" src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1716555370330WhatsApp%20Image%202024-05-24%20at%206.24.07%20PM%20%281%29.jpeg" alt="Placement">
                         <img *ngIf="subMenu4" width="100%" src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1716555370330WhatsApp%20Image%202024-05-24%20at%206.24.07%20PM%20%281%29.jpeg" alt="Placement">
                         <img *ngIf="subMenu5" width="100%" src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1716555370330WhatsApp%20Image%202024-05-24%20at%206.24.07%20PM%20%281%29.jpeg" alt="Placement">
                         <img *ngIf="subMenu6" width="100%" src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1716555370330WhatsApp%20Image%202024-05-24%20at%206.24.07%20PM%20%281%29.jpeg" alt="Placement">
                         <img *ngIf="subMenu7" width="100%" src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1716555370330WhatsApp%20Image%202024-05-24%20at%206.24.07%20PM%20%281%29.jpeg" alt="Placement">
                         <img *ngIf="subMenu8" width="100%" src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1716555370330WhatsApp%20Image%202024-05-24%20at%206.24.07%20PM%20%281%29.jpeg" alt="Placement"> 
                         <img *ngIf="subMenu9" width="100%" src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1716555370330WhatsApp%20Image%202024-05-24%20at%206.24.07%20PM%20%281%29.jpeg" alt="Placement">
                        </div>
                          <div class="col-7 px-5">
                          <ul class="subUl">
                            <li (mouseenter)="selecMenu(1)"><a href="/trainings-and-placement-cell"> T & P Cell</a></li>
                            <li (mouseenter)="selecMenu(2)"><a href="/career-counselling-cell">Career Counselling Cell</a></li>
                            <li (mouseenter)="selecMenu(3)"><a href="/internship">Internships @ JU</a></li>
                            <li (mouseenter)="selecMenu(4)"><a>Industry Collaborations and MoU’s</a></li>
                            <li (mouseenter)="selecMenu(5)"><a href="/placement-statistics">Placement Tracker</a></li>
                            <li><a href="student-placement">Welcome</a></li>
                            <!-- <li (mouseenter)="selecMenu(6)">School of Hotel and Hospitality Management</li>
                            <li (mouseenter)="selecMenu(7)">School of Legal Studies</li>
                            <li (mouseenter)="selecMenu(8)">School of Pharmaceutical Sciences</li> -->
                          </ul>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="col-3">
                        <div *ngIf="subMenu1">
                          <ul class="subUl"> 
                           <li>B.Sc. (Hons.) Agriculture</li>
                           <li>M.Sc Agriculture (Agronomy)</li>
                           <li>M.Sc Agriculture (Genetics & Plant Breeding)</li>
                           <li>Ph.D (Genetics & Plant Breeding)</li>
                          </ul>
                        </div>
                        <div *ngIf="subMenu2">
                          <ul class="subUl">   
                          <li>B.Tech - CSE</li>
                          <li>BCA</li>
                          <li>Ph. D.</li>
                          </ul>
                        </div>
                        <div *ngIf="subMenu3">
                          <ul class="subUl">   
                            <li>B. Ed.</li>
                            <li>B. A.</li>
                            <li>M. A. </li>
                            <li>Ph. D.</li>
                          </ul>
                        </div>
                        <div *ngIf="subMenu4">
                          <ul class="subUl">   
                            <li>B. A. </li>
                            <li>B. Sc. </li>
                            <li>M. A. </li>
                            <li>ZICA/ZIMA</li>
                            <li>Ph. D.</li>
                          </ul>
                        </div>
                        <div *ngIf="subMenu5">
                          <ul class="subUl">   
                            <li>B. Com</li>
                            <li>B.B.A.</li>
                            <li>M.B.A.</li>
                            <li>Ph. D.</li>
                          </ul>
                        </div>
                        <div *ngIf="subMenu6">
                          <ul class="subUl">   
                            <li>Certificate in Hotel Management (CHM)</li>
                            <li>Diploma in Hotel Management (DHM)</li>
                            <li>Bachelor of Hotel Management (BHM)</li>
                            <li>B.A Restaurant Management</li>
                          </ul>
                        </div>
                        <div *ngIf="subMenu7">
                          <ul class="subUl">   
                            <li>BA LL.B</li>
                            <li>BBA LL.B</li>
                            <li>LL.B</li>
                            <li>LL.M-1 Year(Criminal Laws, Constitutional Law &</li>
                            <li>Human Rights & Corporate Laws)</li>
                            <li>LL.M-2 Year(Criminal Laws, Constitutional Law &</li>
                            <li>Human Rights & Corporate Laws)</li>
                            <li>Ph.D.</li>

                          </ul>
                        </div>
                        <div *ngIf="subMenu8">
                          <ul class="subUl">   
                            <li>D. Pharm</li>
                            <li>B. Pharm</li>
                            <li>B. Pharm (Lateral)</li>
                            <li>B.Sc. Clinical Research </li>
                            <li>M.Sc. Clinical Research</li>
                            <li>Master in Public Health</li>
                            <li>MBA Health Care &</li>
                            <li>Hospital Management</li>
                            <li>Ph.D.</li>
                          </ul>
                        </div>
                      </div> -->
                    </div>
                  </div>
                 </div>
              </li>
              <li>
                <a href="#" class="menuList" (mouseenter)="selectTab(1)">Resources</a>
                <div class="menu_list_content">
                  <div class="menu_content">
                    <div class="row justify-content-center align-items-center">
                      <div class="col-12">
                        <div class="row">
                          <div class="col-5">
                          <img *ngIf="subMenu11" width="100%" src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1716555380942WhatsApp%20Image%202024-05-24%20at%206.24.07%20PM.jpeg" alt="Resources">
                         <img *ngIf="subMenu2" width="100%" src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1716555380942WhatsApp%20Image%202024-05-24%20at%206.24.07%20PM.jpeg" alt="Resources">
                         <img *ngIf="subMenu3" width="100%" src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1716555380942WhatsApp%20Image%202024-05-24%20at%206.24.07%20PM.jpeg" alt="Resources">
                         <img *ngIf="subMenu4" width="100%" src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1716555380942WhatsApp%20Image%202024-05-24%20at%206.24.07%20PM.jpeg" alt="Resources">
                         <img *ngIf="subMenu5" width="100%" src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1716555380942WhatsApp%20Image%202024-05-24%20at%206.24.07%20PM.jpeg" alt="Resources">
                         <img *ngIf="subMenu6" width="100%" src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1716555380942WhatsApp%20Image%202024-05-24%20at%206.24.07%20PM.jpeg" alt="Resources">
                         <img *ngIf="subMenu7" width="100%" src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1716555380942WhatsApp%20Image%202024-05-24%20at%206.24.07%20PM.jpeg" alt="Resources">
                         <img *ngIf="subMenu8" width="100%" src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1716555380942WhatsApp%20Image%202024-05-24%20at%206.24.07%20PM.jpeg" alt="Resources"> 
                         <img *ngIf="subMenu9" width="100%" src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1716555380942WhatsApp%20Image%202024-05-24%20at%206.24.07%20PM.jpeg" alt="Resources">
                        </div>
                          <div class="col-7 px-5">
                          <ul class="subUl">
                            <!-- <li (mouseenter)="selecMenu(1)"><a>Academic Resources</a></li>
                            <li (mouseenter)="selecMenu(2)"><a>Recreational Resources</a></li>
                            <li (mouseenter)="selecMenu(3)"><a>Medical Aid</a></li> -->
                            <!-- <li (mouseenter)="selecMenu(4)"><a>Transport Facilities</a></li> -->
                            <li><a href="/clubs">Clubs</a></li>
                            <li (mouseenter)="selecMenu(4)"><a href="/library_facilities">Library</a></li>
                            <!-- <li (mouseenter)="selecMenu(6)">School of Hotel and Hospitality Management</li>
                            <li (mouseenter)="selecMenu(7)">School of Legal Studies</li>
                            <li (mouseenter)="selecMenu(8)">School of Pharmaceutical Sciences</li> -->
                          </ul>
                          </div>
                        </div>
                      </div>
                      <!-- <div class="col-3">
                        <div *ngIf="subMenu1">
                          <ul class="subUl"> 
                           <li>B.Sc. (Hons.) Agriculture</li>
                           <li>M.Sc Agriculture (Agronomy)</li>
                           <li>M.Sc Agriculture (Genetics & Plant Breeding)</li>
                           <li>Ph.D (Genetics & Plant Breeding)</li>
                          </ul>
                        </div>
                        <div *ngIf="subMenu2">
                          <ul class="subUl">   
                          <li>B.Tech - CSE</li>
                          <li>BCA</li>
                          <li>Ph. D.</li>
                          </ul>
                        </div>
                        <div *ngIf="subMenu3">
                          <ul class="subUl">   
                            <li>B. Ed.</li>
                            <li>B. A.</li>
                            <li>M. A. </li>
                            <li>Ph. D.</li>
                          </ul>
                        </div>
                        <div *ngIf="subMenu4">
                          <ul class="subUl">   
                            <li>B. A. </li>
                            <li>B. Sc. </li>
                            <li>M. A. </li>
                            <li>ZICA/ZIMA</li>
                            <li>Ph. D.</li>
                          </ul>
                        </div>
                        <div *ngIf="subMenu5">
                          <ul class="subUl">   
                            <li>B. Com</li>
                            <li>B.B.A.</li>
                            <li>M.B.A.</li>
                            <li>Ph. D.</li>
                          </ul>
                        </div>
                        <div *ngIf="subMenu6">
                          <ul class="subUl">   
                            <li>Certificate in Hotel Management (CHM)</li>
                            <li>Diploma in Hotel Management (DHM)</li>
                            <li>Bachelor of Hotel Management (BHM)</li>
                            <li>B.A Restaurant Management</li>
                          </ul>
                        </div>
                        <div *ngIf="subMenu7">
                          <ul class="subUl">   
                            <li>BA LL.B</li>
                            <li>BBA LL.B</li>
                            <li>LL.B</li>
                            <li>LL.M-1 Year(Criminal Laws, Constitutional Law &</li>
                            <li>Human Rights & Corporate Laws)</li>
                            <li>LL.M-2 Year(Criminal Laws, Constitutional Law &</li>
                            <li>Human Rights & Corporate Laws)</li>
                            <li>Ph.D.</li>

                          </ul>
                        </div>
                        <div *ngIf="subMenu8">
                          <ul class="subUl">   
                            <li>D. Pharm</li>
                            <li>B. Pharm</li>
                            <li>B. Pharm (Lateral)</li>
                            <li>B.Sc. Clinical Research </li>
                            <li>M.Sc. Clinical Research</li>
                            <li>Master in Public Health</li>
                            <li>MBA Health Care &</li>
                            <li>Hospital Management</li>
                            <li>Ph.D.</li>
                          </ul>
                        </div>
                      </div> -->
                    </div>
                  </div>
                 </div>
              </li>
             <!-- <li>campus</li> -->
              <li>
                <a href="#" class="menuList" (mouseenter)="selectTab(1)">Campus Life</a>
                <div class="menu_list_content">
                  <div class="menu_content">
                    <div class="row justify-content-center align-items-center">
                      <div class="col-12">
                        <div class="row">
                          <div class="col-5">
                          <img *ngIf="subMenu11" width="100%" src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1716555100661WhatsApp%20Image%202024-05-24%20at%206.18.38%20PM%20%284%29.jpeg" alt="Campus">
                         <img *ngIf="subMenu2" width="100%" src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1716555100661WhatsApp%20Image%202024-05-24%20at%206.18.38%20PM%20%284%29.jpeg" alt="Campus">
                         <img *ngIf="subMenu3" width="100%" src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1716555100661WhatsApp%20Image%202024-05-24%20at%206.18.38%20PM%20%284%29.jpeg" alt="Campus">
                         <img *ngIf="subMenu4" width="100%" src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1716555100661WhatsApp%20Image%202024-05-24%20at%206.18.38%20PM%20%284%29.jpeg" alt="Campus">
                         <img *ngIf="subMenu5" width="100%" src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1716555100661WhatsApp%20Image%202024-05-24%20at%206.18.38%20PM%20%284%29.jpeg" alt="Campus">
                         <img *ngIf="subMenu6" width="100%" src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1716555100661WhatsApp%20Image%202024-05-24%20at%206.18.38%20PM%20%284%29.jpeg" alt="Campus">
                         <img *ngIf="subMenu7" width="100%" src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1716555100661WhatsApp%20Image%202024-05-24%20at%206.18.38%20PM%20%284%29.jpeg" alt="Campus">
                         <img *ngIf="subMenu8" width="100%" src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1716555100661WhatsApp%20Image%202024-05-24%20at%206.18.38%20PM%20%284%29.jpeg" alt="Campus"> 
                         <img *ngIf="subMenu9" width="100%" src="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1716555100661WhatsApp%20Image%202024-05-24%20at%206.18.38%20PM%20%284%29.jpeg" alt="Campus">
                        </div>
                          <div class="col-7 px-5">
                          <ul class="subUl">
                            <li (mouseenter)="selecMenu(1)"><a href="/hostel">Hostel Facilities</a></li>
                            <li (mouseenter)="selecMenu(2)"><a href="/sports">Sports Facilities</a></li>
                            <li (mouseenter)="selecMenu(3)"><a href="/swimming-sool">Swimming Pool</a></li>
                            <li (mouseenter)="selecMenu(4)"><a href="/medical-facility">Medical Facilities</a></li>
                            <li (mouseenter)="selecMenu(5)"><a href="/library_facilities">Library Facilities</a></li>
                            <li (mouseenter)="selecMenu(6)"><a href="/transport">Transport Facilities</a></li>
                            <li (mouseenter)="selecMenu(7)"><a href="/cafeteria">Cafeteria</a></li>
                          </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                 </div>
              </li>
            </ul>
          </div>
          <div class="search-box">
            <!-- <i class='bx bx-search'></i> -->
            <div class="input-box">
              <input type="text" placeholder="Search...">
            </div>
          </div>
        </div>
      </nav>
    </div>
  </header>
  <!-- <header class="desktop_header">
    <div class="top_header">
      <div class="row">
        <div class="col-md-6 d-flex justify-content-between">
          <div class="social-list d-flex justify-content-between">
            <ul class="social-list d-flex justify-content-between align-items-center">
              <li>
                <img src="../../../../assets/img/jigyasa/icon/facebook.svg" width="100%" alt="facebook">
              </li>
              <li><img src="../../../../assets/img/jigyasa/icon/twitter.svg" alt="twitter"></li>
              <li><img src="../../../../assets/img/jigyasa/icon/instagram.svg" alt="instagram"></li>
              <li><img src="../../../../assets/img/jigyasa/icon/youtube.svg" alt="youtube"></li>
            </ul>
          </div>
        </div>
        <div class="col-md-6">
          <div class="menu_list">
            <ul class="d-flex justify-content-between align-items-center top_ul">
              <li>Portal</li>
              <li>Gallery</li>
              <li>Alumni</li>
              <li>News & Events</li>
              <li><div class="contact_us"> Contact Us </div></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="header">
      <nav>
        <div class="navbar">
          <i class='bx bx-menu'></i>
          <div class="logo">
            <a href="">
            <img src="../../../../assets/img/jigyasa/JigyasaUniversityLogo.png" width="65px" height="65px" alt="jigyasa">
          </a>
          </div>
          <div class="nav-links">
            <ul class="links">
              <li>
                <a href="#" class="menuList" (mouseenter)="selectTab(1)" routerLink="/aboutUs">About Us</a>
               <div class="menu_list_content">
                <div class="menu_content">
                  <div class="row">
                    <div class="col-9">
                      <div class="row">
                        <div class="col-7">
                        <img *ngIf="subMenu11" width="100%" src="https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-09/header-SOAE_0.webp" alt="">
                       <img *ngIf="subMenu2" width="100%" src="https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-09/header-SOB.webp" alt="">
                       <img *ngIf="subMenu3" width="100%" src="	https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-09/header-SOCS.webp" alt="">
                       <img *ngIf="subMenu4" width="100%" src="	https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-09/header-SOD.webp" alt="">
                       <img *ngIf="subMenu5" width="100%" src="	https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-09/header-SOD.webp" alt="">
                       <img *ngIf="subMenu6" width="100%" src="https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-09/header-SOHST.webp" alt="">
                       <img *ngIf="subMenu7" width="100%" src="	https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-09/header-SOL.webp" alt="">
                       <img *ngIf="subMenu8" width="100%" src="	https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-09/header-SFL.webp" alt=""> 
                       <img *ngIf="subMenu9" width="100%" src="https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-09/header-SOB.webp" alt="">
                      </div>
                        <div class="col-5">
                        <ul class="subUl">
                          <li (mouseenter)="selecMenu(1)">The University</li>
                          <li (mouseenter)="selecMenu(2)">Vision</li>
                          <li (mouseenter)="selecMenu(3)">Mission</li>
                          <li (mouseenter)="selecMenu(4)">University Authorities/Our Leadership</li>
                          <li (mouseenter)="selecMenu(5)">Academic Council</li>
                          <li (mouseenter)="selecMenu(6)">Statutory Approvals</li>
                          <li (mouseenter)="selecMenu(7)">Committees</li>
                          <li (mouseenter)="selecMenu(8)">NSS</li>
                        </ul>
                        </div>
                      </div>
                    </div>
                   </div>
                </div>
               </div>
              </li>
              <li>
                <a href="#" class="menuList" (mouseenter)="selectTab(1)">Academics</a>
                <div class="menu_list_content">
                  <div class="menu_content">
                    <div class="row">
                      <div class="col-6">
                        <div class="row">
                          <div class="col-7">
                          <img *ngIf="subMenu11" width="100%" src="https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-09/header-SOAE_0.webp" alt="">
                         <img *ngIf="subMenu2" width="100%" src="https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-09/header-SOB.webp" alt="">
                         <img *ngIf="subMenu3" width="100%" src="	https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-09/header-SOCS.webp" alt="">
                         <img *ngIf="subMenu4" width="100%" src="	https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-09/header-SOD.webp" alt="">
                         <img *ngIf="subMenu5" width="100%" src="	https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-09/header-SOD.webp" alt="">
                         <img *ngIf="subMenu6" width="100%" src="https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-09/header-SOHST.webp" alt="">
                         <img *ngIf="subMenu7" width="100%" src="	https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-09/header-SOL.webp" alt="">
                         <img *ngIf="subMenu8" width="100%" src="	https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-09/header-SFL.webp" alt=""> 
                         <img *ngIf="subMenu9" width="100%" src="https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-09/header-SOB.webp" alt="">
                        </div>
                          <div class="col-5">
                          <ul class="subUl">
                            <li (mouseenter)="selecMenu(1)"><a href="/school-of-agriculture-forestry-and-fisheries">School of Agriculture, Forestry and Fisheries</a></li>
                            <li (mouseenter)="selecMenu(2)"><a href="/school-of-science--technology">School of Science & Technology</a></li>
                            <li (mouseenter)="selecMenu(3)"><a href="/school-of-education-and-humanities">School of Education and Humanities</a></li>
                            <li (mouseenter)="selecMenu(4)"><a href="/school-of-journalism-and-mass-communication">School of Journalism and Mass Communication</a></li>
                            <li (mouseenter)="selecMenu(5)"><a href="/school-of-business-studies">School Of Business Studies</a></li>
                            <li (mouseenter)="selecMenu(6)"><a href="/school-of-hotel-and-hospitality-management">School of Hotel and Hospitality Management</a></li>
                            <li (mouseenter)="selecMenu(7)"><a href="/school-of-legal-studies">School of Legal Studies</a></li>
                            <li (mouseenter)="selecMenu(8)"><a href="/school-of-pharmaceutical-sciences">School of Pharmaceutical Sciences</a></li>
                          </ul>
                          </div>
                        </div>
                      </div>
                      <div class="col-3">
                        <div *ngIf="subMenu1">
                          <ul class="subUl"> 
                           <li><a href="/school-of-agriculture-forestry-and-fisheries/bsc-hons-agriculture">B.Sc. (Hons.) Agriculture</a></li>
                           <li><a href="/school-of-agriculture-forestry-and-fisheries/msc-agriculture-agronomy">M.Sc Agriculture (Agronomy)</a></li>
                           <li><a href="/school-of-agriculture-forestry-and-fisheries/msc-agriculture-genetics--plant-breeding">M.Sc Agriculture (Genetics & Plant Breeding)</a></li>
                           <li><a href="/school-of-agriculture-forestry-and-fisheries/phd-genetics--plant-breeding">Ph.D (Genetics & Plant Breeding)</a></li>
                          </ul>
                        </div>
                        <div *ngIf="subMenu2">
                          <ul class="subUl">   
                          <li><a href="/school-of-science--technology/b-ed">B.Tech - CSE</a></li>
                          <li><a href="/school-of-science--technology/bca">BCA</a></li>
                          <li><a href="/school-of-science--technology/ph-d">Ph. D.</a></li>
                          </ul>
                        </div>
                        <div *ngIf="subMenu3">
                          <ul class="subUl">   
                            <li (mouseenter)="selecSubMenu(1)"><a href="/school-of-education-and-humanities/b-ed">B. Ed.</a></li>
                            <li (mouseenter)="selecSubMenu(2)"><a href="/school-of-education-and-humanities/b-a">B. A.</a></li>
                            <li><a href="/school-of-education-and-humanities/m-a">M. A.</a> </li>
                            <li><a href="/school-of-education-and-humanities/ph-d">Ph. D.</a></li>
                          </ul>
                        </div>
                        <div *ngIf="subMenu4">
                          <ul class="subUl">   
                            <li (mouseenter)="selecSubMenu(3)"><a href="/school-of-journalism-and-mass-communication/b-a">B. A. </a></li>
                            <li (mouseenter)="selecSubMenu(4)"><a href="/school-of-journalism-and-mass-communication/b-sc">B. Sc. </a></li>
                            <li (mouseenter)="selecSubMenu(5)"><a href="/school-of-journalism-and-mass-communication/m-a">M. A. </a></li>
                            <li><a href="/school-of-journalism-and-mass-communication/zicazima">ZICA/ZIMA</a></li>
                            <li><a href="/school-of-journalism-and-mass-communication/ph-d">Ph. D.</a></li>
                          </ul>
                        </div>
                        <div *ngIf="subMenu5">
                          <ul class="subUl">   
                            <li><a href="/school-of-business-studies/b-com">B. Com</a></li>
                            <li (mouseenter)="selecSubMenu(6)"><a href="/school-of-business-studies/bba">B.B.A.</a></li>
                            <li (mouseenter)="selecSubMenu(7)"><a href="/school-of-business-studies/mba">M.B.A.</a></li>
                            <li><a href="/school-of-business-studies/phd">Ph. D.</a></li>
                          </ul>
                        </div>
                        <div *ngIf="subMenu6">
                          <ul class="subUl">   
                            <li><a href="/school-of-hotel-and-hospitality-management/certificate-in-hotel-management-chm">Certificate in Hotel Management (CHM)</a></li>
                            <li><a href="/school-of-hotel-and-hospitality-management/diploma-in-hotel-management-dhm">Diploma in Hotel Management (DHM)</a></li>
                            <li><a href="/school-of-hotel-and-hospitality-management/bachelor-of-hotel-management-bhm">Bachelor of Hotel Management (BHM)</a></li>
                            <li><a href="/school-of-hotel-and-hospitality-management/ba-restaurant-management">B.A Restaurant Management</a></li>
                          </ul>
                        </div>
                        <div *ngIf="subMenu7">
                          <ul class="subUl">   
                            <li><a href="/school-of-legal-studies/ba-llb">BA LL.B</a></li>
                            <li><a href="/school-of-legal-studies/bba-llb">BBA LL.B</a></li>
                            <li><a href="/school-of-legal-studies/llb">LL.B</a></li>
                            <li><a href="/school-of-legal-studies/ba-llb/llm1-yearcriminal-laws-constitutional-law--human-rights--corporate-laws">
                              LL.M-1 Year(Criminal Laws, Constitutional Law & 
                              Human Rights & Corporate Laws)</a></li>
                            <li><a href="/school-of-legal-studies/ba-llb/llm2-yearcriminal-laws-constitutional-law--human-rights--corporate-laws">
                              LL.M-2 Year(Criminal Laws, Constitutional Law &
                              Human Rights & Corporate Laws)</a></li>
                            <li><a href="/school-of-legal-studies/ph-d">Ph.D.</a></li>
                          </ul>
                        </div>
                        <div *ngIf="subMenu8">
                          <ul class="subUl">   
                            <li><a href="/school-of-pharmaceutical-sciences/d-pharm">D. Pharm</a></li>
                            <li><a href="/school-of-pharmaceutical-sciences/b-pharm">B. Pharm</a></li>
                            <li><a href="/school-of-pharmaceutical-sciences/b-pharm-lateral">B. Pharm (Lateral)</a></li>
                            <li><a href="/school-of-pharmaceutical-sciences/bsc-clinical-research">B.Sc. Clinical Research</a> </li>
                            <li><a href="/school-of-pharmaceutical-sciences/msc-clinical-research">M.Sc. Clinical Research</a></li>
                            <li><a href="/school-of-pharmaceutical-sciences/master-in-public-health">Master in Public Health</a></li>
                            <li><a href="/school-of-pharmaceutical-sciences/mba-health-care--hospital-management">MBA Health Care & Hospital Management</a></li>
                            <li><a href="/school-of-pharmaceutical-sciences/ph-d">Ph. D.</a></li>
                          </ul>
                        </div>
                      </div>
                      <div class="col-3">
                        <div *ngIf="subMenuType1">
                          <ul class="subUl"> 
                           <li>Liberal Arts</li>
                          </ul>
                        </div>
                        <div *ngIf="subMenuType2">
                          <ul class="subUl"> 
                           <li>Education</li>
                          </ul>
                        </div>

                        <div *ngIf="subMenuType3">
                          <ul class="subUl"> 
                           <li>Journalism & Mass Comm</li>
                          </ul>
                        </div>
                        <div *ngIf="subMenuType4">
                          <ul class="subUl"> 
                           <li>Animation & Graphics Design</li>
                          </ul>
                        </div>
                        <div *ngIf="subMenuType5">
                          <ul class="subUl"> 
                           <li>Journalism & Mass Comm</li>
                          </ul>
                        </div>
                        <div *ngIf="subMenuType6">
                          <ul class="subUl"> 
                           <li>Logistic and Supply chain management</li>
                          </ul>
                        </div>
                        <div *ngIf="subMenuType7">
                          <ul class="subUl"> 
                           <li>HR</li>
                           <li>Finance</li>
                           <li>Marketing</li>
                           <li>Production & Operation Management</li>
                           <li>Data Analytics</li>
                           <li>Entrepreneur Development Management</li>
                           <li>Labour & Legal Management</li>
                           <li>Tourism Management</li>
                          </ul>
                        </div>
                      </div>
                      <div class="col-md-6"></div>
                      <div class="col-md-6 d-flex justify-content-end">
                        <button class="btn menu_tab_btn">Academic Calender</button>
                        <button class="btn menu_tab_btn">Academic Regulations and Policies</button>
                      </div>
                      </div>
                  </div>
                 </div>
              </li>
              <li>
                <a href="#"  class="menuList" (mouseenter)="selectTab(1)">Research</a>
                <div class="menu_list_content">
                  <div class="menu_content">
                    <div class="row">
                      <div class="col-9">
                        <div class="row">
                          <div class="col-7">
                          <img *ngIf="subMenu11" width="100%" src="https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-09/header-SOAE_0.webp" alt="">
                         <img *ngIf="subMenu2" width="100%" src="https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-09/header-SOB.webp" alt="">
                         <img *ngIf="subMenu3" width="100%" src="	https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-09/header-SOCS.webp" alt="">
                         <img *ngIf="subMenu4" width="100%" src="	https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-09/header-SOD.webp" alt="">
                         <img *ngIf="subMenu5" width="100%" src="	https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-09/header-SOD.webp" alt="">
                         <img *ngIf="subMenu6" width="100%" src="https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-09/header-SOHST.webp" alt="">
                         <img *ngIf="subMenu7" width="100%" src="	https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-09/header-SOL.webp" alt="">
                         <img *ngIf="subMenu8" width="100%" src="	https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-09/header-SFL.webp" alt=""> 
                         <img *ngIf="subMenu9" width="100%" src="https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-09/header-SOB.webp" alt="">
                        </div>
                          <div class="col-5">
                          <ul class="subUl">
                            <li (mouseenter)="selecMenu(1)">R&D Cell</li>
                            <li (mouseenter)="selecMenu(2)">Intellectual Property Rights cell</li>
                            <li (mouseenter)="selecMenu(3)">Ph.D.@ JU</li>
                            <li (mouseenter)="selecMenu(4)">Patents and Awards</li>
                            <li (mouseenter)="selecMenu(5)">Research Updates</li>
                          </ul>
                          </div>
                        </div>
                      </div>
                     </div>
                  </div>
                 </div>
              </li>
              <li>
                <a href="#" class="menuList" (mouseenter)="selectTab(1)">Placements</a>
                <div class="menu_list_content">
                  <div class="menu_content">
                    <div class="row">
                      <div class="col-9">
                        <div class="row">
                          <div class="col-7">
                          <img *ngIf="subMenu11" width="100%" src="https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-09/header-SOAE_0.webp" alt="">
                         <img *ngIf="subMenu2" width="100%" src="https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-09/header-SOB.webp" alt="">
                         <img *ngIf="subMenu3" width="100%" src="	https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-09/header-SOCS.webp" alt="">
                         <img *ngIf="subMenu4" width="100%" src="	https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-09/header-SOD.webp" alt="">
                         <img *ngIf="subMenu5" width="100%" src="	https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-09/header-SOD.webp" alt="">
                         <img *ngIf="subMenu6" width="100%" src="https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-09/header-SOHST.webp" alt="">
                         <img *ngIf="subMenu7" width="100%" src="	https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-09/header-SOL.webp" alt="">
                         <img *ngIf="subMenu8" width="100%" src="	https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-09/header-SFL.webp" alt=""> 
                         <img *ngIf="subMenu9" width="100%" src="https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-09/header-SOB.webp" alt="">
                        </div>
                          <div class="col-5">
                          <ul class="subUl">
                            <li (mouseenter)="selecMenu(1)">T & P Cell</li>
                            <li (mouseenter)="selecMenu(2)">Career Counselling Cell</li>
                            <li (mouseenter)="selecMenu(3)">Internships @ JU</li>
                            <li (mouseenter)="selecMenu(4)">Industry Collaborations and MoU’s</li>
                            <li (mouseenter)="selecMenu(5)">Placement Tracker</li>
                          </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                 </div>
              </li>
              <li>
                <a href="#" class="menuList" (mouseenter)="selectTab(1)">Resources</a>
                <div class="menu_list_content">
                  <div class="menu_content">
                    <div class="row">
                      <div class="col-9">
                        <div class="row">
                          <div class="col-7">
                          <img *ngIf="subMenu11" width="100%" src="https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-09/header-SOAE_0.webp" alt="">
                         <img *ngIf="subMenu2" width="100%" src="https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-09/header-SOB.webp" alt="">
                         <img *ngIf="subMenu3" width="100%" src="	https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-09/header-SOCS.webp" alt="">
                         <img *ngIf="subMenu4" width="100%" src="	https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-09/header-SOD.webp" alt="">
                         <img *ngIf="subMenu5" width="100%" src="	https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-09/header-SOD.webp" alt="">
                         <img *ngIf="subMenu6" width="100%" src="https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-09/header-SOHST.webp" alt="">
                         <img *ngIf="subMenu7" width="100%" src="	https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-09/header-SOL.webp" alt="">
                         <img *ngIf="subMenu8" width="100%" src="	https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-09/header-SFL.webp" alt=""> 
                         <img *ngIf="subMenu9" width="100%" src="https://upes-production-cvb3e7frghdda0a4.z01.azurefd.net/drupal-data/2023-09/header-SOB.webp" alt="">
                        </div>
                          <div class="col-5">
                          <ul class="subUl">
                            <li (mouseenter)="selecMenu(1)">Academic Resources</li>
                            <li (mouseenter)="selecMenu(2)">Recreational Resources</li>
                            <li (mouseenter)="selecMenu(3)">Medical Aid</li>
                            <li (mouseenter)="selecMenu(4)">Transport Facilities</li>
                          </ul>
                          </div>
                        </div>
                      </div>
                    
                    </div>
                  </div>
                 </div>
              </li>
            </ul>
          </div>
          <div class="search-box">
            <div class="input-box">
              <input type="text" placeholder="Search...">
            </div>
          </div>
        </div>
      </nav>
    </div>
  </header> -->
</section>
<section class="mobile_header">
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <a href="">
      <img src="../../../../assets/img/jigyasa/banner/JigyasaUniversityLogo.png" routerLink="" width="180px" height="65px" alt="jigyasa">
    </a>
      <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse toggle_menu_content" id="navbarSupportedContent">
        <div class="d-flex justify-content-end" *ngIf="back_btn">
        <button class="btn back_bnt" (click)="goto_back()"><i class="fa fa-angle-left"></i><span>Back</span></button>
      </div>
        <div class="accordion mt-2" id="accordionExample">
          <div class="accordion-item" *ngIf="acc_item0">
              <h2 class="accordion-header" id="headingOne">
                <a href="aboutUs">
                  <button class="w-100" type="button" aria-controls="collapseOne">
                   About Us
                   </button>
                  </a>
                   <span (click)="closeAccordian(0)" class="accordion-button arrow_click" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true"></span>
              </h2>
              <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                  <div class="accordion-body">
                 <ul class="ul_sub_menu mt-2">
                  <li>The University</li>
                  <li>Vision</li>
                  <li>Mission</li>
                  <li>University Authorities/Our Leadership</li>
                  <li>Academic Council</li>
                  <li>Statutory Approvals</li>
                  <li>Committees</li>
                  <li><a href="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1716384544031BOM.pdf" target="_blank">Board of Management</a></li>
                  <li><a href="https://zeecms.s3.ap-south-1.amazonaws.com/zeecms/1716384533578BOG.pdf" target="">Board of Governor</a></li>
                  <li><a href="/nss">NSS</a></li>
                 </ul>
                  </div>
              </div>
      
          </div>
          <div class="accordion-item" *ngIf="acc_item1" (click)="closeAccordian(1)">
            <h2 class="accordion-header" id="headingOne1">
                <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne1" aria-expanded="true" aria-controls="collapseOne1">
                  Academics
                </button>
            </h2>
            <div id="collapseOne1" class="accordion-collapse collapse py-3" aria-labelledby="headingOne1" data-bs-parent="#accordionExample">
                <div class="accordion-body">
    
                    <div class="accordion" id="sub-accordionExample">
                        <div class="accordion-item item_mrgin mt-2">
                            <h2 class="accordion-header" id="sub-headingOne">
                                <a href="school-of-agriculture-forestry-and-fisheries">
                                  <button class="w-100" type="button" aria-controls="collapseOne">
                                    School of Agriculture, Forestry and Fisheries
                                   </button>
                                  </a>
                                   <span class="accordion-button arrow_click" data-bs-toggle="collapse" data-bs-target="#sub-collapseOne" aria-expanded="true" aria-controls="collapseOne"></span>
                            </h2>
                            <div id="sub-collapseOne" class="accordion-collapse collapse" aria-labelledby="sub-headingOne" data-bs-parent="#sub-accordionExample">
                                <div class="accordion-body">
                                  <ul class="ul_sub_menu"> 
                                    <li><a href="/school-of-agriculture-forestry-and-fisheries/bsc-hons-agriculture">B.Sc. (Hons.) Agriculture</a></li>
                                    <li><a href="/school-of-agriculture-forestry-and-fisheries/msc-agriculture-agronomy">M.Sc Agriculture (Agronomy)</a></li>
                                    <li><a href="/school-of-agriculture-forestry-and-fisheries/msc-agriculture-genetics-and-plant-breeding">M.Sc Agriculture (Genetics and Plant Breeding)</a></li>
                                    <li><a href="/school-of-agriculture-forestry-and-fisheries/phd-genetics-and-plant-breeding">Ph.D (Genetics and Plant Breeding)</a></li>
                                   </ul>
                                </div>
                            </div>
                        </div>
                        <div class="accordion-item item_mrgin">
                            <h2 class="accordion-header" id="sub-headingTwo">
                                <!-- <button class="accordion-button collapsed sub_btn" type="button" data-bs-toggle="collapse" data-bs-target="#sub-collapseTwo" aria-expanded="false" aria-controls="sub-collapseTwo">
                                  School of Science & Technology
                                </button> -->
                                <a href="school-of-science-and-technology">
                                  <button class="w-100" type="button" aria-controls="collapseOne">
                                    School of Science & Technology
                                   </button>
                                  </a>
                                   <span class="accordion-button arrow_click" data-bs-toggle="collapse" data-bs-target="#sub-collapseTwo" aria-expanded="false" aria-controls="sub-collapseTwo"></span>
              
                            </h2>
                            <div id="sub-collapseTwo" class="accordion-collapse collapse" aria-labelledby="sub-headingTwo" data-bs-parent="#sub-accordionExample">
                                <div class="accordion-body">
                                  <ul class="ul_sub_menu ul_padding">   
                                    <li><a href="/school-of-science-and-technology/btech-cse">B.Tech - CSE</a></li>
                                    <li><a href="/school-of-science-and-technology/bca">BCA</a></li>
                                    <li><a href="/school-of-science-and-technology/ph-d">Ph. D.</a></li>
                               </ul>
                               </div>
                            </div>
                        </div>
                        <div class="accordion-item item_mrgin">
                          <h2 class="accordion-header" id="sub-headingTwo1">
                              <!-- <button class="accordion-button collapsed sub_btn" type="button" data-bs-toggle="collapse" data-bs-target="#sub-collapseTwo1" aria-expanded="false" aria-controls="sub-collapseTwo1">
                                School of Education and Humanities
                              </button> -->
                              <a href="school-of-education-and-humanities">
                                <button class="w-100" type="button" aria-controls="collapseOne">
                                  School of Education and Humanities
                                 </button>
                                </a>
                                 <span class="accordion-button arrow_click" data-bs-toggle="collapse" data-bs-target="#sub-collapseTwo1" aria-expanded="false" aria-controls="sub-collapseTwo1"></span>

                          </h2>
                          <div id="sub-collapseTwo1" class="accordion-collapse collapse" aria-labelledby="sub-headingTwo1" data-bs-parent="#sub-accordionExample">
                              <div class="accordion-body">
                                 <div class="accordion mt-2" id="sub-accordionExample1">
                      <!-- <div class="accordion-item">
                          <h2 class="accordion-header" id="sub-headingOne1">
                          
                              <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#sub-collapseOne1" aria-expanded="true" aria-controls="collapseOne1">
                                <a  class="sub_btn" href="/school-of-education-and-humanities/b-ed"> <button>B. Ed.</button></a>
                              </button> 
                          </h2>
                          <div id="sub-collapseOne1" class="accordion-collapse collapse" aria-labelledby="sub-headingOne1" data-bs-parent="#sub-accordionExample1">
                              <div class="accordion-body">
                                <ul class="ul_sub_menu">
                                  <li><a href="">Liberal Arts</a></li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                      <div class="accordion-item mt-2">
                        <h2 class="accordion-header" id="sub-headingOne2">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#sub-collapseOne2" aria-expanded="true" aria-controls="collapseOne2">
                             <a class="sub_btn" href="/school-of-education-and-humanities/b-a"><button>B. A.</button></a>
                            </button>
                        </h2>
                        <div id="sub-collapseOne2" class="accordion-collapse collapse" aria-labelledby="sub-headingOne2" data-bs-parent="#sub-accordionExample2">
                            <div class="accordion-body">
                              <ul class="ul_sub_menu">
                                <li><a href="">Education</a></li>
                                </ul>
                            </div>
                        </div>
                    </div> -->
                                 </div>
                                <ul class="ul_sub_menu">
                                <li><a href="/school-of-education-and-humanities/b-ed">B. Ed.</a></li>
                                <li><a href="/school-of-education-and-humanities/b-a">B. A.</a></li>
                                <li><a class="" href="/school-of-education-and-humanities/m-a">M. A.</a> </li>
                                <li><a class="" href="/school-of-education-and-humanities/ph-d">Ph. D.</a></li>
                             </ul>
                              </div>
                          </div>
                      </div>
                      <div class="accordion-item item_mrgin">
                        <h2 class="accordion-header" id="sub-headingTwo2">
                            <!-- <button class="accordion-button collapsed sub_btn" type="button" data-bs-toggle="collapse" data-bs-target="#sub-collapseTwo2" aria-expanded="false" aria-controls="sub-collapseTwo2">
                              School of Journalism and Mass Communication
                            </button> -->
                            <a href="school-of-journalism-and-mass-communication">
                              <button class="w-100" type="button" aria-controls="collapseOne">
                                School of Journalism and Mass Communication
                               </button>
                              </a>
                               <span class="accordion-button arrow_click" data-bs-toggle="collapse" data-bs-target="#sub-collapseTwo2" aria-expanded="false" aria-controls="sub-collapseTwo2"></span>
                        </h2>
                        <div id="sub-collapseTwo2" class="accordion-collapse collapse" aria-labelledby="sub-headingTwo2" data-bs-parent="#sub-accordionExample">
                            <div class="accordion-body">
                              <!-- <div class="accordion mt-2" id="sub-accordionExample1">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="sub-headingOne1">
                                        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#sub-collapseOne1" aria-expanded="true" aria-controls="collapseOne1">
                                         <a class="sub_btn" href="/school-of-journalism-and-mass-communication/b-a"><button>B. A.</button></a> 
                                        </button>
                                    </h2>
                                    <div id="sub-collapseOne1" class="accordion-collapse collapse" aria-labelledby="sub-headingOne1" data-bs-parent="#sub-accordionExample1">
                                        <div class="accordion-body">
                                          <ul class="ul_sub_menu">
                                            <li><a href="">Journalism & Mass Comm</a></li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item mt-2">
                                  <h2 class="accordion-header" id="sub-headingOne2">
                                      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#sub-collapseOne2" aria-expanded="true" aria-controls="collapseOne2">
                                       <a class="sub_btn" href="school-of-journalism-and-mass-communication/b-sc"><button>B. Sc.</button></a>
                                      </button>
                                  </h2>
                                  <div id="sub-collapseOne2" class="accordion-collapse collapse" aria-labelledby="sub-headingOne2" data-bs-parent="#sub-accordionExample2">
                                      <div class="accordion-body">
                                        <ul class="ul_sub_menu">
                                          <li><a href="">Animation & Graphics Design</a></li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                              <div class="accordion-item mt-2">
                                <h2 class="accordion-header" id="sub-headingOne3">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#sub-collapseOne3" aria-expanded="true" aria-controls="collapseOne3">
                                     <a class="sub_btn" href="/school-of-journalism-and-mass-communication/m-a"><button>M. A.</button></a>
                                    </button>
                                </h2>
                                <div id="sub-collapseOne3" class="accordion-collapse collapse" aria-labelledby="sub-headingOne3" data-bs-parent="#sub-accordionExample3">
                                    <div class="accordion-body">
                                      <ul class="ul_sub_menu">
                                        <li><a href="">Journalism & Mass Comm</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                                           </div> -->
                              <ul class="ul_sub_menu">
                              <li (mouseenter)="selecSubMenu(3)"><a href="/school-of-journalism-and-mass-communication/b-a">B. A. </a></li>
                              <li (mouseenter)="selecSubMenu(4)"><a href="/school-of-journalism-and-mass-communication/b-sc">B. Sc. </a></li>
                              <li (mouseenter)="selecSubMenu(5)"><a href="/school-of-journalism-and-mass-communication/m-a">M. A. </a></li>
                              <li><a href="/school-of-journalism-and-mass-communication/zicazima">ZICA/ZIMA</a></li>
                              <li><a href="/school-of-journalism-and-mass-communication/ph-d">Ph. D.</a></li>
                           </ul>
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item item_mrgin">
                      <h2 class="accordion-header" id="sub-headingTwo3">
                          <!-- <button class="accordion-button collapsed sub_btn" type="button" data-bs-toggle="collapse" data-bs-target="#sub-collapseTwo3" aria-expanded="false" aria-controls="sub-collapseTwo3">
                            School Of Business Studies
                          </button> -->
                          <a href="school-of-business-studies">
                            <button class="w-100" type="button" aria-controls="collapseOne">
                              School Of Business Studies
                             </button>
                            </a>
                             <span class="accordion-button arrow_click" data-bs-toggle="collapse" data-bs-target="#sub-collapseTwo3" aria-expanded="false" aria-controls="sub-collapseTwo3"></span>

                      </h2>
                      <div id="sub-collapseTwo3" class="accordion-collapse collapse" aria-labelledby="sub-headingTwo3" data-bs-parent="#sub-accordionExample">
                          <div class="accordion-body">
                            <!-- <div class="accordion mt-2" id="sub-accordionExample1">
                              <div class="accordion-item">
                                  <h2 class="accordion-header" id="sub-headingOne1">
                                      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#sub-collapseOne1" aria-expanded="true" aria-controls="collapseOne1">
                                       <a class="sub_btn" href="/school-of-journalism-and-mass-communication/b-a"><button>B.B.A.</button></a> 
                                      </button>
                                  </h2>
                                  <div id="sub-collapseOne1" class="accordion-collapse collapse" aria-labelledby="sub-headingOne1" data-bs-parent="#sub-accordionExample1">
                                      <div class="accordion-body">
                                        <ul class="ul_sub_menu">
                                          <li><a href="">Logistic and Supply chain management</a></li>
                                          </ul>
                                      </div>
                                  </div>
                              </div>
                              <div class="accordion-item mt-2">
                                <h2 class="accordion-header" id="sub-headingOne2">
                                    <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#sub-collapseOne2" aria-expanded="true" aria-controls="collapseOne2">
                                     <a class="sub_btn" href="school-of-journalism-and-mass-communication/b-sc"><button>M.B.A.</button></a>
                                    </button>
                                </h2>
                                <div id="sub-collapseOne2" class="accordion-collapse collapse" aria-labelledby="sub-headingOne2" data-bs-parent="#sub-accordionExample2">
                                    <div class="accordion-body">
                                      <ul class="ul_sub_menu">
                                        <li><a href="">HR</a></li>
                                        <li><a href="">Finance</a></li>
                                        <li><a href="">Marketing</a></li>
                                        <li><a href="">Production & Operation Management</a></li>
                                        <li><a href="">Data Analytics</a></li>
                                        <li><a href="">Entrepreneur Development Management</a></li>
                                        <li><a href="">Labour & Legal Management</a></li>
                                        <li><a href="">Tourism Management</a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                             </div> -->
                            <ul class="ul_sub_menu">   
                              <li><a href="/school-of-business-studies/b-com">B. Com</a></li>
                              <li><a href="/school-of-business-studies/bba">B.B.A.</a></li>
                              <li><a href="/school-of-business-studies/mba">M.B.A.</a></li>
                              <li><a href="/school-of-business-studies/phd">Ph. D.</a></li>
                            </ul>
                          </div>
                      </div>
                  </div>
                  <div class="accordion-item item_mrgin">
                    <h2 class="accordion-header" id="sub-headingTwo4">
                        <!-- <button class="accordion-button collapsed sub_btn" type="button" data-bs-toggle="collapse" data-bs-target="#sub-collapseTwo4" aria-expanded="false" aria-controls="sub-collapseTwo4">
                          School of Hotel and Hospitality Management
                        </button> -->
                        <a href="school-of-hotel-and-hospitality-management">
                          <button class="w-100" type="button" aria-controls="collapseOne">
                            School of Hotel and Hospitality Management
                           </button>
                          </a>
                           <span class="accordion-button arrow_click" data-bs-toggle="collapse" data-bs-target="#sub-collapseTwo4" aria-expanded="false" aria-controls="sub-collapseTwo4"></span>

                    </h2>
                    <div id="sub-collapseTwo4" class="accordion-collapse collapse" aria-labelledby="sub-headingTwo4" data-bs-parent="#sub-accordionExample">
                        <div class="accordion-body">
                          <ul class="ul_sub_menu">   
                            <li><a href="/school-of-hotel-and-hospitality-management/certificate-in-hotel-management-chm">Certificate in Hotel Management (CHM)</a></li>
                            <li><a href="/school-of-hotel-and-hospitality-management/diploma-in-hotel-management-dhm">Diploma in Hotel Management (DHM)</a></li>
                            <li><a href="/school-of-hotel-and-hospitality-management/bachelor-of-hotel-management-bhm">Bachelor of Hotel Management (BHM)</a></li>
                            <li><a href="/school-of-hotel-and-hospitality-management/ba-restaurant-management">B.A Restaurant Management</a></li>
                          </ul>
                        </div>
                    </div>
                </div>
                <div class="accordion-item item_mrgin">
                  <h2 class="accordion-header" id="sub-headingTwo5">
                      <!-- <button class="accordion-button collapsed sub_btn" type="button" data-bs-toggle="collapse" data-bs-target="#sub-collapseTwo5" aria-expanded="false" aria-controls="sub-collapseTwo5">
                        School of Legal Studies
                      </button> -->
                      <a href="school-of-legal-studies">
                        <button class="w-100" type="button" aria-controls="collapseOne">
                          School of Legal Studies
                         </button>
                        </a>
                         <span class="accordion-button arrow_click" data-bs-toggle="collapse" data-bs-target="#sub-collapseTwo5" aria-expanded="false" aria-controls="sub-collapseTwo5"></span>

                  </h2>
                  <div id="sub-collapseTwo5" class="accordion-collapse collapse" aria-labelledby="sub-headingTwo5" data-bs-parent="#sub-accordionExample">
                      <div class="accordion-body">
                        <ul class="ul_sub_menu">   
                          <li><a href="/school-of-legal-studies/ba-llb">BA LL.B</a></li>
                          <li><a href="/school-of-legal-studies/bba-llb">BBA LL.B</a></li>
                          <li><a href="/school-of-legal-studies/llb">LL.B</a></li>
                          <li><a href="/school-of-legal-studies/ba-llb/llm1-yearcriminal-laws-constitutional-law-and-human-rights-and-corporate-laws">
                            LL.M-1 Year(Criminal Laws, Constitutional Law and 
                            Human Rights and Corporate Laws)</a></li>
                          <li><a href="/school-of-legal-studies/ba-llb/llm2-yearcriminal-laws-constitutional-law-and-human-rights-and-corporate-laws">
                            LL.M-2 Year(Criminal Laws, Constitutional Law and
                            Human Rights and Corporate Laws)</a></li>
                          <li><a href="/school-of-legal-studies/ph-d">Ph.D.</a></li>
                        </ul>
                      </div>
                  </div>
              </div>
              <div class="accordion-item item_mrgin">
                <h2 class="accordion-header" id="sub-headingTwo6">
                    <!-- <button class="accordion-button collapsed sub_btn" type="button" data-bs-toggle="collapse" data-bs-target="#sub-collapseTwo6" aria-expanded="false" aria-controls="sub-collapseTwo6">
                      School of Pharmaceutical Sciences
                    </button> -->
                    <a href="school-of-pharmaceutical-sciences">
                      <button class="w-100" type="button" aria-controls="collapseOne">
                        School of Pharmaceutical Sciences
                       </button>
                      </a>
                      <span class="accordion-button arrow_click" data-bs-toggle="collapse" data-bs-target="#sub-collapseTwo6" aria-expanded="false" aria-controls="sub-collapseTwo6"></span>
                </h2>
                <div id="sub-collapseTwo6" class="accordion-collapse collapse" aria-labelledby="sub-headingTwo6" data-bs-parent="#sub-accordionExample">
                    <div class="accordion-body">
                      <ul class="ul_sub_menu">   
                        <li><a href="/school-of-pharmaceutical-sciences/d-pharm">D. Pharm</a></li>
                        <li><a href="/school-of-pharmaceutical-sciences/b-pharm">B. Pharm</a></li>
                        <li><a href="/school-of-pharmaceutical-sciences/b-pharm-lateral">B. Pharm (Lateral)</a></li>
                        <li><a href="/school-of-pharmaceutical-sciences/bsc-clinical-research">B.Sc. Clinical Research</a> </li>
                        <li><a href="/school-of-pharmaceutical-sciences/msc-clinical-research">M.Sc. Clinical Research</a></li>
                        <li><a href="/school-of-pharmaceutical-sciences/master-in-public-health">Master in Public Health</a></li>
                        <li><a href="/school-of-pharmaceutical-sciences/mba-health-care-and-hospital-management">MBA Health Care and Hospital Management</a></li>
                        <li><a href="/school-of-pharmaceutical-sciences/ph-d">Ph. D.</a></li>
                      </ul>
                    </div>
                </div>
            </div>
                    </div>
                    <div><a href="../../../../assets/pdf/AcadCalendar_2023_24.pdf" target="_blank"><button class="btn academic_btn text-center w-100 mt-2">Academic Calender</button></a></div>
                    <div><a href="../../../../assets/pdf/AcadRegulations_2023-24_18thJulyfinal.pdf" target="_blank"><button class="btn academic_btn text-center w-100 mt-2">Academic Regulations and Policies</button></a></div>
                   <div><a href="/flexibility"><button class="btn academic_btn text-center w-100 mt-2">Flexibility</button></a></div>
                   <div><a href="/policiesandnotices"><button class="btn academic_btn text-center w-100 mt-2">Policies and Notices</button></a></div>
                     </div>
            </div>
    
        </div>

        <div class="accordion-item" *ngIf="acc_item2" (click)="closeAccordian(2)">
          <h2 class="accordion-header" id="headingOne2">
              <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne2" aria-expanded="true" aria-controls="collapseOne2">
                Research
              </button>
          </h2>
          <div id="collapseOne2" class="accordion-collapse collapse" aria-labelledby="headingOne2" data-bs-parent="#accordionExample">
              <div class="accordion-body">
  
                <ul class="ul_sub_menu"> 
                  <li><a href="/researchanddevelopment">R&D Cell</a></li>
                  <li><a href="/intellectualProppertyRightCell">Intellectual Property Rights cell</a></li>
                  <li><a href="/ph-d">Ph.D.@ JU</a></li>
                  <li><a href="/patentsawards">Patents and Awards</a></li>
                  <li><a href="/Research-Updates">Research Updates</a></li>
                 </ul>
                  <!-- <div class="accordion" id="sub-accordionExample">
                      <div class="accordion-item">
                          <h2 class="accordion-header" id="sub-headingOne">
                              <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#sub-collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                  Accordion Item #1
                              </button>
                          </h2>
                          <div id="sub-collapseOne" class="accordion-collapse collapse show" aria-labelledby="sub-headingOne" data-bs-parent="#sub-accordionExample">
                              <div class="accordion-body">
                                  <strong>This is the first item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                              </div>
                          </div>
                      </div>
                      <div class="accordion-item">
                          <h2 class="accordion-header" id="sub-headingTwo">
                              <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sub-collapseTwo" aria-expanded="false" aria-controls="sub-collapseTwo">
                                  Accordion Item #2
                              </button>
                          </h2>
                          <div id="sub-collapseTwo" class="accordion-collapse collapse" aria-labelledby="sub-headingTwo" data-bs-parent="#sub-accordionExample">
                              <div class="accordion-body">
                                  <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                              </div>
                          </div>
                      </div>
                  </div> -->
              </div>
          </div>
  
      </div>

      <div class="accordion-item" *ngIf="acc_item3" (click)="closeAccordian(3)">
        <h2 class="accordion-header" id="headingOne3">
            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne3" aria-expanded="true" aria-controls="collapseOne3">
              Placement
            </button>
        </h2>
        <div id="collapseOne3" class="accordion-collapse collapse" aria-labelledby="headingOne3" data-bs-parent="#accordionExample">
            <div class="accordion-body">
              <ul class="ul_sub_menu"> 
                <li><a href="/trainings-and-placement-cell">T & P Cell</a></li>
                <li><a href="/career-counselling-cell">Career Counselling Cell</a></li>
                <li><a href="/internship">Internships @ JU</a></li>
                <li><a>Industry Collaborations and MoU’s</a></li>
                <li><a>Placement Tracker</a></li>
                <li><a href="student-placement">Welcome</a></li>
               </ul>
                <!-- <div class="accordion" id="sub-accordionExample">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="sub-headingOne">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#sub-collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                Accordion Item #1
                            </button>
                        </h2>
                        <div id="sub-collapseOne" class="accordion-collapse collapse show" aria-labelledby="sub-headingOne" data-bs-parent="#sub-accordionExample">
                            <div class="accordion-body">
                                <strong>This is the first item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                            </div>
                        </div>
                    </div>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="sub-headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#sub-collapseTwo" aria-expanded="false" aria-controls="sub-collapseTwo">
                                Accordion Item #2
                            </button>
                        </h2>
                        <div id="sub-collapseTwo" class="accordion-collapse collapse" aria-labelledby="sub-headingTwo" data-bs-parent="#sub-accordionExample">
                            <div class="accordion-body">
                                <strong>This is the second item's accordion body.</strong> It is hidden by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                            </div>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>

    </div>

    <div class="accordion-item" *ngIf="acc_item4" (click)="closeAccordian(4)">
      <h2 class="accordion-header" id="headingOne4">
          <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne4" aria-expanded="true" aria-controls="collapseOne4">
            Resources
          </button>
      </h2>
      <div id="collapseOne4" class="accordion-collapse collapse" aria-labelledby="headingOne4" data-bs-parent="#accordionExample">
          <div class="accordion-body">
            <ul class="ul_sub_menu"> 
              <li><a href="/clubs">Clubs</a></li>
              <!-- <li><a>Academic Resources</a></li>
              <li><a>Recreational Resources</a></li>
              <li><a>Medical Aid</a></li>
              <li><a>Transport Facilities</a></li> -->
              <li><a href="/library_facilities">Library</a></li>
             
             </ul>
          </div>
      </div>

  </div>

  <div class="accordion-item" *ngIf="acc_item5" (click)="closeAccordian(5)">
    <h2 class="accordion-header" id="headingOne5">
        <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne5" aria-expanded="true" aria-controls="collapseOne5">
          Campus
        </button>
    </h2>
    <div id="collapseOne5" class="accordion-collapse collapse" aria-labelledby="headingOne5" data-bs-parent="#accordionExample">
        <div class="accordion-body">
          <ul class="ul_sub_menu"> 
            <li><a href="/hostel">Hostel Facilities</a></li>
            <li><a href="/sports">Sports Facilities</a></li>
            <li><a href="/swimming-sool">Swimming Pool</a></li>
            <li><a href="/medical-facility">Medical Facilities</a></li>
            <li><a href="/library">Library Facilities</a></li>
            <li><a href="/transport">Transport Facilities</a></li>
            <li><a href="/cafeteria">Cafeteria</a></li>
           </ul>
        </div>
    </div>

</div>
<ul class="ul_sub_menu">
  <li><a href="/policiesandnotices">Policies And Notices</a></li>
</ul>
      </div>
      </div>
    </div>
  </nav>
  <div class="header_title mb-2">
    <a href="https://apply.jigyasauniversity.com/" target="_blank"><marquee behavior="" direction="">Admissions Open 2024 at Jigyasa University (Formerly Himgiri Zee University)<span class="click_text">"Click here"</span></marquee></a>
  </div>
</section>
<router-outlet></router-outlet>
<app-footer></app-footer>