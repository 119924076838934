import { Component } from '@angular/core';

@Component({
  selector: 'app-carrer-at-ju',
  templateUrl: './carrer-at-ju.component.html',
  styleUrls: ['./carrer-at-ju.component.css']
})
export class CarrerAtJUComponent {

}
