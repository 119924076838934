// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  locationId: 2354,
  api_url: 'https://globalapi.zeelearn.com/Kidzeewebapi/',
  cmsapi_url:'https://api.jigyasauniversity.com/api/',// 'http://10.112.3.50:4000/api/',////'http://10.112.3.50:4000/api/',//'https://cmsapi.zeelearn.com/api/CMS/',
  jigyaUrl:'https://api.jigyasauniversity.com/api/CMS/',
  projectid: 3180,
  applay_url :'https://apply.jigyasauniversity.com/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
