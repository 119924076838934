import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription, of, switchMap, tap } from 'rxjs';
import { ApicallService } from 'src/app/services/apicall.service';
import { HomeSeoService } from 'src/app/services/homeseo.service';
import { ProjectSeoService } from 'src/app/services/projectseo.service';
import { environment } from 'src/environments/environment';
import { NgxSpinnerService } from 'ngx-spinner';
import * as AOS from 'aos';
import { CommonService } from '../service/common.service';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-notable-alumni',
  templateUrl: './notable-alumni.component.html',
  styleUrls: ['./notable-alumni.component.css']
})
export class NotableAlumniComponent {
  project_id = environment.projectid;   
  @Input() alumnidata:any=[];
  customOptions: any = {
    loop: true,
    margin: 10,
    nav: true,
    dots: false,
    navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
    responsive: {
      0: {
        items: 1
      },
      600: {
        items: 3
      },
      1000: {
        items: 3
      }
    },
  }
  items = [...Array(100).keys()].map((x) => ({
    title: `Slide ${x + 1}`,
  }));
  alumnidt:any=[];
  alumnicategory:any=[];
  alumnicoordinatedata:any=[];
  project$: Observable<any> | undefined;
  subscriptionnav!: Subscription;
  @Input() fromslug:string="";
  faculty: any = [];
  facultycategory: any = [];
  getAllDetails: any=[];
  imgFile: any;
  constructor(
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private seoService: HomeSeoService,
    private projectService: ProjectSeoService,
    private apiService: ApicallService,    
    private _service: CommonService,
    private spinner:NgxSpinnerService,
    private _ApicallService: ApicallService,
  ) {

  }

  ngOnInit(): void {
    AOS.init();
    setTimeout(() => {
      this.getalumnidata();
    }, 200);
  }

  geturl(id:any){
    if(id){
      let url="https://www.youtube.com/embed/"+id+"?autoplay=1&amp;rel=0&controls=0&amp;"
     return this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }else{
      return this.sanitizer.bypassSecurityTrustResourceUrl('');
    }

  }
  getalumnidata(){
    this.spinner.show();
    let obj = {
      "Project_Id": this.project_id,
      "Type": "Alumni",
      "pageurl":"home"
    }
    this._service.getContentbytype(obj).subscribe(
      res => {    
        this.spinner.hide();   
        if(res.data){
          this.alumnicategory = JSON.parse(res.data[0].AssignCategory);
          this.alumnidt=JSON.parse(res.data[0].contentData);
          this.seturl(JSON.parse(res.data[0].contentData));
        //this.PlacementList = JSON.parse(res.data[0].contentData);   
          console.log(this.alumnidt);
        }else{
          
        }          
      }
    )
  }
  seturl(data:any){
    let dt=data.filter((x:any)=>{
      return x
    }).map((obj:any)=>{
      return {...obj,url:this.sanitizer.bypassSecurityTrustResourceUrl("https://www.youtube.com/embed/"+obj.slug+"?autoplay=1&amp;rel=0&controls=0&amp;")}
    })

    this.alumnicoordinatedata=dt.filter((a:any)=>{
      return a.category.includes(42) //coordinator
    }).map((obj:any)=>{
      return obj;
    });        
    this.alumnidt=dt.filter((a:any)=>{
      return a.category.includes(43) //notable
    }).map((obj:any)=>{
      return obj;
    })
  }

  showAllDetails(data:any){
    this.getAllDetails = data
    this.imgFile = this.getAllDetails.files
  }
}
