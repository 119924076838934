import { Component } from '@angular/core';

@Component({
  selector: 'app-sports-facilities',
  templateUrl: './sports-facilities.component.html',
  styleUrls: ['./sports-facilities.component.css']
})
export class SportsFacilitiesComponent {

}
