import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription, of, switchMap, tap } from 'rxjs';
import { ApicallService } from 'src/app/services/apicall.service';
import { HomeSeoService } from 'src/app/services/homeseo.service';
import { ProjectSeoService } from 'src/app/services/projectseo.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.css'],
})
export class BlogsComponent implements OnInit {
  project$: Observable<any> | undefined;
  @Input() blogdata:any=[];
  @Input() fromslug:string="";
  subscriptionnav!: Subscription;
  bdata:any=[];
  blogcategory:any=[];
  spinner:boolean=true
  constructor(
    private route: ActivatedRoute,
    private seoService: HomeSeoService,
    private projectService: ProjectSeoService,
    private apiService: ApicallService
  ) {
  }
  ngOnInit(): void {
    if(this.blogdata){
      this.bdata=this.blogdata[0].data;
      this.blogcategory=this.blogdata[0].AssignCategory;
     
    }  

    this.subscriptionnav = this.projectService  
      .onblogMessage()
      .subscribe((message) => {
        this.spinner=false
        if (message) {      
          this.blogdata = message.text;
          if(this.blogdata){
            this.bdata=this.blogdata[0].data;
            this.blogcategory=this.blogdata[0].AssignCategory;
          }  
         // console.log('blog single',this.blogdata)
        }
      });
  }
  filtercategory(id:number){
    if(id!=0){
      this.bdata=this.blogdata[0].data.filter((dt:any)=>{
        return dt.category.includes(id)
      }).map((obj:any)=>{
        return obj;
      })
     }
    else{
      this.bdata=this.blogdata[0].data;
     }
    
  }
}
