<section>
    <div class="mainDiv">
        <div class="legancy_content">
            <div class="whiteTitle">{{headerTitle}}</div>
            <div class="subMenu_content_admission">
                <app-sub-menu></app-sub-menu>
            </div>
        </div>
        <div class="admissionForm">
            <div class="row">
                <div class="col-md-6">
                    <div class="text_content_right">
                        Explore our Preschool Presence In India
                    </div>
                </div>
                <div class="col-md-6">          
                    <app-admissions-form></app-admissions-form>
                </div>
            </div>
        </div>
       
        <div class="cities_list_content mt-5">
            <div class="text_content_right cities_title">
                Cities where our Presence is
            </div>
            <div class="row mt-4">
                <div class="col-md-2">
                    <div class="cities_list text-center">
                        <p (click)="goTocity('Agartala')">Agartala</p>
                        <p (click)="goTocity('Agra')">Agra</p>
                        <p (click)="goTocity('Ahmedabad')">Ahmedabad</p>
                        <p (click)="goTocity('Ahmednagar')">Ahmednagar</p>
                        <p (click)="goTocity('Ajmer')">Ajmer</p>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="cities_list text-center">
                        <p>Chikaballapur</p>
                        <p>Coimbatore</p>
                        <p>Cuttack</p>
                        <p>Davangere</p>
                        <p>Faizabad</p>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="cities_list text-center">
                        <p>Haldwani</p>
                        <p>Hassan</p>
                        <p>Haveri</p>
                        <p>Hoshiarpur</p>
                        <p>Howrah</p>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="cities_list text-center">
                        <p>Kollam</p>
                        <p>Kota</p>
                        <p>Kottayam</p>
                        <p>Lalitpur</p>
                        <p>Lucknow</p>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="cities_list text-center">
                        <p>Ludhiana</p>
                        <p>Mandya</p>
                        <p>Madurai</p>
                        <p>Mohali</p>
                        <p>Mumbai</p>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="cities_list text-center">
                        <p>Patti</p>
                        <p>Pollachi</p>
                        <p>Pondicherry</p>
                        <p>Pune</p>
                        <p>Purnea</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>