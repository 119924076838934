<section>
    <div class="subMenu_content" *ngIf="ishide!=1">
        <app-sub-menu></app-sub-menu>
    </div>
    <div class="" [ngClass]="ishide==1 ? '' :'sub_content'">
        <div class="title" *ngIf="ishide!=1">Internship @JU</div>
        <div class="main_content_para my-4">
                <div class="findYourProgram">
                    <div class="program-tab-outer">
                        <div class="listSearchArea">
                            <ul class="nav nav-tabs tabs-buttons" role="tablist">
                                <li class="nav-item" role="presentation" id="boardOfGovernorsTab0">
                                    <button class="nav-link active" data-bs-toggle="tab" data-bs-target="#boardOfGovernors0"
                                        type="button" role="tab" aria-labelledby="board Of Governors Tab 0"
                                        aria-selected="false">About Internship</button>
                                </li>
                                <li class="nav-item" role="presentation" id="boardOfGovernorsTab1">
                                    <button class="nav-link" data-bs-toggle="tab"
                                        data-bs-target="#boardOfGovernors1" type="button" role="tab"
                                        aria-labelledby="board Of Governors Tab 1" aria-selected="true">Benefits to students</button>
                                </li>
                                <li class="nav-item" role="presentation" id="boardOfGovernorsTab2">
                                    <button class="nav-link " data-bs-toggle="tab" data-bs-target="#boardOfGovernors2"
                                        type="button" role="tab" aria-labelledby="board Of Governors Tab 2"
                                        aria-selected="false">Benefits to the organization</button>
                                </li>
                                <li class="nav-item" role="presentation" id="boardOfGovernorsTab3">
                                    <button class="nav-link " data-bs-toggle="tab" data-bs-target="#boardOfGovernors3"
                                        type="button" role="tab" aria-labelledby="board Of Governors Tab 3"
                                        aria-selected="false">Benefits to the University</button>
                                </li>
                                <li class="nav-item" role="presentation" id="boardOfGovernorsTab4" *ngIf="ishide==1">
                                    <button class="nav-link " data-bs-toggle="tab" data-bs-target="#boardOfGovernors4"
                                        type="button" role="tab" aria-labelledby="board Of Governors Tab 4"
                                        aria-selected="false">Internship</button>
                                </li>
                            </ul>

                        </div>
                    </div>
                    <div class="tab-content" id="findYourProgramTabs-content">

                        <div class="tab-pane fade active show" id="boardOfGovernors0" role="tabpanel"
                            aria-labelledby="board Of Governors Tab 0">
                           <div class="para">
                            <h5 class="mt-4">About Internship</h5>
                            <p>The Internship programme can best be described as an attempt to institutionalize the efforts to bridge the gap between world of work and academia. Internship is a vehicle through which an attempt is made to bridge the gap between theory and practice by involving the student, like a medical intern, to actively participate in the on-going developmental activities of a professional organization during the degree programme, under the direct supervision of faculty member from Jigyasa University
(Formerly Himgiri Zee University) and professional experts from the host organization. The entire effort in internship education is in terms of extending the programmes of education and evaluation outside the classroom of a university.</p>
                            <p>The Internship method of education, a controlled simulation of real life, adopted at the Campus creates the circumstance whereby the university is linked with the world of work. The curriculum through internship finds a formal method of bringing the reality of the world of work into the educational process. In short, it is a part of the total programme and takes the classroom for a period of one full semester to a professional location where the students and the faculty get involved in finding solutions to real-life problems. Thus the internship institutionalizes efforts to establish a bond between the professional world and the educational world thereby enabling the much- needed university-industry linkage. The programme requires that the students undergo the rigor of the professional world in form as well as in substance, providing them an opportunity to apply their classroom knowledge to live situations.</p>
                           </div>
                        </div>

                        <div class="tab-pane fade" id="boardOfGovernors1" role="tabpanel"
                            aria-labelledby="board Of Governors Tab 1">
                            <div class="para">
                                <h5 class="mt-4">Benefits to the students</h5>
                            <p>Students entering into professional world should complement their academic preparation with a range of other experiences, such as undergraduate research experiences, participation in sports and other extracurricular activities, membership of professional organizations, community service, and hands-on experience in the industries. Internship programme offers the chance to a student with an opportunity to work on live projects, under the supervision of faculty member from the institution and professional expert from host organization without the responsibilities of being a permanent employee. It allows them to develop life-long learning skills, understand workplace culture and focus on their career goals. Internship experience helinternship students to avoid that common dilemma that many students face after graduation who do not have exposure to industrial environment. Summarized below are few advantages to the students of internship programme:</p>
                            <li>Enables students to have smooth transition from academics to professional life.</li>
                            <li>Enhances interpersonal skills, leadership qualities etc.</li>
                            <li>Provides an opportunity to apply some of the ideas/skill sets that students learnt during academic programme.</li>
                            <li>Increases the confidence level of students.</li>
                            <li>Enables students to have awareness of personal strengths and limitations as a professional.</li>
                            <li>Increases marketability of students after graduation.</li>
                            <li>Provides link with potential future employers.</li>  
                        </div>
                        </div>

                        <div class="tab-pane fade " id="boardOfGovernors2" role="tabpanel"
                            aria-labelledby="board Of Governors Tab 2">
                            <div class="para">
                                <h5 class="mt-4">Benefits to the Organizations</h5>
                                <p>Although the supervision of Internship programme requires the time and skill of employees in an organization, there are advantages that outweigh the costs. Internship programme running in an organization can:</p>
                                <li>Bring a fresh approach to organizational setting. Students bring enthusiasm and a fresh point of view. They come equipped with the latest academic thinking, innovation and a high level of skill to add value to the workplace.</li>
                                <li>Provide the opportunity for organizational staff to gain supervisory experience.</li>
                                <li>Having linkage with internationally reputed university like BITS, Pilani will increase reputation of the organization.</li>
                                <li>Allow organizational authorities to assess the capacities of a potential employee.</li>
                                <li>Facilitate links between organization and the Institute for possible Research, teaching and recruitment.</li>
                            </div>
                        </div>

                        <div class="tab-pane fade " id="boardOfGovernors3" role="tabpanel"
                            aria-labelledby="board Of Governors Tab 3">
                            <div class="para">
                                <h5 class="mt-4">Benefits to the University</h5>
                                <p>The university-industry linkage accrued through the internship programme benefits Institute as well:</p>
                                <li>Enriches the educational program that results from a strong internship program.</li>
                                <li>Helinternship to bridge the gap between industry and academia, so that on-campus courses can be designed to meet the requirements of the industry.</li>
                                <li>Aids the Institute in getting consultancy projects from the internship stations because of industrial linkage.</li>
                                <li>Enables the Institute to identify need-based executive training programmes linked with consultancy assignment.</li>
                                <li>facilitates the Institute in setting-up center for excellence/labs for conducting research in the contemporary areas.</li>
                            </div>
                        </div>

                        <div class="tab-pane fade " id="boardOfGovernors4" role="tabpanel" *ngIf="ishide==1"
                            aria-labelledby="board Of Governors Tab 4">
                            <div class="para mt-2">
                                <p>We offer a full-term Internship to all the students in the final Semester to give them a first hand experience of the world outside. We expect the companies to give a job opportunity to the students if they find them suitable during internship</p>
                                <p>Salient Features:</p>
                                <ul>
                                        <li>Full Semester credited course.</li>
                                        <li>Regularly monitored by the faculty</li>
                                        <li>It is a win-win situation for the organization and the student. They company can put them on job and train them as per their requirements.</li>
                                        <li>The students gain a practical exposure to the real world outside.</li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </div>
</section>