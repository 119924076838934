<div class="section">
    <div class="subMenu_content">
        <app-sub-menu></app-sub-menu>
    </div>
    <div class="sub_content">
        <section id="OverviewOfRAndDCell">
            <div class="title">Himgiri Zee Excellence and Soft-skills Training Centre
                (Hi-ZEST Centre)</div>
            <div class="row">
                <div class="col-md-3">
                    <img src="../../../assets/img/jigyasa/hiZestCenter.png" width="100%" alt="">
                </div>
                <div class="col-md-9">
                    <div class="para">
                        <p>Successful business leaders, entrepreneurs, professionals and scientists have strong foundation in technical knowledge along with real-life experience. But what make them acknowledged in the business world and society are their social skills, behaviour and attitude. Developing and demonstrating these skills early in one’s career can contribute to faster professional advancement. 80% of career success is driven by soft skills, and only 15% is attributed to technical skills, says a Harvard study. There is another saying that you are hired for the hard skills you have but you are fired for the soft skills you lack.</p>
                     </div>
                </div>
                <div class="col-md-12">
                    <div class="para">
                        <p>Soft skills are on the top of the list of 21st century skills. According to an article published by Forbes (Yolandu Lau, 2021) mentioned as more and more job activities become automated, soft skills, which cannot yet be replicated by machines, have become more important. In 2017, Deloitte also reported that "soft skill-intensive occupations will account for two-thirds of all jobs by 2030" and that hiring employees with more soft skills could increase revenue by more than $90,000.”</p>
                        <p>Keeping in view of the future employability skills, <b>Jigyasa University Excellence and Soft Skills Training Centre (Hi-ZEST Centre)</b> came into existence with an objective to impart training to our students to develop 21st century skill set needed for the future professional world.
                            The Hi-ZEST Centre has designed customised training modules for upcoming professionals to develop and inculcate these skills as personality traits. These modules are designed to provide real-life exposure to students so that they become aware about the basic behavioural needs to face the future challenges and adapt to the changing work culture of the professional world.</p>   
                    </div>
                </div>
            </div>
            <div class="title">Salient features of the training programs at Hi-Zest Centre</div>
                <div class="para">
               <ul>
                <li>Our training program is a blend of lectures by expert and specialised faculty, guest lectures from industry, corporate and academia, workshops and activities.</li>
                <li>Our focus is on the holistic personality development.</li>
                <li>We work strenuously to enhance confidence and self-esteem, positive attitude, team-building and decision-making.</li>
                <li>Activities such as presentations, role-plays, group-discussions, debates, mock-interviews, group-activities, business games are part of our methodology.</li>
                <li>Our courses provide training for the reasoning and aptitude.</li>
                <li>Our team of experts provide guidance to prepare students for the competitive examinations.</li>
               </ul>
                </div>
        </section>
        
        <section>
            <div class="title mt-2">Session on ‘Grooming for Interview’</div>
            <div class="row pb-5">
                <div class="col-md-4 mt-3">
                    <div class="gallery_img">
                        <a data-fancybox="" href="../../../assets/img/jigyasa/ss1.jpg">
                        <img src="../../../assets/img/jigyasa/ss1.jpg" width="100%" height="200px" alt="">
                        </a>
                    </div>
                </div>
                <div class="col-md-4 mt-3">
                    <div class="gallery_img">
                        <a data-fancybox="" href="../../../assets/img/jigyasa/ss3.jpg">
                        <img src="../../../assets/img/jigyasa/ss3.jpg" width="100%" height="200px" alt="">
                        </a>
                    </div>
                </div>
                <div class="col-md-4 mt-3">
                    <div class="gallery_img">
                        <a data-fancybox="" href="../../../assets/img/jigyasa/ss4.jpg">
                        <img src="../../../assets/img/jigyasa/ss4.jpg" width="100%" height="200px" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div class="title mt-2">A session on Challenges of facing interviews</div>
            <div class="row pb-5">
                <div class="col-md-4 mt-3">
                    <div class="gallery_img">
                        <a data-fancybox="" href="../../../assets/img/jigyasa/Ph111.jpg">
                        <img src="../../../assets/img/jigyasa/Ph111.jpg" width="100%" height="200px" alt="">
                        </a>
                    </div>
                </div>
                <div class="col-md-4 mt-3">
                    <div class="gallery_img">
                        <a data-fancybox="" href="../../../assets/img/jigyasa/Phh22.jpeg">
                        <img src="../../../assets/img/jigyasa/Phh22.jpeg" width="100%" height="200px" alt="">
                        </a>
                    </div>
                </div>
                <div class="col-md-4 mt-3">
                    <div class="gallery_img">
                        <a data-fancybox="" href="../../../assets/img/jigyasa/ph33.jpeg">
                        <img src="../../../assets/img/jigyasa/ph33.jpeg" width="100%" height="200px" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div class="title mt-2">Pre-Placement interviews of BCA and B-Tech students</div>
            <div class="row pb-5">
                <div class="col-md-4 mt-3">
                    <div class="gallery_img">
                        <a data-fancybox="" href="../../../assets/img/jigyasa/Mockinterview1.jpg">
                        <img src="../../../assets/img/jigyasa/Mockinterview1.jpg" width="100%" height="200px" alt="">
                        </a>
                    </div>
                </div>
                <div class="col-md-4 mt-3">
                    <div class="gallery_img">
                        <a data-fancybox="" href="../../../assets/img/jigyasa/Mockinterview2.jpg">
                        <img src="../../../assets/img/jigyasa/Mockinterview2.jpg" width="100%" height="200px" alt="">
                        </a>
                    </div>
                </div>
                <div class="col-md-4 mt-3">
                    <div class="gallery_img">
                        <a data-fancybox="" href="../../../assets/img/jigyasa/Mockinterview3.jpg">
                        <img src="../../../assets/img/jigyasa/Mockinterview3.jpg" width="100%" height="200px" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div class="title mt-2">Mock interviews session for Agriculture Students</div>
            <div class="row pb-5">
                <div class="col-md-4 mt-3">
                    <div class="gallery_img">
                        <a data-fancybox="" href="../../../assets/img/jigyasa/t&p1.jpg">
                        <img src="../../../assets/img/jigyasa/t&p1.jpg" width="100%" height="200px" alt="">
                        </a>
                    </div>
                </div>
                <div class="col-md-4 mt-3">
                    <div class="gallery_img">
                        <a data-fancybox="" href="../../../assets/img/jigyasa/t&p2.jpg">
                        <img src="../../../assets/img/jigyasa/t&p2.jpg" width="100%" height="200px" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div class="title mt-2">Mock Interviews session for the students from Journalism and Mass Communication</div>
            <div class="row pb-5">
                <div class="col-md-4 mt-3">
                    <div class="gallery_img">
                        <a data-fancybox="" href="../../../assets/img/jigyasa/bjmc1.jpg">
                        <img src="../../../assets/img/jigyasa/bjmc1.jpg" width="100%" height="200px" alt="">
                        </a>
                    </div>
                </div>
                <div class="col-md-4 mt-3">
                    <div class="gallery_img">
                        <a data-fancybox="" href="../../../assets/img/jigyasa/bjmc2.jpg">
                        <img src="../../../assets/img/jigyasa/bjmc2.jpg" width="100%" height="200px" alt="">
                        </a>
                    </div>
                </div>
                <div class="col-md-4 mt-3">
                    <div class="gallery_img">
                        <a data-fancybox="" href="../../../assets/img/jigyasa/bjmc3.jpg">
                        <img src="../../../assets/img/jigyasa/bjmc3.jpg" width="100%" height="200px" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </section>
        
        <section>
            <div class="title mt-2">Mock interviews session for B. Ed. Students</div>
            <div class="row pb-5">
                <div class="col-md-4 mt-3">
                    <div class="gallery_img">
                        <a data-fancybox="" href="../../../assets/img/jigyasa/bedp1.jpg">
                        <img src="../../../assets/img/jigyasa/bedp1.jpg" width="100%" height="200px" alt="">
                        </a>
                    </div>
                </div>
                <div class="col-md-4 mt-3">
                    <div class="gallery_img">
                        <a data-fancybox="" href="../../../assets/img/jigyasa/bedp2.jpg">
                        <img src="../../../assets/img/jigyasa/bedp2.jpg" width="100%" height="200px" alt="">
                        </a>
                    </div>
                </div>
                <div class="col-md-4 mt-3">
                    <div class="gallery_img">
                        <a data-fancybox="" href="../../../assets/img/jigyasa/bedp3.jpg">
                        <img src="../../../assets/img/jigyasa/bedp3.jpg" width="100%" height="200px" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div class="title mt-2">Our Team</div>
            <p>Hi-ZEST Centre team includes resource persons from diverse fields of knowledge. With corporate background, academic excellence and goal-oriented approach, the team functions for the holistic growth of our students.</p>
            <div class="row pb-5">
                <div class="col-md-4 mt-3 d-flex justify-content-center align-items-center">
                    <div class="gallery_img">
                        <div class="card">
                            <div class="">
                         <img src="../../../assets/img/jigyasa/meenakshiverma.png" width="100%" height="220px" alt="">
                        </div>
                        <div class="para">
                        <div class="title">Dr. Meenakshi Harraw Verma</div>
                        <label for="">Centre Head</label>
                    </div>
                        </div>
                        </div>
                </div>
                <div class="col-md-4 mt-3">
                </div>
                <div class="col-md-4 mt-3">
                      <div class="card">
                        <div class="">
                            <img src="../../../assets/img/jigyasa/Anujaaa.jpeg" width="100%" height="220px" alt="">
                    </div>
                    <div class="para">
                    <div class="title">Dr. Anuja Pandey</div>
                    <label for="">Resource Person</label>
                </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>