<section>
    <div class="mainDiv">
        <div class="legancy_content">
            <div class="whiteTitle">{{headerTitle}}</div>
            <div class="subMenu_content_admission">
                <app-sub-menu></app-sub-menu>
            </div>
        </div>
        <div class="admissionForm">
            <div class="row">
                <div class="col-md-6">
                    <div class="list_content">
                        <div class="row">
                            <div class="col-2">
                                <img srcset="../../../assets/img/1.png" loading="lazy" alt="" class="num responsive" align="center">
                            </div>
                            <div class="col-8">
                                <div class="textList">
                                    Fill the School Admission Inquiry Form on the Website
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-2">
                                <img srcset="../../../assets/img/2.png" loading="lazy" alt="" class="num responsive" align="center">
                            </div>
                            <div class="col-8">
                                <div class="textList">
                                    We will invite you to the nearest Kidzee preschool
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-2">
                                <img srcset="../../../assets/img/3.png" loading="lazy" alt="" class="num responsive" align="center">
                            </div>
                            <div class="col-8">
                                <div class="textList">
                                    Visit the School for Campus Tour
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-2">
                                <img srcset="../../../assets/img/4.png" loading="lazy" alt="" class="num responsive" align="center">
                            </div>
                            <div class="col-8">
                                <div class="textList">
                                    Join the Kidzee Family
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <!-- <div class="form_content">
                        <div class="form-group">
                            <input type="text" class="form-control" placeholder="Name">
                        </div>
                        <div class="form-group mt-4">
                            <input type="text" class="form-control" placeholder="Email">
                        </div>
                        <div class="form-group mt-4">
                            <input type="text" class="form-control" placeholder="Mobile">
                        </div>
                        <div class="form-group mt-4">
                            <input type="text" class="form-control" placeholder="Pin Code">
                        </div>
                        <div class="form-group mt-4">
                            <select name="" id="" class="form-control">
                                <option value="">Country</option>
                            </select>
                        </div>
                        <div class="form-group mt-4">
                            <select name="" id="" class="form-control">
                                <option value="">State/District</option>
                            </select>
                        </div>
                        <div class="form-group mt-4">
                            <select name="" id="" class="form-control">
                                <option value="">City</option>
                            </select>
                        </div>
                        <div class="form-group mt-4 captcha_content">
                            <div>
                                <label for="" class="lebel_text">Enter the text as displayed</label>

                                <input type="text" class="form-control" placeholder="Pin Code">
                            </div>
                            <div class="captcha"></div>
                        </div>
                        <div class="form-group mt-4 d-flex justify-content-center">
                            <div class="submit_btn text-center">Submit</div>
                        </div>
                    </div> -->

                    <app-admissions-form></app-admissions-form>
                </div>
            </div>
        </div>
        <div class="count_content mt-5">
            <div class="row">
                <div class="col-md-2">
                    <div class="box">
                        <div class="top_text">100%</div>
                        <div class="text_bottom">support</div>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="box">
                        <div class="top_text">2,000+</div>
                        <div class="text_bottom">preschools</div>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="box">
                        <div class="top_text">750+</div>
                        <div class="text_bottom">cities</div>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="box">
                        <div class="top_text">700+</div>
                        <div class="text_bottom1">elements in
                            starter kit</div>
                    </div>
                </div>
                <div class="col-md-2">
                    <div class="box1">
                        <div class="top_text">most
                            awarded</div>
                        <div class="text_bottom2">preschool</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="aboutUs_content">
            <app-about-us></app-about-us>
        </div>
        <div class="kidzeeSchool_content mt-5">
            <app-kidzee-pree-school></app-kidzee-pree-school>
        </div>
        <div class="faq_content">
            <app-faqs></app-faqs>
        </div>
 <div class="blogs_content">
            <app-blogs></app-blogs>
        </div>
        <div class="testimonial_content">
            <app-testimonial></app-testimonial>
        </div>
    </div>
</section>
