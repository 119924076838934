<section>
    <div class="mainDiv">
        <div class="banner_content">
            <div class="whiteTitle">{{headerTitle}}</div>
            <div class="subMenu_content">
                <app-sub-menu></app-sub-menu>
            </div>
        </div>
        <div class="subContent">
            <div class="row">
                <div class="col-md-3">
                    <div class="pentemindeLogo">
                        <img src="../../../assets/img/pentemind_Img.png" class="pentemindImg center" alt="Pentemind"
                            align="center">
                    </div>
                </div>
                <div class="col-md-9">
                    <div class="pentemindeContent">
                        <!-- <p>The Learning Minds, our pedagogy nurtures the ‘Learning Minds’ in every child, It shapes children’s overall development in a fun and inspiring manner. With an aim to nurture the future global leaders who possess the mindset of ‘We First’ instead of ‘ Me First’, who will be much in demand internationally.</p> -->
                        <p><b>Péntemind</b> - <b>The Learning Minds,</b> our pedagogy nurtures the ‘Learning Minds’ in
                            every child. It shapes the child’s overall development in a fun and inspiring manner.</p>
                        <p>Our pedagogy Péntemind - The Learning Minds, resonates the belief that <b>"the empires of the
                                future will be empires of the mind,"</b> as eloquently stated by Winston Churchill.
                            Inspired by this profound insight, Péntemind is dedicated to nurturing the 'Learning Minds'
                            in every child with a vision of creating life long learners, by fostering their holistic
                            development in an engaging and inspiring manner.</p>
                        <p>Péntemind, aims to shape future global leaders with a mindset of <b>'We First' rather than
                                'Me First,'</b> instilling in them the<b>values of collaboration, empathy, and
                                constructive contribution to society.</b> Through guided discovery, we empower children
                            to become adaptive, critically diligent individuals who are poised to make a positive impact
                            on the world.</p>
                        <p><b>Péntemind is a set of 5 minds which are:</b></p>
                        <p><b>Empathetic Mind:</b> Emotional Balance propogates real learninig.
                            Balancing self-awareness with consideration for others, the empathetic mind promotes both
                            inward reflection and outward compassion, nurturing a sense of empathy and
                            interconnectedness.</p>
                        <p><b>Conscientious Mind:</b> Knowledge Acquisition.<br>
                            Grounded in informed decision-making, the conscientious mind prioritises cognitive
                            precision, equipping children with the discernment and responsibility to make thoughtful
                            choices.</p>
                        <p><b>Focused Mind:</b> Knowledge Retention.<br>
                            This is the mind that approaches tasks logically and persistently strives for mastery
                            through dedicated practice and effort, emphasising both willpower and skill development.</p>
                        <p><b>Analytical Mind:</b> Knowledge Application.<br>
                            The analytical mind is adept at making decisions based on logic, employing a process of
                            observation, analysis, synthesis, and evaluation to tackle challenges and solve problems
                            effectively.</p>
                        <p><b>Inventive Mind:</b> Knowledge Development.<br>
                            Encouraging creative thinking beyond conventional boundaries, the inventive mind fosters
                            imagination and innovation, inspiring children to explore new ideas and possibilities.
                            By cultivating these five minds in tandem, Péntemind aims to empower children with the
                            essential skills, values, and perspectives necessary to thrive in an ever-evolving world.
                            Join us on this transformative journey of learning and growth, where the potential of every
                            child is nurtured and celebrated.</p>
                        <p>To foster the development of the 5 minds, we have taken the approach of <b>Metacognition</b>
                            which emphasise on<b> Learn-Unlearn-Relearn.</b></p>

                        <p><b>Péntemind's Unique Offerings as an Integrated Curriculum</b></p>
                        <p><b>Artsy</b> - A path breaking art program that will teach children not only art but also
                            appreciation of art </p>
                        <p><b>Mental Might</b> - A program that will help children understand diversity, inclusion, and
                            make them aware of the interconnectedness </p>
                        <p><b>Showstopper</b> - A program that will teach children drama from planning to execution </p>
                        <p><b>Tell-a-Tale</b> - A program that provides opportunity to listen to stories that challenge
                            gender stereotypes, understand right from wrong, scribble their own magical world</p>
                        <p><b>Whirl & Twirl</b> - A program that focus on the 5 elements (Body, Action, Time, Space, and
                            Energy) of dance</p>
                        <p><b>Personality Development</b> - A program that will help children enhance children’s
                            self-esteem and self-confidence</p>
                        <p><b>Critical Thinking</b> - A program that will help children develop systematic ways of
                            thinking and reasoning</p>
                        <p><b>Eco-Conscious</b> - A program that teaches children to lead sustainable lives and make
                            eco-friendly choices</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="learningMindImgList">
        <div class="learningTitle text-center">Meet our Learning minds</div>
        <div class="row mt-5">
            <div class="col-md-1"></div>
            <div class="col-md-2">
                <div class="learningMonkey_content">
                    <div class="learningMonkey">
                    <img src="../../../assets/img/monkey11.png" class="monkeyImgList center" alt="">
                </div>
                    <div class="img_contentText mt-3">
                        <p>Goes deep into a topic with focus and
                            single-minded attention.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="learningMonkey_content">
                    <div class="learningMonkey">
                    <img src="../../../assets/img/monkey22.png" class="monkeyImgList center" alt="">
                </div>
                    <div class="img_contentText mt-3">
                        <p>Selects crucial information from the copious amounts available and arrays that information in ways that make sense to self and others.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="learningMonkey_content">
                    <div class="learningMonkey">
                    <img src="../../../assets/img/monkey33.png" class="monkeyImgList center" alt="">
                </div>
                    <div class="img_contentText mt-3">
                        <p>Goes beyond existing knowledge to pose new questions and offer new solutions.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="learningMonkey_content">
                    <div class="learningMonkey">
                    <img src="../../../assets/img/monkey44.png" class="monkeyImgList center" alt="">
                </div>
                    <div class="img_contentText mt-3">
                        <p>Responds sympathetically and constructively to differences among individuals.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="learningMonkey_content">
                    <div class="learningMonkey">
                    <img src="../../../assets/img/monkey55.png" class="monkeyImgList center" alt="">
                </div>
                    <div class="img_contentText mt-3">
                        <p>Lives in harmony with the natural world.</p>
                    </div>
                </div>
            </div>
            <div class="col-md-1"></div>
        </div>
       
      </div> -->
    </div>


</section>