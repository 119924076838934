<!-- <section  class="section">
    <div>
        <div>
            <P>Jigyasa University
(Formerly Himgiri Zee University) has signed MoU with reputed International Universities that helps to prepare young students for life and active participation in a global multicultural society,<br>
             by developing knowledge and understanding the historical, geographic, cultural and relationships<br>
             among world regions. The perspective is imperative to develop the skills, knowledge, and attitudes needed for responsible participation in a democratic society and in a global community in the twenty-first century.</P>
        </div>
    </div>
</section> -->


<div class="section">
    <!-- <header class="header" id="scroll_header">
        <div class="menu_list">
            <ul>
                <li><a href="researchanddevelopment#OverviewOfRAndDCell"> Overview of R&D cell </a></li>
                <li><a href="researchanddevelopment#goalsAndobjective">Major Goals/Objective</a></li>
                <li><a href="researchanddevelopment#aboutDean">About Dean Research</a></li>
                <li><a href="researchanddevelopment#TeamRandD">Team R&D cell</a></li>
            </ul>
        </div>
    </header> -->
    <div class="subMenu_content">
     <app-sub-menu></app-sub-menu>
    </div>
    <div class="sub_content">
        <section id="OverviewOfRAndDCell">
            <div class="overview">
                <div class="title">International Admission</div>
                <div class="para">
                        <P>Jigyasa University has signed MoU with reputed International Universities that helps to prepare young students for life and active participation in a global multicultural society,
                            by developing knowledge and understanding the historical, geographic, cultural and relationships
                            among world regions. The perspective is imperative to develop the skills, knowledge, and attitudes needed for responsible participation in a democratic society and in a global community in the twenty-first century.</P>
                 </div>
            </div>
        </section>
    </div>
</div>