import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HomeComponent } from './components/home/home.component';
import { BlogsComponent } from './components/blogs/blogs.component';
import { HeaderComponent } from './components/common/header/header.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { LegancyComponent } from './components/legancy/legancy.component';
import { NewsComponent } from './components/news/news.component';
import { FaqsComponent } from './components/faqs/faqs.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { KidzeePreeSchoolComponent } from './components/kidzee-pree-school/kidzee-pree-school.component';
import { TestimonialComponent } from './components/testimonial/testimonial.component';
import { AdmissionsComponent } from './components/admissions/admissions.component';
import { FranchiseOpportunityComponent } from './components/franchise-opportunity/franchise-opportunity.component';
import { BlogComponent } from './components/blog/blog/blog.component';
import { AllBlogComponent } from './components/blog/all-blog/all-blog.component';
import { BlogDetailsComponent } from './components/blog/blog-details/blog-details.component';
import { StaticHeaderComponent } from './components/common/static-header/static-header.component';
import { SubMenuComponent } from './components/common/sub-menu/sub-menu.component';
import { PentemindComponent } from './components/pentemind/pentemind.component';
import { PlayGroupComponent } from './components/play-group/play-group.component';
import { NurseryComponent } from './components/nursery/nursery.component';
import { KindergartenComponent } from './components/kindergarten/kindergarten.component';
import { TeacherTrainingComponent } from './components/teacher-training/teacher-training.component';
import { DayCareComponent } from './components/day-care/day-care.component';
import { LocateUsComponent } from './components/locate-us/locate-us.component';
import { OurApproachComponent } from './components/our-approach/our-approach.component';
import { VisionComponent } from './components/vision/vision.component';
import { AwardsAndRecognitionComponent } from './components/awards-and-recognition/awards-and-recognition.component';
import { ProgrammesComponent } from './components/programmes/programmes.component';
import { AdmissionsFormComponent } from './components/admissions-form/admissions-form.component';
import { OurPresenceComponent } from './components/our-presence/our-presence.component';
import { PreschoolInAgartalaComponent } from './components/preschool-in-agartala/preschool-in-agartala.component';
import { CentersInAgartalaComponent } from './components/centers-in-agartala/centers-in-agartala.component';
import { PartnerLandingComponent } from './components/partner-landing/partner-landing.component';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CommonHeaderComponent } from './components/common/common-header/common-header.component';
import { AdmissionThankyouComponent } from './components/thank-you/admission-thankyou/admission-thankyou.component';
import { FranchiseThankyouComponent } from './components/thank-you/franchise-thankyou/franchise-thankyou.component';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgxSpinnerModule } from "ngx-spinner";
import { PagenotfoundComponent } from './components/pagenotfound/pagenotfound.component';
import { InnewsComponent } from './components/innews/innews.component';
// import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { PlacementsComponent } from './components/placements/placements.component';
import { AcademicsComponent } from './components/academics/academics.component';
import { SchoolcourseComponent } from './components/schoolcourse/schoolcourse.component';
import { CoursespecialityComponent } from './components/coursespeciality/coursespeciality.component';
import { CommonprogramsComponent } from './components/common/commonprograms/commonprograms.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import { LeadershipComponent } from './components/leadership/leadership.component';
import { FacultyComponent } from './components/faculty/faculty.component';
import { AllFaqsComponent } from './components/all-faqs/all-faqs.component';
import { AllPlacementsComponent } from './components/all-placements/all-placements.component';
import { AllTestimonialComponent } from './components/all-testimonial/all-testimonial.component';
import { NgImageSliderModule } from 'ng-image-slider';
import { PlacementCompanyComponent } from './components/placement-company/placement-company.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { AllFacultyComponent } from './components/all-faculty/all-faculty.component';
import {MatDialogModule} from '@angular/material/dialog';
import { AllNewsComponent } from './components/all-news/all-news.component';
import { AboutDehradunComponent } from './components/quickLink/about-dehradun/about-dehradun.component';
import { TermAndConditionsComponent } from './components/quickLink/term-and-conditions/term-and-conditions.component';
import { PrivacyPolicyComponent } from './components/quickLink/privacy-policy/privacy-policy.component';
import { CareerComponent } from './components/quickLink/career/career.component';
import { ResearchAndDevlopementCellComponent } from './components/research-and-devlopement-cell/research-and-devlopement-cell.component';
import { IntellectualPropertyComponent } from './components/intellectual-property/intellectual-property.component';
import { PhDAwardedListComponent } from './components/ph-dawarded-list/ph-dawarded-list.component';
import { EventsComponent } from './components/events/events.component';
import { EventdetailsComponent } from './components/eventdetails/eventdetails.component';
import { PatentsAwardsComponent } from './components/patents-awards/patents-awards.component';
import { PoliciesandnoticesComponent } from './components/policiesandnotices/policiesandnotices.component';
import { TrainingandPlacementComponent } from './components/trainingand-placement/trainingand-placement.component';
import { InternshipsJUComponent } from './components/internships-ju/internships-ju.component';
import { PlacementStatisticsComponent } from './components/placement-statistics/placement-statistics.component';
import { ResearchUpdatesComponent } from './components/research-updates/research-updates.component';
import { RefundPolicyComponent } from './components/quickLink/refund-policy/refund-policy.component';
import { DisclaimerComponent } from './components/quickLink/disclaimer/disclaimer.component';
import { CareerCounsellingCellComponent } from './components/career-counselling-cell/career-counselling-cell.component';
import { AlumniComponent } from './components/alumni/alumni.component';
import { NotableAlumniComponent } from './components/notable-alumni/notable-alumni.component';
import { AllNotableAlumniComponent } from './components/all-notable-alumni/all-notable-alumni.component';
import { HostelFacilitiesComponent } from './components/hostel-facilities/hostel-facilities.component';
import { CarrerAtJUComponent } from './components/carrer-at-ju/carrer-at-ju.component';
import { SportsFacilitiesComponent } from './components/sports-facilities/sports-facilities.component';
import { SwimmingPoolComponent } from './components/swimming-pool/swimming-pool.component';
import { MedicalFacilityComponent } from './components/medical-facility/medical-facility.component';
import { StudentsGrievanceRedressalComponent } from './components/students-grievance-redressal/students-grievance-redressal.component';
import { LibraryFacilitiesComponent } from './components/library-facilities/library-facilities.component';
import { TransportFacilitiesComponent } from './components/transport-facilities/transport-facilities.component';
import { CafeteriaComponent } from './components/cafeteria/cafeteria.component';
import { GalleryComponent } from './components/gallery/gallery.component';
import { AllCoordinatorsAlumniComponent } from './components/all-coordinators-alumni/all-coordinators-alumni.component';
import { IQACComponent } from './components/iqac/iqac.component';
import { ProgrammeComponent } from './components/programme/programme.component';
import { InternationalAdmissionComponent } from './components/international-admission/international-admission.component';
import { HiZESTCentreComponent } from './components/hi-zest-centre/hi-zest-centre.component';
import { SortingPipe } from './components/common/sorting.pipe';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { LibraryComponent } from './components/library/library.component';
import { WhyJUComponent } from './components/why-ju/why-ju.component';
import { FlexibilityComponent } from './components/flexibility/flexibility.component';
import { ClubcoordinatorComponent } from './components/clubcoordinator/clubcoordinator.component';
import { NSSComponent } from './components/nss/nss.component';
import { EventcommonComponent } from './components/eventcommon/eventcommon.component';
import { CareerplacementcommonComponent } from './components/careerplacementcommon/careerplacementcommon.component';
import { InternshipandPlacementComponent } from './components/internshipand-placement/internshipand-placement.component';
import { PlacementListComponent } from './components/placement-list/placement-list.component';


interface NgxSpinnerConfig {
  type?: string;
}
@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    BlogsComponent,
    HeaderComponent,
    FooterComponent,
    LegancyComponent,
    NewsComponent,
    LeadershipComponent,
    FaqsComponent,
    AboutUsComponent,
    AllNotableAlumniComponent,
    AllFaqsComponent,
    AllPlacementsComponent,
    KidzeePreeSchoolComponent,
    AllTestimonialComponent,
    TestimonialComponent,
    AdmissionsComponent,
    FranchiseOpportunityComponent,
    BlogComponent,
    AllBlogComponent,
    AllNewsComponent,
    FacultyComponent,
    BlogDetailsComponent,
    StaticHeaderComponent,
    SubMenuComponent,
    PentemindComponent,
    PlayGroupComponent,
    NurseryComponent,
    KindergartenComponent,
    TeacherTrainingComponent,
    DayCareComponent,
    LocateUsComponent,
    OurApproachComponent,
    VisionComponent,
    AwardsAndRecognitionComponent,
    ProgrammesComponent,
    AdmissionsFormComponent,
    OurPresenceComponent,
    PreschoolInAgartalaComponent,
    CentersInAgartalaComponent,
    PartnerLandingComponent,
    CommonHeaderComponent,
    FranchiseThankyouComponent,
    AdmissionThankyouComponent,
    PagenotfoundComponent,
    InnewsComponent,
    // PrivacyPolicyComponent,
    PlacementsComponent,
    AcademicsComponent,
    SchoolcourseComponent,
    CoursespecialityComponent,
    CommonprogramsComponent,
    PlacementCompanyComponent,
    AllFacultyComponent,
    AboutDehradunComponent,
    TermAndConditionsComponent,
    PrivacyPolicyComponent,
    CareerComponent,
    ResearchAndDevlopementCellComponent,
    IntellectualPropertyComponent,
    PhDAwardedListComponent,
    EventsComponent,
    EventdetailsComponent,
    PoliciesandnoticesComponent,
    PatentsAwardsComponent,
    TrainingandPlacementComponent,
    InternshipsJUComponent,
    PlacementStatisticsComponent,
    ResearchUpdatesComponent,
    RefundPolicyComponent,
    DisclaimerComponent,
    CareerCounsellingCellComponent,
    AlumniComponent,
    NotableAlumniComponent,
    HostelFacilitiesComponent,
    CarrerAtJUComponent,
    SportsFacilitiesComponent,
    SwimmingPoolComponent,
    MedicalFacilityComponent,
    StudentsGrievanceRedressalComponent,
    LibraryFacilitiesComponent,
    TransportFacilitiesComponent,
    CafeteriaComponent,
    GalleryComponent,
    HiZESTCentreComponent,
    AllCoordinatorsAlumniComponent,
    IQACComponent,
    ProgrammeComponent,
    InternationalAdmissionComponent,
    SortingPipe,
    ContactUsComponent,
    LibraryComponent,
    WhyJUComponent,
    FlexibilityComponent,
    ClubcoordinatorComponent,
    NSSComponent,
    EventcommonComponent,
    CareerplacementcommonComponent,
    InternshipandPlacementComponent,
    PlacementListComponent,
    PlacementListComponent,
    InternshipandPlacementComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatInputModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    NgxSpinnerModule,MatDialogModule,
    MatExpansionModule,
    MatAutocompleteModule,
    NgImageSliderModule,
    CarouselModule,
    NgxSpinnerModule.forRoot({ type: 'ball-scale-multiple' }),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}

