import { Component, Input } from '@angular/core';
import { ProjectSeoService } from 'src/app/services/projectseo.service';
import * as AOS from 'aos';
@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.css']
})
export class FaqsComponent {
  subscriptionnav: any;
  spinner:boolean=false;
  //faqdata: any;
  @Input() faqdata:any=[];
  @Input() fromslug:string="";
  faq:any=[];
  faqcategory:any=[];
  public isCollapsed: boolean [] = [];
  showBody:boolean=false;
  valCheck:boolean=false
  
 constructor(private projectService: ProjectSeoService,){}

  ngOnInit(): void {
    AOS.init();
    if(this.faqdata){
      this.faq=this.faqdata[0].data;
      this.faqcategory=this.faqdata[0].AssignCategory;
    }   

    this.subscriptionnav = this.projectService
      .onFaqsMessage()
      .subscribe((message) => {
        this.spinner=false
        if (message) {
      this.faqdata = message.text
      if(this.faqdata){
        this.faq=this.faqdata[0].data;
        this.faqcategory=this.faqdata[0].AssignCategory;
        }  
        }
      });
  }


  getInputVal(val:any){
// console.log('val',val)
  }
  filtercategory(id:number){
    if(id!=0){
      this.faq=this.faqdata[0].data.filter((dt:any)=>{
        return dt.category.includes(id)
      }).map((obj:any)=>{
        return obj;
      })
    }
    else{
      this.faq=this.faqdata[0].data;
    }
    
  }
}
