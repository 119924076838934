<section>
    <div>
        <div>
               <div class="section">
               
                <div class="subMenu_content">
                    <app-sub-menu></app-sub-menu>
                </div>
                <div class="sub_content">
                    <section id="OverviewOfRAndDCell">
                        <div class="overview">
                            <div class="title">Flexibility</div>
                            <div class="para">
                                <div class="main_div">
                                <div class="listSearchArea">
                                    <div class="listSearchArea d-flex justify-content-between align-items-center">
                                        <ul class="nav nav-tabs tabs-buttons mt-3" role="tablist">
                                          <li class="nav-item" role="presentation" id="boardOfGovernorsTab0">
                                          <button id="click_btn" class="nav-link active"
                                              data-bs-toggle="tab" data-bs-target="#boardOfGovernors0" type="button"
                                              role="tab" aria-labelledby="board Of Governors Tab 0" aria-selected="true"
                                              (click)="filtercategory('Elective courses')">Elective courses</button>
                                      </li>
                                            <li class="nav-item" role="presentation" id="boardOfGovernorsTab1">
                                                <button id="click_btn" class="nav-link"
                                                    data-bs-toggle="tab" data-bs-target="#boardOfGovernors0" type="button"
                                                    role="tab" aria-labelledby="board Of Governors Tab 0" aria-selected="true"
                                                    (click)="filtercategory('Degree with Specialization')">Degree with Specialization</button>
                                            </li>
                                            <li class="nav-item" role="presentation" id="boardOfGovernorsTab2">
                                                <button id="click_btn" class="nav-link"
                                                    data-bs-toggle="tab" data-bs-target="#boardOfGovernors0" type="button"
                                                    role="tab" aria-labelledby="board Of Governors Tab 0" aria-selected="true"
                                                    (click)="filtercategory('Repeating a course')">Repeating a course</button>
                                            </li>
                                            <li class="nav-item" role="presentation" id="boardOfGovernorsTab3">
                                                <button id="click_btn" class="nav-link"
                                                    data-bs-toggle="tab" data-bs-target="#boardOfGovernors0" type="button"
                                                    role="tab" aria-labelledby="board Of Governors Tab 0" aria-selected="true"
                                                    (click)="filtercategory('Withdrawal and Substitution of course')">Withdrawal and Substitution of course</button>
                                            </li>
                                        </ul>
                                    </div>     
                                    <div *ngIf="categorystring=='Elective courses'" class="mt-4">
                                       <p> University offers a pool of electives in all disciplines. Student is permitted to choose the elective courses of his choice within 
                                        his own discipline and across the discipline. University also offers flexibility for undergraduate student to register in higher 
                                        degree courses subjected to restrictive conditions.</p>

                                        <p>At JU University we offer different types of elective courses. An elective course done within the specialization stream of the 
                                            discipline is known as Specialization elective. An elective course done within the discipline other than specialization streams 
                                            is known as Discipline elective and courses which are of interdisciplinary nature without having any prerequisites and can be 
                                            done by any student at any level is known as University elective. To encourage managerial skills in the student and to 
                                            inculcate entrepreneurship skills in the student university offers a pool of electives in management courses. 
                                            These courses are known as Management electives.</p>
                                        
                                        <p>Student is permitted to chose a set of elective courses (within and across the discipline) both in undergraduate and post 
                                            graduate programmes.</p>
                                    </div>
                                    <div *ngIf="categorystring=='Degree with Specialization'">
                                        <p>
                                            A student will be awarded Degree with Specialization if he completes a set of courses from a particular stream within the 
                                            discipline. At JU, a student is permitted to register for 8 elective courses is given with a pool of elective courses within and 
                                            across the discipline. A student by careful selection of electives within a particular stream can get degree with specialization. 
                                            That is, a student can get Degree with Specialization during regular programme, without overloading himself.
                                        </p>
                                    </div>
                                    <div *ngIf="categorystring=='Repeating a course'">
                                        <p>A student can repeat a course for two reasons:</p>

                                        <p>Repeating a course to improve the grade and, For clearing the course where he got F grade</p>
                                        <ul>
                                        <li>When a student repeats a course for improving grade, the highest grade obtained by the student will be considered.</li>
                                        <li>While repeating a course the student will appear only for the evaluation components and need not have to attend classes.</li>
                                        <li>Repeating a course implies that the student will re-register for the course. Keeping in mind that the student has already 
                                            attended the course, the student is exempted from attending the regular classes. Marks obtained by the student in 
                                            attendance in his first attempt will be carried forward. However, the student must attend all the evaluation components. 
                                            However, a student repeating a course after obtaining NP Report has to attend all the classes.</li>
                                        <li>Student repeating a course must ensure that the dates of evaluation components do not clash with any of his courses in 
                                            the regular semester. In such cases student is not permitted to repeat the course.</li>
                                        <li>Student can also repeat a course during summer term if course(s) are offered.</li>
                                        <li>Head of the Department concerned can counsel the student in ACB to repeat a course to improve his grade so that he 
                                            can come out of ACB purview. However, decision taken by the student in this regard shall be the final. Student who is 
                                            advised to repeat a course / semester does not mean the graduation period is extended</li>
                                        <li>However, a student who has completed the formalities of graduation cannot repeat a course.</li>
                                        <li>A Student cannot repeat courses like Industrial Practice, Thesis, Summer internship or any other courses which are 
                                            conducted as vocational courses.</li>
                                        <li>For repeating a course, the student has to pay the pre-requisite fee as prescribed by the University.</li>
                                        </ul>
                                    </div>
                                    <div *ngIf="categorystring=='Withdrawal and Substitution of course'">
                                        <p>A Student is permitted to withdraw from elective course within one week after the commencement of the semester and 
                                            substitute it with another elective course subjected to the approval of HOD / Dean of the School.</p>
                                    </div>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>

        </div>
    </div>
</section>
