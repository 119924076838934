<div class="section">
    <!-- <header class="header" id="scroll_header">
        <div class="menu_list">
            <ul>
                <li><a href="researchanddevelopment#OverviewOfRAndDCell"> Overview of R&D cell </a></li>
                <li><a href="researchanddevelopment#goalsAndobjective">Major Goals/Objective</a></li>
                <li><a href="researchanddevelopment#aboutDean">About Dean Research</a></li>
                <li><a href="researchanddevelopment#TeamRandD">Team R&D cell</a></li>
            </ul>
        </div>
    </header> -->
    <div class="subMenu_content">
        <app-sub-menu></app-sub-menu>
    </div>
    <div class="sub_content">
        <section id="OverviewOfRAndDCell">
            <div class="overview">
                <div class="title">Medical Facilities</div>
                <div class="para">
                    <p>Jigyasa University
(Formerly Himgiri Zee University) provides health care facility to all students and staff by providing First-Aid and other basic medical facilities along with:</p>
                    <ul>
                        <li>Visiting Physician ( avail on call 24X7)</li>
                        <li>In-campus clinic for All staff and students</li>
                        <li>Resident nurse available 24X7</li>
                        <li>University Ambulance Available 24X7</li>
                        <li><b>ICICI</b> lombard Insurance for all staff & students</li>
                        <li>Medical Insurance to all hostel students</li>
                        <li>Free Medicine as per prescription of Doctor</li>
                    </ul>
                    <p>In case of emergency the students are taken to authorized & recognized hospital for treatment after giving first-aid.</p>
                </div>
            </div>
        </section>
    <section>
        <div class="majorGoals">
            <div class="para mt-5">
                <!-- <div class="title">Team IPR Cell</div> -->
                <div class="row mt-2">
                    <div class="col-md-6 mt-3">
                        <div class="facultyWrapperItem">
                            <div class="faculty-banner">
                                <div class="row">
                                    <div class="col-md-5">
                                        <img src="../../../assets/img/jigyasa/kapil.png" class="img-responsive">
                                    </div>
                                    <div class="col-md-7">
                                        <div class="profile">
                                            <h3>Dr. Kapil Arora</h3>
                                            <p>Visiting Physician - Jigyasa University
(Formerly Himgiri Zee University)
                                                MBBS (UKMC Regn 4945)</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="facultyContentWrapper_para">
                                <h2 class="titleWrap">About</h2>
                                <p>various other hospitals of Dehradun He is medical consultant of Reserve Bank of India, Uttarakhand and National Bank of Agriculture and Rural Development, Uttarakhand since 2012. Dr. Arora is associated as Guest lecturer in Department of Clinical Sciences in Sardar Bhagwan Singh University. He served as medical consultant in Apollo Hospital, Dehradun from 2010 to 2017.</p>
                                <p>Associated as medical consultant with few reputed boarding schools like Tulas International School, Dehradun and Pestle Weed School, Dehradun since 2010. He is Visting Physician at. Jigyasa University
(Formerly Himgiri Zee University), Dehradun since 2019.</p>
                                <p>Dr. Arora has around 14 years of experience as a physician as well as academician.</p>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6 mt-3">
                        <div class="facultyWrapperItem">
                            <div class="faculty-banner">
                                <div class="row">
                                    <div class="col-md-5">
                                        <img src="../../../assets/img/jigyasa/santoshi.png" style="margin:5px"
                                            class="img-responsive">
                                    </div>
                                    <div class="col-md-7">
                                        <div class="profile">
                                            <h3>Ms.Santoshi Kandari</h3>
                                            <p>Staff nurse <br>Jigyasa University
(Formerly Himgiri Zee University)</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="facultyContentWrapper_para">
                                <h2 class="titleWrap">About</h2>
                                <p class="mt-2">I am Santoshi Kandari Working as staff nurse in Jigyasa University
(Formerly Himgiri Zee University) I am done axillary nurse midwife from ANM Training Centre Pauri Garhwal I did my internship from District Hospital Pauri Garhwal Experience.. -- I have worked as staff nurse in Aastha Hospital (cardio and Gastro specialist) for 1 year after that I worked as a staff nurse in Healing touch hospital (multispeciality hospital) for 1 year</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </section>
        <section>
            <div class="row py-5">
                <div class="col-md-12">
                    <div class="gallery_img">
                        <a data-fancybox="" href="../../../assets/img/jigyasa/hzu-medical.jpg">
                        <img src="../../../assets/img/jigyasa/hzu-medical.jpg" width="100%" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>