<section>

    <div>
        <!-- <ul class="list-group  list-group-horizontal d-flex justify-content-start align-items-center">
            <li class="list-group-item" *ngFor="let dt of programsdata;let i = index" value="{{dt.ProgramsType}}">
          
                <button id="click_btn" class="nav-link" [ngClass]="(i === 1) ? 'active' : ''"
                data-bs-toggle="tab" data-bs-target="#boardOfGovernors0" (click)="getcourses(dt.ProgramsType)"> {{dt.ProgramsType}}</button>
            </li>
        </ul>-->
        <ul class="nav nav-tabs tabs-buttons" role="tablist">
            <li class="nav-item" role="presentation" id="boardOfGovernorsTab0"
                *ngFor="let dt of programsdata ;let i=index" value="{{dt.ProgramsType}}">
                <button id="click_btn" class="nav-link" [ngClass]="(i === 0) ? 'active' : ''" data-bs-toggle="tab"
                    data-bs-target="#boardOfGovernors0" type="button" role="tab"
                    aria-labelledby="board Of Governors Tab 0" aria-selected="true"
                    (click)="getcourses(dt.ProgramsType)">{{dt.ProgramsType}}</button>
            </li>
            <li>
                <div class="container">
                    <form role="search" method="get" class="search-form" action="">
                        <label>
                            <input type="search" class="search-field" [ngModel]="searchtext"
                                (ngModelChange)="getdata($event)" placeholder="Search …" value="" name="s"
                                title="Search for:" />
                        </label>
                        <input type="submit" class="search-submit" value="Search" />
                    </form>
                </div>
            </li>
            <ul class="search_list list-group list-group-horizontal-lg">
                <li class="list-group-item" *ngFor="let a of programs" value="{{a.course_id}}"><a
                        href="{{a.slug}}"> {{a.name}}</a></li>
            </ul>
        </ul>
        <ul class="nav nav-tabs tabs-buttons" role="tablist">
            <li class="nav-item" role="presentation" id="boardOfGovernorsTab0" *ngFor="let a of courses;let i = index"
                value="{{a.name}}">
                <button id="click_btn" class="nav-link" [ngClass]="(i === 0) ? 'active' : ''" data-bs-toggle="tab"
                    data-bs-target="#boardOfGovernors0" type="button" role="tab"
                    aria-labelledby="board Of Governors Tab 0" aria-selected="true"
                    (click)="getdescription(a.name)">{{a.name}}</button>
            </li>
        </ul>
        <div *ngFor="let b of descriptiondt">
            <div class="description">
                <p [innerHTML]="b.Description"></p>
            </div>
            <mat-accordion>
                <mat-expansion-panel *ngFor="let c of b.speciality">
                    <mat-expansion-panel-header>
                        {{c.name}}
                    </mat-expansion-panel-header>
                    <div class="description">
                        <span [innerHTML]="c.Description"></span>
                    </div>
                </mat-expansion-panel>
            </mat-accordion>
            <div class="row">
                <div class="col-12 d-flex justify-content-end align-items-center">
            <a href="{{currentslug}}" class="view_sm_btn " target="_blank">Know More</a>
        </div>
        </div>
        </div>
      
    </div>
</section>
<ngx-spinner type="ball-climbing-dot"></ngx-spinner>