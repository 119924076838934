<section>
  <div class="title" data-aos="fade-up"
  data-aos-duration="3000">Blogs</div>
  <div class="row">
      <div class="col-md-4">
          <div class="card" data-aos="zoom-in">
              <div class="row">
                  <div class="col-2">
                      <div class="profile_img mt-3"></div>
                  </div>
                  <div class="col-10">
                      <div class="content-blog-card">
                          <ul class="ps-0 mb-0 list-unstyled cat">
                              <!-- <li><a href="https://themes.envytheme.com/falar/category/career/">Career</a></li> -->
                          </ul>
                          <div class="card_title">Nominate
                              a teacher who made a difference through Faral’s Greatest High School Teacher
                              contest
                          </div>
                          <ul class="blog-list d-flex justify-content-between mt-3">
                              <li>December 18, 2023</li>
                              <li>2 min read</li>
                          </ul>
                          <span class="readMore" routerLink="/blogDetails">Read More <i class="fa fa-long-arrow-right"></i></span>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="col-md-4">
          <div class="card" data-aos="zoom-in">
              <div class="row">
                  <div class="col-2">
                      <div class="profile_img mt-3"></div>
                  </div>
                  <div class="col-10">
                      <div class="content-blog-card">
                          <ul class="ps-0 mb-0 list-unstyled cat">
                              <li><a href="https://themes.envytheme.com/falar/category/career/">Career</a></li>
                          </ul>
                          <div class="card_title">Nominate
                              a teacher who made a difference through Faral’s Greatest High School Teacher
                              contest
                          </div>
                          <ul class="blog-list d-flex justify-content-between mt-3">
                              <li>December 18, 2023</li>
                              <li>2 min read</li>
                          </ul>
                          <span class="readMore" routerLink="/blogDetails">Read More <i class="fa fa-long-arrow-right"></i></span>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="col-md-4">
          <div class="card" data-aos="zoom-in">
              <div class="row">
                  <div class="col-2">
                      <div class="profile_img mt-3"></div>
                  </div>
                  <div class="col-10">
                      <div class="content-blog-card">
                          <ul class="ps-0 mb-0 list-unstyled cat">
                              <li><a href="https://themes.envytheme.com/falar/category/career/">Career</a></li>
                          </ul>
                          <div class="card_title">Nominate
                              a teacher who made a difference through Faral’s Greatest High School Teacher
                              contest
                          </div>
                          <ul class="blog-list d-flex justify-content-between mt-3">
                              <li>December 18, 2023</li>
                              <li>2 min read</li>
                          </ul>
                          <span class="readMore" routerLink="/blogDetails">Read More <i class="fa fa-long-arrow-right"></i></span>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      <div class="d-flex justify-content-end">
        <button class="btn view_sm_btn mt-4" routerLink="/blogs">View All</button>
      </div>
  </div>
</section>